import { getTotalPrice } from "../../helper/cartHelper";
import { useAppSelector } from "../../store";
import EnquiryList from "../Enquiry/EnquiryList";

const CustomerCart = () => {
  const { activeSession: siteData } = useAppSelector(
    (state) => state.activitySession
  );
  return (
    <div>
      <EnquiryList
        isCustomerView={true}
        kartTotalPrice={getTotalPrice(siteData)}
      />
    </div>
  );
};

export default CustomerCart;
