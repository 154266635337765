import React, {
  LegacyRef,
  RefObject,
  useEffect,
  useRef,
  useState,
} from "react";
import { Modal, Placeholder } from "react-bootstrap";
import { Form } from "react-bootstrap";
import FollowUpListItem from "./FollowUpListItem";
import { activityAxios } from "../../axios/activityAxios";
import {
  EnumFollowUpMode,
  IEnquiryFollowUp,
} from "../../activitiescommonfnb/schemas/enquiry.schema";
import { toast } from "react-toastify";

const FollowUpPopup: React.FC<{
  getEnquiries: () => void;
  showFollowUp: boolean;
  onHide: () => void;
  enquiryId: number;
}> = (props) => {
  const followUpContainerRef: LegacyRef<HTMLDivElement> = useRef(null);
  const scrollToComponent = () => {
    if (followUpContainerRef && followUpContainerRef.current) {
      followUpContainerRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  const initialFollowUpValue: IEnquiryFollowUp = {
    enquiryId: props.enquiryId,
    followUpMessage: "",
    followUpMode: EnumFollowUpMode.CALL,
  };

  const [followUp, setFollowUp] =
    useState<IEnquiryFollowUp>(initialFollowUpValue);

  const [allFollowUps, setAllFollowUps] = useState<IEnquiryFollowUp[]>([]);
  const [loadFollowUp, setLoadFollowUp] = useState(false);

  const createOrEditFollowUp = async () => {
    if (!followUp.followUpMessage.trim()) {
      toast.error("Please enter a follow-up message");
      return;
    }

    const apiRes = await activityAxios.post(
      "/enquiry/createOrEditEnquiryFollowUp",
      { enquiryFollowUpData: followUp }
    );
    if (apiRes.data?.success) {
      toast.success("Follow-up saved");
      setFollowUp(initialFollowUpValue);
      props.getEnquiries();
      getAllFollowUps();
    } else {
      toast.error(apiRes?.data?.errorMessage);
    }
  };

  const handleInpChange = (name: string, value: any) => {
    setFollowUp((old) => {
      return { ...old, [name]: value };
    });
  };

  const getAllFollowUps = async () => {
    setLoadFollowUp(true);
    const apiRes = await activityAxios.post("/enquiry/getAllEnquiryFollowUps", {
      enquiryId: props.enquiryId,
    });
    if (apiRes.data?.success && apiRes.data?.result.length) {
      setAllFollowUps(apiRes.data?.result);
    }
    setLoadFollowUp(false);
  };

  useEffect(() => {
    if (props.showFollowUp) getAllFollowUps();
  }, [props.showFollowUp]);

  return (
    <div>
      <Modal
        show={props.showFollowUp}
        onHide={props.onHide}
        animation={false}
        centered
        scrollable
      >
        <Modal.Header closeButton>
          <Modal.Title className="h6">Follow Up</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div
            className="bg-light border rounded p-3"
            ref={followUpContainerRef}
          >
            <label htmlFor="" className="small">
              Follow Up message
            </label>
            <textarea
              onChange={(e) => {
                handleInpChange("followUpMessage", e.target.value);
              }}
              value={followUp.followUpMessage}
              className="small form-control"
              rows={2}
            />
            <div className="d-flex align-items-center justify-content-between mt-2">
              <div>
                <Form.Check
                  inline
                  label="Call"
                  name="group1"
                  type={"radio"}
                  checked={followUp.followUpMode === EnumFollowUpMode.CALL}
                  onChange={(e) => {
                    handleInpChange("followUpMode", EnumFollowUpMode.CALL);
                  }}
                />
                <Form.Check
                  checked={followUp.followUpMode === EnumFollowUpMode.MESSAGE}
                  inline
                  label="Message"
                  name="group1"
                  type={"radio"}
                  onChange={(e) => {
                    handleInpChange("followUpMode", EnumFollowUpMode.MESSAGE);
                  }}
                />
              </div>
              <div className="text-end">
                <button
                  className="btn btn-secondary btn-sm px-3 me-2"
                  onClick={() => setFollowUp(initialFollowUpValue)}
                >
                  Clear
                </button>
                <button
                  className="btn btn-primary btn-sm px-3"
                  onClick={createOrEditFollowUp}
                >
                  {followUp.id ? "Edit" : "Save"}
                </button>
              </div>
            </div>
          </div>
          <div className="followUpList mt-3">
            {allFollowUps && allFollowUps.length > 0 ? (
              allFollowUps.map((followUp) => {
                return (
                  <FollowUpListItem
                    scrollToTop={() => {
                      scrollToComponent();
                    }}
                    followUpData={followUp}
                    key={followUp.id}
                    editFollowUp={(followUp) => setFollowUp(followUp)}
                  />
                );
              })
            ) : loadFollowUp ? (
              <>
                <div className="border p-3 rounded mb-2">
                  <Placeholder as="p" animation="glow">
                    <Placeholder xs={12} />
                  </Placeholder>
                </div>
                <div className="border p-3 rounded mb-2">
                  <Placeholder as="p" animation="glow">
                    <Placeholder xs={12} />
                  </Placeholder>
                </div>
              </>
            ) : (
              <div className="text-danger border small rounded text-center border-danger p-3">
                No FollowUps
              </div>
            )}
            {/* 
            {loadFollowUp && (
              <>
                <div className="border p-3 rounded mb-2">
                  <Placeholder as="p" animation="glow">
                    <Placeholder xs={12} />
                  </Placeholder>
                </div>
                <div className="border p-3 rounded mb-2">
                  <Placeholder as="p" animation="glow">
                    <Placeholder xs={12} />
                  </Placeholder>
                </div>
              </>
            )} */}
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default FollowUpPopup;
