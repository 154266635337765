import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { IUserData } from "../activitiescommonfnb/types/User.interface";

const initialState: {
  data: IUserData | null;
} = {
  data: null,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuthData: (state, action: PayloadAction<IUserData | null>) => {
      // localStorage.setItem("authData", JSON.stringify(action.payload));
      state.data = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setAuthData } = authSlice.actions;

export const selectAuthData = (state: RootState) => state.authData.data;

export default authSlice.reducer;
