import React, { FormEvent, useEffect, useState } from "react";
import { IntlTelInputWrapper } from "../../commonUi/IntlTelInputWrapper";
import { useNavigate, useSearchParams, Link } from "react-router-dom";
import { toast } from "react-toastify";
import loginpageStyle from "./loginpageStyle.module.scss";
import { activityAxios } from "../../axios/activityAxios";
import { IJSONResponse } from "../../activitiescommonfnb/types/common.interface";
import { setAuthData } from "../../slice/auth.slice";
import { useAppDispatch } from "../../store";

import loginPageImage from "../../assets/images/loginSignup.jpg";
import { useRecaptcha } from "../../hooks/useGoogleRechaptcha";
import { ReCaptchaWrapper } from "../../components/common/ReCaptchaWrapper";
import { LoadingButton } from "../../commonUi/LoadingButton";

type Props = {};

const Login = (props: Props) => {
  return (
    <div className={loginpageStyle.loginPage}>
      <div className="container">
        <div className="d-flex justify-content-center ">
          <div className="image">
            <img
              className={`img-fluid ${loginpageStyle.loginSideImage}`}
              src={loginPageImage}
              alt="login image"
            />
          </div>

          <ReCaptchaWrapper>
            <LoginForm />
          </ReCaptchaWrapper>
        </div>
      </div>
    </div>
  );
};

const LoginForm = () => {
  const dispatch = useAppDispatch();
  const [phoneNumberDetails, setPhoneNumberDetails] = useState<{
    phoneNumber: string;
    phoneCode: string;
    isValid: boolean;
  }>();
  const [searchParams] = useSearchParams();
  const [showOTPInput, setShowOTPInput] = useState(false);
  const [sendOtpLoading, setSendOtpLoading] = useState(false);
  const [verifyOtpLoading, setVerifyOtpLoading] = useState(false);
  const [otp, setOtp] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [referenceNumber, setReferenceNumber] = useState<string | null>(null);
  const { getRecaptchaToken } = useRecaptcha();
  const [timeLeft, setTimeLeft] = useState<number>(0);
  useEffect(() => {
    const timer = setTimeout(() => {
      if (timeLeft) {
        setTimeLeft((old) => {
          if (old) {
            old -= 1;
          }
          return old;
        });
      }
    }, 1000);

    return () => clearTimeout(timer);
  }, [timeLeft]);

  const minutes = Math.floor(timeLeft / 60);
  const seconds = timeLeft % 60;

  const navigate = useNavigate();

  useEffect(() => {
    if (searchParams.get("isPhNoValid") === "true") {
      setShowOTPInput(true);
    }
  }, [searchParams]);

  const sendOtp = async (e: FormEvent<HTMLElement>) => {
    try {
      setSendOtpLoading(true);
      e.preventDefault();
      if (!phoneNumberDetails?.isValid) {
        throw new Error("Please enter a valid phone number.");
      }
      const recaptchaToken = await getRecaptchaToken();
      if (!recaptchaToken) {
        throw new Error("recaptcha token is required");
      }
      const apiRes = await activityAxios.post<IJSONResponse<any>>(
        "/auth/sendOtp",
        {
          phoneCode: phoneNumberDetails.phoneCode,
          phoneNumber: phoneNumberDetails.phoneNumber,
        },
        {
          headers: {
            "captcha-token": recaptchaToken,
          },
        }
      );
      if (apiRes?.data.success) {
        setShowOTPInput(true);
        setTimeLeft(90);
        toast.success("Otp sent successfully");
        setReferenceNumber(apiRes.data.result.referenceNumber);
        navigate(`/login?isPhNoValid=${phoneNumberDetails?.isValid}`);
      } else {
        throw new Error(apiRes?.data.errorMessage);
      }
    } catch (error: any) {
      console.log(error);

      toast.error(error.message || "Something went wrong");
    }
    setSendOtpLoading(false);
  };

  const login = async (e: any) => {
    try {
      setVerifyOtpLoading(true);
      e.preventDefault();
      const recaptchaToken = await getRecaptchaToken();
      if (!recaptchaToken) {
        throw new Error("recaptcha token is required");
      }
      if (phoneNumberDetails) {
        const apiRes = await activityAxios.post(
          "auth/login",
          {
            phoneCode: phoneNumberDetails.phoneCode,
            phoneNumber: phoneNumberDetails.phoneNumber,
            otp,
          },
          {
            headers: {
              "captcha-token": recaptchaToken,
            },
          }
        );
        if (apiRes?.data.success) {
          dispatch(setAuthData(apiRes.data.result));
          navigate(`/`);
        } else {
          throw new Error(apiRes?.data.errorMessage);
        }
      }
    } catch (error: any) {
      setErrorMessage(error.message || "Something went wrong");
      toast.error(error.message || "Something went wrong");
    }
    setVerifyOtpLoading(false);
  };
  return (
    <form
      className={loginpageStyle.loginFormContainer}
      onSubmit={showOTPInput ? login : sendOtp}
    >
      <div className="d-flex">
        {showOTPInput && (
          <div
            role="button"
            className="me-2"
            onClick={() => {
              setErrorMessage("");
              setShowOTPInput(false);
              setReferenceNumber(null);
              navigate(`/login`);
            }}
          >
            <i className="fa-solid fa-arrow-left"></i>
          </div>
        )}
        <b>{showOTPInput ? "Go Back" : "Login"}</b>
      </div>

      {showOTPInput ? (
        <div className="mt-4">
          <label htmlFor="" className="small">
            Enter OTP
          </label>
          <input
            type="text"
            className="form-control"
            placeholder=""
            onChange={(e) => {
              setOtp(e.target.value);
            }}
          />
          <p className="small text-danger p-0 m-0">{errorMessage}</p>
          <br />
          <div className="text-end">
            <LoadingButton
              loading={verifyOtpLoading}
              btnType="submit"
              btnClass="btn-sm btn-success w-100"
            >
              Verify
            </LoadingButton>

            {referenceNumber && showOTPInput && (
              <div
                className="alert alert-warning small py-1 text-center mt-2"
                role="alert"
              >
                OTP sent to your mobile with ref. number:{" "}
                <strong>{referenceNumber}</strong>.
              </div>
            )}

            <p role="button" className={`text-center w-100 small mt-3  }`}>
              {timeLeft > 0 ? (
                <span>
                  Resend OTP {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
                </span>
              ) : (
                <div className="d-flex align-items-center justify-content-center">
                  <span
                    role="button"
                    className="link-primary me-2"
                    onClick={sendOtp}
                  >
                    Resend OTP
                  </span>{" "}
                  {sendOtpLoading && (
                    <>
                      <div
                        className="spinner-grow spinner-grow-sm me-1 "
                        role="status"
                      ></div>
                      <div
                        className="spinner-grow spinner-grow-sm me-1"
                        role="status"
                      ></div>
                      <div
                        className="spinner-grow spinner-grow-sm "
                        role="status"
                      ></div>
                    </>
                  )}
                </div>
              )}
            </p>
          </div>
        </div>
      ) : (
        <>
          <div className="my-3">
            <IntlTelInputWrapper
              defaultValue={phoneNumberDetails?.phoneNumber}
              label="Phone Number"
              onChange={(phoneNumber, phoneCode, isValid) => {
                setPhoneNumberDetails((old) => {
                  return { phoneNumber, phoneCode, isValid };
                });
              }}
            />
          </div>
          <div className="text-end">
            <LoadingButton
              loading={sendOtpLoading}
              btnClass="btn-sm btn-primary w-100"
              btnType="submit"
            >
              Send OTP
            </LoadingButton>
          </div>

          <div className="my-4 text-center">
            <p className="small">Don't have an account ? </p>
            <Link to={"/signUp"}>
              <span className="text-decoration-underline small">Sign Up</span>
            </Link>
          </div>
        </>
      )}
    </form>
  );
};

export default Login;
