import { IUserData } from "./User.interface";

export enum EnumReminderFrequency {
  ONCE = "once",
  HOURLY = "hourly",
  EVERY_SIX_HOUR = "every_six_hour",
  DAILY = "daily",
}

export interface ITask {
  id: number;
  userId: number;
  title: string;
  description: string;
  deadline?: Date | null;
  enquiryId?: number | null;
  createdByUserId: number;
  reminderStartDate?: Date | null;
  reminderStartTime?: string | null;
  reminderEndTime?: string | null;
  reminderFrequency?: EnumReminderFrequency | null;
  isPhotoProofRequired: boolean;
  isDone: boolean;
  user?: IUserData;
  createdByUserData?: IUserData;
  createdAt: Date;
  updatedAt: Date;
}
