import React from "react";
import landingPage from "./DubaiPageStyle.module.scss";
import heroSideImage from "../../assets/images/dubaiHeroImage.png";
// import Swiper from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import introSideImage from "../../assets/images/introSideImage.png";
import inclusionSideImage from "../../assets/images/inclusionSideImage.png";
import hotelIcon from "../../assets/images/icons/hotel.png";
import activity from "../../assets/images/icons/activity.png";
import transferIcon from "../../assets/images/icons/transfer.png";
import visaIcon from "../../assets/images/icons/visa.png";
import dolphin from "../../assets/images/addOnActivity/dolphin.png";
import burjKhalifa from "../../assets/images/addOnActivity/burjKhalifa.png";
import museum from "../../assets/images/addOnActivity/museum.png";
import palmView from "../../assets/images/addOnActivity/palmView.png";
import dubaiFrame from "../../assets/images/addOnActivity/dubaiFrame.png";
import cityMax from "../../assets/images/hotels/cityMax.png";
import Lavender from "../../assets/images/hotels/lavender.jpg";
import ibis from "../../assets/images/hotels/ibis.png";
import cityTour from "../../assets/images/activity/cityTour.jpg";
import desersafari from "../../assets/images/activity/desertSafari.jpg";
import dhowCruise from "../../assets/images/activity/dhowCruise.png";
import { Link } from "react-router-dom";

const DubaiPage = () => {
  return (
    <div className={landingPage.dubaiLandingPage}>
      <section className={landingPage.heroSection}>
        <div className="container">
          <div className="row align-items-end justify-content-between">
            <div className="col-lg-6">
              <div className={landingPage.heading}>
                <div>
                  <span className={landingPage.cityName}>Dubai</span>
                </div>
                <div className={landingPage.subHeading}>
                  Explore Dubai's Majesty with Our{" "}
                  <span>Exclusive Offers!</span>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className={landingPage.packagePrice}>
                <div>
                  Starting From{" "}
                  <div className="d-flex justify-content-md-end align-items-center">
                    <span className="text-decoration-line-through me-2">
                      ₹ 28,000
                    </span>
                    <h1 className="me-2">₹ 25,000</h1> / pax
                  </div>
                </div>
                <div className="">
                  Valid Till{" "}
                  <span className="text-secondary fs-4 fw-bold">August </span>
                  <br />( Dates Must Be Flexible )
                </div>
              </div>
            </div>
          </div>
        </div>
        <a href="tel:+91 7686963000">
          <button
            className={`btn btn-light px-md-5 py-3 rounded-pill ${landingPage.contactButton}`}
          >
            ENQUIRE : <span className="fw-bold">+91 7686963000</span>
          </button>
        </a>
      </section>

      <section className={`${landingPage.packageInclude}`}>
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-lg-6 pe-5">
              <div className={`${landingPage.sectionHeading}`}>
                <h2 className="fw-bold text-secondary">Package Includes</h2>
                <div className={landingPage.spanBorder}></div>
                {/* <p className="small mt-3">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Officiis nam delectus.
                </p> */}
                <div className="mt-3">Rates are valid for minimum 2 Person</div>
              </div>
              <div className={`${landingPage.inclusionPoint}`}>
                <img
                  className={`${landingPage.iconHotel} ${landingPage.icon} img-fluid`}
                  src={hotelIcon}
                  alt=""
                />
                <div>
                  <h6 className="mb-2 ">3 Star Hotel</h6>
                  <p className="small ">Daily Breakfast & 2 Dinner</p>
                </div>
              </div>
              <div className={`${landingPage.inclusionPoint}`}>
                <img
                  className={`${landingPage.iconTransfer} ${landingPage.icon} img-fluid`}
                  src={transferIcon}
                  alt=""
                />
                <div>
                  <h6 className="mb-2 ">Airport Transfer</h6>
                  <p className="small">
                    Transfer on Private Vehicle ( Car / Van )
                  </p>
                </div>
              </div>
              <div className={`${landingPage.inclusionPoint}`}>
                <img
                  className={`${landingPage.iconVisa} ${landingPage.icon} img-fluid`}
                  src={visaIcon}
                  alt=""
                />
                <div>
                  <h6 className="mb-2">Tourist Visa</h6>
                  <p className="small">Single Entry</p>
                </div>
              </div>
              <div className={`${landingPage.inclusionPoint}`}>
                <img
                  className={`${landingPage.activity} ${landingPage.icon}  img-fluid`}
                  src={activity}
                  alt=""
                />
                <div>
                  <h6 className="mb-2 ">3 Activities </h6>
                  <p className="small">
                    Dubai City Tour , Desert Safari , Dhow Cruise. <br />{" "}
                  </p>
                </div>
              </div>
              {/* <button className="btn btn-outline-secondary px-5 py-3 rounded-0">
                Inquire Now
              </button> */}
            </div>
            <div className="col-lg-5">
              <img className="img-fluid" src={inclusionSideImage} alt="" />
            </div>
          </div>

          {/* <div className="row">
            <div className="col-lg-6">
              <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                  <div className={landingPage.includedImage}>
                    <img
                      className="img-fluid"
                      src="https://img.easemytrip.com/EMTHotel-1546068/8/m/l/4653658_0.jpg"
                      alt=""
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                  <div
                    className={`${landingPage.includedDetails} d-flex flex-column align-content-between align-items-start justify-content-between `}
                  >
                    <div>
                      <img
                        className={`${landingPage.icon} img-fluid`}
                        src={hotelIcon}
                        alt=""
                      />
                      <h6>3 Star Hotel</h6>
                    </div>
                    <div className="text-primary d-flex justify-content-between align-items-center">
                      {" "}
                      <span>Inquire Now</span>
                      <span>
                        {" "}
                        <i className="fa-solid fa-arrow-right-long ms-2"></i>
                      </span>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                  <div
                    className={`${landingPage.includedDetails} d-flex flex-column align-content-between align-items-start justify-content-between `}
                  >
                    <div>
                      <img
                        className={`${landingPage.icon} img-fluid`}
                        src={breakfastIcon}
                        alt=""
                      />
                      <h6>Daily Breakfast & 2 Dinner</h6>
                    </div>
                    <div className="text-primary d-flex justify-content-between align-items-center">
                      {" "}
                      <span>Inquire Now</span>
                      <span>
                        {" "}
                        <i className="fa-solid fa-arrow-right-long ms-2"></i>
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                  <div className={landingPage.includedImage}>
                    <img
                      className="img-fluid"
                      src="https://dynamic-media-cdn.tripadvisor.com/media/photo-o/29/aa/35/7b/free-breakfast.jpg?w=1200&h=-1&s=1&cx=1171&cy=732&chk=v1_5e1e13d8a0debd116230"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                  <div className={landingPage.includedImage}>
                    <img
                      className="img-fluid"
                      src="https://tripventura.com/cdn/shop/files/d5de129e77cde9a04a9ba3fce9ed6d4b.jpg?v=1705054194&width=1946"
                      alt=""
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                  <div
                    className={`${landingPage.includedDetails} d-flex flex-column align-content-between align-items-start justify-content-between `}
                  >
                    <div>
                      <img
                        className={`${landingPage.icon} img-fluid`}
                        src={transferIcon}
                        alt=""
                      />
                      <h6>Airport Transfer</h6>
                    </div>
                    <div className="text-primary d-flex justify-content-between align-items-center">
                      {" "}
                      <span>Inquire Now</span>
                      <span>
                        {" "}
                        <i className="fa-solid fa-arrow-right-long ms-2"></i>
                      </span>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                  <div
                    className={`${landingPage.includedDetails} d-flex flex-column align-content-between align-items-start justify-content-between `}
                  >
                    <div>
                      <img
                        className={`${landingPage.icon} img-fluid`}
                        src={visaIcon}
                        alt=""
                      />
                      <h6>Tourist Visa</h6>
                    </div>
                    <div className="text-primary d-flex justify-content-between align-items-center">
                      {" "}
                      <span>Inquire Now</span>
                      <span>
                        {" "}
                        <i className="fa-solid fa-arrow-right-long ms-2"></i>
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                  <div className={landingPage.includedImage}>
                    <img
                      className="img-fluid"
                      src="https://imagesss.ipsinternational.org/1665545435823.jpg"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </section>

      <section className={`${landingPage.hotel}`}>
        <div className="container">
          <div className="row justify-content-center align-items-center">
            <div className="text-white pb-4 text-center">
              <h2 className="text-secondary fw-bold">
                3 Star <span>Hotel</span>{" "}
                <span className="text-white">( Options )</span>
              </h2>
              <p>With Daily BreakFast</p>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12 col-12">
              <div className={landingPage.hotelItem}>
                <img
                  className={`img-fluid ${landingPage.inclusionImage}`}
                  src={cityMax}
                  alt=""
                />
                <div className={landingPage.inclusionDetails}>
                  City Max Hotel
                  <p>
                    <span className="fw-bold text-secondary fs-6">
                      Rs 3,720{" "}
                    </span>
                    per Room / Night
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12 col-12">
              <div className={landingPage.hotelItem}>
                <img
                  className={`img-fluid ${landingPage.inclusionImage}`}
                  src={Lavender}
                  alt=""
                />
                <div className={landingPage.inclusionDetails}>
                  Lavender Hotel
                  <p>
                    <span className="fw-bold text-secondary fs-6">
                      Rs 4,560{" "}
                    </span>
                    per Room / Night
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12 col-12">
              <div className={landingPage.hotelItem}>
                <img
                  className={`img-fluid ${landingPage.inclusionImage}`}
                  src={ibis}
                  alt=""
                />
                <div className={landingPage.inclusionDetails}>
                  Ibis Deira Creekside Dubai
                  <p>
                    <span className="fw-bold text-secondary fs-6">
                      Rs 4,440{" "}
                    </span>
                    per Room / Night
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className={landingPage.intro}>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-5">
              <img className="img-fluid" src={introSideImage} alt="" />
            </div>
            <div className="col-lg-6 ps-md-5 mt-4 mt-md-0">
              <h2 className="text-secondary fw-bold mb-3">
                Get The Best Travel <br /> Experience
              </h2>
              <div className={landingPage.spanBorder}></div>
              <p className="small mt-3">
                Embark on a journey of discovery with our exclusive travel
                package crafted just for you by the expert team. Dive into the
                heart of adventure as we take you on a mesmerizing exploration
                of the world's most captivating destinations. Contact us today
                to begin your journey to the extraordinary.
              </p>

              <div className={landingPage.point}>
                <h5>
                  {" "}
                  <i className="fa-solid fa-check-double text-secondary me-2"></i>
                  20+ Years Experience
                </h5>
              </div>
              <div className={landingPage.point}>
                <h5>
                  {" "}
                  <i className="fa-solid fa-check-double text-secondary me-2"></i>
                  Best Travel Agents
                </h5>
              </div>
              <div className={landingPage.point}>
                <h5>
                  {" "}
                  <i className="fa-solid fa-check-double text-secondary me-2"></i>
                  Best Guide
                </h5>
              </div>
              <Link to={"/aboutUs"} target="blank">
                <button className="btn btn-outline-secondary rounded-0 px-5 py-3">
                  About Us <i className="fa-solid fa-arrow-right-long ms-2"></i>
                </button>
              </Link>
            </div>
          </div>
        </div>
      </section>

      <section className={landingPage.activities}>
        <div className="container">
          <div className={`${landingPage.sectionHeading} text-center`}>
            <h3 className="text-secondary">Activities Included</h3>
            {/*  */}
          </div>
          <div className="row">
            <div className="col-lg-4">
              <div className={landingPage.activityItem}>
                <img
                  className={`img-fluid ${landingPage.activityImage}`}
                  src={cityTour}
                  alt=""
                />
                <div className={landingPage.activityDetails}>
                  <h5>Dubai City Tour</h5>
                  <div className={landingPage.spanBorder}></div>
                  <p className="small">
                    It is a 4-5 hour tour of Dubai City. Our tour guide/driver
                    will show you major attractions of Dubai. Only photo stops
                    will be given at the attraction/monument for 10-15 minutes
                    per attraction.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className={landingPage.activityItem}>
                <img
                  className={`img-fluid ${landingPage.activityImage}`}
                  src={desersafari}
                  alt=""
                />
                <div className={landingPage.activityDetails}>
                  <h5>Desert safari</h5>
                  <div className={landingPage.spanBorder}></div>
                  <p className="small">
                    BBQ dinner with vegetarian and non-vegetarian dishes , Live
                    entertaining show of Belly dance and Tanoura show , Fire
                    show , Shisha smoking facility at the shisha corner (Apple
                    flavor) , Camel riding Sofi music ( DJ ) , Sufi dance show.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className={landingPage.activityItem}>
                <img
                  className={`img-fluid ${landingPage.activityImage}`}
                  src={dhowCruise}
                  alt=""
                />
                <div className={landingPage.activityDetails}>
                  <h5>Dhow Cruise</h5>
                  <div className={landingPage.spanBorder}></div>
                  <p className="small">
                    Buffet Dinner, Live Entertainment, Dubai Marina Views and
                    Landmarks, Benefit from the onboard facilities, including
                    washrooms and comfortable seating arrangements, Soft Drinks
                    and Mineral Water.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className={landingPage.addOnActivity}>
        <div className="container">
          <div className="text-center mb-4">
            <h3 className="text-secondary fw-bold">Add On Activities</h3>
            <p className="small">
              {" "}
              Additional Activity can be Included as per Customer Demand
            </p>
          </div>
          <div className="row justify-content-between align-items-center my-5 py-4">
            <div className="col-lg-5">
              <div className={landingPage.addOnActivityItem}>
                <img className={`img-fluid`} src={dolphin} alt="" />
              </div>
            </div>
            <div className="col-lg-6">
              <div className={landingPage.addOnActivityItem}>
                <div className={landingPage.activityName}>
                  <div>
                    Dubai Dolphinarium
                    <p className="fw-normal">
                      Dolphin & Seal Show Regular Ticket
                      <ul className="mt-3">
                        <li>
                          <i className="fa-solid fa-location-arrow me-2"></i>{" "}
                          Enjoy a cool and refreshing encounter with some exotic
                          birds.
                        </li>
                        <li>
                          {" "}
                          <i className="fa-solid fa-location-arrow me-2"></i>{" "}
                          Entrance ticket to Dolphin Live Show{" "}
                        </li>
                        <li>
                          {" "}
                          <i className="fa-solid fa-location-arrow me-2"></i>{" "}
                          Opportunity to click cool images with some imposingly
                          mighty birds like Stellar’s Sea Eagle, Harris Hawk
                          etc.
                        </li>
                      </ul>
                      <div className="text-secondary fs-5 fw-bold">
                        ₹ 2615 <span className="small">/ pax</span>{" "}
                        <span className="fs-6 fw-normal text-dark">
                          {" "}
                          Transfer on Shared Vehicle ( Car / Van )
                        </span>
                      </div>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row  align-items-center flex-md-row-reverse  my-5 py-4">
            <div className="col-lg-6">
              <div className={landingPage.addOnActivityItem}>
                <img className={`img-fluid`} src={burjKhalifa} alt="" />
              </div>
            </div>
            <div className="col-lg-6">
              <div className={landingPage.addOnActivityItem}>
                <div className={landingPage.activityName}>
                  <div>
                    The Burj Khalifa Tickets
                    <p className="fw-normal">
                      124th + 125th Floor Non-Prime Hour Tickets
                      <ul className="mt-3">
                        <li>
                          {" "}
                          <i className="fa-solid fa-location-arrow me-2"></i>{" "}
                          Take a closer look at the world below through
                          avant-garde, high-powered, telescopes.
                        </li>
                        <li>
                          {" "}
                          <i className="fa-solid fa-location-arrow me-2"></i>{" "}
                          Level 125 offers a spacious deck tastefully decorated
                          in Arabic mashrabiya for stunning 360-degree views.{" "}
                        </li>
                        <li>
                          {" "}
                          <i className="fa-solid fa-location-arrow me-2"></i>{" "}
                          Set off on a virtual reality experience to the
                          pinnacle of Burj Khalifa.
                        </li>
                      </ul>
                      <div className="text-secondary fs-5 fw-bold">
                        ₹ 4840 <span className="small">/ pax</span>{" "}
                        <span className="fs-6 fw-normal text-dark">
                          {" "}
                          Transfer on Shared Vehicle ( Car / Van )
                        </span>
                      </div>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row  align-items-center my-5 py-4">
            <div className="col-lg-6">
              <div className={landingPage.addOnActivityItem}>
                <img className={`img-fluid`} src={museum} alt="" />
              </div>
            </div>
            <div className="col-lg-6">
              <div className={landingPage.addOnActivityItem}>
                <div className={landingPage.activityName}>
                  <div>
                    Museum of the Future
                    <p className="fw-normal">
                      <ul className="mt-3">
                        <li>
                          {" "}
                          <i className="fa-solid fa-location-arrow me-2"></i>{" "}
                          General Access to the Museum
                        </li>
                      </ul>
                      <div className="text-secondary fs-5 fw-bold">
                        ₹ 5815 <span className="small">/ pax</span>{" "}
                        <span className="fs-6 fw-normal text-dark">
                          {" "}
                          Transfer on Private Vehicle ( Car / Van )
                        </span>
                      </div>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row align-items-center flex-md-row-reverse  my-5 py-4">
            <div className="col-lg-6">
              <div className={landingPage.addOnActivityItem}>
                <img className={`img-fluid`} src={palmView} alt="" />
              </div>
            </div>
            <div className="col-lg-6">
              <div className={landingPage.addOnActivityItem}>
                <div className={landingPage.activityName}>
                  <div>
                    The View at The Palm Jumeirah Dubai
                    <p className="fw-normal">
                      View General Admission - Non Prime Hour
                      <ul className="mt-3">
                        <li>
                          {" "}
                          <i className="fa-solid fa-location-arrow me-2"></i>{" "}
                          Access to Level 52.
                        </li>
                        <li>
                          {" "}
                          <i className="fa-solid fa-location-arrow me-2"></i>{" "}
                          Access to an interactive theatre that introduces to
                          the enlightening history of the island
                        </li>
                        <li>
                          {" "}
                          <i className="fa-solid fa-location-arrow me-2"></i>{" "}
                          Grand 360 degree display of the iconic Palm Jumeirah,
                          Arabian Gulf and beyond.
                        </li>
                      </ul>
                      <div className="text-secondary fs-5 fw-bold">
                        ₹ 4570 <span className="small">/ pax</span>
                        <span className="fs-6 fw-normal text-dark">
                          {" "}
                          Transfer on Private Vehicle ( Car / Van )
                        </span>
                      </div>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row  align-items-center my-5 py-4">
            <div className="col-lg-6">
              <div className={landingPage.addOnActivityItem}>
                <img className={`img-fluid`} src={dubaiFrame} alt="" />
              </div>
            </div>
            <div className="col-lg-6">
              <div className={landingPage.addOnActivityItem}>
                <div className={landingPage.activityName}>
                  <div>
                    Dubai Frame
                    <p className="fw-normal">
                      <ul className="mt-3">
                        <li>
                          {" "}
                          <i className="fa-solid fa-location-arrow me-2"></i>{" "}
                          Dubai Frame Entrance Tickets
                        </li>
                        <li>
                          {" "}
                          <i className="fa-solid fa-location-arrow me-2"></i>{" "}
                          Step back in time at Dubai Past Gallery
                        </li>
                        <li>
                          {" "}
                          <i className="fa-solid fa-location-arrow me-2"></i>{" "}
                          Enjoy 360-degree views of Old and New Dubai from a
                          150-meter high Sky Deck
                        </li>
                        <li>
                          {" "}
                          <i className="fa-solid fa-location-arrow me-2"></i>{" "}
                          Walk over the breathtaking Glass Bridge
                        </li>
                        <li>
                          {" "}
                          <i className="fa-solid fa-location-arrow me-2"></i>{" "}
                          Glance into Dubai’s imposing future with a Virtual
                          Metropolis experience
                        </li>
                      </ul>
                      <div className="text-secondary fs-5 fw-bold">
                        ₹ 2350 <span className="small">/ pax</span>{" "}
                        <span className="fs-6 fw-normal text-dark">
                          {" "}
                          Transfer on Shared Vehicle ( Car / Van )
                        </span>
                      </div>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="text-center">
          <a href="tel:+91 7686963000">
            <button
              className={`btn btn-light rounded-0 px-5 py-3 rounded-pill ${landingPage.noteHeading}`}
            >
              ENQUIRE : <span className="fw-bold">+91 7686963000</span>
            </button>
          </a>
        </div>
      </section>

      {/* <section className={landingPage.tours}>
        <div className="container">
          <div className={`${landingPage.sectionHeading} text-center`}>
            <h3 className="text-secondary">Popular Destination</h3>
            <p className="small">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Officiis
              nam delectus.
            </p>
          </div>
          <Swiper
            slidesPerView={1}
            spaceBetween={16}
            autoplay={{
              delay: 9000,
              disableOnInteraction: false,
            }}
            navigation={true}
            // pagination={true}
            modules={[Navigation]}
            className="mySwiper"
            breakpoints={{
              640: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              1024: {
                slidesPerView: 4,
                spaceBetween: 20,
              },
            }}
          >
            <SwiperSlide>
              <div className={landingPage.tourItem}>
                <img
                  className={`img-fluid ${landingPage.tourImage}`}
                  src="https://images.unsplash.com/photo-1459600661907-3c72c54955de?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                  alt=""
                />
                <div className={landingPage.tourItemContent}>
                  <h6>Dibai City Tour</h6>
                  <span className="small text-muted">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Similique debitis.
                  </span>
                </div>
                <div className={landingPage.button}>
                  <span>Enquiry Now</span>
                  <i className="fa-solid fa-arrow-right-long"></i>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className={landingPage.tourItem}>
                <img
                  className={`img-fluid ${landingPage.tourImage}`}
                  src="https://images.unsplash.com/photo-1580674684081-7617fbf3d745?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                  alt=""
                />
                <div className={landingPage.tourItemContent}>
                  <h6>Burj Khalifa</h6>
                  <span className="small text-muted">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Similique debitis.
                  </span>
                </div>
                <div className={landingPage.button}>
                  <span>Enquiry Now</span>
                  <i className="fa-solid fa-arrow-right-long"></i>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className={landingPage.tourItem}>
                <img
                  className={`img-fluid ${landingPage.tourImage}`}
                  src="https://images.unsplash.com/photo-1588310558566-b983c7d257e4?q=80&w=1888&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                  alt=""
                />
                <div className={landingPage.tourItemContent}>
                  <h6>Desert Safari</h6>
                  <span className="small text-muted">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Similique debitis.
                  </span>
                </div>
                <div className={landingPage.button}>
                  <span>Enquiry Now</span>
                  <i className="fa-solid fa-arrow-right-long"></i>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className={landingPage.tourItem}>
                <img
                  className={`img-fluid ${landingPage.tourImage}`}
                  src="https://images.unsplash.com/photo-1640968272493-8bc83db2cf41?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                  alt=""
                />
                <div className={landingPage.tourItemContent}>
                  <h6>Sky Diving</h6>
                  <span className="small text-muted">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Similique debitis.
                  </span>
                </div>
                <div className={landingPage.button}>
                  <span>Enquiry Now</span>
                  <i className="fa-solid fa-arrow-right-long"></i>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </section> */}

      {/* <section className={landingPage.planDubai}>
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-lg-5">
              <img className="img-fluid" src={planSideImage} alt="" />
            </div>
            <div className="col-lg-5">
              <h2>Plan Your Trip</h2>

              <div className={`${landingPage.planPoint} border-bottom`}>
                <div className={landingPage.icon}>
                  <i className="fa-solid fa-paper-plane"></i>
                </div>
                <div>
                  <h5>Flights</h5>
                  <span>Oneway , Return</span>
                </div>
              </div>
              <div className={`${landingPage.planPoint} border-bottom`}>
                <div className={landingPage.icon}>
                  <i className="fa-solid fa-hotel"></i>
                </div>
                <div>
                  <h5>Hotels</h5>
                  <span>3 Star , 4 Star , 5 Star Hotel Booking</span>
                </div>
              </div>
              <div className={`${landingPage.planPoint} border-bottom`}>
                <div className={landingPage.icon}>
                  <i className="fa-solid fa-taxi"></i>
                </div>
                <div>
                  <h5>Transfers</h5>
                  <span>Airport Transfer , Private , Sharing</span>
                </div>
              </div>
              <div className={`${landingPage.planPoint}`}>
                <div className={landingPage.icon}>
                  <i className="fa-solid fa-person-walking-luggage"></i>
                </div>
                <div>
                  <h5>Activity</h5>
                  <span>Desert Safari , Sky Diving , City Tour , etc.</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      {/* <section className={landingPage.activityImages}>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-3">
              <div className={landingPage.sectionHeading}>
                Popular Activity to-do in <br /> <span>Dubai</span>
              </div>
            </div>
            <div className="col-lg-9">
              <Swiper
                slidesPerView={4}
                spaceBetween={30}
                autoplay={{
                  delay: 2000,
                  disableOnInteraction: false,
                }}
                pagination={{
                  clickable: true,
                }}
                modules={[Autoplay]}
                className="mySwiper"
              >
                <SwiperSlide>
                  <img
                    className={`img-fluid ${landingPage.activityImage}`}
                    src="https://images.unsplash.com/photo-1588310558566-b983c7d257e4?q=80&w=1888&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                    alt=""
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    className={`img-fluid ${landingPage.activityImage}`}
                    src="https://images.unsplash.com/photo-1639580858747-7da64a469cd6?q=80&w=2127&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                    alt=""
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    className={`img-fluid ${landingPage.activityImage}`}
                    src="https://images.unsplash.com/photo-1640968272493-8bc83db2cf41?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                    alt=""
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    className={`img-fluid ${landingPage.activityImage}`}
                    src="https://images.unsplash.com/photo-1636435462738-4166c45015b3?q=80&w=1922&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                    alt=""
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    className={`img-fluid ${landingPage.activityImage}`}
                    src="https://images.unsplash.com/photo-1548594882-09ba1908cde2?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                    alt=""
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    className={`img-fluid ${landingPage.activityImage}`}
                    src="https://images.unsplash.com/photo-1577544728904-c1ae02851346?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                    alt=""
                  />
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
        </div>
      </section> */}

      {/* <section className={`${landingPage.exploreMore} bg-white`}>
        <div className="container">
          <div
            className={`${landingPage.sectionHeading} mb-4 d-flex justify-content-between align-items-center`}
          >
            <div>
              <span>Explore</span> More
            </div>
            <div className="text-primary fs-6" role="button">
              {" "}
              Inquire Now <i className="fa-solid fa-arrow-right-long ms-2"></i>
            </div>
          </div>
          <Swiper
            slidesPerView={1}
            spaceBetween={30}
            autoplay={{
              delay: 2000,
              disableOnInteraction: false,
            }}
            pagination={{
              clickable: true,
            }}
            modules={[Autoplay]}
            className="mySwiper"
          >
            <SwiperSlide>
              <div className={landingPage.moreExploreItem}>
                <img
                  className={`img-fluid`}
                  src="https://images.unsplash.com/photo-1588310558566-b983c7d257e4?q=80&w=1888&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                  alt=""
                />
                <div className={landingPage.activityName}>
                  <div>
                    Desert Safari
                    <p className="small fw-normal">
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Iure, beatae laborum et nulla odit neque voluptatibus
                      officiis possimus. Maiores facere nobis debitis
                      reprehenderit ipsa. Dolorum repudiandae et possimus
                      voluptatem non.
                    </p>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className={landingPage.moreExploreItem}>
                <img
                  className={`img-fluid`}
                  src="https://images.unsplash.com/photo-1639580858747-7da64a469cd6?q=80&w=2127&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                  alt=""
                />
                <div className={landingPage.activityName}>
                  {" "}
                  <div>
                    Dhow Cruise
                    <p className="small fw-normal">
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Iure, beatae laborum et nulla odit neque voluptatibus
                      officiis possimus. Maiores facere nobis debitis
                      reprehenderit ipsa. Dolorum repudiandae et possimus
                      voluptatem non.
                    </p>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className={landingPage.moreExploreItem}>
                <img
                  className={`img-fluid`}
                  src="https://images.unsplash.com/photo-1640968272493-8bc83db2cf41?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                  alt=""
                />
                <div className={landingPage.activityName}>
                  {" "}
                  <div>
                    Sky Diving
                    <p className="small fw-normal">
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Iure, beatae laborum et nulla odit neque voluptatibus
                      officiis possimus. Maiores facere nobis debitis
                      reprehenderit ipsa. Dolorum repudiandae et possimus
                      voluptatem non.
                    </p>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className={landingPage.moreExploreItem}>
                <img
                  className={`img-fluid`}
                  src="https://images.unsplash.com/photo-1636435462738-4166c45015b3?q=80&w=1922&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                  alt=""
                />
                <div className={landingPage.activityName}>
                  {" "}
                  <div>
                    Dubai Museum
                    <p className="small fw-normal">
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Iure, beatae laborum et nulla odit neque voluptatibus
                      officiis possimus. Maiores facere nobis debitis
                      reprehenderit ipsa. Dolorum repudiandae et possimus
                      voluptatem non.
                    </p>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className={landingPage.moreExploreItem}>
                <img
                  className={`img-fluid`}
                  src="https://images.unsplash.com/photo-1548594882-09ba1908cde2?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                  alt=""
                />
                <div className={landingPage.activityName}>
                  {" "}
                  <div>
                    Water World
                    <p className="small fw-normal">
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Iure, beatae laborum et nulla odit neque voluptatibus
                      officiis possimus. Maiores facere nobis debitis
                      reprehenderit ipsa. Dolorum repudiandae et possimus
                      voluptatem non.
                    </p>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </section> */}
    </div>
  );
};

export default DubaiPage;
