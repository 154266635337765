import { HTMLInputTypeAttribute, InputHTMLAttributes } from "react";
import { ErrorMessage } from "./ErrorMessage";
import {
  useController,
  FieldValues,
  UseControllerProps,
  Path,
  UseFormRegister,
  RegisterOptions,
} from "react-hook-form";

interface IAppInputProps<N extends string> {
  id?: string;
  label?: string;
  containerClassName?: string;
  isRequired?: boolean;
  type?: HTMLInputTypeAttribute;
  fieldName: N;
  value?: string | null;
  errorMessage?: string | null;
  placeholder?: string;
  onChange: (fieldName: N, value: string) => void;
}

interface IAppControlInputProps {
  id?: string;
  label?: string;
  containerClassName?: string;
  type?: HTMLInputTypeAttribute;
  isRequired?: boolean;
}

interface IRegisterProps<K extends FieldValues> {
  name: Path<K>;
  register: UseFormRegister<K>;
  registerOptions?: RegisterOptions<K>;
}

type TAppRegisterInputProps<K extends FieldValues> =
  InputHTMLAttributes<HTMLInputElement> & IRegisterProps<K>;

export const AppInput = <N extends string>(props: IAppInputProps<N>) => {
  return (
    <div className={props.containerClassName}>
      {props.label && (
        <label
          htmlFor={props.id}
          className={`${props.isRequired ? "required" : ""}`}
        >
          {props.label}
        </label>
      )}
      <input
        id={props.id}
        name={props.fieldName}
        placeholder={props.placeholder}
        type={props.type}
        onChange={(e) => {
          props.onChange(props.fieldName, e.target.value);
        }}
        value={props.value || ""}
        className="form-control"
      />
      <ErrorMessage errorMessage={props.errorMessage} />
    </div>
  );
};

export const AppControlInput = <T extends FieldValues>(
  props: UseControllerProps<T> & IAppControlInputProps
) => {
  const {
    field,
    fieldState: { error },
  } = useController({
    name: props.name,
    control: props.control,
    defaultValue: props.defaultValue,
    disabled: props.disabled,
    rules: props.rules,
    shouldUnregister: props.shouldUnregister,
  });

  return (
    <div className={props.containerClassName}>
      {props.label && (
        <label
          htmlFor={props.id}
          className={`${props.isRequired ? "required" : ""} small`}
        >
          {props.label}
        </label>
      )}
      <input
        id={props.id}
        {...field}
        type={props.type}
        className="form-control"
      />
      <ErrorMessage errorMessage={error?.message} />
    </div>
  );
};

export const AppRegisterInput = <T extends FieldValues>({
  register,
  registerOptions,
  ...inputAttributes
}: TAppRegisterInputProps<T>) => {
  return (
    <input
      className="form-control"
      {...inputAttributes}
      {...register(inputAttributes.name, registerOptions)}
    />
  );
};
