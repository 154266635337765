import moment from "moment";
import { Placeholder } from "react-bootstrap";
import Card from "react-bootstrap/Card";

export const TourResultItemLoadingSkeleton = () => {
  return (
    <div>
      <div className="border rounded  p-3 mb-2 ">
        <div className="row">
          <div className="col-lg-4">
            <Placeholder animation="glow">
              <Placeholder xs={12} />
            </Placeholder>
          </div>
          <div className="col-lg-3">
            <Placeholder animation="glow">
              <Placeholder xs={12} />
            </Placeholder>
          </div>
          <div className="col-lg-2">
            <Placeholder animation="glow">
              <Placeholder xs={12} />
            </Placeholder>
          </div>
          <div className="col-lg-2">
            <Placeholder animation="glow">
              <Placeholder xs={12} />
            </Placeholder>
          </div>
          <div className="col-lg-1">
            <Placeholder animation="glow">
              <Placeholder xs={12} />
            </Placeholder>
          </div>
        </div>
      </div>
    </div>
  );
};

export const TourResultLoadingSkeleton = () => {
  return (
    <div className="border rounded  mt-3 mb-3">
      <div className="row">
        <div className="col-lg-4 pe-0" style={{ height: "12rem" }}>
          {" "}
          <div className="bg-light w-100 h-100 rounded"></div>
        </div>
        <div className="col-lg-6">
          <div className="p-3 h-100 d-flex flex-column justify-content-between">
            <Placeholder as={Card.Title} animation="glow">
              <Placeholder xs={10} />
              <Placeholder xs={6} size="xs" />
            </Placeholder>
            <div>
              <Placeholder.Button variant="light" xs={8} />
            </div>
          </div>
        </div>
        <div className="col-lg-2 ps-0">
          <div className="p-3 text-end h-100 d-flex flex-column justify-content-between">
            <Placeholder as={Card.Title} animation="glow">
              <Placeholder xs={12} />
              <Placeholder xs={6} size="xs" />
            </Placeholder>
            <div>
              <Placeholder.Button variant="success" xs={12} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const convertToUnixTimeStamp = (time: string | undefined) => {
  const [hours, minutes] = time ? time.split(":").map(Number) : [0, 0];
  const unixTimeStamp = hours * 3600 + minutes * 60;
  return unixTimeStamp;
};

export const convertUnixToTimeString = (unixTimestamp: number) => {
  const hours = Math.floor(unixTimestamp / 3600);
  const minutes = Math.floor((unixTimestamp % 3600) / 60);
  const formattedHours = String(hours).padStart(2, "0");
  const formattedMinutes = String(minutes).padStart(2, "0");
  return `${formattedHours}:${formattedMinutes}`;
};

export const defaultDate = {
  from: moment().add(5, "days").toDate(),
  to: moment().add(9, "days").toDate(),
};
