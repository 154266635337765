import React from "react";

const UploadAndDisplayImage: React.FC<{
  selectedImage: File | null;
  onSelectImage: (e: File | null) => void;
}> = (props) => {
  return (
    <div>
      {props.selectedImage && (
        <div className="text-center">
          <img
            alt="not found"
            width={"250px"}
            src={URL.createObjectURL(props.selectedImage)}
          />
          <br />
          <button
            onClick={() => props.onSelectImage(null)}
            className="btn btn-danger mt-2"
          >
            Remove
          </button>
        </div>
      )}

      <br />
      <br />

      {!props.selectedImage && (
        <input
          type="file"
          name="myImage"
          onChange={(event) => {
            props.onSelectImage(event?.target?.files?.[0] || null);
          }}
        />
      )}
    </div>
  );
};

export default UploadAndDisplayImage;
