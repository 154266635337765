import { useEffect, useRef, useState } from "react";
import { IS3File } from "../../activitiescommonfnb/types/s3Items.interface";
import { activityAxios } from "../../axios/activityAxios";
import { IJSONResponse } from "../../activitiescommonfnb/types/common.interface";
import { toast } from "react-toastify";
import { LoadingButton } from "../../commonUi/LoadingButton";
import { Spinner } from "react-bootstrap";

const SingleExistingPhotoProofItem: React.FC<{
  photoItem: IS3File;
  taskId: number;
  getPhotoProofsByTaskId: () => Promise<void>;
}> = (props) => {
  const [isDeleting, setIsDeleting] = useState(false);

  const deletePhotoProof = async (fileUrl: string) => {
    setIsDeleting(true);

    const apiResponse = await activityAxios.post<IJSONResponse>(
      "/task/deletePhotoProof",
      {
        taskId: props.taskId,
        fileUrl: fileUrl,
      }
    );

    setIsDeleting(false);

    if (apiResponse.data.success) {
      toast.success("Photo proof deleted successfully.");
      await props.getPhotoProofsByTaskId();
    } else {
      toast.error(apiResponse.data.errorMessage);
    }
  };

  return (
    <div className="col-6 p-3 text-end">
      <img
        className="h-100 border w-100"
        src={props.photoItem.fileUrl}
        alt={props.photoItem.fileName}
      />
      <LoadingButton
        btnClass="btn btn-sm small btn-danger"
        loading={isDeleting}
        onClick={() => deletePhotoProof(props.photoItem.fileUrl)}
      >
        Delete Permanent
      </LoadingButton>
    </div>
  );
};

export const UploadTaskPhotoProof: React.FC<{
  taskId: number;
}> = (props) => {
  const [selectedPhotos, setSelectedPhotos] = useState<File[]>([]);
  const [loadingState, setLoadingState] = useState({
    isUploading: false,
    isDeleting: false,
    isFetchingExistingPhotoProofs: false,
  });
  const [existingPhotoProofs, setExistingPhotoProofs] = useState<IS3File[]>([]);
  const inputRef = useRef<HTMLInputElement>(null);

  const uploadedPhotos = async () => {
    const formData = new FormData();
    setLoadingState((old) => {
      old.isUploading = true;
      return { ...old };
    });
    for (const singlePhoto of selectedPhotos) {
      formData.append("photos", singlePhoto);
    }

    formData.append("taskId", props.taskId.toString());

    const apiResponse = await activityAxios.post<IJSONResponse>(
      "/task/uploadPhotoProofs",
      formData,
      {
        headers: {
          "Content-Type": "multipart/formdata",
        },
      }
    );

    setLoadingState((old) => {
      old.isUploading = false;
      return { ...old };
    });

    if (apiResponse.data.success) {
      toast.success("Photo proof uploaded successfully.");
      setSelectedPhotos([]);
      if (inputRef.current) {
        inputRef.current.value = "";
      }
      await getPhotoProofsByTaskId();
    } else {
      toast.error(apiResponse.data.errorMessage);
    }
  };

  const getPhotoProofsByTaskId = async () => {
    setLoadingState((old) => {
      old.isFetchingExistingPhotoProofs = true;
      return { ...old };
    });
    const apiResponse = await activityAxios.post<IJSONResponse>(
      "/task/getPhotoProofsByTaskId",
      {
        taskId: props.taskId,
      }
    );
    setLoadingState((old) => {
      old.isFetchingExistingPhotoProofs = false;
      return { ...old };
    });

    if (apiResponse.data.success) {
      // Save in state
      setExistingPhotoProofs(apiResponse.data.result);
    } else {
      toast.error(apiResponse.data.errorMessage);
    }
  };

  useEffect(() => {
    getPhotoProofsByTaskId();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="row">
      <div className="col-12">
        {selectedPhotos.length > 0 && <h6>Selected Photo Proof: </h6>}
      </div>
      {selectedPhotos.map((singlePhoto, index) => {
        return (
          <div
            className="col-6 p-3  position-relative"
            key={`uploadedImage-${index}`}
          >
            <button
              className="btn btn-sm rounded-pill small btn-danger position-absolute"
              style={{
                right: 0,
                top: 0,
              }}
              onClick={() => {
                if (selectedPhotos.length === 1 && inputRef.current) {
                  inputRef.current.value = "";
                }

                setSelectedPhotos((old) => {
                  const newSelectedPhotos = old.filter((_, i) => i !== index);
                  return newSelectedPhotos;
                });
              }}
            >
              <i className="fa-solid fa-xmark"></i>
            </button>
            <img
              className="h-100 border w-100"
              src={URL.createObjectURL(singlePhoto)}
              alt={singlePhoto.name}
            />
          </div>
        );
      })}
      <div className="col-10 mt-3">
        <input
          className="form-control py-1"
          type="file"
          accept="image/*"
          multiple
          ref={inputRef}
          onChange={(e) => {
            const selectedFile = e.target.files?.[0];
            if (!selectedFile) {
              return;
            }

            setSelectedPhotos((old) => {
              return [...old, selectedFile];
            });
          }}
        />
      </div>

      <div className="col-2 text-end mt-3">
        <LoadingButton
          btnClass="btn btn-sm btn-success w-100"
          onClick={uploadedPhotos}
          loading={loadingState.isUploading}
          minWidth="51px"
        >
          <i className="fa-solid fa-upload"></i> Upload
        </LoadingButton>
      </div>

      <div className="col-12">
        <h6 className="mt-2">Existing Photo Proofs: </h6>
      </div>
      {!loadingState.isFetchingExistingPhotoProofs &&
        existingPhotoProofs.map((singlePhoto, index) => {
          return (
            <SingleExistingPhotoProofItem
              key={`existingImage-${index}`}
              photoItem={singlePhoto}
              getPhotoProofsByTaskId={getPhotoProofsByTaskId}
              taskId={props.taskId}
            />
          );
        })}

      {loadingState.isFetchingExistingPhotoProofs && (
        <div className="text-center">
          <Spinner />
        </div>
      )}

      {!loadingState.isFetchingExistingPhotoProofs &&
        existingPhotoProofs.length === 0 && (
          <div className="text-danger small text-center">
            No Existing Photo Proof
          </div>
        )}

      <div className="mb-3"></div>
    </div>
  );
};
