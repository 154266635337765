import { activityAxios } from "../../../axios/activityAxios";
import { IJSONResponse } from "../../../activitiescommonfnb/types/common.interface";
import { IUserData } from "../../../activitiescommonfnb/types/User.interface";
import { AppSelect } from "../../../commonUi/AppSelect";
import { useEffect, useState } from "react";

export const StaffStaticSelect: React.FC<{
  containerClassName?: string;
  labelClassName?: string;
  label?: string;
  value?: IUserData;

  onChange: (user: IUserData | null) => void;
  //   onChange?: (value: string | null) => void;
}> = (props) => {
  const [staffList, setStaffList] = useState<IUserData[]>([]);
  const getAndSetAllStaff = async () => {
    const apiRes = await activityAxios.post<IJSONResponse<IUserData[]>>(
      "/user/getAllStaff"
    );

    if (apiRes.data.success && apiRes.data.result) {
      setStaffList(apiRes.data.result);
    }

    return apiRes.data.result || [];
  };

  useEffect(() => {
    getAndSetAllStaff();
  }, []);

  return (
    <div className={props.containerClassName}>
      {props.label && (
        <label htmlFor="user" className={props.labelClassName}>
          {props.label}
        </label>
      )}
      <AppSelect
        options={staffList.map((staff) => {
          return {
            label: `${staff.firstName} ${staff.lastName} ${staff.phoneCode}-${staff.phoneNumber}`,
            value: staff.id,
            user: staff,
          };
        })}
        onChange={(selectedOption) =>
          props.onChange(selectedOption?.user || null)
        }
        value={
          props.value
            ? {
                label: `${props.value?.firstName} ${props.value?.lastName} ${props.value?.phoneCode}-${props.value?.phoneNumber}`,
                value: props.value.id,
                user: props.value,
              }
            : undefined
        }
      />
    </div>
  );
};
