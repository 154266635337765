import React, { ReactNode } from "react";
import { useAppSelector } from "../../store";
import { getAuthToken } from "../../functions/commonFx";
import { Navigate } from "react-router-dom";
import { EnumUserType } from "../../activitiescommonfnb/types/User.interface";

export const GuestRoutes: React.FC<{
  children: ReactNode;
}> = (props) => {
  const token = getAuthToken();

  const { data: userData } = useAppSelector((state) => state.authData);

  if (token && userData) {
    if (!token || !userData) {
      if (userData.userType === EnumUserType.STAFF) {
        return <Navigate to="/enquiries/dashboard" />;
      }
    }
    return <Navigate to="/" />;
  }

  return <React.Fragment>{props.children}</React.Fragment>;
};
