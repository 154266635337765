import _ from "lodash";
import moment from "moment";

export const handleWheel = (e: React.WheelEvent<HTMLInputElement>) => {
  (e.target as HTMLInputElement).blur();
};

export const generateDateRange = (
  from: Date | string,
  to: Date | string
): Date[] => {
  const dateArray: Date[] = [];
  const startDate = moment(from);
  const endDate = moment(to);
  const daysDiff = endDate.diff(startDate, "days");
  for (let i = 0; i <= daysDiff; i++) {
    dateArray.push(startDate.clone().add(i, "days").toDate());
  }
  return dateArray;
};

export const capitaliseStr = (str: string | undefined) => {
  return _.startCase(_.toLower(str));
};
