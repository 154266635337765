import React, { useEffect, useState } from "react";
import { activityAxios } from "../../../axios/activityAxios";
import AppCkEditor from "../../../commonUi/AppCkEditor";
import { toast } from "react-toastify";
import { ISettings } from "../../../activitiescommonfnb/types/admin.interface";
import { ZodHelperService } from "../../../activitiescommonfnb/schemas/zodHelper.service";
import { SettingsSchema } from "../../../activitiescommonfnb/schemas/admin.schema";

const SettingsList: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [settings, setSettings] = useState<Partial<ISettings>>({
    domesticQuotationTandC: "",
    internationalQuotationTandC: "",
    b2bMarkup: 0,
    b2cMarkup: 0,
  });

  useEffect(() => {
    getSettings();
  }, []);

  const getSettings = async () => {
    setLoading(true);
    try {
      const res = await activityAxios.post("/master/getSettings");
      if (res.data.success) {
        setSettings(res.data.result);
      }
    } catch (error) {
      console.error("Error fetching settings:", error);
    }
    setLoading(false);
  };

  const handleSave = async () => {
    const validatedData = SettingsSchema.safeParse(settings);
    if (!validatedData.success) {
      const validationErrors = ZodHelperService.formatZodErrors(
        validatedData.error,
        "errors"
      );
      throw validationErrors;
    }

    try {
      const res = await activityAxios.post("/master/saveAndUpdateSettings", {
        ...settings,
      });

      if (res.data.success) {
        toast.success("Settings updated successfully");
        getSettings();
      }
    } catch (error) {
      toast.error("failed to update Settings");
      console.error("Error saving settings:", error);
    }
  };

  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-lg-12 mt-4">
          {!loading && (
            <form>
              <div className="mb-4">
                <label>Domestic Quotation Terms & Conditions</label>
                <AppCkEditor
                  value={settings?.domesticQuotationTandC}
                  onChange={(e) =>
                    setSettings({
                      ...settings,
                      domesticQuotationTandC: e || "",
                    })
                  }
                />
              </div>
              <div className="my-3">
                <label>International Quotation Terms & Conditions</label>
                <AppCkEditor
                  value={settings?.internationalQuotationTandC}
                  onChange={(e) =>
                    setSettings({
                      ...settings,
                      internationalQuotationTandC: e || "",
                    })
                  }
                />
              </div>
              <div className="my-3">
                <label>B2B Markup</label>
                <input
                  type="text"
                  name="b2bMarkup"
                  className="form-control"
                  value={settings.b2bMarkup || ""}
                  onChange={(e) => {
                    let value = parseInt(e.target.value);
                    setSettings((old) => ({
                      ...old,
                      b2bMarkup: value || undefined,
                    }));
                  }}
                />
              </div>
              <div className="my-3">
                <label>B2C Markup</label>
                <input
                  type="text"
                  name="b2cMarkup"
                  className="form-control"
                  value={settings.b2cMarkup || ""}
                  onChange={(e) => {
                    let value = parseInt(e.target.value);
                    setSettings((old) => ({
                      ...old,
                      b2cMarkup: value || undefined,
                    }));
                  }}
                />
              </div>
              <div className="mb-3">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={handleSave}
                >
                  Save
                </button>
              </div>
            </form>
          )}
          {loading && <p>Loading...</p>}
        </div>
      </div>
    </div>
  );
};

export default SettingsList;
