import _ from "lodash";
import React, { ChangeEvent, useState } from "react";
import { toast } from "react-toastify";

export interface IPaxInfo {
  adult: number;
  child: number;
  infant: number;
}

const paxArray: Array<keyof IPaxInfo> = ["adult", "child", "infant"];

export const PaxInfoPopOver: React.FC<{
  showPassenegerPopup: boolean;
  setShowPassengerPopup: (show: boolean) => void;
  paxInfo: IPaxInfo;
  onSubmit: (paxInfo: IPaxInfo) => void;
  containerClassName?: string;
  isDisabled: boolean;
}> = (props) => {
  const [paxInfo, setPaxInfo] = useState<IPaxInfo>(props.paxInfo);

  const onPaxChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const paxType = e.target.name as keyof IPaxInfo;
    let totalAdults = paxInfo.adult;

    if (paxType === "adult" || paxType === "child") {
      let totalPax = parseInt(e.target.value);
      if (paxType === "adult") {
        totalAdults = totalPax;
        totalPax += paxInfo.child;

        if (paxInfo.infant > parseInt(e.target.value)) {
          return toast.warning(
            "Total no. of infant can not be greater than adult"
          );
        }
      } else if (paxType === "child") {
        totalPax += paxInfo.adult;
      }

      if (totalPax > 9) {
        return toast.warning(
          "Total no. of passenger can not be greater than 9"
        );
      }
    }

    if (paxType === "infant") {
      if (totalAdults < parseInt(e.target.value)) {
        return toast.warning(
          "Total no. of infant can not be greater than adult"
        );
      }
    }

    setPaxInfo((pax) => {
      return {
        ...pax,
        [paxType]: parseInt(e.target.value),
      };
    });
  };

  return (
    <div className={props.containerClassName}>
      <label htmlFor="" className="small">
        Passenger
      </label>
      <div className="passenger-btn">
        <button
          className="w-100 text-start form-control"
          onClick={() => props.setShowPassengerPopup(true)}
          type="button"
          data-toggle="show"
          data-target="#collapseExample"
          aria-expanded="true"
          aria-controls="collapseExample"
        >
          <div className=" flight-passenger-info d-flex justify-content-between align-items-center">
            <span>
              <i className="fa-solid fa-user-group pe-2 small text-muted"></i>
            </span>
            <span className="small">
              {props.paxInfo.adult} Ad / {props.paxInfo.child} Ch /{" "}
              {props.paxInfo.infant} In
            </span>
          </div>
        </button>
        {props.showPassenegerPopup && props.isDisabled && (
          <div className="form-popup p-3" id="passenger">
            <div className="row">
              {paxArray.map((paxType) => {
                return (
                  <div className="col-lg-4 col-md-4 col-sm-4 col-4">
                    <label className=" small" htmlFor={paxType}>
                      {paxType.toUpperCase()}
                    </label>
                    <select
                      id={paxType}
                      className="form-control py-2"
                      name={paxType}
                      value={paxInfo[paxType]}
                      onChange={onPaxChange}
                    >
                      {_.range(0, 10).map((num) => (
                        <option key={num} value={num}>
                          {num}
                        </option>
                      ))}
                    </select>
                  </div>
                );
              })}
            </div>

            <div className="row mt-3">
              <div className="col-lg-6 col-md-6 col-sm-6 col-6 pe-0">
                <button
                  type="submit"
                  onClick={() => {
                    props.onSubmit(paxInfo);
                  }}
                  className="btn btn-primary w-100 btn-sm"
                >
                  Submit
                </button>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                <button
                  type="button"
                  onClick={() => {
                    props.setShowPassengerPopup(false);
                    setPaxInfo(props.paxInfo);
                  }}
                  className="btn btn-outline-secondary w-100 btn-sm"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
