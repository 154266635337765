import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { IActivitySupplierUser } from "../activitiescommonfnb/types/supplier.interface";

const initialState: {
  data: IActivitySupplierUser | null;
} = {
  data: null,
};

export const supplierAuthSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setSupplierAuthData: (
      state,
      action: PayloadAction<IActivitySupplierUser | null>
    ) => {
      // localStorage.setItem("authData", JSON.stringify(action.payload));
      state.data = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setSupplierAuthData } = supplierAuthSlice.actions;

export const selectSupplierAuthData = (state: RootState) => state.authData.data;

export default supplierAuthSlice.reducer;
