import { useState } from "react";
import { toast } from "react-toastify";
import { activitySupplierAxios } from "../../axios/activitySupplierAxios";
import { Navigate, useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import { setSupplierAuthData } from "../../slice/supplierAuth.slice";

export const SupplierLogin: React.FC<{}> = () => {
  const dispatch = useDispatch();
  const [loginDetails, setLoginDetails] = useState({
    email: "rohan@mailinator.com",
    password: "Pooja@123",
  });
  const navigate = useNavigate();
  const handleLoginDetails = (e: React.ChangeEvent<HTMLInputElement>) => {
    let name: string = e.target.name;
    let value: string = e.target.value;
    setLoginDetails((old) => {
      return { ...old, [name]: value };
    });
  };
  const loginUser = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      const loginRes = await activitySupplierAxios.post("/login", {
        loginDetails,
      });

      if (!loginRes?.data?.success) {
        throw new Error("Something went wrong");
      } else if (loginRes?.data?.success) {
        dispatch(setSupplierAuthData(loginRes?.data.result));
        navigate("/supplier/dashboard");
      }
    } catch (error: any) {
      toast.error("Something went wrong");
    }
  };
  return (
    <div className="container">
      <h4 className="text-center my-3">Supplier Login</h4>
      <div className="row">
        <div className="col-6 offset-3 offset-md-4 col-md-4 ">
          <form className="border p-3 rounded" onSubmit={loginUser}>
            <div className="form-group mb-3">
              <label htmlFor="exampleInputEmail1">Email address</label>
              <input
                onChange={handleLoginDetails}
                name="email"
                value={loginDetails.email}
                type="email"
                className="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                placeholder="Enter email"
              />
              <small id="emailHelp" className="form-text text-muted small">
                We'll never share your email with anyone else.
              </small>
            </div>
            <div className="form-group mb-3">
              <label htmlFor="exampleInputPassword1">Password</label>
              <input
                onChange={handleLoginDetails}
                value={loginDetails.password}
                name="password"
                type="password"
                className="form-control"
                id="exampleInputPassword1"
                placeholder="Password"
              />
            </div>
            {/* <div className="form-check ">
              <input
                type="checkbox"
                className="form-check-input"
                id="exampleCheck1"
              />
              <label className="form-check-label" htmlFor="exampleCheck1">
                Remember me
              </label>
            </div> */}
            <button type="submit" className="btn btn-primary w-100">
              Submit
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};
