import Cookies from "js-cookie";
import { activityAxios } from "../axios/activityAxios";
import { IJSONResponse } from "../activitiescommonfnb/types/common.interface";
import { TCity } from "../activitiescommonfnb/schemas/master.schema";
import moment from "moment";

export const getAuthToken = () => {
  return Cookies.get("auth-token");
};

export const getSupplierAuthToken = () => {
  return Cookies.get("supplier-token");
};

export const checkIsDecimalNumber = (numberStr: string) => {
  let decimalRegEx = /^[0-9]*\.?[0-9]*$/;

  return decimalRegEx.test(numberStr);
};

export const getCityByCitySlug = async (citySlug: string) => {
  const cityDetails = await activityAxios.post<IJSONResponse<TCity>>(
    "/search/getCityByCitySlug",
    { citySlug }
  );
  return cityDetails.data.result?.name;
};

export const formatDate = (date: string | Date): string =>
  moment(date).format("DD-MM-YYYY");
