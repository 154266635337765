import React from "react";
import QueueFilterStyle from "./BookingQueueFilterStyle.module.scss";

const BookingQueueFilter: React.FC<{}> = () => {
  return (
    <div>
      <div className={QueueFilterStyle.bookingQueueFilter}>
        <div className={QueueFilterStyle.filterHeader}>
          <h6>Filter</h6>
          <span className="small"> Clear</span>
        </div>
        <div className={QueueFilterStyle.filterIntputs}>
          <div className="mb-2">
            <label htmlFor="" className="small">
              Tour Option
            </label>
            <input
              className="form-control"
              type="
                  "
            />
          </div>
          <div className="mb-2">
            <label htmlFor="" className="small">
              Date Range
            </label>
            <input className="form-control" type="date" />
          </div>
          <div className="mb-2">
            <label htmlFor="" className="small">
              Transfer Type
            </label>
            <select name="" id="" className="form-select">
              <option value="">Without Transfer</option>
              <option value="">Sharing Transfer</option>
              <option value="">Private Transfer</option>
            </select>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookingQueueFilter;
