import { useEffect, useState } from "react";
import { activitySupplierAxios } from "../../axios/activitySupplierAxios";
import { TCreateActivitySupplierUser } from "../../activitiescommonfnb/schemas/supplier.schema";
import { toast } from "react-toastify";
import e from "express";
import { Modal } from "react-bootstrap";
import { AppInput } from "../../commonUi/AppInput";
import { IActivitySupplierUser } from "../../activitiescommonfnb/types/supplier.interface";
import { IJSONResponse } from "../../activitiescommonfnb/types/common.interface";

export const CreateOrBlockSuppliers: React.FC<{}> = () => {
  const [showCreateNewSupplierModal, setShowCreateNewSupplierModal] =
    useState(false);
  const [supplier, setSupplier] = useState<TCreateActivitySupplierUser>({
    email: "",
    firstName: "",
    isAdmin: false,
    isLoginBlocked: false,
    lastName: "",
  });
  const [suppliers, setSuppliers] = useState<IActivitySupplierUser[]>([]);
  useEffect(() => {
    getSuppliers();
  }, []);
  const getSuppliers = async () => {
    try {
      const suppliersRes = await activitySupplierAxios.post<
        IJSONResponse<IActivitySupplierUser[]>
      >("/getSupplierUsers", {});
      if (suppliersRes.data.success) {
        setSuppliers(suppliersRes?.data?.result || []);
      }
    } catch (error: any) {
      toast.error("Something went wrong");
    }
  };

  const editIsLoginBloked = async (
    supplier: IActivitySupplierUser,
    loginBlockedStatus: boolean
  ) => {
    try {
      const editIsLoginBlokedRes = await activitySupplierAxios.post(
        "editActivitySupplierUser",
        {
          supplierUserDetails: {
            id: supplier.id,
            isLoginBlocked: loginBlockedStatus,
          },
        }
      );
      if (editIsLoginBlokedRes.data.success) {
        toast.success("Supplier Details updated");
      } else {
        toast.error("somthing went wrong");
      }
      getSuppliers();
    } catch (error: any) {
      toast.error(error || "something went wrong");
    }
  };

  const createSupplier = async () => {
    try {
      const createSuppRes = await activitySupplierAxios.post(
        "/createActivitySupplierUser",
        {
          supplierUserDetails: supplier,
        }
      );
      if (createSuppRes.data.success) {
        toast.success("Supplier Created");
      } else {
        throw new Error("Something went wrong");
      }
    } catch (error: any) {
      toast.error(error || "something went wrong");
    }
  };

  const handleSupplier = (name: string, value: string) => {
    setSupplier((old: TCreateActivitySupplierUser) => {
      return { ...old, [name]: value };
    });
  };

  return (
    <div className="container">
      <div className="mt-5">
        {showCreateNewSupplierModal && (
          <Modal
            onHide={() => {
              setShowCreateNewSupplierModal(false);
            }}
            show={showCreateNewSupplierModal}
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <div className="p-3 pb-0">
              <AppInput
                type="text"
                fieldName="firstName"
                onChange={handleSupplier}
                containerClassName=" mb-2"
                label="First Name"
                value={supplier?.firstName || ""}
              />
              <AppInput
                type="text"
                fieldName="lastName"
                onChange={handleSupplier}
                containerClassName="mb-2"
                label="Last Name"
                value={supplier?.lastName || ""}
              />
              <AppInput
                type="email"
                fieldName="email"
                onChange={handleSupplier}
                containerClassName="mb-2"
                label="Email"
                value={supplier?.email || ""}
              />
              <div className="form-check form-switch m-2 py-3">
                <input
                  checked={supplier?.isAdmin}
                  onChange={(e) => {
                    setSupplier((old) => {
                      return { ...old, isAdmin: e.target.checked };
                    });
                  }}
                  className="form-check-input"
                  type="checkbox"
                  role="switch"
                  id="flexSwitchCheckDefault"
                />
                <label htmlFor="">isAdmin</label>
              </div>
            </div>
            <Modal.Footer>
              <button
                className="btn btn-outline-secondary btn-sm"
                onClick={() => setShowCreateNewSupplierModal(false)}
              >
                Close
              </button>
              <button
                className="btn btn-primary btn-sm"
                onClick={() => {
                  setShowCreateNewSupplierModal(false);
                  createSupplier();
                }}
              >
                Submit
              </button>
            </Modal.Footer>
          </Modal>
        )}
        <div className="d-flex justify-content-between align-items-center mb-3">
          <h5>Create or Block suppliers</h5>
          <button
            className="btn btn-success btn-sm"
            onClick={() => {
              setShowCreateNewSupplierModal(true);
            }}
          >
            Create suplier
          </button>
        </div>
        <div className="supplierListSubHeading bg-light p-3 mb-1 text-muted">
          <div className="row">
            <div className="col-2 ">S.No.</div>
            <div className="col-2 ">Full Name</div>
            <div className="col-3 ">Email</div>
            <div className="col-2 ">isAdmin</div>
            <div className="col-3 ">isLoginBlocked</div>
          </div>
        </div>
        {suppliers &&
          suppliers?.map((supplier, index) => {
            return (
              <div className="supplierListItem border p-3 mb-2">
                <div className="row" key={supplier?.id}>
                  <div className="col-2 ">{index + 1}.</div>
                  <div className="col-2 ">
                    {supplier?.firstName} {supplier?.lastName}
                  </div>
                  <div className="col-3 ">{supplier?.email}</div>
                  <div className="col-2 ">{supplier?.isAdmin.toString()}</div>
                  <div className=" col-3  form-check form-switch">
                    <input
                      checked={supplier?.isLoginBlocked}
                      onChange={(e) => {
                        editIsLoginBloked(supplier, e.target.checked);
                      }}
                      className="form-check-input"
                      type="checkbox"
                      role="switch"
                      id="flexSwitchCheckDefault"
                    />
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};
