import React, { ReactNode, useEffect } from "react";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

export const ReCaptchaWrapper: React.FC<{
  children: ReactNode;
}> = (props) => {
  const CAPTCHA_SITE_KEY = "6Ldhd6QUAAAAAHuViiVYDUCdOqdKj-zd2hN-IJa-";

  return (
    <GoogleReCaptchaProvider reCaptchaKey={CAPTCHA_SITE_KEY}>
      {props.children}
    </GoogleReCaptchaProvider>
  );
};
