import { useEffect, useState } from "react";
import {
  ITourOptionTransferWithPrice,
  ITourOptionWithTransfer,
} from "../../activitiescommonfnb/types/activitySearch.interface";
import { ReactDatePicker } from "../../commonUi/ReactDatePicker";
import { useParams, useSearchParams } from "react-router-dom";
import TourOptionItemStyle from "./TourOptionItemStyle.module.scss";
import Price from "../../commonUi/Price";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { ActivitySessionActions } from "../../slice/session.slice";
import moment from "moment";
import TourOptionTimeSlot from "./TourOptionTimeSlot";
import { TourOptionHelper } from "../../functions/TourOptionHelper";
import { IBookingTourOption } from "../../activitiescommonfnb/types/booking.interface";
import { IPaxInfo } from "../common/Search/PaxInfoPopOver";
import _ from "lodash";
import { EnumTourOptionTransferType } from "../../activitiescommonfnb/types/tour.interface";

export const TourOption: React.FC<{
  onSelectedTranferChange: (
    selectedTransfer: ITourOptionTransferWithPrice
  ) => void;
  onTourOptionDateChange: (
    date: string,
    updatedTourOption: ITourOptionWithTransfer,
    selectedTransfer: ITourOptionTransferWithPrice
  ) => void;
  tourOptionDetails: ITourOptionWithTransfer;
  isComboTour: boolean;
  paxInfo: IPaxInfo;
  travelDate: string;
}> = (props) => {
  const dispatch = useDispatch();
  const [params] = useSearchParams();
  const { cityslug } = useParams();
  const adultCount = parseInt(params.get("adult") || "1");
  const childCount = parseInt(params.get("child") || "0");
  const infantCount = parseInt(params.get("infant") || "0");
  const fromTravelDate = params.get("from");
  const toTravelDate = params.get("to");
  const [loading, setLoading] = useState(false);
  const [showTourOptionTimeSlots, setShowTourOptionTimeSlots] = useState(false);
  const [selectedDate, setSelectedDate] = useState<{
    from: Date;
    to: Date;
    selected: Date;
  }>({
    from: moment(fromTravelDate).toDate(),
    to: moment(toTravelDate).toDate(),
    selected: moment(fromTravelDate).toDate(),
  });

  const [selectedTranfer, setSelectedTranfer] =
    useState<ITourOptionTransferWithPrice>(
      props.tourOptionDetails.transfers[0] || {}
    );

  const getTotalPrice = () => {
    const totalPrice =
      selectedTranfer.adultPrice * adultCount +
      selectedTranfer.childPrice * childCount +
      selectedTranfer.infantPrice * infantCount;
    return totalPrice;
  };

  const addToCart = (timeSlotId: string | null) => {
    const bookingTourOptionData: IBookingTourOption =
      TourOptionHelper.tranformTourOptionToBookingTourOption({
        tourOption: props.tourOptionDetails,
        transfer: selectedTranfer,
        travelDate: moment(selectedDate.selected).format("YYYY-MM-DD"),
        adultCount: adultCount,
        childCount: childCount,
        infantCount: infantCount,
        timeSlotId: timeSlotId,
      });

    dispatch(
      ActivitySessionActions.addTourOptionInActiveSession({
        isComboTour: false,
        tourId: props.tourOptionDetails.tourId,
        tourOptions: [bookingTourOptionData],
      })
    );
    setShowTourOptionTimeSlots(false);
  };

  const onDateChange = async (date: any) => {
    setSelectedDate((old) => ({ ...old, selected: date }));
    setLoading(true);
    const traveDate = moment(date).format("YYYY-MM-DD");

    const tourDetails = await TourOptionHelper.getSingleTourOptionWithTransfer({
      tourId: props.tourOptionDetails.tourId,
      tourOptionId: props.tourOptionDetails.id,
      citySlug: cityslug || "",
      adultCount: adultCount,
      childCount: childCount,
      infantCount: infantCount,
      travelDate: traveDate,
    });

    setLoading(false);

    const transferDetails = tourDetails?.transfers.find((transfer) => {
      return transfer?.transferType === selectedTranfer.transferType;
    });

    if (tourDetails && transferDetails) {
      setSelectedTranfer(transferDetails);
      props.onTourOptionDateChange(traveDate, tourDetails, selectedTranfer);
    } else {
      toast.error("Unable to get tour option details");
    }
  };

  const { from, to, selected } = selectedDate;
  const startDate = moment(from);
  const endDate = moment(to);
  const daysDifference = endDate.diff(startDate, "days");

  const dateOptions = _.range(0, daysDifference + 1).map((day) => {
    const date = startDate.clone().add(day, "days");
    return {
      day: day + 1,
      date: date.format("YYYY-MM-DD"),
    };
  });

  return (
    <div className={TourOptionItemStyle.TourOptionItem}>
      <div className="row align-items-center">
        <div className="col-md-3 col-12">
          <div>{props.tourOptionDetails.tourOptionName}</div>
        </div>
        <div className="col-md-3 col-sm-5 col-6">
          <select
            className="form-select small"
            value={selectedTranfer.transferType}
            name="transferOption"
            id=""
            onChange={(e) => {
              const foundTransfer = props.tourOptionDetails.transfers.find(
                (transfer) => {
                  return transfer?.transferType === e.target.value;
                }
              );
              if (foundTransfer) {
                setSelectedTranfer(foundTransfer);
                props.onSelectedTranferChange(foundTransfer);
              }
            }}
          >
            {props.tourOptionDetails?.transfers.map((transfer) => {
              return (
                <option value={transfer.transferType} key={transfer.id}>
                  {transfer.transferType ===
                  EnumTourOptionTransferType.WITHOUT_TRANSFER
                    ? "Ticket Only (No Transfer)"
                    : transfer.transferType ===
                      EnumTourOptionTransferType.PRIVATE
                    ? "With Private Transfer"
                    : "With Sharing Transfer"}
                </option>
              );
            })}
          </select>
        </div>
        <div className="col-md-2 col-sm-3 col-6">
          <select
            className="form-control py-2 small w-100"
            value={moment(selected).format("YYYY-MM-DD")}
            onChange={(e) => onDateChange(moment(e.target.value).toDate())}
          >
            {dateOptions.map(({ day, date }) => (
              <option key={day} value={date}>
                Day {day}: {date}
              </option>
            ))}
          </select>
        </div>

        <div className="col-md-2 col-sm-3 col-6">
          <div className={TourOptionItemStyle.TourOptionItemPrice}>
            {loading ? (
              "loading..."
            ) : (
              <Price amount={getTotalPrice()} currency={"₹"} />
            )}
          </div>
        </div>
        {!props.isComboTour && (
          <div className="col-sm-2 col-6">
            <button
              onClick={() => {
                if (props.tourOptionDetails.hasSlot) {
                  setShowTourOptionTimeSlots(true);
                } else {
                  addToCart(null);
                }
              }}
              disabled={loading}
              className="btn text-white bg-secondary btn-sm w-100"
            >
              <i className="fa-solid fa-cart-plus"></i> Add
            </button>
          </div>
        )}
      </div>
      {showTourOptionTimeSlots && (
        <TourOptionTimeSlot
          show={showTourOptionTimeSlots}
          onClose={() => {
            setShowTourOptionTimeSlots(false);
          }}
          addToCart={addToCart}
          selectedTransferDetail={selectedTranfer}
          paxInfo={props.paxInfo}
          travelDate={props.travelDate}
          tourId={props.tourOptionDetails.tourId}
        />
      )}
    </div>
  );
};
