import { ReactNode } from "react";
import { Spinner } from "react-bootstrap";

type btnTypes = "button" | "submit" | "reset";

export const LoadingButton: React.FC<{
  loading: boolean;
  onClick?: () => void;
  btnClass?: string;
  btnType?: btnTypes;
  minWidth?: string;
  disabled?: boolean;
  children: ReactNode;
}> = ({
  loading,
  onClick,
  btnClass,
  btnType = "button",
  minWidth = "101px",
  children,
  disabled,
}) => {
  return (
    <button
      type={btnType}
      className={`btn search-button ${btnClass}`}
      onClick={onClick}
      disabled={disabled || loading}
    >
      {loading ? (
        <div style={{ minWidth: minWidth }} className="">
          <Spinner animation="border" size="sm" />
        </div>
      ) : (
        children
      )}
    </button>
  );
};
