import React, { useEffect, useState } from "react";
import moment from "moment";
import { AppRangeDatePicker } from "../../commonUi/AppRangeDatePicker";
import { IDateRange } from "../../commonUi/AppRangeDatePicker";
import dashboardViewStyle from "./Dashboard.module.scss";

export enum EnumEnquiryFilter {
  TODAY = "today",
  PREVIOUS_WEEK = "previous_week",
}

const DashboardDateFilters: React.FC<{
  onFilterChange: (filters: any) => void;
}> = (props) => {
  const defaultDateRange: IDateRange = {
    from: moment().startOf("day").toDate(), 
    to: moment().endOf("day").toDate(),
  };

  const [dateRange, setDateRange] = useState<IDateRange>(defaultDateRange);
  const [enquiryFilter, setEnquiryFilter] = useState<EnumEnquiryFilter>(
    EnumEnquiryFilter.TODAY
  );

  useEffect(() => {
    if (dateRange.from && dateRange.to) {
      props.onFilterChange(dateRange);
    }
  }, [dateRange]);

  const handleSelect = (selectedFilter: EnumEnquiryFilter | null) => {
    if (selectedFilter) {
      setEnquiryFilter(selectedFilter);
    }

    if (selectedFilter === EnumEnquiryFilter.TODAY) {
      setDateRange({
        from: moment().startOf("day").toDate(),
        to: moment().endOf("day").toDate(),
      });
    } else if (selectedFilter === EnumEnquiryFilter.PREVIOUS_WEEK) {
      setDateRange({
        from: moment().subtract(7, "days").startOf("day").toDate(),
        to: moment().endOf("day").toDate(),
      });
    }
  };

  return (
    <>
      <div className={dashboardViewStyle.dateFilter} id="dasboard-date-filter">
        <label
          htmlFor=""
          className="d-flex align-items-center justify-content-between small mb-1"
        >
          <span>Select Dates</span>
          <span>
            {" "}
            <button
              className={`btn ${
                enquiryFilter === EnumEnquiryFilter.TODAY
                  ? "btn-primary"
                  : " text-muted"
              } badge`}
              onClick={() => {
                handleSelect(EnumEnquiryFilter.TODAY);
              }}
            >
              Today
            </button>
            <button
              className={`btn ${
                enquiryFilter === EnumEnquiryFilter.PREVIOUS_WEEK
                  ? "btn-primary"
                  : " text-muted"
              } badge`}
              onClick={() => {
                handleSelect(EnumEnquiryFilter.PREVIOUS_WEEK);
              }}
            >
              Previous Week
            </button>
          </span>
        </label>
        <AppRangeDatePicker
          // label="Select Dates"
          containerClassName=""
          wrapperClassName="w-100"
          dateRange={dateRange}
          onChange={(date) => {
            setDateRange(date);
          }}
        />
        {/* <Tabs
            id="controlled-tab-example"
            activeKey={key}
            onSelect={handleSelect}
            className="mb-3 border-0 nav-pills"
          >
            <Tab eventKey="Today" title="Today"></Tab>
            <Tab eventKey="Previous Week" title="Previous Week"></Tab>
          </Tabs> */}
      </div>
    </>
  );
};

export default DashboardDateFilters;
