import { useEffect, useState } from "react";
import { activityAxios } from "../../axios/activityAxios";
import { CreateOrEditTaskPopup } from "../../components/Tasks/CreateOrEditTaskPopup";
import { ITask } from "../../activitiescommonfnb/types/task.interface";
import { SingleTaskItem } from "../../components/Tasks/SingleTaskItem";
import { IJSONResponse } from "../../activitiescommonfnb/types/common.interface";
import { toast } from "react-toastify";
import manageTaskPageStyle from "./manageTaskStyle.module.scss";
import { Placeholder } from "react-bootstrap";

const TaskListSkeleton = () => {
  return (
    <>
      <div className="border-bottom p-3">
        <Placeholder animation="glow">
          <Placeholder xs={12} />
          <Placeholder xs={12} size="xs" />
        </Placeholder>
      </div>
    </>
  );
};

export const ManageTasks: React.FC = () => {
  const [showNewTaskPopup, setShowNewTaskPopup] = useState(false);
  const [isTaskDataLoading, setIsTaskDataLoading] = useState(false);
  const [tasks, setTasks] = useState<ITask[]>([]);

  const getTasks = async () => {
    setIsTaskDataLoading(true);
    const apiRes = await activityAxios.post<IJSONResponse>("/task/getAllTask");
    if (apiRes.data.success && apiRes.data.result) {
      setTasks(apiRes.data?.result);
    } else {
      toast.error(apiRes.data.errorMessage);
    }

    setIsTaskDataLoading(false);
  };
  useEffect(() => {
    getTasks();
  }, []);
  return (
    <div className={manageTaskPageStyle.manageTaskPage}>
      <div className="container">
        <div className="d-flex justify-content-between align-items-center pb-4">
          <h6 className="mb-0">Manage Tasks</h6>
          <span>
            <button
              className="btn btn-primary"
              onClick={() => {
                setShowNewTaskPopup(true);
              }}
            >
              + Create task
            </button>
          </span>
        </div>

        <div className="container-fluid">
          <div
            className={`row align-items-end small p-3 ${manageTaskPageStyle.labelRow}`}
          >
            <div className="col-3">Title</div>
            <div className="col-1">Deadline</div>
            <div className="col-1">Reminder Start Date</div>
            <div className="col-1">Reminder Start Time</div>

            <div className="col-1">
              Reminder <br /> End Time
            </div>
            <div className="col-1">Frequency</div>
            <div className="col-1">Photo Proof Required</div>
            <div className="col-1">Status</div>
            <div className="col-1"></div>
          </div>

          {isTaskDataLoading && (
            <div>
              <TaskListSkeleton />
              <TaskListSkeleton />
              <TaskListSkeleton />
              <TaskListSkeleton />
              <TaskListSkeleton />
              <TaskListSkeleton />
            </div>
          )}
        </div>

        <div className="container-fluid">
          {!isTaskDataLoading &&
            tasks.map((task, index) => {
              return (
                <SingleTaskItem
                  singleTaskItem={task}
                  taskIndex={index}
                  updateTask={(updatedTask) => {
                    setTasks((old) => {
                      old[index] = { ...updatedTask };

                      return [...old];
                    });
                  }}
                  getTasks={getTasks}
                  key={task.id}
                />
              );
            })}
        </div>

        {tasks.length === 0 && !isTaskDataLoading && (
          <div className="text-danger text-center">No Task Found</div>
        )}

        {showNewTaskPopup && (
          <CreateOrEditTaskPopup
            show={showNewTaskPopup}
            onHide={() => {
              setShowNewTaskPopup(false);
            }}
            onSave={() => {
              setShowNewTaskPopup(false);
              getTasks();
            }}
          />
        )}
      </div>
    </div>
  );
};
