import { useEffect, useState } from "react";
import { activityAxios } from "../../axios/activityAxios";
import { IJSONResponse } from "../../activitiescommonfnb/types/common.interface";
import { IBookinkData } from "../../activitiescommonfnb/types/booking.interface";
import { toast } from "react-toastify";
import QueuePageStyle from "./TourBookingQueuePageStyle.module.scss";
import QueueTourOptionItem from "../../components/BookingQueue/QueueTourOptionItem";
import moment from "moment";
import { Offcanvas, Placeholder } from "react-bootstrap";
import BookingQueueFilter from "../../components/BookingQueue/BookingQueueFilter";

const TourBookingQueueSkeleton = () => {
  return (
    <div>
      <div className="border bg-white rounded mb-3">
        <div className="row">
          <div className="col-lg-12">
            <div className="py-2 px-3 border-bottom rounded">
              <Placeholder animation="glow">
                <Placeholder xs={3} size="xs" />
              </Placeholder>
            </div>
          </div>
          <div className="col-lg-10">
            <div className="p-3">
              <Placeholder animation="glow">
                <Placeholder xs={6} size="xs" />
                <Placeholder xs={8} size="xs" />
                <Placeholder xs={5} size="xs" />
              </Placeholder>
            </div>
          </div>
          <div className="col-lg-2 ps-0">
            <div className="bg-light h-100 p-3 ">
              <Placeholder animation="glow">
                <Placeholder xs={12} size="xs" />
                <Placeholder xs={12} size="xs" />
                <Placeholder xs={12} size="xs" />
              </Placeholder>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const TourBookingsQueue: React.FC<{}> = () => {
  const [bookings, setBookings] = useState<IBookinkData[]>([]);
  const [loadBookings, setLoadBookings] = useState(false);

  const [showBookingQueueFilterCanvas, setShowBookingQueueFilterCanvas] =
    useState(false);

  const getBookings = async () => {
    setLoadBookings(true);
    const apiRes = await activityAxios.post<IJSONResponse<IBookinkData[]>>(
      "/booking/getBookings",
      {}
    );
    if (apiRes.data.success && apiRes.data.result) {
      setBookings(apiRes.data.result);
    } else {
      toast.error(apiRes.data.errorMessage || "Something went wrong");
    }
    setLoadBookings(false);
  };
  // console.log(bookings);

  useEffect(() => {
    getBookings();
  }, []);
  return (
    <div className={QueuePageStyle.tourBookingQueuePage}>
      <div className="headerImage">
        <div className="container">
          <h3>Activity Bookings</h3>
        </div>
      </div>
      <div className="mt-4">
        <div className="container">
          <div className="row">
            <div className="col-lg-3">
              <div className={QueuePageStyle.queueFilterDesktop}>
                <BookingQueueFilter />
              </div>
            </div>
            <div className="col-lg-9">
              <div className={QueuePageStyle.queueListHeading}>
                <div>
                  <h6>Activity Bookings</h6>
                </div>
                <div className={QueuePageStyle.bookingQueueFilterMobile}>
                  <button
                    className="btn btn-outline-secondary btn-sm"
                    onClick={() => {
                      setShowBookingQueueFilterCanvas(true);
                    }}
                  >
                    Filter <i className="fa-solid fa-filter ms-1"></i>
                  </button>
                </div>
              </div>

              <Offcanvas
                show={showBookingQueueFilterCanvas}
                onHide={() => {
                  setShowBookingQueueFilterCanvas(false);
                }}
              >
                <Offcanvas.Body className="px-0">
                  <div
                    className={`${QueuePageStyle.queueFilterMobile} d-flex flex-column align-content-between justify-content-between h-100 `}
                  >
                    <BookingQueueFilter />
                    <div className="container-fluid">
                      <div className="row mx-1">
                        <div className="col-6">
                          <button
                            className="btn btn-outline-primary w-100"
                            onClick={() => {
                              setShowBookingQueueFilterCanvas(false);
                            }}
                          >
                            Cancel
                          </button>
                        </div>
                        <div className="col-6">
                          <button className="btn btn-primary w-100">
                            Apply
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </Offcanvas.Body>
              </Offcanvas>

              {!loadBookings ? (
                <div>
                  {bookings.length > 0 ? (
                    bookings?.map((bookingItem) => {
                      return (
                        <div className={QueuePageStyle.bookingQueue}>
                          <div className={QueuePageStyle.cartNameRow}>
                            <div className="row align-items-center">
                              <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                                <div className={QueuePageStyle.cartName}>
                                  Cart Name
                                </div>
                              </div>
                              <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                                <div className="small text-end">
                                  <span className="text-secondary fw-bold">
                                    {moment(bookingItem.from).format("ll")}
                                  </span>{" "}
                                  -{" "}
                                  <span className="text-secondary fw-bold">
                                    {moment(bookingItem.to).format("ll")}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                          {bookingItem.bookingTourOptions.map(
                            (tourOptionItem) => {
                              return (
                                <>
                                  <QueueTourOptionItem
                                    queueTourOptionDetails={tourOptionItem}
                                  />
                                </>
                              );
                            }
                          )}
                        </div>
                      );
                    })
                  ) : (
                    <div className="border text-center py-5 bg-white rounded">
                      <div className=" text-danger">No Bookings</div>
                    </div>
                  )}
                </div>
              ) : (
                <>
                  <TourBookingQueueSkeleton />
                  <TourBookingQueueSkeleton />
                  <TourBookingQueueSkeleton />
                  <TourBookingQueueSkeleton />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
