import _, { capitalize } from "lodash";
import moment from "moment";
import {
  ErrorMapCtx,
  ZodError,
  ZodIssueCode,
  ZodIssueOptionalMessage,
  z,
} from "zod";

export class ZodHelperService {
  static getZodDate = () => {
    return z.union([
      z.date(),
      z.string().transform((date, ctx) => {
        const momentDateObj = moment(date);

        if (!momentDateObj.isValid()) {
          ctx.addIssue({
            code: "invalid_date",
            message: "Invalid date.",
          });
        }
        return momentDateObj.toDate();
      }),
    ]);
  };

  /**
   * @description This error map is implemented for custom error message with field name,
   * this is not implemented completely yet. Modify this if needed
   *
   * This error map works fine for objects, arrays, string, number, enums.
   * this may show give abnormal behaviour for array of array.
   */
  static errorMap(error: ZodIssueOptionalMessage, ctx: ErrorMapCtx) {
    const errorMap = {
      message: error.message || ctx.defaultError,
    };

    /**
     * if error.message already exist return the message
     * it will exists in case when provided with refine or superrefine
     */
    if (error.message) {
      return errorMap;
    }

    const curField = error.path[error.path.length - 1];

    // curField can be undefined when the data to validate is not provided or null
    let fieldName = curField || "Data";

    // if the type of curField is number i.e., there is an error in an element of an array
    if (typeof curField === "number" && error.path.length >= 2) {
      fieldName = `${error.path[error.path.length - 2]} ${curField + 1}`;
    }

    if (typeof fieldName === "string") {
      fieldName = capitalize(fieldName);
    }

    switch (error.code) {
      case ZodIssueCode.invalid_type:
        errorMap.message = `${fieldName} is invalid`;
        break;
      case ZodIssueCode.too_small:
        const fieldType = error.type;
        if (fieldType === "string") {
          errorMap.message = `${fieldName} must contain at least ${error.minimum} characters`;
        } else if (fieldType === "array") {
          errorMap.message = `Atleast ${error.minimum} ${fieldName} is required`;
        }
        break;
      case ZodIssueCode.invalid_enum_value:
        errorMap.message = `${fieldName} can contain only ${error.options.join(
          ", "
        )}`;
    }

    return errorMap;
  }

  // zodErrorFormatter ------------------------->
  static formatZodErrors(error: ZodError, describe: string) {
    const errorMap: {
      [key: string]: string;
    } = {};
    error.errors.forEach((error) => {
      if (error.path.length > 0) {
        _.set(errorMap, error.path.join("."), error.message);
      } else {
        errorMap[describe] = error.message;
      }
    });

    return errorMap;
  }
}
