import React from "react";
import { Link } from "react-router-dom";
import { IMenuItemDetail } from "./MainHeader";

const SideMenuItems: React.FC<{
  menuItemDetails: IMenuItemDetail;
  onClick: () => void;
}> = (props) => {
  return (
    <>
      <li className="nav-item">
        <Link
          className="nav-link"
          aria-disabled="true"
          to={props.menuItemDetails.url}
          onClick={props.onClick}
        >
          <img src={props.menuItemDetails.icon} className="img-fluid" alt="" />{" "}
          {props.menuItemDetails.name}
        </Link>
      </li>
    </>
  );
};

export default SideMenuItems;
