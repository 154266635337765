import { ICartItem } from "../activitiescommonfnb/types/booking.interface";

export const isTourOptionOverlapping = (
  startTime: number,
  cartItems: ICartItem[]
): boolean => {
  const tourOptionsArray =
    cartItems?.flatMap((cartItem) => cartItem.tourOptions) || [];

  for (const option of tourOptionsArray) {
    if (option?.startTime && option.durationInMinutes && option.buffer) {
      const existingStartTime = option?.startTime - option.buffer;
      const existingEndTime =
        option?.startTime + option.durationInMinutes + option.buffer;

      if (startTime >= existingStartTime && startTime < existingEndTime) {
        return true;
      }
    }
  }

  return false;
};
