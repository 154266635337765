import { useAppSelector } from "../../store";
import cartPageStyle from "./cartPageStyle.module.scss";
import { CartItem } from "../../components/cart/CartItem";
import Price from "../../commonUi/Price";
import { AppRegisterInput } from "../../commonUi/AppInput";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { CartWrapper } from "../../components/cart/CartWrapper";
import { ErrorMessage } from "../../commonUi/ErrorMessage";
import { getPaxCount, getTotalAmount } from "../../helper/cartHelper";
import {
  createQuotationSchema,
  TCreateQuotation,
} from "../../activitiescommonfnb/schemas/cart.schema";
import { activityAxios } from "../../axios/activityAxios";
import { toast } from "react-toastify";
import moment from "moment";
import { useState } from "react";
import { generateDateRange } from "../../helper/globalHelper";
import { formatDate } from "../../functions/commonFx";
import { Spinner } from "react-bootstrap";
import { IJSONResponse } from "../../activitiescommonfnb/types/common.interface";
import { ISession } from "../../activitiescommonfnb/types/booking.interface";
import { Link, useNavigate } from "react-router-dom";

const CartDetails = () => {
  const { activeSession: sessionData } = useAppSelector(
    (state) => state.activitySession
  );
  const [loading, setLoading] = useState(false);
  const quotationTitle = "New Quotation";
  const travelDate = sessionData?.cartItems[0]?.tourOptions[0].travelDate;
  const adultCount = getPaxCount(sessionData).adultCount;
  const childCount = getPaxCount(sessionData).childCount;
  const infantCount = getPaxCount(sessionData).infantCount;
  const passengerCount = adultCount + childCount + infantCount;
  const navigate = useNavigate();

  const [childrenAges, setChildrenAges] = useState<number[]>(
    Array(childCount).fill(0)
  );

  const dateRange =
    sessionData?.from && sessionData?.to
      ? generateDateRange(sessionData?.from, sessionData?.to)
      : [];

  const tourIds = sessionData?.cartItems
    .filter((tourOption) => tourOption.tourId)
    .map((tourOption) => tourOption.tourId);

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<TCreateQuotation>({
    resolver: zodResolver(createQuotationSchema),
    defaultValues: {
      travelDate: moment(travelDate).toDate(),
      leadPassengerName: "",
      title: `${quotationTitle} | ${travelDate} | ${passengerCount} `,
    },
  });

  const handleChildAgeChange = (index: number, age: number) => {
    const newAges = [...childrenAges];
    newAges[index] = age;
    setChildrenAges(newAges);
  };

  const createSession = async () => {
    if (loading) return;
    setLoading(true);
    const apiRes = await activityAxios.post<IJSONResponse<ISession>>(
      "/booking/generateSession",
      {
        sessionData: sessionData,
      }
    );
    if (apiRes.data.success) {
      return apiRes.data;
    } else {
      toast.error(apiRes.data.errorMessage || "Something went wrong");
    }
    setLoading(false);
  };

  const createQuotation = async (data: TCreateQuotation) => {
    setLoading(true);
    const createSessionRes = await createSession();
    navigate(
      `/checkout/cartDetails?sessionSlug=${createSessionRes?.result?.sessionSlug}`
    );
    const passengers = [
      ...Array(adultCount).fill({
        paxType: "adult",
      }),
      ...Array(infantCount).fill({
        paxType: "infant",
      }),
      ...childrenAges.map((age) => ({ paxType: "child", age })),
    ];
    const payload = {
      ...data,
      passengers: passengers,
      tourIds: tourIds,
      sessionId: createSessionRes?.result?.id,
    };

    const apiRes = await activityAxios.post("/booking/createBookingQuotation", {
      data: payload,
    });

    if (apiRes.data.success) {
      toast.success("Quotation created successfully");
      navigate("/supplier/cart");
    }
    setLoading(false);
  };

  return (
    <div id="cartPage-pk" className={cartPageStyle.cartPage}>
      <div className="container">
        <div className="row">
          <div className="col-lg-8">
            <div className="">
              <div className="text-primary bg-light border p-3 mb-3 rounded fw-bold d-flex justify-content-between align-items-center">
                <h5 className="mb-0">Kart</h5>
                <button
                  className="btn btn-primary btn-sm badge rounded py-2 px-3 fw-bold"
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  <i className="fa-solid fa-plus"></i> Add Activities
                </button>
              </div>
              <div>
                {dateRange.map((date, dayIndex) => {
                  const formattedDate = formatDate(date);
                  const cartItemsForDay = sessionData?.cartItems.filter(
                    (cartItem) =>
                      cartItem.tourOptions.some(
                        (option) =>
                          formatDate(option.travelDate) === formattedDate
                      )
                  );

                  return (
                    <div key={dayIndex} className="mb-2 border rounded">
                      <div className=" bg-light rounded-top px-3 py-2 small">
                        <div className="d-flex justify-content-between align-items-center">
                          <span>Day {dayIndex + 1}</span>
                          <span>{formattedDate}</span>
                        </div>
                      </div>
                      {cartItemsForDay && cartItemsForDay.length > 0 ? (
                        cartItemsForDay.map((cartItem, index) => (
                          <div
                            key={index}
                            className={`px-3 ${cartPageStyle.cartItemDetails}`}
                          >
                            {cartItem.tourOptions.map(
                              (singleTourOption, index) => (
                                <CartItem
                                  tourOptionDetail={singleTourOption}
                                  showMoreDetails={true}
                                  key={index}
                                />
                              )
                            )}
                          </div>
                        ))
                      ) : (
                        <p className="text-danger text-center py-3 small">
                          No activities available
                        </p>
                      )}
                    </div>
                  );
                })}
              </div>
              <div className={` ${cartPageStyle.finalPayment}`}>
                <h6 className="m-0">Final Payment</h6>
                <span className="fs-6 fw-bold">
                  <Price
                    amount={getTotalAmount(sessionData?.cartItems)}
                    currency="₹"
                  />
                </span>
              </div>
            </div>
          </div>
          <div className="ps-3 col-lg-4">
            <form
              className={`border bg-white rounded px-4 py-4 sticky-top`}
              onSubmit={handleSubmit(createQuotation)}
            >
              <h6 className="text-primary pb-3">Kart Details</h6>
              <div className="mb-3">
                <label className="required small">Title</label>
                <AppRegisterInput register={register} name="title" />
                <ErrorMessage errorMessage={errors.title?.message} />
              </div>
              <div className="mb-3">
                <label className="required small">Lead Passenger Name</label>
                <AppRegisterInput
                  register={register}
                  name="leadPassengerName"
                  required
                />
                <ErrorMessage
                  errorMessage={errors.leadPassengerName?.message}
                />
              </div>

              {childCount > 0 &&
                Array.from({ length: childCount }, (_, index) => (
                  <div className="mb-3" key={index}>
                    <label className="required small">
                      Age of Child {index + 1}
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      value={childrenAges[index]}
                      onChange={(e) =>
                        handleChildAgeChange(index, parseInt(e.target.value))
                      }
                      required
                    />
                  </div>
                ))}

              <button type="submit" className="btn btn-primary w-100">
                <span className="me-2"> Save Kart </span>
                {loading ? <Spinner animation="border" size="sm" /> : ""}
              </button>
            </form>
            {/* <div className="mt-5">
              <CartWrapper showCartItems={false} showCartData={false} />
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CartDetails;
