import { toast } from "react-toastify";
import {
  EnumSharingType,
  IQuotationDataTemplate,
  TQuotationDataZod,
} from "../activitiescommonfnb/types/quotation.interface";

export const getQuotationDataTemplate = (enquiryId: number | undefined) => {
  const quotationDataTemplate: IQuotationDataTemplate = {
    enquiryId: enquiryId!,
    quotationBody: "",
    title: "",
    visaCost: 0,
    travelDate: new Date(),
    flightCost: 0,
    hotels: [
      { hotelName: "", hotelCost: 0, sharingType: EnumSharingType.SINGLE },
    ],
    landPartCost: 0,
    markupPercentage: 0,
    showBreakup: true,
    internalRemark: "",
    HTMLData: "",
  };

  return quotationDataTemplate;
};

export const fields = [
  { name: "title", label: "Quotation Title", type: "text" },
  { name: "visaCost", label: "Visa Cost", type: "number" },
  { name: "flightCost", label: "Flight Cost", type: "number" },
  { name: "landPartCost", label: "Land Part Cost", type: "number" },
];

export const removeHotel = (
  e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  quotationData: TQuotationDataZod,
  setQuotationData: React.Dispatch<React.SetStateAction<TQuotationDataZod>>
) => {
  e.preventDefault();
  if (quotationData.hotels.length > 1) {
    const updatedHotels = quotationData.hotels.slice(0, -1);
    setQuotationData((old) => ({ ...old, hotels: updatedHotels }));
  }
};

export const addHotel = (
  e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  quotationData: TQuotationDataZod,
  setQuotationData: React.Dispatch<React.SetStateAction<TQuotationDataZod>>
) => {
  e.preventDefault();
  const lastHotel = quotationData.hotels[quotationData.hotels?.length - 1];
  if (lastHotel.hotelName && lastHotel.hotelCost >= 0) {
    setQuotationData({
      ...quotationData,
      hotels: [
        ...quotationData.hotels,
        { hotelName: "", hotelCost: 0, sharingType: EnumSharingType.SINGLE },
      ],
    });
  } else {
    toast.warning(
      "Please fill out the current hotel name and cost before adding another."
    );
  }
};
