import axios, { AxiosError } from "axios";
import { getAuthToken, getSupplierAuthToken } from "../functions/commonFx";

import _ from "lodash";
import { IJSONResponse } from "../activitiescommonfnb/types/common.interface";

const Axios = axios.create({
  baseURL: "/activities/supplier",
  headers: {
    "Content-Type": "application/json",
  },
});

Axios.interceptors.request.use(
  (config) => {
    if (config.headers) {
      return new Promise((resolve) => {
        const interval = setInterval(() => {
          // const siteData = localStorage.getItem("siteData");
          if (config.headers) {
            // if (siteData) {
            // const authToken = getSupplierAuthToken();
            // if (authToken) {
            //   config.headers["supplier-token"] = authToken;
            // }
            const authToken = getAuthToken();
            if (authToken) {
              config.headers["auth-token"] = authToken;
            }
            // config.headers["site-id"] = JSON.parse(siteData || "")._id;
            clearInterval(interval);
            resolve(config);
          }
          // }
        }, 100);
      });
      // }
    }

    return config;
  },

  (error) => Promise.reject(error)
);

// add try catch block in all api calls
Axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error: AxiosError<IJSONResponse>) => {
    let errorMessage = "Something went wrong. Please try again later.";

    if (error.response?.status === 500) {
      errorMessage = "Please check your internet connection and try again.";
    }

    if (error?.response?.data?.errorMessage) {
      errorMessage = error.response.data.errorMessage;
    }

    _.set(error, "response.data", {
      result: null,
      error: error.response?.data?.error || null,
      success: false,
      errorMessage,
    });

    return error.response;
  }
);

export const activitySupplierAxios = Axios;
