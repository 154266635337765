import { useAppDispatch, useAppSelector } from "../../store";
import headerStyle from "./HeaderStyle.module.scss";
import { Link, useNavigate } from "react-router-dom";
import { Collapse, Offcanvas } from "react-bootstrap";
import { activityAxios } from "../../axios/activityAxios";
import { setAuthData } from "../../slice/auth.slice";
import logo from "../../assets/images/packageKartLogo.png";
import { EnumUserType } from "../../activitiescommonfnb/types/User.interface";
import { useState } from "react";
import homeIcon from "../../assets/images/menuIcon/home.png";
import dashboardIcon from "../../assets/images/menuIcon/dashboard.png";
import enquiryIcon from "../../assets/images/menuIcon/enquiry.png";
import cartIcon from "../../assets/images/menuIcon/cart.png";
import settingIcon from "../../assets/images/menuIcon/setting.png";
import taskIcon from "../../assets/images/menuIcon/task.png";
import cityIcon from "../../assets/images/menuIcon/city.png";
import staffIcon from "../../assets/images/menuIcon/staff.png";
import priceIcon from "../../assets/images/menuIcon/price.png";
import SideMenuItems from "./SideMenuItems";

export interface IMenuItemDetail {
  name: string;
  icon: any;
  url: string;
  isStaffMenu?: boolean;
}
const menuItems: IMenuItemDetail[] = [
  {
    name: "Home",
    icon: homeIcon,
    url: "/",
    isStaffMenu: false,
  },
  {
    name: "Enquiry",
    icon: enquiryIcon,
    url: "/enquiries",
    isStaffMenu: true,
  },
  {
    name: "Dashboard",
    icon: dashboardIcon,
    url: "/enquiries/dashboard",
    isStaffMenu: true,
  },
  {
    name: "Kart",
    icon: cartIcon,
    url: "/supplier/cart",
    isStaffMenu: true,
  },
];

const settingsMenuItems: IMenuItemDetail[] = [
  {
    name: "City",
    icon: cityIcon,
    url: "/admin/master/cities",
  },
  {
    name: "Price",
    icon: priceIcon,
    url: "/supplier/managePrice",
  },
  {
    name: "Staff",
    icon: staffIcon,
    url: "/admin/manageStaff",
  },
];

const MainHeader: React.FC = () => {
  const userData = useAppSelector((state) => state.authData.data);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [showSideMenu, setShowSideMenu] = useState(false);
  const [openMenuDropdown, setOpenMenuDropdown] = useState(false);

  const logout = async () => {
    await activityAxios.post("/auth/logout");
    dispatch(setAuthData(null));
    setShowSideMenu(false);
    navigate("/login");
  };

  return (
    <nav
      className={`navbar navbar-expand-lg sticky-top  ${headerStyle.mainNavbar}`}
    >
      <div className="container">
        <a className="navbar-brand fw-bold text-primary text-end" href="/">
          <img className={`img-fluid ${headerStyle.logo}`} src={logo} alt="" />
        </a>
        <div className="d-flex align-items-center" id="pk2024-main-navbar">
          <ul className="navbar-nav ms-auto mb-2 mb-lg-0 small"></ul>

          {userData ? (
            <div
              className={`small z-3 text-primary text-muted ps-3 d-inline ${headerStyle.profileButton}`}
              role="button"
            >
              <span className="bg-primary rounded-pill p-1 px-2 me-1 fw-bold text-white">
                {userData.firstName[0] || "U"}
              </span>

              {userData.firstName || "User Name"}
            </div>
          ) : (
            <form role="search" className="ms-3">
              {window.location.pathname !== "/tour/dubai" && (
                <Link to={"/login"}>
                  <button className="btn btn-outline-primary btn-sm px-3 px-md-4">
                    Login
                  </button>
                </Link>
              )}

              <Link to={"/signUp"}>
                <button className="btn btn-primary btn-sm px-3 px-md-4 ms-2">
                  Sign up
                </button>
              </Link>
            </form>
          )}

          <button
            className="btn btn-primary btn-sm px-3 ms-2"
            onClick={() => {
              setShowSideMenu(true);
            }}
          >
            <i className="fa-solid fa-bars"></i>
          </button>

          <Offcanvas
            show={showSideMenu}
            onHide={() => {
              setShowSideMenu(false);
            }}
            placement="end"
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title>Menu</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body className="position-relative">
              <ul className="">
                <>
                  {/* customer menu items */}
                  {menuItems.map((item) => {
                    if (
                      item.isStaffMenu &&
                      userData?.userType !== EnumUserType.STAFF
                    ) {
                      return null;
                    }
                    return (
                      <>
                        <SideMenuItems
                          menuItemDetails={item}
                          onClick={() => setShowSideMenu(false)}
                        />
                      </>
                    );
                  })}

                  {userData?.userType === EnumUserType.STAFF && (
                    <>
                      <li
                        onClick={() => setOpenMenuDropdown(!openMenuDropdown)}
                        className={openMenuDropdown ? "li-active" : ""}
                      >
                        <div className="nav-link d-flex justify-content-between">
                          <span>
                            <img
                              src={settingIcon}
                              className="img-fluid"
                              alt=""
                            />
                            Settings
                          </span>
                          <span>
                            {openMenuDropdown ? (
                              <i className="fa-solid fa-angle-up"></i>
                            ) : (
                              <i className="fa-solid fa-angle-down"></i>
                            )}
                          </span>
                        </div>
                      </li>
                      <Collapse in={openMenuDropdown}>
                        <div id="menu-dropdown" className="">
                          <li className="nav-item">
                            <Link
                              to={"/manageTasks"}
                              className="nav-link"
                              onClick={() => {
                                setShowSideMenu(false);
                              }}
                            >
                              <img
                                src={taskIcon}
                                className="img-fluid"
                                alt=""
                              />{" "}
                              Task
                            </Link>
                          </li>
                          {userData?.isAdmin && (
                            <>
                              {settingsMenuItems.map((settingMenu) => {
                                return (
                                  <>
                                    <SideMenuItems
                                      menuItemDetails={settingMenu}
                                      onClick={() => setShowSideMenu(false)}
                                    />
                                  </>
                                );
                              })}
                            </>
                          )}
                        </div>
                      </Collapse>
                    </>
                  )}
                </>
              </ul>
              {userData && (
                <div className="position-absolute bottom-0 start-0 p-3 w-100">
                  <button
                    onClick={logout}
                    className="btn btn-secondary w-100 mt-2 py-2"
                  >
                    Logout
                  </button>
                </div>
              )}
            </Offcanvas.Body>
          </Offcanvas>
        </div>
      </div>
    </nav>
  );
};

export default MainHeader;
