import React, { useState } from "react";
import EnquiryListItemStyle from "./EnquiryListItemStyle.module.scss";
import {
  EnumEnquiryStatus,
  IEnquiry,
} from "../../activitiescommonfnb/types/enquiry.interface";
import CreateEnquiry from "./CreateEnquiry";
import moment from "moment";
import { EnumPaxType } from "../../activitiescommonfnb/schemas/booking.schema";
import _, { capitalize } from "lodash";
import { activityAxios } from "../../axios/activityAxios";
import { IJSONResponse } from "../../activitiescommonfnb/types/common.interface";
import { toast } from "react-toastify";
import { Collapse, Form, OverlayTrigger, Popover } from "react-bootstrap";
import { UserSelect } from "../../components/common/Selects/UserSelect";
import {
  TUpdateEnquiry,
  updateEnquirySchema,
} from "../../activitiescommonfnb/schemas/enquiry.schema";
import { CreateOrEditEnquiryNotes } from "./CreateOrEditEnquiryNotes";
import FollowUpPopup from "../../components/Enquiries/FollowUpPopup";
import { ZodHelperService } from "../../activitiescommonfnb/schemas/zodHelper.service";
import { LoadingButton } from "../../commonUi/LoadingButton";
import { useAppSelector } from "../../store";
import {
  calculateTimeSinceStaled,
  getLastUpdatedFollowup,
  rejectionStatuses,
  statusColor,
} from "../../helper/enquiryHelper";
import { capitaliseStr } from "../../helper/globalHelper";
import Price from "../../commonUi/Price";

const EnquiryListItem: React.FC<{
  enquiryDetail: IEnquiry;
  setEnquiry: (enquiry: IEnquiry) => void;
  getEnquiries: () => void;
  toggleQuotationOffCanvas: (k: boolean) => void;
  setSelectedEnquiry: (enquiry: IEnquiry) => void;
  showActionButtons: boolean;
  isCustomerView?: boolean;
  kartTotalPrice?: number;
}> = (props) => {
  const userData = useAppSelector((state) => state.authData.data);
  const [showEditEnquiryModal, setShowEditEnquiryModal] = useState(false);
  const [isUpdatingEnquiry, setIsUpdatingEnquiry] = useState(false);
  const [openInputCollapse, setOpenInputCollapse] = useState(false);
  const [showNotesModal, setShowNotesModal] = useState(false);
  const [showFollowUp, setShowFollowUp] = useState(false);
  const [rejectionError, setRejectionErrror] = useState("");
  const [updateEnquiryObj, setUpdateEnquiryObj] = useState<TUpdateEnquiry>({
    status: props.enquiryDetail.status,
    assignedToStaffId: props.enquiryDetail.assignedToStaffId,
    rejectionMessage: props.enquiryDetail.rejectionMessage,
  });

  let adultCount = 0;
  let childCount = 0;
  let infantCount = 0;

  props.enquiryDetail.passengers.forEach((pax) => {
    if (pax.paxType === EnumPaxType.ADULT) {
      adultCount++;
    } else if (pax.paxType === EnumPaxType.CHILD) {
      childCount++;
    } else if (pax.paxType === EnumPaxType.INFANT) {
      infantCount++;
    }
  });

  const updateEnquiry = async <K extends keyof TUpdateEnquiry>(
    key: K,
    value: TUpdateEnquiry[K]
  ) => {
    if (!props.enquiryDetail.id) {
      return;
    }
    setIsUpdatingEnquiry(true);

    let payload = { ...updateEnquiryObj };

    if (
      updateEnquiryObj.status &&
      !rejectionStatuses.includes(updateEnquiryObj.status)
    ) {
      payload.rejectionMessage = null;
    }
    setUpdateEnquiryObj(payload);

    const rejectionMessageValidation =
      updateEnquirySchema.safeParse(updateEnquiryObj);

    if (!rejectionMessageValidation.success) {
      const formattedError = ZodHelperService.formatZodErrors(
        rejectionMessageValidation.error,
        "Enquiry"
      );
      setRejectionErrror(formattedError.rejectionMessage);
      setIsUpdatingEnquiry(false);

      return;
    }

    const updateResponse = await activityAxios.post<IJSONResponse>(
      "/enquiry/updateEnquiry",
      {
        enquiryId: props.enquiryDetail.id,
        toBeUpdate: rejectionMessageValidation.data,
      }
    );

    if (updateResponse.data.success) {
      props.setEnquiry(updateResponse.data.result);
      toast.success(`updated successfully`);
      setOpenInputCollapse(false);
    } else {
      toast.error(updateResponse.data.errorMessage || "updated failed");
    }
    setIsUpdatingEnquiry(false);
  };

  const saveEnquiryNotes = async (enquiryNotes: string) => {
    const apiRes = await activityAxios.post<IJSONResponse>(
      "/enquiry/createOrEditEnquiryNotes",
      {
        notesData: {
          enquiryId: props.enquiryDetail.id,
          message: enquiryNotes,
          id: props.enquiryDetail?.notes?.id,
        },
      }
    );

    if (apiRes.data.success) {
      toast.success("notes saved successfully.");
      setShowNotesModal(false);
      props.setEnquiry({
        ...props.enquiryDetail,
        notes: {
          ...apiRes.data.result,
        },
      });
      // props.onSave();
    } else {
      toast.error(apiRes.data.errorMessage || "unable to save notes");
    }
  };

  const handleJustification = async (enquiryId: number) => {
    const updateResponse = await activityAxios.post<IJSONResponse>(
      "/enquiry/acceptJustification",
      {
        enquiryId: enquiryId,
      }
    );

    if (updateResponse.data.success) {
      props.setEnquiry({
        ...props.enquiryDetail,
        isJustificationAccepted: true,
      });
      toast.success(`Justification Accepted sucessfully`);
    } else {
      toast.error(updateResponse.data.errorMessage || "updated failed");
    }
  };

  const handleQuotationClick = () => {
    props.toggleQuotationOffCanvas(true);
  };

  return (
    <div>
      {!props.isCustomerView ? (
        <div
          className={EnquiryListItemStyle.enquiryListItem}
          onClick={() => props.setSelectedEnquiry(props.enquiryDetail)}
        >
          <div className={EnquiryListItemStyle.userDetails}>
            <div className={EnquiryListItemStyle.user}>
              <div># {props.enquiryDetail.id}</div>

              <div className=" ms-3">
                <span className={EnquiryListItemStyle.textLight}>User :</span>
                {props.enquiryDetail.user?.firstName}
              </div>
              <span className="mx-2">|</span>
              <div>{props.enquiryDetail.user?.phoneNumber}</div>
            </div>
            <div className="d-flex align-items-center justify-content-end mt-2 mt-md-0">
              <div
                className={`${statusColor(
                  props.enquiryDetail.status
                )} text-capitalize fw-bold  py-1 px-2 text-center mb-0 small`}
              >
                {props.enquiryDetail.status}
              </div>
              {props.showActionButtons && (
                <button
                  className="btn btn-sm btn-primary small ms-2 px-3"
                  onClick={() => props.toggleQuotationOffCanvas(true)}
                >
                  Quotations
                </button>
              )}
              {props.showActionButtons && (
                <div
                  className={`${EnquiryListItemStyle.deleteorEditEnquiryBtn} ms-2`}
                >
                  <button
                    className="btn btn-sm btn-secondary badge rounded-pill"
                    onClick={() => {
                      setShowEditEnquiryModal(true);
                    }}
                  >
                    <i className="fa-solid fa-pencil"></i>
                  </button>
                  {/* <button className="btn btn-sm btn-danger me-2 rounded-pill">
                  <i className="fa-solid fa-trash"></i>
                  </button> */}
                </div>
              )}

              <OverlayTrigger
                placement="bottom"
                overlay={
                  <Popover id="popover-basic">
                    <Popover.Body>
                      <div>
                        <span className={EnquiryListItemStyle.textLight}>
                          Created At:
                        </span>
                        {moment(props.enquiryDetail.createdAt).format("lll")}
                      </div>
                    </Popover.Body>
                  </Popover>
                }
              >
                <span className="p-2 ">
                  <i className="fa-solid fa-circle-info fa-xl	"></i>
                </span>
              </OverlayTrigger>
            </div>
          </div>
          <div className={EnquiryListItemStyle.enquiryDetails}>
            <div className="row mb-2">
              <div className="col-lg-3">
                <span className={EnquiryListItemStyle.textLight}>Title : </span>
                <div className={EnquiryListItemStyle.title}>
                  {props.enquiryDetail.title}
                </div>

                <span className={EnquiryListItemStyle.textLight}>
                  Destination :
                </span>
                <div className={EnquiryListItemStyle.destination}>
                  <div className="d-flex flex-wrap">
                    {props.enquiryDetail.destinations
                      ?.map((destination) => {
                        return destination.city?.name;
                      })
                      .join(" , ")}
                  </div>
                </div>
                <div className={EnquiryListItemStyle.pax}>
                  <span className={EnquiryListItemStyle.textLight}>Pax :</span>
                  <span className="text-muted fw-bold">
                    {adultCount} Ad , {childCount} Ch ,{infantCount} In
                  </span>
                </div>
                <span className={EnquiryListItemStyle.textLight}>
                  Customer :
                </span>
                <div className={EnquiryListItemStyle.destination}>
                  <div className="d-flex flex-wrap">
                    {props.enquiryDetail.user.firstName}{" "}
                    {props.enquiryDetail.user.lastName}{" "}
                  </div>
                </div>
              </div>
              <div className="col-lg-3">
                {/* <span
                    className={`${statusColor()} text-capitalize fw-bold w-50 py-1 px-2 text-center mb-1 small`}
                  >
                    {props.enquiryDetail.status}
                  </span> */}
                <span className={EnquiryListItemStyle.textLight}>
                  Lead Passenger :
                </span>
                <div className={EnquiryListItemStyle.guestName}>
                  {props.enquiryDetail.leadPassengerName}
                </div>
                <span className={EnquiryListItemStyle.textLight}>
                  Assigned To :
                </span>
                <div className={EnquiryListItemStyle.guestName}>
                  {props.enquiryDetail.assignedToStaffDetail ? (
                    props.enquiryDetail.assignedToStaffDetail.firstName +
                    props.enquiryDetail.assignedToStaffDetail.lastName
                  ) : (
                    <span className="text-danger">unassigned</span>
                  )}
                </div>
                {!rejectionStatuses.includes(props.enquiryDetail.status) &&
                  props.showActionButtons && (
                    <div
                      className="small py-2 alert-secondary text-secondary"
                      role="button"
                      onClick={() => setOpenInputCollapse(!openInputCollapse)}
                    >
                      Assigned / Status <i className="fas fa-chevron-down"></i>
                    </div>
                  )}
              </div>
              <div className="col-lg-3">
                <div>
                  <span className={EnquiryListItemStyle.textLight}>
                    Created By :
                  </span>
                  <div className={EnquiryListItemStyle.createdBy}>
                    {props.enquiryDetail.createdByStaffDetail.firstName}
                  </div>
                  <span className={EnquiryListItemStyle.textLight}>
                    Staled Since :
                  </span>
                  <div className={EnquiryListItemStyle.createdAt}>
                    {calculateTimeSinceStaled(props.enquiryDetail.updatedAt)}
                  </div>
                </div>

                {props.showActionButtons && (
                  <button
                    className={`btn py-0 mt-1 ${
                      props?.enquiryDetail?.enquiryFollowUps?.length > 0
                        ? "btn-outline-secondary w-100"
                        : "btn-success"
                    } btn-sm small`}
                    onClick={() => {
                      setShowFollowUp(true);
                    }}
                  >
                    {props?.enquiryDetail?.enquiryFollowUps?.length > 0 ? (
                      <div className="text-start p-1">
                        <b>Follow-up : </b>
                        {
                          getLastUpdatedFollowup(
                            props.enquiryDetail.enquiryFollowUps
                          )?.followUpMessage
                        }
                        <br />
                        <div className="fw-bold text-end">
                          {moment(
                            getLastUpdatedFollowup(
                              props.enquiryDetail.enquiryFollowUps
                            )?.createdAt
                          ).format("DD MMM , h:mm a")}
                          <i className="fa-solid fa-arrow-up-right-from-square ms-2"></i>
                        </div>
                      </div>
                    ) : (
                      "+ Follow Up"
                    )}
                  </button>
                )}
              </div>
              <div className="col-lg-3">
                {props.showActionButtons ? (
                  <div
                    className={`alert alert-warning small my-2 ${EnquiryListItemStyle.enquiryListItemNotes}`}
                  >
                    {props.enquiryDetail.notes ? (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: props.enquiryDetail.notes.message,
                        }}
                      ></div>
                    ) : (
                      "No Notes"
                    )}

                    <div
                      className={EnquiryListItemStyle.addIcon}
                      onClick={() => setShowNotesModal(true)}
                    >
                      <i className="fa-solid fa-pencil"></i>
                    </div>
                  </div>
                ) : (
                  <div>
                    <span className={EnquiryListItemStyle.textLight}>
                      Notes :
                    </span>
                    <div className={EnquiryListItemStyle.createdBy}>
                      {props.enquiryDetail.notes ? (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: props.enquiryDetail.notes.message,
                          }}
                        ></div>
                      ) : (
                        "No Notes"
                      )}{" "}
                    </div>
                  </div>
                )}
              </div>
            </div>

            {props.enquiryDetail.rejectionMessage && (
              <div className={EnquiryListItemStyle.rejectionItem}>
                <div className={""}>
                  <span className={EnquiryListItemStyle.textLight}>
                    Rejection Message :
                  </span>
                  <span className="ms-1 small">
                    {props.enquiryDetail.rejectionMessage}
                  </span>
                </div>
                {userData?.isSupervisor && (
                  <div>
                    {props.enquiryDetail.isJustificationAccepted ? (
                      <span className="small badge bg-success">
                        <i className="fa-solid fa-check me-1 "></i>
                        Justification Accepted
                      </span>
                    ) : (
                      <button
                        className="btn small py-0 mt-1 btn-sm mr-2 btn-primary"
                        onClick={() =>
                          handleJustification(props.enquiryDetail.id as number)
                        }
                      >
                        Accept Justification
                      </button>
                    )}
                  </div>
                )}
              </div>
            )}

            {props.enquiryDetail.internalRemark && (
              <div className={EnquiryListItemStyle.internalRemark}>
                <span className={EnquiryListItemStyle.textLight}>
                  Internal Remark :
                </span>
                {props.enquiryDetail.internalRemark}
              </div>
            )}

            <Collapse in={openInputCollapse} className="bg-light py-2 mt-3">
              <div className="row">
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 mb-2">
                  <label className={EnquiryListItemStyle.textLight} htmlFor="">
                    Status :{" "}
                  </label>

                  <Form.Select
                    aria-label="Default select example"
                    className={`small py-2`}
                    onChange={(e) => {
                      setUpdateEnquiryObj((old) => {
                        return {
                          ...old,
                          status: e.target.value as EnumEnquiryStatus,
                        };
                      });
                    }}
                    value={updateEnquiryObj.status}
                  >
                    {Object.values(EnumEnquiryStatus).map((status, index) => (
                      <option key={index} value={status}>
                        {capitalize(status)}
                      </option>
                    ))}
                  </Form.Select>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-6 mb-2">
                  <label className={EnquiryListItemStyle.textLight} htmlFor="">
                    Assigned To Staff :{" "}
                  </label>

                  <UserSelect
                    containerClassName="small"
                    // value={updateEnquiryObj.assignedToStaffId }
                    onChange={(e) => {
                      const assignedToStaffId = e?.id || null;

                      if (
                        assignedToStaffId ===
                        props.enquiryDetail.assignedToStaffId
                      ) {
                        return;
                      }
                      setUpdateEnquiryObj((old) => {
                        return {
                          ...old,
                          assignedToStaffId: assignedToStaffId,
                        };
                      });
                    }}
                    onlyStaff={true}
                  />
                </div>

                <div className="col-lg-12">
                  <div className="row align-items-end">
                    <div className="col-lg-8 col-md-8 col-sm-12 col-12">
                      {updateEnquiryObj.status &&
                        rejectionStatuses.includes(updateEnquiryObj.status) && (
                          <div className="mb-2 mb-md-0">
                            <label className={EnquiryListItemStyle.textLight}>
                              Reason For Rejection :{" "}
                            </label>
                            <textarea
                              style={{ resize: "none", overflow: "hidden" }}
                              value={
                                updateEnquiryObj.rejectionMessage as string
                              }
                              className="form-control small"
                              onChange={(e) => {
                                setUpdateEnquiryObj((old) => {
                                  return {
                                    ...old,
                                    rejectionMessage: e.target.value,
                                  };
                                });
                              }}
                            ></textarea>
                          </div>
                        )}
                      {rejectionError && (
                        <div className="text-danger small">
                          {rejectionError}
                        </div>
                      )}
                    </div>
                    <div className="col-lg-2 col-md-2 col-sm-6 col-6">
                      <LoadingButton
                        loading={isUpdatingEnquiry}
                        btnClass="btn btn-primary btn-sm w-100"
                        onClick={() =>
                          updateEnquiry(
                            "status",
                            updateEnquiryObj.status as EnumEnquiryStatus
                          )
                        }
                      >
                        Save
                      </LoadingButton>
                    </div>
                    <div className="col-lg-2 col-md-2 col-sm-6 col-6">
                      <button
                        className="btn btn-outline-danger btn-sm w-100"
                        onClick={() => {
                          props.setEnquiry({
                            ...props.enquiryDetail,
                            status: props.enquiryDetail.status,
                          });
                          setOpenInputCollapse(false);
                          setUpdateEnquiryObj({
                            status: props.enquiryDetail.status,
                            assignedToStaffId:
                              props.enquiryDetail.assignedToStaffId,
                            rejectionMessage:
                              props.enquiryDetail.rejectionMessage,
                          });
                        }}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Collapse>
          </div>

          <div className={EnquiryListItemStyle.remark}>
            <span className={EnquiryListItemStyle.textLight}>Remark : </span>
            <span
              className="ms-1"
              dangerouslySetInnerHTML={{
                __html: props.enquiryDetail.remark,
              }}
            ></span>
          </div>
        </div>
      ) : (
        <>
          <div
            className={EnquiryListItemStyle.enquiryListItem}
            onClick={() => props.setSelectedEnquiry(props.enquiryDetail)}
          >
            <div className={EnquiryListItemStyle.userDetails}>
              <div className={EnquiryListItemStyle.user}>
                {props.enquiryDetail.title}{" "}
              </div>
              <button
                className="btn btn-primary btn-sm small"
                onClick={() => props.toggleQuotationOffCanvas(true)}
              >
                <i className="fa-solid fa-download"> </i> Quotation
              </button>
            </div>
            <div className="border rounded p-3 bg-white">
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <span className={EnquiryListItemStyle.textLight}>
                    Destination :
                  </span>
                  <div className={EnquiryListItemStyle.destination}>
                    <div className="d-flex flex-wrap">
                      {props.enquiryDetail.destinations
                        ?.map((destination) => {
                          return destination.city?.name;
                        })
                        .join(" , ")}
                    </div>
                  </div>
                </div>
                <div>
                  <span className={EnquiryListItemStyle.textLight}>
                    Passengers :
                  </span>
                  <div className={EnquiryListItemStyle.destination}>
                    {adultCount} Adult , {childCount} Child ,{infantCount}{" "}
                    Infant
                  </div>
                </div>

                <div>
                  <span className={EnquiryListItemStyle.textLight}>
                    Lead Passenger:
                  </span>
                  <div className={EnquiryListItemStyle.destination}>
                    <div className="d-flex flex-wrap">
                      {capitaliseStr(props.enquiryDetail.leadPassengerName)}
                    </div>
                  </div>
                </div>
                <div>
                  <span className={EnquiryListItemStyle.textLight}>
                    Total Price :
                  </span>
                  <div className={EnquiryListItemStyle.destination}>
                    <Price amount={props.kartTotalPrice} currency="₹" />
                  </div>
                </div>

                <div>
                  <span className={EnquiryListItemStyle.textLight}>
                    Created At :
                  </span>
                  <div className={EnquiryListItemStyle.destination}>
                    <div className="d-flex flex-wrap">
                      {moment(props.enquiryDetail.createdAt).format("lll")}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {showEditEnquiryModal && (
        <CreateEnquiry
          show={showEditEnquiryModal}
          onClose={() => setShowEditEnquiryModal(false)}
          id={props.enquiryDetail.id}
          onSave={(enquiryData) => {
            // props.getEnquiries();
            props.setEnquiry(enquiryData);
            setShowEditEnquiryModal(false);
          }}
        />
      )}

      {showNotesModal && (
        <CreateOrEditEnquiryNotes
          notesDetail={props.enquiryDetail.notes || null}
          onClose={() => {
            setShowNotesModal(false);
          }}
          show={showNotesModal}
          onSave={saveEnquiryNotes}
        />
      )}

      {props.enquiryDetail?.id && (
        <FollowUpPopup
          getEnquiries={props.getEnquiries}
          enquiryId={props.enquiryDetail?.id}
          showFollowUp={showFollowUp}
          onHide={() => {
            setShowFollowUp(false);
          }}
        />
      )}
    </div>
  );
};

export default EnquiryListItem;
