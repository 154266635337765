import React from "react";

const TermandConditions = () => {
  return (
    <div className="mt-5">
      <div className="container">
        <h4 className="text-primary fw-bold mb-3">Terms and Conditions</h4>
        <p>
          This website (the"Site") is published and maintained by Sippi Software
          Solutions Private Limited ("PackageKart.com"), a company incorporated
          and existing in accordance with the laws of the Republic of India.
          When you access,browse or use this Site you accept, without limitation
          or qualification, the terms and conditions set forth below. When you
          access any sub-site (whether belonging to an ‘associate’ of
          PackageKart.com) through this site, then such sub-site may have its
          own terms and conditions of use which are specific to such sub-site.
          Sub-sites may contain such additional terms and conditions of use as
          may be set out in such sub-sites.
        </p>
        <br />
        <h6 className="fw-bold">Site and Its Contents</h6>
        <p>
          This Site is only for your personal use. You shall not distribute,
          exchange, modify, sell or transmit anything you copy from this Site,
          including but not limited to any text,images, audio and video, for any
          business, commercial or public purpose. As long as you comply with the
          terms of these Terms and Conditions of Use, PackageKart.com grants you
          a non-exclusive, non-transferable, limited right to enter, view and
          use this Site. You agree not to interrupt or attempt to interrupt the
          operation of this Site in any way. Access to certain areas of theSite
          may only be available to registered members. To become a registered
          member, you may be required to answer certain questions. Answers to
          such questions may be mandatory and/or optional. You represent and
          warrant that all information you supply to us, about yourself, and
          others, is true and accurate.
        </p>
        <br />
        <h6 className="fw-bold">Ownership</h6>
        <p>
          All materials on this Site, including but not limited to audio,
          images, software, text, icons and such like (the "Content"), are
          protected by copyright under international conventions and copyright
          laws. You cannot use the Content,except as specified herein. You agree
          to follow all instructions on this Site limiting the way you may use
          the Content. There are a number of proprietary logos, service marks
          and trademarks found on this Site whether owned/used by
          PackageKart.com or otherwise. By displaying them on this Site,
          PackageKart.com is not granting you any license to utilize those
          proprietary logos, service marks, or trademarks. Any unauthorized use
          of the Content may violate copyright laws, trademark laws, the laws of
          privacy and publicity, and civil and criminal statutes. You may
          download such copy/copies of the Content to be used only by you for
          your personal use at home unless the sub site you are accessing states
          that you may not. If you download any Content from this Site, you
          shall not remove any copyright or trademark notices or other notices
          that go with it.
        </p>
        <br />
        <h6 className="fw-bold">Others Rights</h6>
        <p>
          If this Site contains bulletin boards, chat rooms, access to mailing
          lists or other message or communication facilities, you agree to use
          the same only to send and receive messages and materials that are
          proper and related thereto. By way of example and not as a limitation,
          you agree that when using the Site or any facility available here, you
          shall not do any of the following:
          <ul className="mt-4">
            <li>
              Defame, abuse, harass, stalk, threaten or otherwise violate the
              legal rights (such as rights of privacy and publicity) of others.
            </li>
            <li>
              Publish, post, distribute or disseminate any defamatory,
              infringing, obscene, indecent or unlawful material or information.
            </li>
            <li>
              Upload or attach files that contain software or other material
              protected by intellectual property laws (or by rights of privacy
              and publicity) unless the User owns or controls the rights thereto
              or has received all consents therefor as may be required by law.
            </li>
            <li>
              Upload or attach files that contain viruses, corrupted files or
              any other similar software or programs that may damage the
              operation of another’s computer.
            </li>
            <li>
              Delete any author attributions, legal notices proprietary
              designations or labels in any file that is uploaded.
            </li>

            <li>
              Falsify the origin or source of software or other material
              contained in an uploaded file.
            </li>

            <li>
              Advertise or offer to sell any goods or services, conduct or
              forward surveys, contests or chain letters, or download any file
              posted by another user of a Forum that the User knows, or
              reasonably should know, cannot be legally distributed in such
              manner.
            </li>
          </ul>
        </p>
        <br />
        <h6 className="fw-bold">User's Material</h6>
        <p>
          You are prohibited from posting or transmitting any defamatory,
          libellous, obscene, pornographic,profane, threatening or unlawful
          material or any material that could constitute or encourage conduct
          that would be considered a criminal offence or give rise to civil
          liability, or otherwise violate any law. <br /> <br />
          PackageKart.com assumes no liability or responsibility arising from
          the contents of any communications containing any defamatory,
          erroneous, inaccurate, libellous,obscene or profane material.
          PackageKart.com may change, edit, or remove any user material or
          conversations that are illegal, indecent, obscene or offensive, or
          that violate PackageKart.com 's policies in any way. <br /> <br />
          PackageKart.com willfully cooperate with any law enforcement
          authorities or court order requesting or directing PackageKart.com to
          disclose the identity of anyone posting such materials.
        </p>
        <br />
        <h6 className="fw-bold">Important Terms and Conditions</h6>
        <p>
          PackageKart.com (Sippi Software Solutions Private Limited (OPC)) is
          not a tour operator/hotel chain/airline company or entertainment
          company. Therefore, it is concluded that we (packagekart.com/ Sippi
          Software Solutions Private Limited (OPC)) are not the end service
          provider in any condition. If there is any kind of lack/in
          consistency/in ability/delay in providing any service booked through
          Sippi Software Solutions Private Limited (OPC) / PackageKart.com then
          we are not responsible. <br /> <br />
          Sippi SoftwareSolutions Private Limited (OPC) / PackageKart.com
          provides you with a platform/technology/medium to book travel services
          online. Any kind of services booked from our website are booked at the
          customer's / travel agent's own will and risk. We are pure
          agents/intermediaries and we book on behalf of customers/users/travel
          agents. Funds for the bookings/services are transferred to the
          supplier/hotel/travel company/airlines on behalf of the
          customer/travel agent / corporate client/user and the remaining
          amount/money is the service charge for providing a technology/platform
          to book travel arrangements for you.
        </p>
        <br />
        <h6 className="fw-bold">Content & Copyright</h6>
        <p>
          PackageKart.com is a product designed and owned by Sippi Software
          Solutions Private Limited (OPC). All the content on this website has
          been provided by suppliers / third parties at their own wish for their
          own benefit. If content/functionality/technology on this website
          violates any copyright law/patent or it is the intellectual property
          of any third party. Then the party must contact Sippi Software
          Solutions Private Limited (OPC) at support@sippisoftware.com &
          sales@sippisoftware.comand +91 7686963000 and +91 8199977740 in order
          to request/notify us to modify and remove the disputed content if
          required. A minimum time of 15 days has to be allowed to change the
          content if required. Any legal action must only be considered only if,
          after notifying us of the given contact details and providing
          appropriate time to make the required changes Sippi Software Solutions
          Private Limited (OPC) / PackageKart.com fails to modify/remove the
          disputed content. <br /> <br />
          PackageKart.com / SippiSoftware Solutions Private Limited (OPC) has no
          intention to harm/defame any
          person/company/organisation/institution/religion in any condition.
          This website/product's only motive is to make travel arrangements
          simple, easy,effective and affordable. This product has been designed
          to increase efficiency and reduce human effort. If in case of any
          technical error or human error by the team of Sippi Software Solutions
          Private Limited (OPC) / packagekart.com,any kind of loss
          (financial/legal / time related) happens to any
          person/organisation/institution/community then it is a pure accident
          and not intentional at all. We are not responsible/liable for any kind
          of loss due to human or technical error.
        </p>
        <br />
        <h6 className="fw-bold">PackageKart.com Rights</h6>
        <p>
          If you send any communications or materials to the Site by electronic
          mail or otherwise,including any comments, data, questions, suggestions
          or the like, all such communications are, and will be treated by
          PackageKart.com, as non-confidential. <br /> <br />
          You hereby give up any and all claim that any use of such material
          violates any of your rights including moral rights, privacy rights,
          proprietary or other property rights,publicity rights, rights to
          credit for material or ideas, or any other right,including the right
          to approve the way PackageKart.com uses such material. <br /> <br />
          Any material submitted to this Site may be adapted, broadcast,
          changed, copied, disclosed, licensed,performed, posted, published,
          sold, transmitted or used by PackageKart.com anywhere in the world, in
          any medium, forever.
        </p>
        <br />
        <h6 className="fw-bold">Transmitted Material</h6>
        <p>
          Internet transmissions are never completely private or secure. You
          understand that any message or information you send to this Site may
          be read or intercepted by others unless there is a special notice that
          a particular message (for example, credit card information)is
          encrypted (send in code). Sending a message to PackageKart.com does
          not cause PackageKart.com to have any special responsibility to you.{" "}
          <br /> <br />
          The copyright in the contents of this website belongs to
          PackageKart.com. Accordingly, PackgeKart.com reserves all rights.
          Copying of part or all the contents of this website without permission
          of PackageKart.com is prohibited except to the extent that such
          copying/printing is necessary for the purposes of availing of the paid
          services provided.
        </p>
        <br />
        <h6 className="fw-bold">Disclaimer</h6>
        <p>
          The material on this Site could include technical in accuracies or
          typographical errors.PackageKart.com may make changes or improvements
          at any time. <br /> <br />
          The materials on this site are provided on an “As Is” basis, without
          warranties of any kind either expressed or implied. To the fullest
          extent permissible pursuant to applicable law, PackageKart.com
          disclaims all warranties of merchantability and fitness for a
          particular purpose. <br /> <br />
          It is the responsibility of the hotel chain and/or the individual
          property to ensure the accuracy of the photos displayed. '
          PackageKart.com ' is not responsible for any inaccuracies in the
          photos. <br /> <br />
          PackageKart.com does not warrant that the functions contained in this
          site will be uninterrupted or error-free, that defects will be
          corrected, or that this site or the servers that make it available are
          free of viruses or other harmful components, but shall endeavour to
          ensure your fullest satisfaction. <br /> <br />
          PackageKart.com does not warrant or make any representations regarding
          the use of or the result of the use of the material on the site in
          terms of their correctness, accuracy,reliability, or otherwise,
          insofar as such material is derived from other service providers such
          as airlines, hotel owners and tour operators. <br /> <br />
          You acknowledge that this Website is provided only on the basis set
          out in these terms and conditions. Your uninterrupted access or use of
          this Website on this basis maybe prevented by certain factors outside
          our reasonable control including,without limitation, the
          unavailability, in operability or interruption of theInternet or other
          telecommunications services or as a result of any maintenance or other
          service work carried out on this Website. PackageKart.com does not
          accept any responsibility and will not be liable for any loss or
          damage whats'o ever arising out of or in connection with any
          ability/inability to accessor use the Site. <br /> <br />
          You also acknowledge that through this Site, PackageKart.com merely
          provides intermediary services to facilitate the highest quality
          services to you PackageKart.com is not the last-mile service provider
          to you and therefore, PackageKart.com shall not be or deemed to be
          responsible for any lack or deficiency of services provided by any
          person (airline, travel/tour operator, hotel, facility or similar
          agency) you shall engage or hire or appoint under or resulting from,
          the material available in this Site.
          <br /> <br />
          PackageKart.com will not be liable to you or to any other person for
          any direct, indirect,incidental, punitive or consequential loss,
          damage, cost or expense of any kind whatsoever and howsoever caused
          from out of your usage of this Site.Not with standing anything else to
          the contrary contained elsewhere herein or otherwise at
          law,PackageKart.com’s liability (whether by way of indemnification to
          you or otherwise) shall be restricted to a maximum of INR 10000
          (Indian Rupees One Thousand only).
        </p>
        <br />
        <h6 className="fw-bold">Availability</h6>
        <p>
          The products and services displayed on the Site may not be available
          for purchase in your particular country or locality. The reference to
          such products and services on the Site does not imply or warrant that
          these products or services will be available at any time in your
          particular geographical location. You should check with your local
          PackageKart.com authorized representative for the availability of
          specific products and services in your area.
        </p>
        <br />
        <h6 className="fw-bold">Terms and Conditions of Use</h6>
        <p>
          PackageKart.com may add to, change or remove any part of these Terms
          and Conditions of Use at anytime, without notice. Any changes to these
          Terms and Conditions of Use or any terms posted on this Site apply as
          soon as they are posted. By continuing to use this Site after any
          changes are posted, you are indicating your acceptance of those
          changes.
          <br /> <br />
          PackageKart.com may add, change, discontinue, remove or suspend any
          other Content posted on thisSite, including features and
          specifications of products described or depicted on the Site,
          temporarily or permanently, at any time, without notice and without
          liability.
          <br /> <br />
          PackageKart.com reserves the right to undertake all necessary steps to
          ensure that the security, safety and integrity of PackageKart.com 's
          systems as well as its clients’ interests are and remain,
          well-protected. Towards this end,PackageKart.com may take various
          steps to verify and confirm the authenticity,enforceability and
          validity of orders placed by you.
          <br /> <br />
          If PackageKart.com, inits sole and exclusive discretion, concludes
          that the said orders are not or do not reasonably appear to be,
          authentic, enforceable or valid, thenPackageKart.com may cancel the
          said orders at any time up to 4 hours before the scheduled time of
          departure of the relevant flight or 4 hours before the expected date
          of visit to any property booked through PackageKart.com.
        </p>
        <br />
        <h6 className="fw-bold">GeneralProvisions</h6>
        <p>
          You may travel to certain destinations which involve greater risks
          than others, entirely at your risk as to cost and consequences.
          <br /> <br />
          PackageKart.com requests you to consult your local authorities and
          evaluate travel prohibitions, warnings, announcements, and advisories
          issued by them before booking travel to certain international
          destinations.
          <br /> <br />
          By offering for sale travel to particular international destinations,
          PackageKart.com does not represent or warrant that travel to such
          point is advisable or without risk.PackageKart.com does not accept
          liability for damages, losses, or delays that may result from improper
          documents for entry, exit, length of stay, or travel to such
          destinations.
          <br /> <br />
          PackageKart.com reserves its exclusive right in its sole discretion to
          alter, limit or discontinue the Site or any material posted herein, in
          any respect.PackageKart.com shall have no obligation to take the needs
          of any User into consideration in connection there with.
          <br /> <br /> PackageKart.com reserves its right to deny in its sole
          discretion any user access to this Site or any portion thereof without
          notice.
          <br /> <br />
          No waiver byPackageKart.com of any provision of these Terms and
          Conditions shall be binding except as set forth in writing and signed
          by its duly authorized representative.
          <br /> <br />
          If any dispute arises between you and PackageKart.com during your use
          of the Site or thereafter, in connection with and arising from your
          use or attempt to use this Site, the dispute shall be referred to
          arbitration. The place of arbitration shall be Kurukshetra. The
          arbitration proceedings shall be in the English language.
          <br /> <br />
          The said arbitration proceedings shall be governed and construed in
          accordance with the Arbitration and Conciliation Act, 1996 and
          modifications thereof as in force at the relevant time.
          <br /> <br />
          These terms and conditions are governed by and shall be construed in
          accordance with the laws of the Republic of India and any dispute
          shall exclusively be subject to the jurisdiction of the appropriate
          Courts situated at Kurukshetra, Haryana, India. 
        </p>
      </div>
    </div>
  );
};

export default TermandConditions;
