import { Collapse, Modal } from "react-bootstrap";
import { useTaskNotification } from "../../hooks/useTaskNotification";
import { activityAxios } from "../../axios/activityAxios";
import { IJSONResponse } from "../../activitiescommonfnb/types/common.interface";
import { toast } from "react-toastify";
import taskNotificationStyle from "./TaskNotificationListStyle.module.scss";
import moment from "moment";
import { ITask } from "../../activitiescommonfnb/types/task.interface";
import React, { useState } from "react";
import { UploadTaskPhotoProof } from "./UploadTaskPhotoProof";
import { LoadingButton } from "../../commonUi/LoadingButton";

const TaskNotificationItem: React.FC<{
  taskItem: ITask;
  dismissTillNextReminder: () => void;
}> = (props) => {
  const [showUploadedPhotoProofCollapse, setShowUploadedPhotoProofCollapse] =
    useState(false);

  const [isUpdating, setIsUpdating] = useState(false);

  const markTaskAsDone = async () => {
    setIsUpdating(true);
    const apiResponse = await activityAxios.post<IJSONResponse>(
      "/task/updateTaskDoneStatus",
      {
        taskId: props.taskItem.id,
        isDone: true,
      }
    );

    if (apiResponse.data.success) {
      toast.success("Task updated successfully.");
      props.dismissTillNextReminder();
    } else {
      toast.error(apiResponse.data.errorMessage);
    }
    setIsUpdating(false);
  };

  return (
    <div className={`${taskNotificationStyle.taskNotificationListItem}`}>
      <div className="row">
        <div className="col-6">
          <div>{props.taskItem.title}</div>
          <span className="small text-muted">{props.taskItem.description}</span>
          <div>
            <span className="small fw-bold">
              <span className="text-muted fw-normal">Created By : </span>
              {props.taskItem.createdByUserData?.firstName}{" "}
              {props.taskItem.createdByUserData?.lastName}{" "}
              <span className="text-muted fw-normal">at </span>
              {moment(props.taskItem.createdAt).format("ll")}
            </span>
          </div>
        </div>

        <div className="col-6 text-end">
          <div className="mb-3">
            <span className="text-muted small">Deadline : </span>
            <span className="small">
              {moment(props.taskItem.deadline).format("ll")} |{" "}
            </span>
            <span className="px-2 py-1 badge bg-secondary small">
              <span className="text-capitalize">
                <i className="fa-solid fa-bell"></i>{" "}
                {props.taskItem.reminderFrequency}
              </span>
            </span>
          </div>
          <div>
            <button
              className="btn btn-sm btn-outline-secondary me-2"
              onClick={props.dismissTillNextReminder}
            >
              <i className="fa-solid fa-bell-slash"></i> Snooze
            </button>
            {props.taskItem.isPhotoProofRequired ? (
              <button
                className="btn btn-sm btn-primary"
                onClick={() => {
                  setShowUploadedPhotoProofCollapse((old) => !old);
                }}
              >
                Upload & Done
              </button>
            ) : (
              <LoadingButton
                loading={isUpdating}
                btnClass="btn btn-sm btn-success"
                onClick={markTaskAsDone}
              >
                Mark as done
              </LoadingButton>
            )}
          </div>
        </div>
      </div>
      <Collapse in={showUploadedPhotoProofCollapse}>
        <div className="border-top mt-2">
          <UploadTaskPhotoProof taskId={props.taskItem.id} />
          <div className="text-center">
            <LoadingButton
              loading={isUpdating}
              btnClass="btn btn-sm btn-success"
              onClick={markTaskAsDone}
            >
              Mark as done
            </LoadingButton>
          </div>
        </div>
      </Collapse>
    </div>
  );
};

export const TaskNotificationModal = () => {
  const {
    showTaskModal,
    snoozeAllTaskNotifications,
    taskNotifications,
    dismissTaskNotification,
  } = useTaskNotification();

  return (
    <Modal show={showTaskModal} centered size="lg">
      <Modal.Header>Task Notifications</Modal.Header>
      <Modal.Body>
        {taskNotifications.map((singleTask) => {
          return (
            <TaskNotificationItem
              key={singleTask.id}
              taskItem={singleTask}
              dismissTillNextReminder={() => {
                dismissTaskNotification(singleTask.id);
              }}
            />
          );
        })}
      </Modal.Body>
      {/* <Modal.Footer>
        <button
          className="btn btn-primary"
          onClick={snoozeAllTaskNotifications}
        >
          Snooze all
        </button>
      </Modal.Footer> */}
    </Modal>
  );
};
