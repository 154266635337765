import { number, z } from "zod";
import { ZodHelperService } from "../schemas/zodHelper.service";
import { IUserData } from "./User.interface";
import { Path } from "react-hook-form";

export enum EnumSharingType {
  SINGLE = "single",
  DOUBLE = "double",
  TRIPLE = "triple",
}

export const quotationDataSchema = z.object({
  id: z.number().optional(),
  version: z.number().optional(),
  enquiryId: z.number().min(1, { message: "Enquiry ID is required" }),
  quotationBody: z
    .string()
    .trim()
    .min(1, { message: "Quotation body cannot be empty" }),
  title: z.string().trim().min(1, { message: "Title cannot be empty" }),
  visaCost: z
    .number()
    .min(0, { message: "Visa cost is required and must be a positive number" }),
  travelDate: ZodHelperService.getZodDate(),
  flightCost: z.number().min(0, {
    message: "Flight cost is required and must be a positive number",
  }),
  hotels: z.array(
    z.object({
      hotelName: z
        .string()
        .trim()
        .min(1, { message: "Hotel name must contain at least 1 character." }),
      hotelCost: z.number().min(0, {
        message: "Hotel cost is required and must be a positive number",
      }),
      sharingType: z.nativeEnum(EnumSharingType),
    })
  ),
  landPartCost: z.number().min(0, {
    message: "Land part cost is required and must be a positive number",
  }),
  markupPercentage: z
    .number()
    .min(0, { message: "Markup percentage is required" }),
  showBreakup: z.boolean(),
  internalRemark: z.string().optional(),
  HTMLData: z.string(),
});

export type TQuotationDataZod = z.infer<typeof quotationDataSchema>;

export interface IQuotation extends TQuotationDataZod {
  staffId?: number;
  staff?: Pick<IUserData, "firstName" | "lastName">;
  updatedAt?: Date;
}

export interface IQuotationVersion {
  id: number;
  quotationId: number;
  version: number;
  HTMLData: string;
  JsonData: any;
  staff?: Pick<IUserData, "firstName" | "lastName">;
  createdAt: Date;
  updatedAt: Date;
}

export interface ITermsAndConditions {
  name: string;
  termsAndConditions: string;
}
export interface IQuotationTabs {
  id: string;
  title: string;
}

export interface IQuotationPreview {
  previewBody?: string;
  quotationData?: IQuotation;
  versionId?: number;
  staff?: Pick<IUserData, "firstName" | "lastName">;
  termsAndConditions?: ITermsAndConditions[];
}

export interface IQuotationDataTemplate extends TQuotationDataZod {
  staffId?: number;
  staff?: Pick<IUserData, "firstName" | "lastName">;
  updatedAt?: Date;
}

export interface IQuotationFormRC {
  setQuotationData: React.Dispatch<React.SetStateAction<TQuotationDataZod>>;
  quotationData: TQuotationDataZod;
  quotationError?: { [key in Path<TQuotationDataZod>]: string } | null;
}
