import { Button, Modal } from "react-bootstrap";
import { AppInput } from "../../commonUi/AppInput";
import { useEffect, useState } from "react";
import { IntlTelInputWrapper } from "../../commonUi/IntlTelInputWrapper";
import { activityAxios } from "../../axios/activityAxios";
import { TCreateUserZod } from "../../activitiescommonfnb/schemas/user.schema";
import { toast } from "react-toastify";

export const CreateOrEditStaffPopup = (props: {
  show: boolean;
  onHide: () => void;
  staffId?: number;
}) => {
  const [staff, setStaff] = useState<TCreateUserZod>({
    phoneCode: "",
    phoneNumber: "",
  });
  const [loading, setLoading] = useState(false);
  const handleStaff = (name: string, value: string) => {
    setStaff((old) => {
      return { ...old, [name]: value };
    });
  };

  const submit = async () => {
    if (loading) return;
    setLoading(true);
    const apiRes = await activityAxios.post("/user/createNewStaff", {
      userDetails: staff,
    });
    if (apiRes.data.success) {
      toast.success("Staff saved");
    }
    setLoading(false);
  };

  const getStaffById = async () => {
    const apiRes = await activityAxios.post("", { id: props.staffId });
    if (apiRes.data.success) {
      setStaff(apiRes.data.result);
    }
  };
  useEffect(() => {
    if (props.staffId) {
      // getStaffById();
    }
  }, [props.staffId]);
  return (
    <Modal
      show={props.show}
      onHide={() => {
        props.onHide();
      }}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <h5 className="py-2">
          {typeof props.staffId === "number" ? "Edit Staff" : "Create Staff"}
        </h5>
        <AppInput
          label="First Name"
          fieldName="firstName"
          onChange={handleStaff}
          value={staff?.firstName}
        />
        <AppInput
          label="Last Name"
          fieldName="lastName"
          onChange={handleStaff}
          value={staff?.lastName}
        />
        <AppInput
          label="User Email"
          fieldName="email"
          onChange={handleStaff}
          value={staff?.email}
        />
        <IntlTelInputWrapper
          dialCode={staff?.phoneCode}
          defaultValue={staff?.phoneNumber}
          label="Phone Number"
          onChange={(phoneNumber, dialCode, isValid) => {
            if (isValid) {
              setStaff((old) => {
                return { ...old, phoneCode: dialCode, phoneNumber };
              });
            }
          }}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
        <button className="btn btn-success" onClick={submit}>
          Submit
        </button>
      </Modal.Footer>
    </Modal>
  );
};
