import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { ITourWithPrice } from "../activitiescommonfnb/types/activitySearch.interface";
import { activityAxios } from "../axios/activityAxios";
import { IJSONResponse } from "../activitiescommonfnb/types/common.interface";
import { toast } from "react-toastify";
import { IPaxInfo } from "../components/common/Search/PaxInfoPopOver";

export type TTourSearchQuery = {
  citySlug: string;
  from: string;
  to: string;
  paxInfo: IPaxInfo;
};

const initialState: {
  data: ITourWithPrice[];
  isSearching: boolean;
} = {
  data: [],
  isSearching: false,
};

export const getTourResults = createAsyncThunk(
  "tourResult/getTourResults",
  async (searchPayload: TTourSearchQuery, thunkAPI) => {
    thunkAPI.dispatch(TourResultAction.setIsSearching(true));

    const apiRes = await activityAxios.post<IJSONResponse<ITourWithPrice[]>>(
      "/tour/search",
      searchPayload
    );

    if (apiRes.data.success && apiRes.data.result) {
      return apiRes.data.result;
    }

    throw new Error(apiRes.data.errorMessage);
  }
);

export const tourResultSlice = createSlice({
  name: "tourResult",
  initialState,
  reducers: {
    setTourResults: (state, action: PayloadAction<ITourWithPrice[]>) => {
      state.data = action.payload;
    },

    setIsSearching: (state, action: PayloadAction<boolean>) => {
      state.isSearching = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getTourResults.fulfilled, (state, action) => {
      state.data = action.payload;
      state.isSearching = false;
    });
    builder.addCase(getTourResults.rejected, (state, action) => {
      toast.error(action.error.message || "Unable to get tours.");
      state.isSearching = false;
    });
  },
});

export const TourResultAction = tourResultSlice.actions;

export default tourResultSlice.reducer;
