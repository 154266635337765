import { z } from "zod";
import { EnumTourOptionTransferType } from "../activitiescommonfnb/types/tour.interface";

export interface ITourSearchResult {
  id: number;
  name: string;
  countryId: number;
  cityId: number;
  rating: number;
  reviewCount: number;
  departurePoint: string;
  reportingTime: string;
  childAge: number;
  infantAge: number;
  childCancellationPolicyDescription: string;
  cancellationPolicyDescription: string;
  faqDetails: string;
  usefullInformation: string;
  importantInformation: string;
  whatsInThisTour: string;
  itenararyDescription: string;
  termsAndConditions: string;
  toursAdvantage: string;
  tourInclusion: string;
  tourDescription: string;
  tourShortDescription: string;
  onlyChild: boolean;
  createdAt: Date;
  updatedAt: Date;
  amount: number;
}

export interface ITourOptionBookingDetails {
  tourId: number | undefined;
  tourOptionId: number | undefined;
  transferType: EnumTourOptionTransferType | undefined | string;
  travelDate: Date;
  price: number | undefined;
  adult: number | null;
  child: number | null;
  infant: number | null;
}

export const bookingDetailsZod = z.object({
  tourId: z.number(),
  tourOptionId: z.number(),
  transferOption: z.string(),
  tourDate: z.date(),
  amount: z.number(),
});

export enum EnumUser {
  ADMIN = "admin",
  SUPPLIER = "supplier",
}

export interface IRange {
  min: number;
  max: number;
}
