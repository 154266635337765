import React from 'react'
import { ITermsAndConditions } from '../../activitiescommonfnb/types/quotation.interface'

const QuotationTandC: React.FC<{
    termsAndConditions: ITermsAndConditions[] | undefined
}> = (props) => {

    return (
        <div>
            <h5 className='mt-4'>Terms And Conditions</h5>
            {
                props.termsAndConditions?.map((item, index) => (
                    <div key={index}>
                        <h6 className=''>{item.name} : </h6>
                        <div dangerouslySetInnerHTML={{ __html: item.termsAndConditions }}></div>
                    </div>
                ))
            }
        </div>
    )
}

export default QuotationTandC
