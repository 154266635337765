import React from "react";

const PaymentAndRefundPolicy = () => {
  return (
    <div className="mt-5">
      <div className="container ">
        <h4 className="text-primary fw-bold mb-4">Payment & Refund Policy</h4>
        <h6 className="fw-bold"> Initial Payment</h6>
        <p>
          PackageKart.com will charge the customer with an initial
          non-refundable amount if the customer chooses to pay the token amount
          when the kart/package/travel arrangement is sent for confirmation. If
          the booking is confirmed, this token amount will be adjusted in the
          total amount for the booking and if the booking is not confirmed, it
          will be deducted and billed as a service fee.
          <br /> <br />
          If the date of travel is within 7 days of the booking date then 100%
          payment will be charged in advance.
          <br /> <br />
          The customer can also choose to pay 100% of the booking amount at
          once. We will process his booking to provide him with all booking
          vouchers/confirmations, except in the case of non-availability or any
          other factor due to which the booking can not be processed.
        </p>
        <br />
        <h6 className="fw-bold"> Final Payment </h6>
        <p>
          All the funds/payments must be provided to Sippi Software Solutions
          Private Limited in advance (at least 7 days before travel) so that
          Sippi Software Solutions Private Limited transfer the booking amount
          or give ensurity to airlines/suppliers/hotel chains/tour operators on
          your(customer/consumer) behalf.
        </p>
        <br />
        <h6 className="fw-bold"> Billing & Invoicing</h6>
        <p>
          After receiving complete payment from the customer, Sippi Software
          Solutions Private Limited will provide a debit memo on behalf of the
          supplier. A final invoice will be raised by the supplier in the name
          of the customer or agent after the completion of the service.{" "}
        </p>{" "}
        <br />
        <h6 className="fw-bold"> Availability </h6>
        <p>
          All bookings are subject to availability. In case of non-availability
          of resources / booking components or the entire booking, the token
          amount & booking amount (if received) will be refunded any additional
          loss due to non-availability of resources will not be entertained. It
          is advised to make any further bookings only after receiving booking
          vouchers from Package Kart.
        </p>
        <br />
        <h6 className="fw-bold"> Amendment or Cancellation</h6>
        <p>
          Booking can only be changed/cancelled before 4 Days from Travel, after
          that all bookings are non-changeable and non-refundable.
        </p>
        <br />
        <h6 className="fw-bold">
          {" "}
          Non-Availability or Cancellation form Supplier / Service Provider
        </h6>
        <p>
          If in case of cancellation or no show or service is not provided
          (properly or completely) Sippi Software Solutions Private Limited /
          packageKart.com will only provide the refund equal to the refund
          amount provided by the supplier after deducting our own cost of
          operation. Refunds will only be provided after the funds are received
          by Sippi Software Solutions Private Limited. If the case supplier does
          not provide the refund amount to Sippi Software Solutions Private
          Limited or the Customer then we are not liable/responsible for that.
          We can fight/argue/discuss with the supplier on behalf of our customer
          but we will not bear the loss in any condition.
        </p>
      </div>
    </div>
  );
};

export default PaymentAndRefundPolicy;
