import { Collapse } from "react-bootstrap";
import TourResultItemStyle from "./TourResultItemStyle.module.scss";
import React, { useEffect, useState } from "react";
import { activityAxios } from "../../axios/activityAxios";
import { Ratings } from "../../commonUi/Ratings";
import { TourOptionDetailsModal } from "./TourOptionDetailsModal";
import {
  ITourOptionTransferWithPrice,
  ITourOptionWithTransfer,
  ITourWithPrice,
} from "../../activitiescommonfnb/types/activitySearch.interface";
import { useParams } from "react-router";
import Price from "../../commonUi/Price";
import { Link, useSearchParams } from "react-router-dom";
import moment from "moment";
import { toast } from "react-toastify";
import {
  IBookingTourOption,
  ICartItem,
} from "../../activitiescommonfnb/types/booking.interface";
import { IJSONResponse } from "../../activitiescommonfnb/types/common.interface";
import { TourOption } from "./TourOption";
import { ActivitySessionActions } from "../../slice/session.slice";
import { useAppDispatch } from "../../store";
import { TourResultItemLoadingSkeleton } from "../../helper/tourHelper";

export const TourResultItem: React.FC<{
  tourDetails: ITourWithPrice;
  searchStr: string;
}> = (props) => {
  const { cityslug } = useParams();
  const [params] = useSearchParams();
  const dispatch = useAppDispatch();

  const searchDetails = {
    paxInfo: {
      adult: parseInt(params.get("adult") || "0"),
      child: parseInt(params.get("child") || "0"),
      infant: parseInt(params.get("infant") || "0"),
    },

    travelDate: params.get("from") || moment().format("YYYY-MM-DD"),
  };

  const [showTourOptions, setShowTourOptions] = useState(false);
  const [loadTourOption, setLoadTourOption] = useState(false);
  const [tourOptions, setTourOptions] = useState<ITourOptionWithTransfer[]>([]);
  const [tourOptionDetailsModalShow, setTourOptionDetailsModalShow] =
    useState(false);

  const [comboBookingTourDetail, setComboBookingTourDetail] =
    useState<ICartItem | null>(null);

  const addComboToCart = () => {
    if (!comboBookingTourDetail) {
      return;
    }
    dispatch(
      ActivitySessionActions.addTourOptionInActiveSession(
        comboBookingTourDetail
      )
    );
  };

  const getTourOptions = async (tourId: number) => {
    setLoadTourOption(true);
    setShowTourOptions(true);
    const tourOptionsResponse = await activityAxios.post<
      IJSONResponse<ITourOptionWithTransfer[]>
    >("/tour/getTourOptions", {
      tourId,
      citySlug: cityslug,
      travelDate: searchDetails.travelDate,
    });

    if (
      tourOptionsResponse?.data?.success &&
      tourOptionsResponse?.data?.result
    ) {
      setTourOptions(tourOptionsResponse?.data?.result);

      if (props.tourDetails.isComboTour) {
        const comboCartItems: IBookingTourOption[] =
          tourOptionsResponse.data.result.map((singleTourOption) => {
            const selectedTransfer = singleTourOption.transfers[0];

            return {
              tourId: singleTourOption.tourId,
              tourOptionId: singleTourOption.id,
              tourOption: singleTourOption,
              startTime: singleTourOption.startTime,
              buffer: singleTourOption.buffer,
              durationInMinutes: singleTourOption.durationInMinutes,
              adultCount: searchDetails.paxInfo.adult,
              childCount: searchDetails.paxInfo.child,
              infantCount: searchDetails.paxInfo.infant,
              adultPrice: selectedTransfer.adultPrice,
              childPrice: selectedTransfer.childPrice,
              infantPrice: selectedTransfer.infantPrice,
              pickUpCoordinates: {
                x: 0,
                y: 0,
              },
              pickupLocation: "",
              timeSlotId: null,
              transferId: selectedTransfer.id,
              transferType: selectedTransfer.transferType,
              travelDate: searchDetails.travelDate,
              uniqueResultId: selectedTransfer.uniqueResultId,
            };
          });

        // Initially setting combo item state if tour is combo.
        setComboBookingTourDetail({
          tourId: props.tourDetails.id,
          isComboTour: true,
          tourOptions: comboCartItems,
        });
      }
    } else {
      setLoadTourOption(false);
      toast.error(
        tourOptionsResponse.data.errorMessage || "something went wrong"
      );
    }
    setLoadTourOption(false);
  };

  const onComboTourOptionChange = (
    traveDate: string,
    selectedTransfer: ITourOptionTransferWithPrice
  ) => {
    if (!props.tourDetails.isComboTour) {
      return;
    }
    setComboBookingTourDetail((old) => {
      if (!old) {
        return null;
      }

      const changedTourOptionIndex = old.tourOptions.findIndex(
        (singleBookingTourOption) =>
          singleBookingTourOption.tourOptionId === selectedTransfer.tourOptionId
      );

      if (old.tourOptions[changedTourOptionIndex]) {
        old.tourOptions[changedTourOptionIndex].travelDate = traveDate;
        old.tourOptions[changedTourOptionIndex].transferId =
          selectedTransfer.id;
        old.tourOptions[changedTourOptionIndex].transferType =
          selectedTransfer.transferType;
        old.tourOptions[changedTourOptionIndex].adultPrice =
          selectedTransfer.adultPrice;
        old.tourOptions[changedTourOptionIndex].childPrice =
          selectedTransfer.childPrice;
        old.tourOptions[changedTourOptionIndex].infantPrice =
          selectedTransfer.infantPrice;
      }

      return { ...old };
    });
  };

  const onSingleTourOptionDateChange = (
    traveDate: string,
    updatedtourOption: ITourOptionWithTransfer,
    selectedTransfer: ITourOptionTransferWithPrice
  ) => {
    setTourOptions((old) => {
      const touOptionToUpdateIndex = old.findIndex(
        (option) => option.id === updatedtourOption.id
      );
      old[touOptionToUpdateIndex] = updatedtourOption;
      return [...old];
    });

    onComboTourOptionChange(traveDate, selectedTransfer);
  };

  const searchUrl = `/${cityslug}/${props.tourDetails.slug}=${searchDetails.paxInfo.adult}&child=${searchDetails.paxInfo.child}&infant=${searchDetails.paxInfo.infant}`;

  useEffect(() => {
    if (props.searchStr.length > 0) {
      setShowTourOptions(false);
      setTourOptions([]);
    }
  }, [props.searchStr]);

  const defaultTourImageUrl =
    "https://images.unsplash.com/photo-1538512126441-b70b1d71c50b?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D";

  return (
    <div className={`${TourResultItemStyle.tourResultItem}`}>
      {tourOptionDetailsModalShow && (
        <TourOptionDetailsModal
          tourDetails={props.tourDetails}
          show={tourOptionDetailsModalShow}
          onHide={() => {
            setTourOptionDetailsModalShow(false);
          }}
        />
      )}
      <div className="container-fluid">
        <div className="row">
          {/* Tour Image */}
          <div className={`col-lg-4 col-md-4 col-sm-12 col-12 p-0`}>
            <img
              className={`img-fluid ${TourResultItemStyle.ItemImage}`}
              src={
                props.tourDetails?.tourImages?.[0]?.fileUrl ||
                defaultTourImageUrl
              }
              alt=""
            />
          </div>
          {/* tour details */}
          <div className="col-lg-6 col-md-6 col-sm-12 col-12">
            <div
              className={`${TourResultItemStyle.tourResultShortDetails} d-flex flex-column align-content-between justify-content-between h-100 `}
            >
              <div>
                <Link to={searchUrl}>
                  <h6 className="fw-bold text-muted">
                    {props.tourDetails.name}
                  </h6>
                </Link>

                <div className={TourResultItemStyle.shortDesicription}>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: props.tourDetails.tourShortDescription,
                    }}
                  ></span>
                </div>
              </div>
              <div className="mt-3">
                <div
                  role="button"
                  className={TourResultItemStyle.importantInfoBtn}
                  onClick={() => {
                    setTourOptionDetailsModalShow(true);
                  }}
                >
                  <i
                    className={`fa-solid fa-exclamation small ${TourResultItemStyle.icon} me-2`}
                  ></i>{" "}
                  <span>Important Info</span> <br />
                  {/* <i
                    className={`fa-solid fa-exclamation small ${TourResultItemStyle.icon}  mx-2`}
                  ></i>{" "}
                  <span>Inclusion</span> */}
                </div>
              </div>
            </div>
          </div>
          {/* rating & price details */}
          <div className="col-lg-2 col-md-2 col-sm-12 col-12 px-0">
            <div
              className={`${TourResultItemStyle.tourResultPriceCol} d-flex align-content-between flex-wrap h-100 justify-content-end`}
            >
              <div className={TourResultItemStyle.review}>
                <Ratings
                  rating={props.tourDetails.rating}
                  reviewCount={props.tourDetails.reviewCount}
                />
              </div>
              <div className="w-100">
                <span className="small">starts from</span>
                <div className="d-flex align-items-end justify-content-end">
                  <Price
                    className={`fs-6 ${TourResultItemStyle.price}`}
                    amount={props.tourDetails.amount}
                    currency={"₹"}
                  />
                  <span className="small ms-1">/ Adult</span>
                </div>
                <button
                  className={`btn btn-sm w-100  ${
                    showTourOptions ? "btn-outline-primary" : "btn-primary"
                  }`}
                  onClick={() => {
                    if (showTourOptions) {
                      setShowTourOptions(false);
                    } else {
                      getTourOptions(props.tourDetails.id);
                    }
                  }}
                  aria-controls="example-collapse-text"
                  aria-expanded={showTourOptions}
                >
                  Select
                  {showTourOptions ? (
                    <i className="fa-solid fa-angle-up ms-1 pt-1"></i>
                  ) : (
                    <i className="fa-solid fa-angle-down ms-1 pt-1"></i>
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
        <Collapse in={showTourOptions}>
          <div id="example-collapse-text" className="py-3">
            <div className={TourResultItemStyle.TourOptionsLabelHeader}>
              <div className="row ">
                <div className="col-3">Tour Options</div>
                <div className="col-3">Transfer Options</div>
                <div className="col-2">Tour Day</div>
                <div className="col-2 text-end">Total Amount</div>
              </div>
            </div>

            {/* Tour Options */}

            {loadTourOption ? (
              <React.Fragment>
                <TourResultItemLoadingSkeleton />
                <TourResultItemLoadingSkeleton />
                <TourResultItemLoadingSkeleton />
              </React.Fragment>
            ) : (
              tourOptions.map((tourOption) => {
                return (
                  <TourOption
                    isComboTour={props.tourDetails.isComboTour}
                    onTourOptionDateChange={onSingleTourOptionDateChange}
                    onSelectedTranferChange={(selectedTranfer) => {
                      onComboTourOptionChange(
                        searchDetails.travelDate,
                        selectedTranfer
                      );
                    }}
                    tourOptionDetails={tourOption}
                    key={tourOption.id}
                    paxInfo={searchDetails.paxInfo}
                    travelDate={searchDetails.travelDate}
                  />
                );
              })
            )}

            {props.tourDetails.isComboTour && (
              <div className="text-end">
                <button
                  onClick={addComboToCart}
                  className="btn text-white bg-secondary btn-sm px-3"
                >
                  <i className="fa-solid fa-cart-plus"></i> Add
                </button>
              </div>
            )}
          </div>
        </Collapse>
      </div>
    </div>
  );
};
