import { Badge, ListGroup, Form } from "react-bootstrap";
import { activityAxios } from "../../../axios/activityAxios";
import { useEffect, useState } from "react";

import { TCountry } from "../../../activitiescommonfnb/schemas/master.schema";
import { EditCountryModal } from "./EditCountryModal";
import countryListStyle from "./CountryListStyle.module.scss";

export const CountrySettings: React.FC<{}> = () => {
  const [countries, setCountries] = useState<TCountry[]>([]);
  const [editCountriesModalShow, setEditCountriesModalShow] = useState(false);
  const [selectedCountryDetail, setSelectedCountryDetail] = useState<TCountry>({
    countryCode: "",
    countryName: "",
    currency: "",
    roe: 0,
    transactionCost: 0,
    imageUrl: "",
    isActive: false,
  });

  useEffect(() => {
    getCountries();
  }, []);

  const config = { headers: { "Content-Type": "multipart/form-data" } };
  const getCountries = async () => {
    const res = await activityAxios.get("/master/getCountries", {});

    if (res?.data?.success) {
      setCountries(res?.data.result);
    }
  };
  const hideModal = () => {
    setEditCountriesModalShow(false);
  };
  return (
    <div className="container-fluid">
      <div className={countryListStyle.countryPage}>
        {editCountriesModalShow && (
          <EditCountryModal
            show={true}
            onHide={() => setEditCountriesModalShow(false)}
            selectedCountryDetails={selectedCountryDetail}
            getCountries={getCountries}
            HideModal={hideModal}
          />
        )}
        <div className={`${countryListStyle.countryHeading} `}>
          <h5>Countries </h5>
          <button
            className="btn btn-primary"
            onClick={() => {
              setSelectedCountryDetail({
                countryCode: "",
                countryName: "",
                currency: "",
                roe: 0,
                transactionCost: 0,
                isActive: false,
              });
              setEditCountriesModalShow(true);
            }}
          >
            + Add New
          </button>
        </div>

        {countries.length > 0 &&
          countries.map((country, key) => {
            return (
              <div
                key={key}
                className={`container-fluid ${countryListStyle.countryListItem}`}
              >
                <div className="row align-items-center">
                  <div className="col-lg-1 px-0">
                    <div>
                      <img
                        className={`img-fluid ${countryListStyle.countryImg}`}
                        src="https://images.unsplash.com/photo-1572252009286-268acec5ca0a?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="col-lg-11">
                    <div className={`container-fluid`}>
                      <div className="row align-items-center">
                        <div className="col-lg-4">
                          <div className="d-flex align-items-center">
                            <div>
                              <div className="small text-muted">
                                <span className="small">Country </span>
                                <span
                                  className={`badge ${
                                    country.isActive
                                      ? "bg-success"
                                      : "bg-danger"
                                  }`}
                                >
                                  {country.isActive ? "Active" : "Not Active"}
                                </span>
                              </div>
                              <div className={countryListStyle.itemData}>
                                {country.countryName}{" "}
                                <span className="small text-secondary">
                                  {country.countryCode}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-3">
                          <div className="small text-muted">ROE :</div>
                          <div className={countryListStyle.itemData}>
                            {country.countryName}
                          </div>
                        </div>
                        <div className="col-lg-3">
                          <div className="small text-muted">
                            Transaction Cost :
                          </div>
                          <div className={countryListStyle.itemData}>
                            {country.countryName}
                          </div>
                        </div>
                        <div className="col-lg-1">
                          <div className="small text-muted">Currency :</div>
                          <div className={countryListStyle.itemData}>
                            {country.currency}
                          </div>
                        </div>
                        <div className="col-lg-1">
                          <button
                            className="btn btn-outline-primary btn-sm w-100"
                            onClick={() => {
                              setEditCountriesModalShow(true);
                              setSelectedCountryDetail(country);
                            }}
                          >
                            Edit
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};
