import { z } from "zod";
import { EnumTourOptionTransferType } from "../types/tour.interface";
import { ZodHelperService } from "./zodHelper.service";

z.setErrorMap(ZodHelperService.errorMap);

export const tourOptionZod = z.object({
  id: z.number().optional(),
  tourOptionName: z.string(),
  tourOptionDescription: z.string(),
  tourId: z.number(),
  startTime: z.coerce.number(),
  buffer: z.coerce.number(),
  childAge: z.string(),
  infantAge: z.string(),
  cancellationPolicyDescription: z.string(),
  childPolicyDescription: z.string(),
  durationInMinutes: z.coerce.number(),
  timezone: z.string(),
  hasTourGuide: z.boolean(),
  hasSlot: z.boolean(),
  isHourly: z.boolean(),
  monday: z.boolean(),
  tuesday: z.boolean(),
  wednesday: z.boolean(),
  thrusday: z.boolean(),
  friday: z.boolean(),
  saturday: z.boolean(),
  sunday: z.boolean(),
});

export const createOrEditTourOptionTransfer = z.object({
  id: z.number().optional(),
  tourOptionId: z.number(),
  transferType: z.nativeEnum(EnumTourOptionTransferType),
  transferTime: z.string().min(1),
  transferDurationInMinutes: z.coerce.number(),
  disableChild: z.boolean(),
  disableInfant: z.boolean(),
  twoWayTransfer: z.boolean(),
});

export type TCreateOrEditTourOptionTransfer = z.infer<
  typeof createOrEditTourOptionTransfer
>;

export type TTourOption = z.infer<typeof tourOptionZod>;

export enum EnumTransferType {
  WITHOUT_TRANSFER = "withoutTransfer",
  PRIVATE_TRANSFER = "privateTransfer",
  SHARING_TRANSFER = "sharingTransfer",
}
