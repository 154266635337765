import Select, { Props, SingleValue } from "react-select";
import {
  useController,
  FieldValues,
  UseControllerProps,
} from "react-hook-form";
import { ErrorMessage } from "./ErrorMessage";
import AsyncSelect from "react-select/async";
import { useRef } from "react";

export interface IOptions {
  label: string;
  value: string | number;
  [key: string]: any;
}

interface IAppControlSelectProps<O extends IOptions> {
  id?: string;
  label?: string;
  options: Array<O>;
  containerClassName?: string;
  isRequired?: boolean;
}

interface ISelectProps<T extends IOptions> {
  id?: string;
  label?: string;
  error?: string;
  containerClassName?: string;
  options: Array<T>;
  value?: T;
  onChange: (selectedOption: SingleValue<T>) => void;
  required?: boolean;
}

interface IAppAsyncSelectProps<T extends Object, isMulti extends boolean>
  extends Props<T, isMulti> {
  loadOptions: (inputValue: string) => Promise<T[]>;
}

export const AppSelect = <T extends IOptions>(props: ISelectProps<T>) => {
  return (
    <div className={props.containerClassName || ""}>
      {props.label && (
        <label
          className={`small ${props.required ? "required" : ""}`}
          htmlFor={props.id}
        >
          {props.label}
        </label>
      )}
      <Select
        id={props.id}
        value={props.value}
        options={props.options}
        onChange={props.onChange}
        isClearable
      />
    </div>
  );
};

export const ControlledAppSelect = <T extends FieldValues, O extends IOptions>(
  props: UseControllerProps<T> & IAppControlSelectProps<O>
) => {
  const {
    field,
    fieldState: { error },
  } = useController({
    name: props.name,
    control: props.control,
    defaultValue: props.defaultValue,
    disabled: props.defaultValue,
    rules: props.rules,
    shouldUnregister: props.shouldUnregister,
  });
  return (
    <div className={props.containerClassName}>
      {props.label && (
        <label
          htmlFor={props.id}
          className={`${props.isRequired ? "required" : ""} small`}
        >
          {props.label}
        </label>
      )}
      <select id={props.id} {...field} className="form-select">
        {props.options.map((opt) => (
          <option key={opt.value} value={opt.value}>
            {opt.label}
          </option>
        ))}
      </select>
      <ErrorMessage errorMessage={error?.message} />
    </div>
  );
};

export const AppAsyncSelect = <T extends Object, isMulti extends boolean>(
  selectProps: IAppAsyncSelectProps<T, isMulti>
) => {
  const timerRef = useRef<NodeJS.Timeout>();

  return (
    <AsyncSelect
      {...selectProps}
      loadOptions={(inputValue, callback) => {
        if (timerRef.current) {
          clearTimeout(timerRef.current);
        }

        timerRef.current = setTimeout(async () => {
          const options = await selectProps.loadOptions(inputValue);
          callback(options);
        }, 500);
      }}
    />
  );
};
