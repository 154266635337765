import React, { Dispatch, SetStateAction } from "react";
import {
  EnumFollowUpMode,
  IEnquiryFollowUp,
} from "../../activitiescommonfnb/schemas/enquiry.schema";
import moment from "moment";
import { toast } from "react-toastify";

const FollowUpListItem: React.FC<{
  scrollToTop: () => void;
  followUpData: IEnquiryFollowUp;
  editFollowUp: (followUp: IEnquiryFollowUp) => void;
}> = (props) => {
  const handleEditFollowUp = () => {
    if (
      moment(props.followUpData.createdAt).isBefore(moment().subtract(1, "day"))
    ) {
      toast.warning("You cannot edit a follow-up after 24 hours.");
      return;
    }
    props.editFollowUp(props.followUpData);
    props.scrollToTop();
  };

  return (
    <div className={`followUpListItem border rounded mb-2`}>
      <div className="d-flex justify-content-between small">
        <span className="p-3 pt-2 pb-0 text-capitalize fw-bold text-muted ">
          {props.followUpData.followUpMode === EnumFollowUpMode.CALL ? (
            <i className="fa-solid fa-phone me-2 text-warning"></i>
          ) : (
            <i className="fa-solid fa-comments me-2 text-warning "></i>
          )}
          {props.followUpData?.createdByStaffDetail?.firstName}{" "}
          {props.followUpData?.createdByStaffDetail?.lastName}
        </span>

        <span className="p-3 pt-2 pb-0 text-muted">
          {moment(props.followUpData?.createdAt).format("lll")}
          <i
            className="fa-solid fa-pen ms-2 text-muted"
            role="button"
            onClick={handleEditFollowUp}
          ></i>
        </span>
      </div>
      <div className="small px-3 py-2">
        {props.followUpData?.followUpMessage}
      </div>
    </div>
  );
};

export default FollowUpListItem;
