import { useEffect, useState } from "react";
import { TCity } from "../../../activitiescommonfnb/schemas/master.schema";
import { activityAxios } from "../../../axios/activityAxios";
import { IJSONResponse } from "../../../activitiescommonfnb/types/common.interface";
import { CreateOrEditTourModal } from "./CreateOrEditTour";
import { SingleTourListItem } from "./SingleTourListItem";
import { CitySelect } from "./CitySelect";
import { EnumUser } from "../../../types/tourSearch.interface";
import {
  ITour,
  ITourOption,
} from "../../../activitiescommonfnb/types/tour.interface";
import { Placeholder } from "react-bootstrap";

const TourLoadingSkeleton = () => {
  return (
    <div className="border rounded  mb-3">
      <div className="row">
        <div className="col-lg-3 pe-0" style={{ height: "12rem" }}>
          {" "}
          <div className="bg-light w-100 h-100 rounded"></div>
        </div>
        <div className="col-lg-9">
          <div className="p-3 h-100 d-flex flex-column justify-content-between">
            <Placeholder animation="glow">
              <Placeholder xs={10} />
              <Placeholder xs={6} size="xs" />
            </Placeholder>

            <div>
              <Placeholder.Button variant="light" xs={3} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const TourList: React.FC = () => {
  const [selectedCity, setSelectedCity] = useState<Required<TCity>>();
  const [tours, setTours] = useState<ITour[]>([]);
  const [loadingTours, setLoadingTours] = useState(false);

  const [showCreateModal, setShowCreateModal] = useState(false);

  const getTours = async (slug: string) => {
    setTours([]);
    setLoadingTours(true);
    const apiRes = await activityAxios.post<IJSONResponse>(
      "/master/getToursByCitySlug",
      {
        citySlug: slug,
      }
    );

    if (apiRes.data.success && apiRes.data.result) {
      setTours(apiRes.data.result);
    }
    setLoadingTours(false);
  };

  useEffect(() => {
    if (selectedCity) {
      getTours(selectedCity.slug);
    } else {
      setTours([]);
    }
  }, [selectedCity]);

  return (
    <div className="container-fluid px-0">
      <div className="row align-items-end justify-content-between py-4 px-3 bg-light">
        <div className="col-lg-6 ">
          <h5>Tours</h5>
        </div>
        <div className="col-lg-6 ">
          <div className="row align-items-end">
            <div className="col-lg-9">
              <CitySelect
                onCityChange={(city) => {
                  setSelectedCity(city);
                }}
              />
            </div>
            <div className="col-lg-3">
              <button
                className="btn btn-primary w-100"
                onClick={() => {
                  setShowCreateModal(true);
                }}
              >
                + Tour
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-3 px-3">
        {selectedCity?.name && (
          <div className="mb-3">
            <span className="text-muted">{selectedCity?.name}</span>
            <span>
              <i className="fa-solid fa-angle-right mx-2 text-muted"></i>
            </span>
            <span className="fs-5">Tour List</span>
          </div>
        )}
        {/* <h4>{selectedCity?.name} </h4> */}
        {tours.map((singleTour, index) => {
          return (
            <SingleTourListItem
              userType={EnumUser.ADMIN}
              getTours={() => {
                if (selectedCity) {
                  getTours(selectedCity.slug);
                }
              }}
              key={singleTour.id}
              tourDetails={singleTour}
            />
          );
        })}

        {loadingTours && (
          <div>
            <TourLoadingSkeleton />
            <TourLoadingSkeleton />
            <TourLoadingSkeleton />
            <TourLoadingSkeleton />
          </div>
        )}
      </div>

      {showCreateModal && (
        <CreateOrEditTourModal
          show={showCreateModal}
          onClose={() => {
            setShowCreateModal(false);
          }}
          onSave={() => {
            setShowCreateModal(false);
            if (selectedCity) {
              getTours(selectedCity.slug);
            }
          }}
        />
      )}
    </div>
  );
};

export default TourList;
