import React from "react";
import { Link } from "react-router-dom";
import iata from "../../assets/images/certificate/logo/IATAlogo.png";
import taai from "../../assets/images/certificate/logo/taailogo.png";
import adtoi from "../../assets/images/certificate/logo/ADTOILogo.png";
import iatte from "../../assets/images/certificate/logo/iattelogo.png";
import hdfc from "../../assets/images/aboutUs/hdfc.png";
import icici from "../../assets/images/aboutUs/icicc.png";
import sbi from "../../assets/images/aboutUs/sbi.png";
import qrPaymentScanner from "../../assets/images/aboutUs/qrScanner.jpeg";
import quotationFooterStyle from "./QuotationFooterStyle.module.scss";

const QuotationFooter: React.FC<{}> = () => {
  const handleClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    window.open("/aboutUs", "_blank");
  };
  return (
    <div className={`bg-light p-4 ${quotationFooterStyle.quotationFooter}`}>
      <div className={quotationFooterStyle.aboutUs}>
        <div className="d-flex align-items-center justify-content-between mb-4 small">
          <h5 className="text-center m-0">Company Details </h5>
          <a
            href="/aboutUs"
            onClick={handleClick}
            className="btn bg-secondary text-white btn-sm rounded-pill fw-bold px-4"
          >
            <div className="rounded-pill">
              Company Profile
              <i className="fa-solid fa-link ms-1"></i>
            </div>
          </a>
        </div>
        <h6>About Us </h6>
        <div className={quotationFooterStyle.aboutpara}>
          Maya Travels Pvt. Ltd. is one stop solution for Travel Trade. We
          provide B2B solutions for our travel partners. Our goal is to simplify
          and fulfil the needs and demands of travel industry with the aid of
          technology and collaborations effort of highly trained and passionate
          team of travel enthusiasts. Technology, honesty and expertise are the
          supporting pillars & foundation of our organisation. Founder of Maya
          Travels has extensive experience of over two decades in travel trade.
        </div>
      </div>
      <div className={quotationFooterStyle.certificate}>
        <img
          className={quotationFooterStyle.certificateIcon}
          src={iata}
          alt=""
        />
        <img
          className={quotationFooterStyle.certificateIcon}
          src={adtoi}
          alt=""
        />
        <img
          className={quotationFooterStyle.certificateIcon}
          src={iatte}
          alt=""
        />
        <img
          className={quotationFooterStyle.certificateIcon}
          src={taai}
          alt=""
        />
      </div>
      <div className={quotationFooterStyle.dealsIn}>
        <h6 className="m-0">Deals In : </h6>
        <div className={quotationFooterStyle.moduleItem}>Flights</div>
        <div className={quotationFooterStyle.moduleItem}>Visa</div>
        <div className={quotationFooterStyle.moduleItem}>Tour Package</div>
        <div className={quotationFooterStyle.moduleItem}>Hotels</div>
        <div className={quotationFooterStyle.moduleItem}>Travel Insurance</div>
      </div>
      <div className={quotationFooterStyle.bankDetails}>
        {/* <h6 className="mt-2"> QR Code Scanner</h6> */}
        <div className="row align-items-center">
          <div className="col-lg-8 col-md-9 col-sm-9 col-9">
            <div className="">
              <h6>Bank Account Details :</h6>
              <span className="text-secondary fw-bold">
                Please Make All Transaction in the Following Details.
              </span>
              <div>
                Cash you can deposit in hdfc only below 50k INR. Cash deposit
                not allowed in any other account. <br /> Please don't transfer
                any payment from any account outside india. <br /> Bank holds
                payment sent in business account with wrong purpose code e.g
                family maintenance.
              </div>
            </div>
          </div>
          <div className="col-lg-2 col-md-3 col-sm-3 col-3">
            <div className="bg-light text-center">
              <img
                className={`img-fluid ${quotationFooterStyle.qrScanner}`}
                src={qrPaymentScanner}
                alt=""
              />
              <span className="fw-bold">QR / UPI Scanner</span>
            </div>
          </div>
        </div>
        <div className={quotationFooterStyle.bankDetailsItem}>
          <div className="row align-items-center">
            <div className="col-lg-2 col-md-2 col-sm-2 col-2">
              <img
                src={hdfc}
                className={`img-fluid ${quotationFooterStyle.bankLogo}`}
                alt=""
              />
            </div>
            <div className="col-lg-4 col-md-4 col-sm-4 col-4">
              <p className="small">Account Holder :</p>
              MAYA TRAVELS PVT. LTD.
            </div>
            <div className="col-lg-3 col-md-3 col-sm-3 col-3">
              <p className="small">Account No. :</p>
              50200023438355
            </div>
            <div className="col-lg-3 col-md-3 col-sm-3 col-3">
              <p className="small">IFSC CODE :</p>
              HDFC0000871
            </div>
          </div>
        </div>
        <div className={quotationFooterStyle.bankDetailsItem}>
          <div className="row align-items-center">
            <div className="col-lg-2 col-md-2 col-sm-2 col-2">
              <img
                src={sbi}
                className={`img-fluid ${quotationFooterStyle.bankLogo}`}
                alt=""
              />
            </div>
            <div className="col-lg-4 col-md-4 col-sm-4 col-4">
              <p className="small">Account Holder :</p>
              MAYA TRAVELS PVT. LTD.
            </div>
            <div className="col-lg-3 col-md-3 col-sm-3 col-3">
              <p className="small">Account No. :</p>
              6520 4194 412
            </div>
            <div className="col-lg-3 col-md-3 col-sm-3 col-3">
              <p className="small">IFSC CODE :</p>
              SBIN0001760
            </div>
          </div>
        </div>
        <div className={quotationFooterStyle.bankDetailsItem}>
          <div className="row align-items-center">
            <div className="col-lg-2 col-md-2 col-sm-2 col-2">
              <img
                src={icici}
                className={`img-fluid ${quotationFooterStyle.bankLogo}`}
                alt=""
              />
            </div>
            <div className="col-lg-4 col-md-4 col-sm-4 col-4">
              <p className="small">Account Holder :</p>
              MAYA TRAVELS PVT. LTD.
            </div>
            <div className="col-lg-3 col-md-3 col-sm-3 col-3">
              <p className="small">Account No. :</p>
              35910500-0013
            </div>
            <div className="col-lg-3 col-md-3 col-sm-3 col-3">
              <p className="small">IFSC CODE :</p>
              ICIC0003591
            </div>
          </div>
        </div>
      </div>
      <div className={quotationFooterStyle.contactSupport}>
        <h6 className="mb-3">For Feedback / Complaints Contact :</h6>{" "}
        <span className="alert bg-secondary fw-bold text-white py-2">
          Phone No : +91 81999 77740 ,
        </span>
        <span className="alert bg-secondary fw-bold text-white py-2 ms-3">
          Email : jasmohan@mayatravelsindia.com
        </span>
      </div>
      <div className="mt-3 small">
        <div className="d-flex justify-content-between align-items-center">
          <div> Maya Travel Private Limited © 2024</div>
          <div>
            <Link to={"/aboutUs"}>
              To Know More{" "}
              <i className="fa-solid fa-arrow-up-right-from-square ms-1"></i>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuotationFooter;
