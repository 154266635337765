import React from "react";
import Table from "react-bootstrap/Table";

type PriceData = {
  pax: number;
  sharingBasis: number;
  privateBasis: number;
  ticketOnlyAdult?: number; // Optional for Ticket Only
  ticketOnlyChild?: number; // Optional for Ticket Only
};

type PriceChartProps = {
  data: PriceData[];
};

const PriceChart: React.FC<PriceChartProps> = ({ data }) => {
  const headers = ["Type", "Sharing Basis", "Private Basis", "Ticket Only"];
  const ticketOnlySubHeaders = ["Adult", "Child"];

  return (
    <Table responsive className="price-chart">
      <thead className="bg-primary text-dark">
        <tr>
          {headers.map((header, index) => (
            <th
              key={index}
              colSpan={
                header === "Ticket Only" ? ticketOnlySubHeaders.length : 1
              }
            >
              {header}
            </th>
          ))}
        </tr>
        <tr>
          <th></th>
          <th></th>
          <th></th>
          {ticketOnlySubHeaders.map((subHeader, index) => (
            <th key={`sub-${index}`}>{subHeader}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.map((item, index) => (
          <tr key={index} className="price-chart-row">
            <td>{item.pax} pax</td>
            <td>{item.sharingBasis}</td>
            <td>{item.privateBasis}</td>
            <td>
              {item.ticketOnlyAdult !== undefined ? item.ticketOnlyAdult : ""}
            </td>
            <td>
              {item.ticketOnlyChild !== undefined ? item.ticketOnlyChild : ""}
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default PriceChart;
