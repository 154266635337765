import React, { useEffect, useState } from "react";
import { activityAxios } from "../../../axios/activityAxios";
import { Nav, Tab, Modal, Button, Form } from "react-bootstrap";
import {
  destinationGroupTemplate,
  IDestinationGroup,
  IDestinationGroupTabs,
} from "../../../activitiescommonfnb/types/master.interface";
import AppCkEditor from "../../../commonUi/AppCkEditor";
import {
  destinationGroupSchema,
  TCity,
} from "../../../activitiescommonfnb/schemas/master.schema";
import { ZodHelperService } from "../../../activitiescommonfnb/schemas/zodHelper.service";
import { toast } from "react-toastify";
import { AppSelect } from "../../../commonUi/AppSelect";
import { IJSONResponse } from "../../../activitiescommonfnb/types/common.interface";

const DestinationGroup = () => {
  const [destinationGroupData, setDestinationGroupData] =
    useState<IDestinationGroup>(destinationGroupTemplate);
  const [destinationGroupTabs, setDestinationGroupTabs] = useState<
    IDestinationGroupTabs[]
  >([]);
  const [cities, setCities] = useState<Required<TCity>[]>([]);
  const [key, setKey] = useState<number | null>(null);
  const [selectedCity, setSelectedCity] = useState<Partial<TCity>>({});
  const [showAddModal, setShowAddModal] = useState<boolean>(false);
  const [newDestinationGroupTitle, setNewDestinationGroupTitle] =
    useState<string>("");
  const [showConfirmationModal, setShowConfirmationModal] =
    useState<boolean>(false);
  const [validatedData, setValidatedData] = useState({});

  const getDestinationGroupTabs = async () => {
    const apiRes = await activityAxios.post("/master/getDestinationGroupTabs");
    if (apiRes.data.success) {
      setDestinationGroupTabs(apiRes.data.result);
      if (!key) setKey(apiRes.data.result[0]?.id);
    }
  };

  const getDestinationGroupData = async (id: number | null) => {
    const apiRes = await activityAxios.post("/master/getDestinationGroupByID", {
      destinationGroupId: id,
    });
    if (apiRes.data.success) {
      setDestinationGroupData(apiRes.data.result);
    }
  };

  const addOrEditDestinationGroup = async (
    destinationGroupData: IDestinationGroup
  ) => {
    const transformedData = {
      ...destinationGroupData,
      cityIds: destinationGroupData.cities.map((city) => city.id),
    };

    const validatedData = destinationGroupSchema.safeParse(transformedData);

    if (!validatedData.success) {
      const formattedError: any = ZodHelperService.formatZodErrors(
        validatedData.error,
        "Error"
      );
      toast.error(formattedError);
      return;
    }
    setValidatedData(validatedData.data);
    setShowConfirmationModal(true);
  };

  const handleConfirmationModalClose = () => {
    setShowConfirmationModal(false);
  };

  const handleConfirmationModalConfirm = async () => {
    setShowConfirmationModal(false);

    const apiRes = await activityAxios.post(
      "/master/addOrEditDestinationGroup",
      { destinationGroupData: validatedData }
    );

    if (apiRes.data.success) {
      setDestinationGroupData(apiRes.data.result);
      toast.success("Destination Group Updated Successfully");
    } else {
      toast.error("Failed to update");
    }
  };

  const getAllCities = async () => {
    const apiRes = await activityAxios.get<IJSONResponse>(
      "/search/getAllCities"
    );
    if (apiRes.data.success && apiRes.data.result) {
      setCities(apiRes.data.result);
    }
  };

  const addDestinationGroup = async () => {
    const apiRes = await activityAxios.post(
      "/master/addOrEditDestinationGroup",
      {
        destinationGroupData: {
          title:
            newDestinationGroupTitle.length === 0
              ? "New Destination Group"
              : newDestinationGroupTitle,
          remark: "",
          termsAndConditions: "",
          cityIds: [],
        },
      }
    );

    if (apiRes.data.success) {
      setShowAddModal(false);
      getDestinationGroupTabs();
      setNewDestinationGroupTitle("");
      setKey(apiRes.data.result?.id);
    } else {
      toast.error("Failed to add destination group");
    }
  };

  const handleCityDelete = (id: number | null) => {
    const updatedData = destinationGroupData.cities.filter(
      (item) => item.id !== id
    );
    setDestinationGroupData({ ...destinationGroupData, cities: updatedData });
  };

  const handleTabClick = (key: number | null) => {
    if (key) {
      setKey(key);
      getDestinationGroupData(key);
      setSelectedCity({});
    }
  };

  const handleCitySelect = (data: any) => {
    const cityExists = destinationGroupData.cities.find(
      (city) => city.id === data?.city.id
    );

    if (data && !cityExists) {
      setSelectedCity(data?.city);
      setDestinationGroupData({
        ...destinationGroupData,
        cities: [
          ...destinationGroupData.cities,
          { id: data.city.id, name: data.city.name },
        ],
      });
    }
  };

  useEffect(() => {
    getDestinationGroupTabs();
    getAllCities();
  }, []);

  useEffect(() => {
    if (key) handleTabClick(key);
  }, [key]);

  return (
    <>
      <Tab.Container
        id="destinationGroupTabs"
        activeKey={`${key}`}
        onSelect={(key) => key && handleTabClick(parseInt(key))}
      >
        <div className="row">
          <div className="d-flex justify-content-between py-3 px-4 bg-success p-2 text-dark bg-opacity-25">
            <div>
              Choose Destination Group{" "}
              <i className="fa-solid fa-angle-down ms-2"></i>
            </div>
            <button
              className="text-white small  btn btn-primary"
              onClick={() => setShowAddModal(true)}
            >
              Add Destination Group
            </button>
          </div>
          <div className={`col-lg-3 ps-0 `}>
            <div className="">
              <Nav variant="pills" className="flex-column">
                {destinationGroupTabs?.map((tab) => (
                  <Nav.Item key={tab.id}>
                    <Nav.Link eventKey={tab.id}>{tab.title}</Nav.Link>
                  </Nav.Item>
                ))}
              </Nav>
            </div>
          </div>
          <div className="col-lg-9">
            {destinationGroupTabs.length > 0 && (
              <Tab.Content>
                <form action="">
                  <label className="mt-4">Title</label>
                  <input
                    type="text"
                    name="title"
                    className="form-control"
                    value={destinationGroupData?.title}
                    onChange={(e) =>
                      setDestinationGroupData((old) => ({
                        ...old,
                        title: e.target.value || "",
                      }))
                    }
                  />
                  <AppCkEditor
                    label="Terms & Conditions"
                    className="mt-4"
                    value={destinationGroupData?.termsAndConditions}
                    onChange={(e) => {
                      setDestinationGroupData((old) => ({
                        ...old,
                        termsAndConditions: e || "",
                      }));
                    }}
                  />
                  <label className="mt-4">Remark</label>
                  <input
                    type="text"
                    name="remark"
                    className="form-control"
                    value={destinationGroupData?.remark}
                    onChange={(e) =>
                      setDestinationGroupData((old) => ({
                        ...old,
                        remark: e.target.value || "",
                      }))
                    }
                  />
                  <label className="mt-4">Cities</label>
                  <AppSelect
                    value={
                      selectedCity
                        ? {
                            label: selectedCity.name ? selectedCity.name : "",
                            value: selectedCity.id ? selectedCity.id : "",
                            city: selectedCity,
                          }
                        : undefined
                    }
                    onChange={(data) => handleCitySelect(data)}
                    options={cities.map((city) => ({
                      label: city.name,
                      value: city.id,
                      city: city,
                    }))}
                  />
                  <div className="my-4">
                    {destinationGroupData?.cities?.map((city) => (
                      <span
                        className="bg-success me-2 p-2 text-white rounded-2"
                        key={city.id}
                      >
                        {city.name}{" "}
                        <i
                          className="fa-solid fa-xmark"
                          role="button"
                          onClick={() => handleCityDelete(city.id)}
                        ></i>
                      </span>
                    ))}
                  </div>
                  <div className="d-flex justify-content-end my-4 me-4">
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        addOrEditDestinationGroup(destinationGroupData);
                      }}
                      className="btn btn-primary btn-sm small"
                    >
                      Save
                    </button>
                  </div>
                </form>
              </Tab.Content>
            )}
          </div>
        </div>
      </Tab.Container>

      <Modal show={showConfirmationModal} onHide={handleConfirmationModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Save Changes</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="text-danger">
            Warning: This can/will impact the existing quotation/bookings linked
            with the related cites.
          </p>
          Are you sure you want to continue?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleConfirmationModalClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleConfirmationModalConfirm}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showAddModal}
        onHide={() => {
          setShowAddModal(false);
          setNewDestinationGroupTitle("");
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add New Destination Group</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="formDestinationGroupTitle">
              <Form.Label>Title</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter title"
                value={newDestinationGroupTitle}
                onChange={(e) => setNewDestinationGroupTitle(e.target.value)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setShowAddModal(false);
              setNewDestinationGroupTitle("");
            }}
          >
            Close
          </Button>
          <Button variant="primary" onClick={() => addDestinationGroup()}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DestinationGroup;
