import React, { Dispatch, SetStateAction, useState } from "react";

import { activityAxios } from "../../../axios/activityAxios";
import { toast } from "react-toastify";
import { z } from "zod";

export interface ISaveOrEditCity {
  id?: number;
  name: string;
  countryId?: number;
  slug: string;
}

export const saveOrEditCityZod = z.object({
  id: z.number().optional(),
  name: z.string().min(1),
  countryId: z.number().optional(),
  slug: z.string(),
});

const CreateOrEditCity: React.FC<{
  newCity: ISaveOrEditCity;
  setShowAddNewCountry: Dispatch<SetStateAction<boolean>>;
  selectedCountry: { countryName: string | undefined; countryId: number };
  getCities: (countryId: number) => void;
  setEditCity: Dispatch<SetStateAction<number | undefined>>;
}> = (props) => {
  const [newCity, setNewCity] = useState<ISaveOrEditCity>(props.newCity);
  const [loading, setLoading] = useState(false);

  const saveOrEditCity = async () => {
    try {
      if (loading) return;
      setLoading(true);
      const verifiedCityData = saveOrEditCityZod.safeParse(newCity);
      if (!verifiedCityData || !newCity.countryId) {
        console.log(newCity);

        throw new Error("Something went wrong");
      }
      const saveOrEditCityResponse = await activityAxios.post(
        "/master/createOrEditCity",
        { cityData: newCity }
      );
      if (saveOrEditCityResponse.data.success) {
        toast.success("City Saved");
        if (props.selectedCountry)
          props.getCities(props.selectedCountry?.countryId);
        props.setEditCity(undefined);
        props.setShowAddNewCountry(false);
      }
    } catch (error: any) {
      toast.error(error);
    }
    setLoading(false);
  };
  return (
    <div>
      <div className="border p-3 bg-white rounded w-100 mb-3">
        <label className="small text-muted">City Name</label>
        <input
          value={newCity.name}
          placeholder="City Name"
          type="text"
          className="form-control"
          onChange={(e) => {
            setNewCity((old) => {
              return { ...old, name: e.target.value };
            });
          }}
        />{" "}
        <label className="small text-muted">Slug</label>
        <input
          value={newCity.slug}
          placeholder="Enter Slug"
          type="text"
          className="form-control"
          onChange={(e) =>
            setNewCity((old) => {
              return { ...old, slug: e.target.value };
            })
          }
        />
        <div className="row mt-3">
          <div className="col-lg-6">
            <button
              className="btn btn-primary btn-sm w-100"
              onClick={saveOrEditCity}
            >
              Save
            </button>
          </div>
          <div className="col-lg-6">
            <button
              className="btn btn-outline-secondary btn-sm w-100"
              onClick={() => {
                props.setShowAddNewCountry(false);
                props.setEditCity(undefined);
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateOrEditCity;
