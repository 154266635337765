import { z } from "zod";
import { ZodHelperService } from "./zodHelper.service";
import { EnumReminderFrequency } from "../types/task.interface";

export const createOrEditTaskSchema = z
  .object({
    id: z.number().optional(),
    userId: z.number(),
    title: z.string().min(1),
    description: z.string().min(1).nullish(),
    deadline: ZodHelperService.getZodDate().nullish(),
    enquiryId: z.number().optional().nullable(),
    reminderStartDate: ZodHelperService.getZodDate().optional().nullable(),
    reminderStartTime: ZodHelperService.getZodDate().optional().nullable(),
    reminderEndTime: ZodHelperService.getZodDate().optional().nullable(),
    reminderFrequency: z
      .nativeEnum(EnumReminderFrequency)
      .optional()
      .nullable(),
    isPhotoProofRequired: z.boolean(),
  })
  .superRefine((data, ctx) => {
    if (data.reminderFrequency) {
      if (!data.reminderStartDate) {
        ctx.addIssue({
          code: "custom",
          path: ["reminderStartDate"],
          message: `Reminder start date is required.`,
        });
      }

      if (!data.reminderStartTime) {
        ctx.addIssue({
          code: "custom",
          path: ["reminderStartTime"],
          message: `Reminder start time is required.`,
        });
      }

      if (
        (data.reminderFrequency === EnumReminderFrequency.HOURLY ||
          data.reminderFrequency === EnumReminderFrequency.EVERY_SIX_HOUR) &&
        !data.reminderEndTime
      ) {
        ctx.addIssue({
          code: "custom",
          path: ["reminderEndTime"],
          message: `Reminder end time is required.`,
        });
      }
    }

    return z.NEVER;
  })
  .transform((data) => {
    /**
     *  This code will run after all the validations when the user is trying to submit the task form.
     *  Here we are setting the date and time to null which is not required based on the frequency setting,
     *  so that we don't save any redundant data in DB.
     *
     *  Case 1. If reminder frequency is either Daily or Once
     *          Then setting end time to null.
     *
     *  Case 2. If reminder frequency is null
     *          Then set all the date and time fields to null.
     */
    if (data.reminderFrequency) {
      if (
        data.reminderFrequency === EnumReminderFrequency.DAILY ||
        data.reminderFrequency === EnumReminderFrequency.ONCE
      ) {
        data.reminderEndTime = null;
      }
    } else {
      data.reminderStartDate = null;
      data.reminderStartTime = null;
      data.reminderEndTime = null;
    }

    return data;
  });

export type TCreateOrEditTaskZod = z.infer<typeof createOrEditTaskSchema>;
