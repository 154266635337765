import { Collapse, FormCheck, Modal } from "react-bootstrap";
import { AppButton } from "../../../commonUi/AppButton";
import { CitySelect } from "./CitySelect";
import { useForm } from "react-hook-form";
import {
  TCreateOrEditTour,
  TTour,
  createOrEditTourZod,
} from "../../../activitiescommonfnb/schemas/master.schema";
import { zodResolver } from "@hookform/resolvers/zod";
import { AppControlInput, AppInput } from "../../../commonUi/AppInput";
import AppCkEditor from "../../../commonUi/AppCkEditor";
import { useEffect, useState } from "react";
import { activityAxios } from "../../../axios/activityAxios";
import { IJSONResponse } from "../../../activitiescommonfnb/types/common.interface";
import { toast } from "react-toastify";

export const CreateOrEditTourModal: React.FC<{
  id?: number;
  show: boolean;
  onSave: () => void;
  onClose: () => void;
}> = (props) => {
  const { watch, formState, setValue, control, handleSubmit, reset } = useForm<
    Partial<TCreateOrEditTour>
  >({
    defaultValues: {
      isComboTour: false,
    },
    resolver: zodResolver(createOrEditTourZod),
  });

  const [additionalDetailsCollapse, setAdditionalDetailsCollapse] =
    useState(false);

  const tourState = watch();

  const tourError = formState.errors;

  const getTourById = async (id: number) => {
    const apiRes = await activityAxios.post<IJSONResponse<TTour>>(
      "/master/getTourById",
      {
        tourId: id,
      }
    );

    if (apiRes.data.success && apiRes.data.result) {
      reset(apiRes.data.result);
    }
  };

  const saveTourHandler = async (tourDetails: Partial<TCreateOrEditTour>) => {
    if (props.id) {
      tourDetails.id = props.id;
    }

    if (
      tourDetails.infantAge &&
      tourDetails.childAge &&
      tourDetails.childAge <= tourDetails.infantAge
    ) {
      toast.error("Infant's age should be less than child's age");
      return;
    }

    const apiRes = await activityAxios.post<IJSONResponse>(
      "/master/createOrEditTour",
      {
        tourData: tourDetails,
      }
    );

    if (apiRes.data.success) {
      toast.success(`Tour ${props.id ? "Edited" : "Created"} Successfully.`);
      props.onSave();
    } else {
      toast.error(
        apiRes.data.errorMessage ||
          `Unable to ${props.id ? "edit" : "create"} tour.`
      );
    }
  };

  useEffect(() => {
    if (props.id) {
      getTourById(props.id);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Modal show={props.show} onHide={props.onClose} size="lg" centered>
      <Modal.Header closeButton>
        <Modal.Title>{props.id ? "Edit Tour" : "Add New Tour"}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <CitySelect
            defaultCityId={tourState.cityId}
            onCityChange={(city) => {
              setValue("cityId", city?.id);
              setValue("countryId", city?.countryId);
            }}
          />

          <AppInput
            containerClassName="col-6 mt-2"
            label="Tour Name"
            fieldName="name"
            isRequired
            onChange={setValue}
            value={tourState.name}
          />

          <AppControlInput
            containerClassName="col-6 mt-2"
            label="Tour Slug"
            isRequired
            control={control}
            name="slug"
          />

          <div className="col-12 mt-2">
            <label htmlFor="isComboTour" className="required">
              Is Combo Tour ?
            </label>
            <div className="d-flex justify-content-between">
              <div className="w-50 d-flex">
                <FormCheck
                  checked={tourState.isComboTour}
                  type="radio"
                  name="isComboTour"
                  id="isComboTour_yes"
                  onChange={() => {
                    setValue("isComboTour", true);
                  }}
                />
                <label htmlFor="isComboTour_yes" className="ms-2">
                  Yes
                </label>
              </div>
              <div className="w-50 d-flex">
                <FormCheck
                  checked={!tourState.isComboTour}
                  type="radio"
                  name="isComboTour"
                  id="isComboTour_no"
                  onChange={() => {
                    setValue("isComboTour", false);
                  }}
                />
                <label htmlFor="isComboTour_no" className="ms-2">
                  No
                </label>
              </div>
            </div>
          </div>

          <AppInput
            containerClassName="col-12 mt-2"
            label="Reporting Time"
            isRequired
            fieldName="reportingTime"
            onChange={setValue}
            value={tourState.reportingTime}
          />

          <AppInput
            containerClassName="col-6 mt-2"
            label="Pickup Point"
            isRequired
            fieldName="pickupPoint"
            onChange={setValue}
            value={tourState.pickupPoint}
          />
          <AppInput
            containerClassName="col-6 mt-2"
            label="Drop Point"
            fieldName="dropPoint"
            onChange={setValue}
            value={tourState.dropPoint}
          />

          <AppInput
            containerClassName="col-6 mt-2"
            label="Child Age"
            fieldName="childAge"
            onChange={setValue}
            value={tourState.childAge}
          />
          <AppInput
            containerClassName="col-6 mt-2"
            label="Infant Age"
            fieldName="infantAge"
            onChange={setValue}
            value={tourState.infantAge}
          />
          <AppInput
            containerClassName="col-6 mt-2"
            label="Ratings"
            type="number"
            fieldName="rating"
            onChange={(fieldName, value) => setValue("rating", parseInt(value))}
            value={tourState.rating?.toString()}
            errorMessage={formState.errors.rating?.message}
          />
          <AppInput
            containerClassName="col-6 mt-2"
            label="Reviews"
            type="number"
            fieldName="reviewCount"
            onChange={(fieldName, value) =>
              setValue("reviewCount", parseInt(value))
            }
            value={tourState.reviewCount?.toString()}
          />

          <AppCkEditor
            className="mt-2"
            isRequired
            label="Tour Short Description"
            value={tourState.tourShortDescription}
            onChange={(value) => setValue("tourShortDescription", value)}
          />
          <AppCkEditor
            className="mt-2"
            isRequired
            label="Tour Description"
            value={tourState.tourDescription}
            onChange={(value) => setValue("tourDescription", value)}
          />
          <AppCkEditor
            className="mt-2"
            isRequired
            label="Cancellation Policy"
            value={tourState.cancellationPolicyDescription}
            onChange={(value) =>
              setValue("cancellationPolicyDescription", value)
            }
          />
          <div
            onClick={() => {
              setAdditionalDetailsCollapse((old) => !old);
            }}
            className="col-12 text-end text-primary mt-1"
            role="button"
          >
            additional details{" "}
            {additionalDetailsCollapse ? (
              <i className="fa-solid fa-chevron-up"></i>
            ) : (
              <i className="fa-solid fa-chevron-down"></i>
            )}
          </div>
          <Collapse in={additionalDetailsCollapse}>
            <div className="container-fluid">
              <div className="row">
                <AppCkEditor
                  className="mt-2"
                  label="Child Cancellation Policy"
                  value={tourState.childCancellationPolicyDescription}
                  onChange={(value) =>
                    setValue("childCancellationPolicyDescription", value)
                  }
                />
                <AppCkEditor
                  className="mt-2"
                  label="Itinarary Description"
                  value={tourState.itenararyDescription}
                  onChange={(value) => setValue("itenararyDescription", value)}
                />
                <AppCkEditor
                  className="mt-2"
                  label="Terms And Conditions"
                  value={tourState.termsAndConditions}
                  onChange={(value) => setValue("termsAndConditions", value)}
                />
                <AppCkEditor
                  className="mt-2"
                  label="Faq Details"
                  value={tourState.faqDetails}
                  onChange={(value) => setValue("faqDetails", value)}
                />
                <AppCkEditor
                  className="mt-2"
                  label="Useful Information"
                  value={tourState.usefullInformation}
                  onChange={(value) => setValue("usefullInformation", value)}
                />
                <AppCkEditor
                  className="mt-2"
                  label="Important Information"
                  value={tourState.importantInformation}
                  onChange={(value) => setValue("importantInformation", value)}
                />
                <AppCkEditor
                  className="mt-2"
                  label="Whats in this Tour?"
                  value={tourState.whatsInThisTour}
                  onChange={(value) => setValue("whatsInThisTour", value)}
                />
                <AppCkEditor
                  className="mt-2"
                  label="Tours Advantage"
                  value={tourState.toursAdvantage}
                  onChange={(value) => setValue("toursAdvantage", value)}
                />
                <AppCkEditor
                  className="mt-2"
                  label="Tour Inclusion"
                  value={tourState.tourInclusion}
                  onChange={(value) => setValue("tourInclusion", value)}
                />
              </div>
            </div>
          </Collapse>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <AppButton onClick={props.onClose} variant="secondary">
          Close
        </AppButton>
        <AppButton onClick={handleSubmit(saveTourHandler)}>Submit</AppButton>
      </Modal.Footer>
    </Modal>
  );
};
