import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import singleActivitiPageStyle from "./SingleActivityPageStyle.module.scss";
import { activityAxios } from "../../axios/activityAxios";
import { IJSONResponse } from "../../activitiescommonfnb/types/common.interface";
import { Ratings } from "../../commonUi/Ratings";
import { ITourWithPrice } from "../../activitiescommonfnb/types/activitySearch.interface";
import Price from "../../commonUi/Price";
import Carousel from "react-bootstrap/Carousel";
import { MainLoader } from "../../commonUi/MainLoader";
import { TourOption } from "../../components/Tour/TourOption";
import moment from "moment";

const ActivitySinglePage = () => {
  const [tourDetail, setTourDetail] = useState<ITourWithPrice>();
  const [loadTourSinglePage, setLoadTourSinglePage] = useState(false);
  const searchParams = useParams<{
    citySlug: string;
    activitySlug: string;
  }>();

  const getTourBySlug = async () => {
    setLoadTourSinglePage(true);
    const apiRes = await activityAxios.post<IJSONResponse<ITourWithPrice>>(
      "/master/tour/getTourDetailsWithPrice",
      {
        tourSlug: searchParams.activitySlug,
        citySlug: searchParams.citySlug,
      }
    );

    if (apiRes.data.success && apiRes.data.result) {
      // reset(apiRes.data.result);
      setTourDetail(apiRes.data.result);
    }
    setLoadTourSinglePage(false);
  };

  useEffect(() => {
    getTourBySlug();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {!loadTourSinglePage && tourDetail ? (
        <div className={singleActivitiPageStyle.singleActivityPage}>
          <div className={singleActivitiPageStyle.activityImageHeaderContainer}>
            <div className="">
              <Carousel slide interval={2000}>
                {tourDetail.tourImages?.map((tourImage) => {
                  return (
                    <Carousel.Item>
                      <img
                        className={`img-fluid ${singleActivitiPageStyle.carouselImage}`}
                        src={tourImage.fileUrl}
                        alt=""
                      />
                    </Carousel.Item>
                  );
                })}
              </Carousel>
            </div>

            <div className={singleActivitiPageStyle.tourDetailsHeader}>
              <div className="container">
                <div className="row align-items-center">
                  <div className="col-lg-10">
                    <div className="d-flex justify-content-between align-items-center">
                      <h5 className="fw-bold text-secondary">
                        {tourDetail?.name}
                      </h5>
                      <Ratings
                        rating={tourDetail?.rating || 0}
                        reviewCount={tourDetail?.reviewCount || 0}
                        key={tourDetail?.id}
                      />
                    </div>
                    <div
                      className={singleActivitiPageStyle.shortDescription}
                      dangerouslySetInnerHTML={{
                        __html: tourDetail?.tourShortDescription || "",
                      }}
                    ></div>
                  </div>
                  <div className="col-lg-2">
                    <div className={singleActivitiPageStyle.TourPrice}>
                      Per Person <span className="small">from</span> <br />
                      <h5 className="text-success">
                        <Price amount={tourDetail?.amount} currency={"₹"} />
                      </h5>
                      <button className="btn btn-primary w-100">
                        Book Now
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="container">
            <div className="row">
              <div className="col-lg-7"></div>

              <div className="col-lg-5 ">
                <div className={singleActivitiPageStyle.tourDetailsHeader}>
                  <div className="d-flex flex-column align-content-between justify-content-between h-100">
                    <div>
                      <div className="d-flex justify-content-between align-items-center">
                        <h5>{tourDetail?.name}</h5>
                        <Ratings
                          rating={tourDetail?.rating || 0}
                          reviewCount={tourDetail?.reviewCount || 0}
                          key={tourDetail?.id}
                        />
                      </div>
                      <div
                        className={singleActivitiPageStyle.shortDescription}
                        dangerouslySetInnerHTML={{
                          __html: tourDetail?.tourShortDescription || "",
                        }}
                      ></div>
                    </div>

                    <div className={singleActivitiPageStyle.TourPrice}>
                      <div>
                        Per Person <span className="small">from</span> <br />
                        <h5 className="text-success">
                          <Price amount={tourDetail?.amount} currency={"₹"} />
                        </h5>
                      </div>
                      <div>
                        <button className="btn btn-primary px-4">
                          Select{" "}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          {/* {searchParams.citySlug}
        <br />
        {searchParams.activitySlug} */}

          {/* tour option box */}
          <div className="container">
            <div className={singleActivitiPageStyle.tourDetailsOption}>
              <h6 className="fw-bold pb-3 text-muted">Tour Option</h6>
              <div>
                {tourDetail?.tourOptions?.map((tourOption) => {
                  return (
                    <TourOption
                      isComboTour={tourDetail.isComboTour}
                      onTourOptionDateChange={() => {}}
                      onSelectedTranferChange={() => {}}
                      tourOptionDetails={tourOption}
                      key={tourOption.id}
                      paxInfo={{
                        adult: 1,
                        child: 0,
                        infant: 0,
                      }}
                      travelDate={moment().format("YYYY-MM-DD")}
                    />
                  );
                })}
              </div>
            </div>
          </div>

          {/* tour details points */}
          <div className="container">
            <div className={singleActivitiPageStyle.tourDetailsParagraph}>
              <div className={singleActivitiPageStyle.paragraphBox}>
                <h5>Whats In This Tour</h5>
                <div
                  dangerouslySetInnerHTML={{
                    __html: tourDetail?.whatsInThisTour || "",
                  }}
                ></div>
              </div>

              {/*  */}

              <div className={singleActivitiPageStyle.paragraphBox}>
                <h5>Tour Description</h5>
                <div
                  dangerouslySetInnerHTML={{
                    __html: tourDetail?.tourDescription || "",
                  }}
                ></div>
              </div>

              {/*  */}

              <div className={singleActivitiPageStyle.paragraphBox}>
                <h5>Inclusion</h5>
                <div
                  dangerouslySetInnerHTML={{
                    __html: tourDetail?.tourInclusion || "",
                  }}
                ></div>
              </div>
              {/*  */}

              <div className={singleActivitiPageStyle.paragraphBox}>
                <h5>Itinerary Description</h5>
                <div
                  dangerouslySetInnerHTML={{
                    __html: tourDetail?.itenararyDescription || "",
                  }}
                ></div>
              </div>

              {/*  */}

              <div className={singleActivitiPageStyle.paragraphBox}>
                <h5>Important Information</h5>
                <div
                  dangerouslySetInnerHTML={{
                    __html: tourDetail?.importantInformation || "",
                  }}
                ></div>
              </div>

              {/*  */}
            </div>
          </div>
        </div>
      ) : (
        <MainLoader loading={true} height="80vh" />
      )}
    </>
  );
};

export default ActivitySinglePage;
