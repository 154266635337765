import { Placeholder } from "react-bootstrap";

export const EnquiryListLoadingSkeleton = () => {
    return (
      <>
        <div className="border bg-white p-3 pb-2 mb-4">
          <div className="row mb-2">
            <div className="border-bottom pb-3 mb-3">
              <Placeholder animation="glow">
                <Placeholder xs={12} size="sm" />
              </Placeholder>
            </div>
            <div className="col-3">
              {" "}
              <Placeholder animation="glow">
                <Placeholder xs={12} size="sm" />
              </Placeholder>
            </div>
            <div className="col-3">
              {" "}
              <Placeholder animation="glow">
                <Placeholder xs={12} size="sm" />
              </Placeholder>
            </div>
            <div className="col-3">
              {" "}
              <Placeholder animation="glow">
                <Placeholder xs={12} size="sm" />
              </Placeholder>
            </div>
            <div className="col-3">
              {" "}
              <Placeholder animation="glow">
                <Placeholder xs={12} size="sm" />
              </Placeholder>
            </div>
          </div>
          <div className="row mb-2">
            <div className="col-3">
              {" "}
              <Placeholder animation="glow">
                <Placeholder xs={12} size="sm" />
              </Placeholder>
            </div>
            <div className="col-3">
              {" "}
              <Placeholder animation="glow">
                <Placeholder xs={12} size="sm" />
              </Placeholder>
            </div>
            <div className="col-3">
              {" "}
              <Placeholder animation="glow">
                <Placeholder xs={12} size="sm" />
              </Placeholder>
            </div>
            <div className="col-3">
              {" "}
              <Placeholder animation="glow">
                <Placeholder xs={12} size="sm" />
              </Placeholder>
            </div>
          </div>
        </div>
      </>
    );
  };