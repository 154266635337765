import { AxiosResponse } from "axios";
import { activityAxios } from "../axios/activityAxios";
import { IJSONResponse } from "../activitiescommonfnb/types/common.interface";
import { TCountry } from "../activitiescommonfnb/schemas/master.schema";

export class MasterService {
  static getCountries = async () => {
    const apiResponse: AxiosResponse<IJSONResponse<TCountry[]>> =
      await activityAxios.get("/master/getCountries");
    return apiResponse;
  };
}
