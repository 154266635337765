import React from "react";
import QuotationFooter from "./QuotationFooter";
import QuotationHeader from "./QuotationHeader";
import { IQuotationPreview } from "../../activitiescommonfnb/types/quotation.interface";
import Price from "../../commonUi/Price";
import QuotationTandC from "./QuotationTandC";
import _ from "lodash";

const QuotationPreview = React.forwardRef<
  HTMLDivElement,
  {
    quotationPreview: IQuotationPreview | null;
    enquiryId: number | undefined;
  }
>(({ quotationPreview, enquiryId }, ref) => {
  const markUp: number = quotationPreview?.quotationData?.markupPercentage
    ? quotationPreview.quotationData.markupPercentage / 100
    : 0.05;

  const price = {
    flight: quotationPreview?.quotationData?.flightCost
      ? quotationPreview.quotationData.flightCost * (1 + markUp)
      : 0,
    visa: quotationPreview?.quotationData?.visaCost
      ? quotationPreview.quotationData.visaCost
      : 0,
    landPart: quotationPreview?.quotationData?.landPartCost
      ? quotationPreview.quotationData.landPartCost * (1 + markUp)
      : 0,
    hotels:
      quotationPreview?.quotationData?.hotels.map((hotel) => ({
        hotelName: hotel.hotelName,
        hotelCost: hotel.hotelCost * (1 + markUp),
        sharingType: hotel.sharingType,
      })) || [],
  };

  const gtotal =
    (quotationPreview?.quotationData?.showBreakup
      ? price.flight + price.visa
      : 0) +
    price.landPart +
    price.hotels.reduce((total, hotel) => total + hotel.hotelCost, 0);

  return (
    <div
      id="pk-activity-quotation-preview"
      ref={ref}
      style={{ overscrollBehavior: "contain" }}
    >
      <QuotationHeader
        quotationPreview={quotationPreview}
        enquiryId={enquiryId}
      />
      <div className="p-4">
        {quotationPreview?.quotationData?.showBreakup ? (
          <table className="table table-bordered my-4">
            <thead>
              <tr>
                <th colSpan={3} className="bg-light">
                  Price :{" "}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Flight Cost : </td>
                <td colSpan={2}>
                  <Price currency="₹" amount={price.flight} />
                </td>
              </tr>
              <tr>
                <td>Visa Cost : </td>
                <td colSpan={2}>
                  {" "}
                  <Price currency="₹" amount={price.visa} />
                </td>
              </tr>
              <tr>
                <td>LandPart Cost :</td>
                <td colSpan={2}>
                  {" "}
                  <Price currency="₹" amount={price.landPart} />
                </td>
              </tr>
              <tr>
                <td>Hotel : </td>
                <td colSpan={2} className="p-0">
                  <table className="table m-0">
                    {price.hotels.map((hotel, index) => (
                      <tr className="border-bottom" key={index}>
                        <td width={"40%"}>
                          <span className="small">Hotel Name : </span>
                          {hotel.hotelName}
                        </td>
                        <td
                          width={"30%"}
                          className="d-flex w-100 align-items-center"
                        >
                          {" "}
                          <span className="small">Hotel Cost : </span>
                          <Price currency="₹" amount={hotel.hotelCost} />
                        </td>
                        <td width={"30%"}>
                          <span className="small">Sharing Type : </span>
                          {_.upperFirst(hotel.sharingType)}
                        </td>
                      </tr>
                    ))}
                  </table>
                </td>
              </tr>
              <tr>
                <td colSpan={2} className="bg-light">
                  Grand Total:
                </td>
                <td>
                  <Price currency="₹" amount={gtotal} />
                </td>
              </tr>
            </tbody>
          </table>
        ) : (
          <table className="table table-bordered my-4">
            <thead>
              <tr>
                <th colSpan={3} className="bg-light">
                  Price :{" "}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Including Hotels, tours and transfers :</td>
                <td colSpan={2}>
                  {" "}
                  <Price currency="₹" amount={gtotal} />
                </td>
              </tr>
              <tr>
                <td>Hotel : </td>
                <td colSpan={2} className="p-0">
                  <table className="table m-0">
                    {price.hotels.map((hotel, index) => (
                      <tr className="border-bottom" key={index}>
                        <td width={"40%"}>
                          <span className="small">Hotel Name : </span>
                          {hotel.hotelName}
                        </td>

                        <td width={"30%"}>
                          <span className="small">Sharing Type : </span>
                          {_.upperFirst(hotel.sharingType)}
                        </td>
                      </tr>
                    ))}
                  </table>
                </td>
              </tr>
              <tr>
                <td colSpan={2} className="bg-light">
                  Grand Total:
                </td>
                <td>
                  <Price currency="₹" amount={gtotal} />
                </td>
              </tr>
            </tbody>
          </table>
        )}

        <span className="fw-bold ">Tour Inclusions : </span>
        <div className="mb-4 mt-2">
          {quotationPreview?.previewBody ? (
            <div
              dangerouslySetInnerHTML={{ __html: quotationPreview.previewBody }}
            ></div>
          ) : null}
        </div>

        <QuotationTandC
          termsAndConditions={quotationPreview?.termsAndConditions}
        />
      </div>
      <div className="break-inside">
        <QuotationFooter />
      </div>
    </div>
  );
});

export default QuotationPreview;
