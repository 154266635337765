import moment from "moment";

export class DateService {
  static convertUtcTimeToLocalDateTime = (time?: string | null) => {
    if (!time) {
      return null;
    }

    return moment.utc(time, "HH:mm:ss").local().toDate();
  };

  static convertUtcTimeToLocalTime = (time?: string | null) => {
    if (!time) {
      return null;
    }

    return moment.utc(time, "HH:mm:ss").local().format("hh:mm a");
  };
}
