import { useDispatch } from "react-redux";
import { IBookingTourOption } from "../../activitiescommonfnb/types/booking.interface";
import cartItemStyle from "./CartItemStyle.module.scss";
import { ActivitySessionActions } from "../../slice/session.slice";
import { formatDate } from "../../functions/commonFx";

export const CartItem: React.FC<{
  showMoreDetails: boolean;
  tourOptionDetail: IBookingTourOption;
}> = (props) => {
  const dispatch = useDispatch();

  const getTourOptionPrice = () => {
    return (
      parseFloat(props.tourOptionDetail.adultPrice.toString()) *
        parseFloat(props.tourOptionDetail.adultCount.toString()) +
      parseFloat(props.tourOptionDetail.childPrice.toString()) *
        parseFloat(props.tourOptionDetail.childCount.toString()) +
      parseFloat(props.tourOptionDetail.infantCount.toString()) *
        parseFloat(props.tourOptionDetail.infantPrice.toString())
    ).toFixed(2);
  };

  return (
    <div className={`${cartItemStyle.cartItem} my-3`}>
      <div className="">
        <div className="row">
          <div className="col-lg-4">
            <img
              src="https://plus.unsplash.com/premium_photo-1668116307088-583ee0d4aaf7?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OXx8dGh1bWJuYWlsfGVufDB8fDB8fHww"
              className={`img-fluid ${cartItemStyle.image} w-100`}
              alt=""
            />
          </div>
          <div className="col-lg-8 ">
            <div className={`${cartItemStyle.tourName}`}>
              <div>{props?.tourOptionDetail?.tourOption?.tourOptionName}</div>
              <div className={cartItemStyle.transferType}>
                <div className="mb-1">
                  {props.tourOptionDetail.transferType} <br />
                  <span className={cartItemStyle.travelDate}>
                    {formatDate(props.tourOptionDetail.travelDate)}
                  </span>
                </div>
              </div>
              <p className="fw-bold text-muted">₹ {getTourOptionPrice()}</p>
            </div>
          </div>
        </div>
      </div>

      <div
        className={`z-0 ${cartItemStyle.deleteItemBtn}`}
        onClick={() => {
          dispatch(
            ActivitySessionActions.deleteTourOption({
              tourId: props.tourOptionDetail.tourId,
              tourOptionId: props.tourOptionDetail.tourOptionId,
            })
          );
        }}
      >
        <i className="fa-solid fa-trash"></i>
      </div>
    </div>
  );
};
