import { useNavigate, useSearchParams } from "react-router-dom";
import { activitySupplierAxios } from "../axios/activitySupplierAxios";
import { AppInput } from "../commonUi/AppInput";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";

export const GeneratePassword: React.FC<{}> = () => {
  const [params, setParams] = useSearchParams();
  const token = params.get("token");
  const navigate = useNavigate();
  useEffect(() => {
    if (!token) {
      navigate("/supplier/login");
    }
  }, []);
  const [password, setPassword] = useState("");
  const generatePassword = async () => {
    try {
      const generatePasswordRes = await activitySupplierAxios.post(
        "/generatePassword",
        {
          token,
          password,
        }
      );
      if (generatePasswordRes.data.success) {
        toast.success("Password saved");
        navigate("/supplier/dashboard");
      }
    } catch (err) {
      toast.error("something went wrong");
    }
  };

  return (
    <div className="container">
      <h4>generate password</h4>
      <div className="row">
        <div className="offset-4 col-4 ">
          <div className="border p-3 shadow rounded">
            <AppInput
              fieldName="password"
              onChange={(name, value) => setPassword(value)}
              label="Password"
              value={password}
            />
            <button
              className="btn btn-success w-100 mt-2"
              onClick={generatePassword}
            >
              generate
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
