import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import Price from "../../commonUi/Price";
import Slider from "rc-slider";
import { useAppSelector } from "../../store";
import { ITourWithPrice } from "../../activitiescommonfnb/types/activitySearch.interface";
import _ from "lodash";

const FilterResultPage: React.FC<{
  setTourResultsLocalState: Dispatch<SetStateAction<ITourWithPrice[]>>;
}> = (props) => {
  const [priceFilterRange, setPriceFilterRange] = useState<{
    min: number;
    max: number;
    currentMin: number;
    currentMax: number;
  }>({
    min: 0,
    max: 0,
    currentMin: 0,
    currentMax: 0,
  });

  const { data: tourSearchResults, isSearching } = useAppSelector(
    (state) => state.tourResult
  );

  const filterTourResultsByPrice = () => {
    let tourResultsArr = _.cloneDeep(tourSearchResults);
    if (
      typeof priceFilterRange.currentMin === "number" &&
      typeof priceFilterRange.currentMax === "number" &&
      !isSearching
    ) {
      tourResultsArr = tourResultsArr.filter((tourResult) => {
        if (
          parseFloat(tourResult.amount.toFixed(2)) >=
            priceFilterRange.currentMin &&
          parseFloat(tourResult.amount.toFixed(2)) <=
            priceFilterRange.currentMax
        )
          return true;
        else return false;
      });
      props.setTourResultsLocalState(tourResultsArr);
    }
  };

  useEffect(() => {
    if (!isSearching && tourSearchResults.length > 0) {
      props.setTourResultsLocalState(tourSearchResults);
      let amountArr = tourSearchResults.map((tourresult) => tourresult.amount);
      setPriceFilterRange({
        min: parseFloat(Math.min(...amountArr).toFixed(2)),
        max: parseFloat(Math.max(...amountArr).toFixed(2)),
        currentMin: parseFloat(Math.min(...amountArr).toFixed(2)),
        currentMax: parseFloat(Math.max(...amountArr).toFixed(2)),
      });
      filterTourResultsByPrice();
    }
  }, [tourSearchResults]);

  useEffect(() => {
    const id = setTimeout(() => {
      filterTourResultsByPrice();
    }, 300);
    return () => clearTimeout(id);
  }, [priceFilterRange]);

  return (
    <div>
      <div className={` rounded`}>
        {priceFilterRange.min && priceFilterRange.max && (
          <div className="w-100 border bg-light rounded py-4 px-4">
            <div className="d-flex align-items-center justify-content-between mx-0 mb-2">
              <div className=" small text-primary fw-bold">Price</div>
              <div className="d-flex align-items-center text-muted">
                <span className="small fw-bold">
                  {!isSearching && (
                    <Price amount={priceFilterRange.currentMin} currency="₹" />
                  )}
                </span>
                <span className="mx-1 ms-2">-</span>
                <span className="small fw-bold">
                  {!isSearching && (
                    <Price amount={priceFilterRange.currentMax} currency="₹" />
                  )}
                </span>
              </div>
            </div>

            <div
              style={{
                position: "relative",
                zIndex: 0,
              }}
            >
              <Slider
                range
                count={1}
                value={[
                  priceFilterRange.currentMin,
                  priceFilterRange.currentMax,
                ]}
                onChange={(values: any) => {
                  setPriceFilterRange((old) => {
                    return {
                      ...old,
                      currentMin: values[0],
                      currentMax: values[1],
                    };
                  });
                }}
                min={priceFilterRange.min}
                max={priceFilterRange.max}
                allowCross={false}
              />
            </div>

            {/* <div className="d-flex justify-content-between small mt-2 mx-0">
                        <span className="bg-white py-1 px-2">
                          {!isSearching && (
                            <Price
                              amount={priceFilterRange.currentMin}
                              currency="₹"
                            />
                          )}
                        </span>
                        <span className="bg-white py-1 px-2">
                          {!isSearching && (
                            <Price
                              amount={priceFilterRange.currentMax}
                              currency="₹"
                            />
                          )}
                        </span>
                      </div> */}
          </div>
        )}
      </div>
    </div>
  );
};

export default FilterResultPage;
