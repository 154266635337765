import React, { ChangeEvent, useEffect, useState } from "react";
import SeasonListItemStyle from "./SeasonListItem.module.scss";
import { activityAxios } from "../../../axios/activityAxios";
import { AddSeasonModal } from "./AddSeasonModal";
import {
  TCountry,
  TSeason,
} from "../../../activitiescommonfnb/schemas/master.schema";
import SeasonalTimeline from "./SeasonalTimeline";

const SeasonsListItem: React.FC = () => {
  const [allSeasonsWithCountries, setallSeasonsWithCountries] = useState<
    TCountry[]
  >([]);
  const [showAddSeasonModal, setShowAddSeasonModal] = useState(false);
  const [selectedSeasonWithCountry, setSelectedSeasonWithCountry] = useState<{
    countryId: number;
    priority: number;
    fromDate: Date;
    toDate: Date;
    name: string;
    id?: number;
  }>();
  const [country, setCountry] = useState("");
  useEffect(() => {
    getAllCountryWithSeason();
  }, []);

  const getAllCountryWithSeason = async () => {
    const allSeasonsRes = await activityAxios.get(
      "/master/getAllCountryWithSeason",
      {}
    );
    if (allSeasonsRes.data.success) {
      setallSeasonsWithCountries(allSeasonsRes.data.result);
    }
  };

  return (
    <div className="container-fluid">
      <div className={SeasonListItemStyle.SeasonList}>
        <div className={`${SeasonListItemStyle.seasonHeading} `}>
          <h5>Season </h5>
        </div>
        {showAddSeasonModal && selectedSeasonWithCountry?.countryId && (
          <AddSeasonModal
            refresh={() => {
              getAllCountryWithSeason();
              setShowAddSeasonModal(false);
            }}
            countryName={country}
            seasonWithCountry={selectedSeasonWithCountry}
            show={showAddSeasonModal}
            onHide={() => setShowAddSeasonModal(false)}
          />
        )}
        {allSeasonsWithCountries.length > 0 &&
          allSeasonsWithCountries.map((seasonsWithCountries) => {
            return (
              <div
                className={`${SeasonListItemStyle.SeasonListItem}`}
                key={seasonsWithCountries.id}
              >
                <div className={SeasonListItemStyle.countryBox}>
                  <div>
                    <span className={SeasonListItemStyle.countryName}>
                      {seasonsWithCountries.countryName}{" "}
                    </span>
                    <span className="small text-muted">
                      ({seasonsWithCountries.countryCode})
                    </span>
                  </div>
                  <button
                    className="btn btn-outline-secondary btn-sm text-center"
                    onClick={() => {
                      setShowAddSeasonModal(true);
                      setCountry(seasonsWithCountries.countryName);
                      if (seasonsWithCountries.id)
                        setSelectedSeasonWithCountry({
                          countryId: seasonsWithCountries.id,
                          fromDate: new Date(),
                          toDate: new Date(),
                          name: "",
                          priority: 0,
                        });
                    }}
                  >
                    + Add Season
                  </button>
                </div>

                <SeasonalTimeline seasons={seasonsWithCountries.seasons} />

                <div className={`${SeasonListItemStyle.countrySeasons}`}>
                  {seasonsWithCountries.seasons ? (
                    seasonsWithCountries?.seasons.map((season: TSeason) => {
                      return (
                        <div
                          className={`${SeasonListItemStyle.seasonsItem} `}
                          key={season.id}
                        >
                          <div className="d-flex justify-content-between align-items-center">
                            <span>
                              {season.name}
                              <span className="small ms-3 text-muted">
                                ( From:{" "}
                                {new Date(season.fromDate).toDateString()} To:{" "}
                                {new Date(season.toDate).toDateString()} ){" "}
                              </span>
                            </span>
                            <button
                              className="btn btn-outline-primary btn-sm"
                              onClick={() => {
                                if (season.countryId) {
                                  setShowAddSeasonModal(true);
                                  setCountry(seasonsWithCountries.countryName);
                                  setSelectedSeasonWithCountry({
                                    countryId: season.countryId,
                                    fromDate: season.fromDate,
                                    name: season.name,
                                    toDate: season.toDate,
                                    id: season.id,
                                    priority: season.priority,
                                  });
                                }
                              }}
                            >
                              <i className="fa-solid fa-pen"></i>
                            </button>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <p className="text-danger text-center">No seasons added!</p>
                  )}
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default SeasonsListItem;
