import React, { useEffect, useState } from "react";
import { Offcanvas } from "react-bootstrap";
import {
  ITourOptionTimeslot,
  ITourOptionTransferWithPrice,
} from "../../activitiescommonfnb/types/activitySearch.interface";
import { activityAxios } from "../../axios/activityAxios";
import { toast } from "react-toastify";
import Price from "../../commonUi/Price";
import { IPaxInfo } from "../common/Search/PaxInfoPopOver";

const TimeSlotOptionSkeleton = () => {
  return (
    <div className="text-center my-5 py-5">
      <div
        className="spinner-grow spinner-grow-sm mx-1 bg-primary"
        role="status"
      ></div>
      <div
        className="spinner-grow spinner-grow-sm mx-1 bg-secondary"
        role="status"
      ></div>
      <div
        className="spinner-grow spinner-grow-sm mx-1 bg-primary"
        role="status"
      ></div>
    </div>
  );
};

const TourOptionTimeSlot: React.FC<{
  show: boolean;
  onClose: () => void;
  addToCart: (timeSlotId: string | null) => void;
  paxInfo: IPaxInfo;
  travelDate: string;
  tourId: number;
  selectedTransferDetail: ITourOptionTransferWithPrice;
}> = (props) => {
  const [selectTimeSlot, setSelectedTimeSlot] = useState<ITourOptionTimeslot>();
  const [tourOptionTimeSlots, setTourOptionTimeSlots] = useState<
    ITourOptionTimeslot[]
  >([]);
  const [loadTimeSlotOptions, setLoadTimeSlotOptions] = useState(false);

  const getTourOptionTimeSlots = async () => {
    setLoadTimeSlotOptions(true);
    const tourOptionsTimeSlotsResponse = await activityAxios.post(
      "/master/tour/getTourTimeslots",
      {
        uniqueResultId: props.selectedTransferDetail.uniqueResultId,
        tourId: props.tourId,
        tourOptionId: props.selectedTransferDetail.tourOptionId,
        transferId: props.selectedTransferDetail.id,
        noOfAdult: props.paxInfo.adult,
        noOfChild: props.paxInfo.child,
        travelDate: props.travelDate,
      }
    );

    if (tourOptionsTimeSlotsResponse?.data?.success) {
      if (tourOptionsTimeSlotsResponse?.data?.result.length > 0)
        setTourOptionTimeSlots(tourOptionsTimeSlotsResponse?.data?.result);
      else {
        toast.warning("No time slots found for this tour option.");
        props.addToCart(null);
      } //set timeSlotId = null of no time slots exists
    } else {
      return toast.error(
        tourOptionsTimeSlotsResponse?.data.errorMessage ||
          "Something went wrong"
      );
    }
    setLoadTimeSlotOptions(false);
  };

  useEffect(() => {
    getTourOptionTimeSlots();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Offcanvas
      show={props.show}
      onHide={props.onClose}
      id="time-slot-page"
      placement="bottom"
    >
      <Offcanvas.Header closeButton className="border-bottom my-2">
        <Offcanvas.Title className="pb-2">
          <h6 className="m-0">
            {"Tour Option Name"}
            <span className="text-secondary ms-3">{props.travelDate}</span>
          </h6>
        </Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        {/* <!-- tour date --> */}

        {!loadTimeSlotOptions && (
          <>
            {/* Pax  Count*/}

            <div className="mb-3">
              <div className="heading-time-option d-flex align-items-center p-3">
                <h6 className="m-0">Passenger : </h6>
                <span className="bg-light small border rounded px-3 py-2 mx-2">
                  Adult :{" "}
                  <span className="fw-bold"> {props.paxInfo.adult}</span>
                </span>
                <span className="bg-light small border rounded px-3 py-2 mx-2">
                  Child :{" "}
                  <span className="fw-bold"> {props.paxInfo.child}</span>
                </span>
                <span className="bg-light small border rounded px-3 py-2 mx-2">
                  Infant :{" "}
                  <span className="fw-bold"> {props.paxInfo.infant}</span>
                </span>
              </div>
            </div>

            {/* <!-- time slot select --> */}
            <div className="time-slot-option ">
              <div className="heading-time-option bg-light p-3">
                <h6 className="m-0">Select Time Option </h6>
              </div>

              <div className="option-items mt-3">
                <div className="row">
                  {!loadTimeSlotOptions &&
                    tourOptionTimeSlots.map((timeSlot) => {
                      return (
                        <div className="col-lg-2 col-md-3 col-sm-4 col-6 ">
                          <div
                            className={`border rounded py-3 ${
                              selectTimeSlot?.timeSlotId === timeSlot.timeSlotId
                                ? "border-primary"
                                : ""
                            }`}
                          >
                            <div className="form-check ps-5">
                              <input
                                className="form-check-input rounded-circle border border-secondary"
                                type="radio"
                                name="flexRadioDefault"
                                id={timeSlot.timeSlotId}
                                checked={
                                  selectTimeSlot?.timeSlotId ===
                                  timeSlot.timeSlotId
                                }
                                onChange={() => {
                                  setSelectedTimeSlot(timeSlot);
                                }}
                              />
                              <label
                                className="form-check-label"
                                htmlFor={timeSlot.timeSlotId}
                                role="button"
                              >
                                <span
                                  className={`fw-bold ${
                                    selectTimeSlot?.timeSlotId ===
                                    timeSlot.timeSlotId
                                      ? "text-primary"
                                      : "text-muted"
                                  }`}
                                >
                                  {timeSlot.timeSlot}
                                </span>
                                <div className="time-with-price d-flex mt-2 small">
                                  <div
                                    className={`d-flex border bg-light px-3 py-2 me-2 rounded`}
                                  >
                                    Adult:
                                    <span className="me-2 fw-bold ">
                                      <Price
                                        amount={timeSlot.adultPrice}
                                        currency={"₹"}
                                      />
                                    </span>
                                  </div>
                                  <div
                                    className={`d-flex border bg-light px-3 py-2  rounded`}
                                  >
                                    Child:{" "}
                                    <span className="me-2 fw-bold ">
                                      <Price
                                        amount={timeSlot.childPrice}
                                        currency={"₹"}
                                      />
                                    </span>
                                  </div>
                                </div>
                              </label>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>

            {/* <!-- adult child infant --> */}
          </>
        )}

        {loadTimeSlotOptions && (
          <>
            <TimeSlotOptionSkeleton />
          </>
        )}

        <div className="passenger-price mt-4"></div>
        <div className="offcanvas-footer d-flex justify-content-between align-items-center bg-light p-3 rounded">
          <h5 className="m-0 d-flex">
            Total :{" "}
            {selectTimeSlot && (
              <Price
                amount={
                  props.paxInfo.adult * selectTimeSlot?.adultPrice +
                  props.paxInfo.child * selectTimeSlot.childPrice +
                  props.paxInfo.infant * 0
                }
                currency={"₹"}
              />
            )}
          </h5>
          <button
            type="button"
            className="btn btn-success"
            onClick={() => {
              if (selectTimeSlot?.timeSlotId) {
                props.addToCart(selectTimeSlot?.timeSlotId);
              }
            }}
          >
            Add to Cart
          </button>
        </div>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default TourOptionTimeSlot;
