import React, { useEffect, useState } from "react";
import { FormCheck, FormSelect, Modal, Spinner } from "react-bootstrap";
import {
  TCreateOrEditTourOptionTransfer,
  createOrEditTourOptionTransfer,
} from "../../../../activitiescommonfnb/schemas/tour.schema";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  EnumTourOptionTransferType,
  ITourOptionTransfer,
} from "../../../../activitiescommonfnb/types/tour.interface";
import { capitalize } from "lodash";
import { AppControlInput } from "../../../../commonUi/AppInput";
import { activityAxios } from "../../../../axios/activityAxios";
import { IJSONResponse } from "../../../../activitiescommonfnb/types/common.interface";
import { toast } from "react-toastify";

const SingleTransferForm: React.FC<{
  tourOptionId: number;
  transferType: EnumTourOptionTransferType;
  onSaveTransfer: (data: Partial<TCreateOrEditTourOptionTransfer>) => void;
  transferDetails?: TCreateOrEditTourOptionTransfer;
}> = (props) => {
  const tranferDefaultValues: Partial<TCreateOrEditTourOptionTransfer> = {
    tourOptionId: props.tourOptionId,
    transferType: props.transferType,
    disableChild: false,
    disableInfant: false,
    twoWayTransfer: false,
  };
  const { control, watch, setValue, handleSubmit, reset } = useForm<
    Partial<TCreateOrEditTourOptionTransfer>
  >({
    resolver: zodResolver(createOrEditTourOptionTransfer),
    defaultValues: tranferDefaultValues,
  });

  const tranferDetailState = watch();

  useEffect(() => {
    if (props.transferDetails) {
      reset(props.transferDetails);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.transferDetails]);

  return (
    <form
      className="p-3 border bg-light mb-3 "
      onSubmit={handleSubmit(props.onSaveTransfer)}
    >
      <div className="row">
        <div className="col-6">
          <label htmlFor={props.transferType} className="small required">
            Transfer Type
          </label>
          <FormSelect id={props.transferType} disabled>
            <option value={props.transferType}>
              {capitalize(props.transferType)}
            </option>
          </FormSelect>
        </div>
        <div className="col-6">
          <AppControlInput
            name="transferDurationInMinutes"
            label="Transfer Duration (In Minutes)"
            isRequired
            control={control}
          />
        </div>
        <div className="col-12 mb-2">
          <AppControlInput
            name="transferTime"
            label="Transfer Time"
            isRequired
            control={control}
          />
        </div>

        <div className="col-4">
          <FormCheck
            type="checkbox"
            id="disableChild"
            checked={tranferDetailState.disableChild}
            onChange={(e) => {
              setValue("disableChild", e.target.checked);
            }}
            label="Disable Child?"
          />
        </div>
        <div className="col-4">
          <FormCheck
            type="checkbox"
            id="isHourly"
            label="Disable Infant?"
            checked={tranferDetailState.disableInfant}
            onChange={(e) => {
              setValue("disableInfant", e.target.checked);
            }}
          />
        </div>
        <div className="col-4">
          <FormCheck
            type="checkbox"
            id="hasSlot"
            label="Is Two Way Transfer?"
            checked={tranferDetailState.twoWayTransfer}
            onChange={(e) => {
              setValue("twoWayTransfer", e.target.checked);
            }}
          />
        </div>

        <div className="col-12 text-center mt-2 border-top">
          <button
            type="submit"
            className="btn btn-outline-primary btn-sm mt-3 px-3"
          >
            Save
          </button>
        </div>
      </div>
    </form>
  );
};

export const ManageTransferModal: React.FC<{
  tourOptionId: number;
  onClose: () => void;
}> = (props) => {
  const [isFetchingTranferDetails, setIsFetchingTranferDetails] =
    useState(false);

  const [withoutTransferDetails, setWithoutTransferDetails] =
    useState<ITourOptionTransfer>();
  const [privateTransferDetails, setPrivateTransferDetails] =
    useState<ITourOptionTransfer>();
  const [sharingTransferDetails, setSharingTransferDetails] =
    useState<ITourOptionTransfer>();

  const getTourOptionTransfers = async () => {
    setIsFetchingTranferDetails(true);

    const apiRes = await activityAxios.post<
      IJSONResponse<ITourOptionTransfer[]>
    >("/master/tour/getTourOptionTransfers", {
      tourOptionId: props.tourOptionId,
    });

    if (apiRes.data.success && apiRes.data.result) {
      setWithoutTransferDetails(
        apiRes.data.result.find(
          (singleTransfer) =>
            singleTransfer.transferType ===
            EnumTourOptionTransferType.WITHOUT_TRANSFER
        )
      );
      setPrivateTransferDetails(
        apiRes.data.result.find(
          (singleTransfer) =>
            singleTransfer.transferType === EnumTourOptionTransferType.PRIVATE
        )
      );
      setSharingTransferDetails(
        apiRes.data.result.find(
          (singleTransfer) =>
            singleTransfer.transferType === EnumTourOptionTransferType.SHARING
        )
      );
    } else {
      toast.error(apiRes.data.errorMessage);
    }

    setIsFetchingTranferDetails(false);
  };

  const onSaveTransfer = async (
    transferDetails: Partial<TCreateOrEditTourOptionTransfer>
  ) => {
    const apiRes = await activityAxios.post<IJSONResponse<ITourOptionTransfer>>(
      "/master/tour/saveTourOptionTransfer",
      {
        transferDetails: transferDetails,
      }
    );
    const savedTransferDetails = apiRes.data.result;
    if (apiRes.data.success && savedTransferDetails) {
      toast.success("Transfer saved successfully");

      switch (savedTransferDetails.transferType) {
        case EnumTourOptionTransferType.WITHOUT_TRANSFER:
          setWithoutTransferDetails(savedTransferDetails);
          break;

        case EnumTourOptionTransferType.PRIVATE:
          setPrivateTransferDetails(savedTransferDetails);
          break;
        case EnumTourOptionTransferType.SHARING:
          setSharingTransferDetails(savedTransferDetails);
          break;
        default:
          break;
      }
    } else {
      toast.error(apiRes.data.errorMessage);
    }
  };

  useEffect(() => {
    getTourOptionTransfers();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Modal show onHide={props.onClose} centered size="lg">
      <Modal.Header closeButton>
        <h6 className="mb-0">Manage Transfer</h6>
      </Modal.Header>

      <Modal.Body>
        {/* Manage Transfer form */}
        <div className="position-relative">
          {isFetchingTranferDetails && (
            <div className="w-100 h-100 position-absolute bg-light opacity-75 d-flex justify-content-center align-items-center">
              <Spinner />
            </div>
          )}
          <SingleTransferForm
            tourOptionId={props.tourOptionId}
            transferType={EnumTourOptionTransferType.WITHOUT_TRANSFER}
            transferDetails={withoutTransferDetails}
            onSaveTransfer={onSaveTransfer}
          />
          <SingleTransferForm
            tourOptionId={props.tourOptionId}
            transferType={EnumTourOptionTransferType.SHARING}
            transferDetails={sharingTransferDetails}
            onSaveTransfer={onSaveTransfer}
          />
          <SingleTransferForm
            tourOptionId={props.tourOptionId}
            transferType={EnumTourOptionTransferType.PRIVATE}
            transferDetails={privateTransferDetails}
            onSaveTransfer={onSaveTransfer}
          />
        </div>
      </Modal.Body>

      <Modal.Footer>
        <button className="btn btn-secondary btn-sm">Close</button>
      </Modal.Footer>
    </Modal>
  );
};
