import React, { useEffect, useState } from "react";
import {
  enquiryStatuses,
  EnumEnquiryStatus,
} from "../../activitiescommonfnb/types/enquiry.interface";
import { Button, Form } from "react-bootstrap";
import { capitalize } from "lodash";
import { UserSelect } from "../../components/common/Selects/UserSelect";
import { useAppSelector } from "../../store";
import { IUserData } from "../../activitiescommonfnb/types/User.interface";
import { TFilterState } from "../../activitiescommonfnb/schemas/enquiry.schema";
import { handleWheel } from "../../helper/globalHelper";
import { days, staledDays, useDebounce } from "../../helper/enquiryHelper";

export const EnquiryListFilter: React.FC<{
  onFilterChange: (filters: TFilterState) => void;
  staledEnquiriesDays: number;
  filters: TFilterState;
  isCustomerView?: boolean;
}> = (props) => {
  const userData = useAppSelector((state) => state.authData.data);

  const [selectedAssignedStaff, setSelectedAssignedStaff] =
    useState<IUserData | null>(userData);
  const [selectedUser, setSelectedUser] = useState<IUserData | null>(null);

  const filterStateTemplate: TFilterState = {
    assignedToStaffId: userData?.id,
    staledEnquiriesDays: 0,
    searchTitle: undefined,
    leadPassenger: undefined,
    status: EnumEnquiryStatus.All,
    unassigned: undefined,
    searchByUserId: undefined,
    searchId: undefined,
    datefilter: undefined,
  };
  const [filterState, setFilterState] =
    useState<TFilterState>(filterStateTemplate);

  const debouncedStatus = useDebounce(filterState.status, 500);
  const debouncedSearchId = useDebounce(filterState.searchId, 500);
  const debouncedSearchTitle = useDebounce(filterState.searchTitle, 500);
  const debouncedLeadPassenger = useDebounce(filterState.leadPassenger, 500);
  const debouncedAssignedToStaffId = useDebounce(
    filterState.assignedToStaffId,
    500
  );
  const debouncedSearchByUserId = useDebounce(filterState.searchByUserId, 500);
  const debouncedDateFilter = useDebounce(filterState.datefilter, 500);
  const debouncedStaledEnquiriesDays = useDebounce(
    filterState.staledEnquiriesDays,
    500
  );
  const debouncedUnassigned = useDebounce(filterState.unassigned, 500);

  const updateFilter = (updatedFilter: TFilterState) => {
    const appliedFilter = {
      ...filterState,
      ...updatedFilter,
    };
    setFilterState(appliedFilter);
  };

  const resetfilters = () => {
    props.onFilterChange({
      staledEnquiriesDays: filterStateTemplate.staledEnquiriesDays,
      assignedToStaffId: filterStateTemplate.assignedToStaffId,
      status: EnumEnquiryStatus.All,
    });
    setSelectedAssignedStaff(null);
    setFilterState(filterStateTemplate);
    setSelectedUser(null);
  };

  useEffect(() => {
    if (props.staledEnquiriesDays) {
      updateFilter({
        staledEnquiriesDays: props?.staledEnquiriesDays,
      });
    }
  }, [props.staledEnquiriesDays]);

  useEffect(() => {
    props.onFilterChange({
      status: debouncedStatus,
      searchId: debouncedSearchId,
      searchTitle: debouncedSearchTitle,
      leadPassenger: debouncedLeadPassenger,
      assignedToStaffId: debouncedAssignedToStaffId,
      searchByUserId: debouncedSearchByUserId,
      datefilter: debouncedDateFilter,
      staledEnquiriesDays: debouncedStaledEnquiriesDays,
      unassigned: debouncedUnassigned,
    });
  }, [
    debouncedStatus,
    debouncedSearchId,
    debouncedSearchTitle,
    debouncedLeadPassenger,
    debouncedAssignedToStaffId,
    debouncedSearchByUserId,
    debouncedDateFilter,
    debouncedStaledEnquiriesDays,
    debouncedUnassigned,
  ]);

  return (
    <>
      <div className="mt-0 mt-md-5 bg-white border">
        <div className="filterHeader bg-light rounded-top p-3">Filter</div>
        <div className="d-flex justify-content-end font-weight-bold">
          <Button variant="link" onClick={resetfilters} className="small">
            Reset Filters
          </Button>
        </div>{" "}
        <div className="p-3 pt-1">
          {!props.isCustomerView && (
            <>
              <div className="filter-item mb-2">
                <span className="small">Status</span>
                <select
                  className="form-control small"
                  onChange={(e) => {
                    updateFilter({
                      status: e.target.value as EnumEnquiryStatus,
                    });
                  }}
                  value={filterState.status}
                >
                  {enquiryStatuses.map((item, index) => {
                    const key = Object.keys(item)[0];
                    const subOptions = item[key];

                    return (
                      <>
                        <option value={key} className="font-weight-bold">
                          {capitalize(key.replace(/_/g, " "))}
                        </option>
                        {subOptions.length > 0 &&
                          subOptions.map((subOption, subIndex) => (
                            <option
                              key={subIndex}
                              value={subOption}
                              className=""
                            >
                              &nbsp; -{" "}
                              {capitalize(subOption.replace(/_/g, " "))}
                            </option>
                          ))}
                      </>
                    );
                  })}
                </select>
              </div>

              <div className="filter-item mb-2">
                <label className="small">Search by enquiry id</label>
                <input
                  type="number"
                  className="py-2 small form-control"
                  placeholder="Enter enquiry id"
                  value={filterState.searchId ? filterState.searchId : ""}
                  onWheel={handleWheel}
                  onChange={(e) => {
                    updateFilter({
                      searchId: parseInt(e.target.value) || undefined,
                    });
                  }}
                />
              </div>
            </>
          )}
          <div className="filter-item mb-2">
            <label className="small">
              {!props.isCustomerView
                ? "Search by enquiry title"
                : "Search by Kart Title"}
            </label>
            <input
              type="text"
              className="py-2 small form-control"
              placeholder="Enter enquiry title"
              value={filterState.searchTitle ? filterState.searchTitle : ""}
              onChange={(e) => {
                updateFilter({
                  searchTitle: e.target.value,
                });
              }}
            />
          </div>
          <div className="filter-item mb-2">
            <label className="small">Search by lead passenger</label>
            <input
              type="text"
              className="py-2 small form-control"
              placeholder="Enter lead passenger"
              value={filterState.leadPassenger ? filterState.leadPassenger : ""}
              onChange={(e) => {
                updateFilter({
                  leadPassenger: e.target.value,
                });
              }}
            />
          </div>

          {!props.isCustomerView && (
            <>
              <div className="filter-item mb-2">
                <label className="small">Check staff enquiries</label>
                <UserSelect
                  onlyStaff={true}
                  containerClassName="small"
                  value={selectedAssignedStaff}
                  onChange={(e) => {
                    setSelectedAssignedStaff(e);

                    updateFilter({
                      assignedToStaffId: e?.id,
                      unassigned: false,
                    });
                    setFilterState((old) => ({ ...old, unassigned: false }));
                  }}
                />
              </div>
              <div className="filter-item mb-2">
                <label className="small">Search by customer</label>
                <UserSelect
                  containerClassName="small"
                  value={selectedUser}
                  onChange={(e) => {
                    setSelectedUser(e);
                    if (e?.id === filterState.assignedToStaffId) {
                      return;
                    }
                    updateFilter({
                      searchByUserId: e?.id,
                    });
                  }}
                />
              </div>
              <div className="filter-item mb-2">
                <label className="small">
                  Search Enquiry by last updated Days
                </label>
                <Form.Select
                  aria-label="Default select example"
                  className={`small py-2 mb-2`}
                  value={filterState.datefilter || ""}
                  onChange={(e) => {
                    updateFilter({
                      datefilter: parseInt(e.target.value),
                    });
                  }}
                >
                  <option value={""} disabled selected>
                    Select a value
                  </option>
                  {days.map((value) => (
                    <option key={value} value={value}>
                      {value}
                    </option>
                  ))}
                </Form.Select>
              </div>
              <div className="filter-item mb-2">
                <label className="small">Search by no. of staled days</label>
                <select
                  aria-label="Default select example"
                  className={`small py-2 mb-2 form-control`}
                  value={
                    typeof filterState.staledEnquiriesDays === "number"
                      ? filterState.staledEnquiriesDays
                      : 0
                  }
                  onChange={(e) => {
                    updateFilter({
                      staledEnquiriesDays: parseInt(e.target.value),
                    });
                  }}
                >
                  <option value={""} disabled selected>
                    Select a value
                  </option>
                  {staledDays.map((value) => (
                    <option key={value} value={value}>
                      {value}
                    </option>
                  ))}
                </select>
              </div>
              <div className="filter-item mb-2">
                <label className="small">Show Unassigned Enquiries</label>

                <span className="form-check form-switch">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="flexSwitchCheckChecked"
                    checked={filterState.unassigned}
                    onChange={() => {
                      updateFilter({
                        unassigned: !filterState.unassigned,
                        assignedToStaffId: undefined,
                      });
                    }}
                  />
                </span>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};
