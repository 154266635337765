export enum EnumUserType {
  RETAIL = "retail",
  STAFF = "staff",
  TRAVEL_AGENT = "travelAgent",
}

export interface IUserData {
  id: number;
  firstName: string;
  lastName: string;
  email: string | null;
  alias: string;
  userType: EnumUserType;
  phoneNumber: string;
  phoneCode: string;
  isAdmin: boolean;
  isPhoneVerified: boolean;
  panNumber: string | null;
  createdAt: Date;
  updatedAt: Date;
  isSupervisor?: boolean;
}
