import { useState } from "react";
import { CitySelect } from "../../admin/Tours/CitySelect";
import {
  AppRangeDatePicker,
  IDateRange,
} from "../../../commonUi/AppRangeDatePicker";
import { IPaxInfo, PaxInfoPopOver } from "./PaxInfoPopOver";
import { toast } from "react-toastify";
import moment from "moment";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { AppDispatch, useAppSelector } from "../../../store";
import { useDispatch } from "react-redux";
import { EnumSessionType } from "../../../activitiescommonfnb/schemas/booking.schema";
import { ISessionWithCartDetail } from "../../../activitiescommonfnb/types/booking.interface";
import swal from "sweetalert";
import { activityAxios } from "../../../axios/activityAxios";
import { IJSONResponse } from "../../../activitiescommonfnb/types/common.interface";
import { TCity } from "../../../activitiescommonfnb/schemas/master.schema";
import {
  ActivitySessionActions,
  generateNewSession,
} from "../../../slice/session.slice";
import { defaultDate } from "../../../helper/tourHelper";
export type TSearchDetail = {
  citySlug: string | null;
  travelDateRange: IDateRange;
  paxInfo: IPaxInfo;
};

export const ActivitySearch: React.FC<{
  containerClassName?: string;
  isModifySearch?: boolean;
  onSearch?: (
    citySlug: string,
    from: string,
    to: string,
    paxInfo: IPaxInfo
  ) => void;
  isDisabled: boolean;
}> = (props) => {
  const [searchParams] = useSearchParams();
  const fromDate = searchParams.get("from");
  const toDate = searchParams.get("to");
  const { cityslug } = useParams();

  const dispatch = useDispatch<AppDispatch>();
  const defaultSearchDetails: TSearchDetail = {
    citySlug: cityslug || null,
    travelDateRange: {
      from: fromDate ? moment(fromDate).toDate() : defaultDate.from,
      to: toDate ? moment(toDate).toDate() : defaultDate.to,
    },
    paxInfo: {
      adult: parseInt(searchParams.get("adult") || "2"),
      child: parseInt(searchParams.get("child") || "0"),
      infant: parseInt(searchParams.get("infant") || "0"),
    },
  };
  const sessionDetails = useAppSelector(
    (state) => state.activitySession.activeSession
  );

  const [searchDetailState, setSearchDetailState] =
    useState<TSearchDetail>(defaultSearchDetails);

  const navigate = useNavigate();

  const [showPaxPopover, setShowPaxPopover] = useState(false);

  const searchActivity = () => {
    if (!searchDetailState.citySlug) {
      return toast.error("Please select city.");
    }

    if (
      !searchDetailState.travelDateRange.from ||
      !searchDetailState.travelDateRange.to
    ) {
      return toast.error("Please select date.");
    }

    const fromDate = moment(searchDetailState.travelDateRange.from).format(
      "YYYY-MM-DD"
    );
    const toDate = moment(searchDetailState.travelDateRange.to).format(
      "YYYY-MM-DD"
    );

    let searchUrl = `/search/${searchDetailState.citySlug}?from=${fromDate}&to=${toDate}&adult=${searchDetailState.paxInfo.adult}&child=${searchDetailState.paxInfo.child}&infant=${searchDetailState.paxInfo.infant}`;

    if (props.isModifySearch) {
      searchUrl += `&modifySearch=true`;
    }

    navigate(searchUrl, {
      replace: props.isModifySearch,
    });

    if (props.onSearch) {
      props.onSearch(
        searchDetailState.citySlug,
        moment(searchDetailState.travelDateRange.from).format("YYYY-MM-DD"),
        moment(searchDetailState.travelDateRange.to).format("YYYY-MM-DD"),
        searchDetailState.paxInfo
      );
    }
  };

  const updateReduxSession = () => {
    if (sessionDetails)
      dispatch(
        ActivitySessionActions.setActiveSessionData({
          ...sessionDetails,
          from: moment(searchDetailState.travelDateRange.from).format(
            "YYYY-MM-DD"
          ),
          to: moment(searchDetailState.travelDateRange.to).format("YYYY-MM-DD"),
          adultCount: searchDetailState.paxInfo.adult,
          childCount: searchDetailState.paxInfo.child,
          infantCount: searchDetailState.paxInfo.infant,
        })
      );
  };

  const createNewSession = () => {
    if (sessionDetails) {
      dispatch(generateNewSession(sessionDetails));
    }
    if (
      searchDetailState.travelDateRange.from &&
      searchDetailState.travelDateRange.to
    ) {
      const activeSessionData: ISessionWithCartDetail = {
        adultCount: searchDetailState.paxInfo.adult,
        childCount: searchDetailState.paxInfo.child,
        infantCount: searchDetailState.paxInfo.infant,
        from: searchDetailState.travelDateRange.from.toDateString(),
        to: searchDetailState.travelDateRange.to.toDateString(),
        type: EnumSessionType.CART,
        cartItems: [],
      };
      dispatch(ActivitySessionActions.setActiveSessionData(activeSessionData));
    }
  };

  const getCitySlug = async (tourId: number) => {
    const apiRes = await activityAxios.post<IJSONResponse<TCity>>(
      "/master/getCityByTourId",
      {
        tourId,
      }
    );
    if (apiRes.data.success) return apiRes.data.result?.slug;
    else toast.error(apiRes.data.errorMessage);
  };

  const navigateToResultPage = async () => {
    if (sessionDetails) {
      const citySlug = await getCitySlug(sessionDetails.cartItems[0].tourId);
      if (!citySlug) {
        return toast.error("City slug not found");
      }
      const searchUrl = `/search/${citySlug}?from=${moment(
        sessionDetails.from
      ).format("YYYY-MM-DD")}&to=${moment(sessionDetails.to).format(
        "YYYY-MM-DD"
      )}&adult=${sessionDetails.adultCount}&child=${
        sessionDetails.childCount
      }&infant=${sessionDetails.infantCount}&modifySearch=true`;

      navigate(searchUrl);
    }
  };

  const checkExistingSession = () => {
    if (sessionDetails && sessionDetails.cartItems.length > 0) {
      const sessionFromDate = moment(sessionDetails.from).toDate();
      const sessionToDate = moment(sessionDetails.to).toDate();

      const isSamePax =
        sessionDetails.adultCount !== searchDetailState.paxInfo.adult ||
        sessionDetails.childCount !== searchDetailState.paxInfo.child ||
        sessionDetails.infantCount !== searchDetailState.paxInfo.infant;

      if (
        !moment(sessionFromDate).isSame(
          searchDetailState.travelDateRange.from
        ) ||
        !moment(sessionToDate).isSame(searchDetailState.travelDateRange.to) ||
        isSamePax
      ) {
        swal({
          title: "Are you sure?",
          text: ` ${
            isSamePax
              ? "Changing Passengers will affect the prices in the Kart."
              : ""
          } You want create a new cart ?`,
          icon: "warning",
          buttons: [`continue with existing cart`, "create new cart"],
          dangerMode: true,
          closeOnClickOutside: false,
        }).then((createNewCart) => {
          if (createNewCart) {
            createNewSession();
            searchActivity();
          } else {
            setSearchDetailState(defaultSearchDetails);
            if (!props.isModifySearch) {
              navigateToResultPage();
            }
          }
        });
      } else {
        updateReduxSession();
        searchActivity();
      }
    } else {
      updateReduxSession();
      searchActivity();
    }
  };

  return (
    <div className={props.containerClassName}>
      <div className="row align-items-end">
        <CitySelect
          className="col-lg-4 col-md-4 col-sm-12 col-12 mb-2"
          defaultCitySlug={searchDetailState.citySlug || undefined}
          onCityChange={(city) => {
            setSearchDetailState((old) => {
              old.citySlug = city?.slug || null;
              return { ...old };
            });
          }}
        />
        <AppRangeDatePicker
          label="Select Dates"
          containerClassName="col-lg-3 col-md-3 col-sm-12 col-12 mb-2"
          wrapperClassName="w-100"
          dateRange={
            searchDetailState.travelDateRange.from == null
              ? defaultDate
              : searchDetailState.travelDateRange
          }
          minDate={new Date()}
          onChange={(dateRange) => {
            setSearchDetailState((old) => {
              old.travelDateRange = dateRange;
              return { ...old };
            });
          }}
        />

        <PaxInfoPopOver
          containerClassName="col-lg-3 col-md-3 col-sm-12 col-12 mb-2"
          onSubmit={(paxInfo) => {
            setShowPaxPopover(false);
            setSearchDetailState((old) => {
              old.paxInfo = paxInfo;
              return { ...old };
            });
          }}
          paxInfo={searchDetailState.paxInfo}
          showPassenegerPopup={showPaxPopover}
          setShowPassengerPopup={(show) => {
            setShowPaxPopover(show);
          }}
          isDisabled={props.isDisabled}
        />
        <div className="col-lg-2 col-md-2 col-sm-12 col-12 mb-2">
          <button
            className="btn btn-primary w-100 mt-2"
            onClick={() => {
              checkExistingSession();
            }}
          >
            Search
          </button>
        </div>
      </div>
    </div>
  );
};
