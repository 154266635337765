import React, { ReactNode } from "react";
import { Navigate } from "react-router-dom";
import { getAuthToken } from "../../functions/commonFx";
import { useAppSelector } from "../../store";

export const AdminRoute: React.FC<{
  children: ReactNode;
}> = (props) => {
  const token = getAuthToken();

  const { data: userData } = useAppSelector((state) => state.authData);

  if (!token || !userData) {
    return <Navigate to="/login" />;
  }
  if (!userData?.isAdmin) {
    return <Navigate to="/notFound" replace />;
  }

  return <React.Fragment>{props.children}</React.Fragment>;
};
