import { useEffect, useState } from "react";
import { TCity } from "../../../activitiescommonfnb/schemas/master.schema";
import { activityAxios } from "../../../axios/activityAxios";
import { IJSONResponse } from "../../../activitiescommonfnb/types/common.interface";
import Select from "react-select";

export const CityMultiSelect: React.FC<{
  id?: string;
  className?: string;
  isClearable?: boolean;
  selectedCites: Required<TCity>[];
  onCityChange: (cities: Required<TCity>[]) => void;
}> = ({ selectedCites, onCityChange, ...selectProps }) => {
  const [cities, setCities] = useState<Required<TCity>[]>([]);

  const getAllCities = async () => {
    const apiRes = await activityAxios.get<IJSONResponse>(
      "/search/getAllCities"
    );
    if (apiRes.data.success && apiRes.data.result) {
      setCities(apiRes.data.result);
    }
  };

  const mapCitiesToOptions = (data: Required<TCity>[]) => {
    return data.map((city) => {
      return {
        label: city.name,
        value: city.id,
        city: city,
      };
    });
  };

  useEffect(() => {
    getAllCities();
  }, []);

  return (
    <Select
      {...selectProps}
      isMulti
      options={mapCitiesToOptions(cities)}
      value={mapCitiesToOptions(selectedCites)}
      onChange={(data) => {
        let selectedCities = data.map((d) => d.city);
        onCityChange(selectedCities);
      }}
    />
  );
};
