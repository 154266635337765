import React, { useState } from "react";
import { ITask } from "../../activitiescommonfnb/types/task.interface";
import moment from "moment";
import { CreateOrEditTaskPopup } from "./CreateOrEditTaskPopup";
import { DateService } from "../../services/Date.service";
import { UploadPhotosModal } from "./UploadPhotosModal";
import { Spinner } from "react-bootstrap";
import { capitalize } from "lodash";
import { toast } from "react-toastify";
import { activityAxios } from "../../axios/activityAxios";
import { IJSONResponse } from "../../activitiescommonfnb/types/common.interface";
import manageTaskPageStyle from "../../screens/ManageTask/manageTaskStyle.module.scss";

export const SingleTaskItem: React.FC<{
  taskIndex: number;
  singleTaskItem: ITask;
  updateTask: (task: ITask) => void;
  getTasks: () => void;
}> = (props) => {
  const [showEditModal, setShowEditModal] = useState(false);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [statusLoading, setStatusLoading] = useState(false);

  const markTaskAsDone = async (isDone: boolean) => {
    setStatusLoading(true);
    const apiResponse = await activityAxios.post<IJSONResponse>(
      "/task/updateTaskDoneStatus",
      {
        taskId: props.singleTaskItem.id,
        isDone: isDone,
      }
    );

    if (apiResponse.data.success) {
      toast.success("Task updated successfully.");
      props.updateTask({
        ...props.singleTaskItem,
        isDone: isDone,
      });
    } else {
      toast.error(apiResponse.data.errorMessage);
    }
    setStatusLoading(false);
  };

  return (
    <React.Fragment>
      <div>
        <div
          className={`row align-items-center ${manageTaskPageStyle.singleTaskListItem}`}
        >
          <div className="col-lg-3 col-md-4 col-sm-8 col-8">
            <div className="fw-bold">
              {props.taskIndex + 1}. {props.singleTaskItem.title}
            </div>
            <div className="text-muted">{props.singleTaskItem.description}</div>
          </div>
          <div className="col-lg-1 col-md-2 col-sm-4 col-4">
            <span className="alert alert-warning py-1 px-2">
              {props.singleTaskItem.deadline
                ? moment(props.singleTaskItem.deadline).format("DD/MM/YYYY")
                : "N/A"}
            </span>
          </div>
          <div className="col-lg-1 col-md-2 col-sm-4 col-4">
            <div className={`small text-muted ${manageTaskPageStyle.label}`}>
              Start Date : <br />{" "}
            </div>
            {props.singleTaskItem.reminderStartDate
              ? moment(props.singleTaskItem.reminderStartDate).format(
                  "DD/MM/YYYY"
                )
              : "N/A"}
          </div>
          <div className="col-lg-1 col-md-2 col-sm-4 col-4">
            <div className={`small text-muted ${manageTaskPageStyle.label}`}>
              Start Time : <br />{" "}
            </div>
            {DateService.convertUtcTimeToLocalTime(
              props.singleTaskItem.reminderStartTime
            ) || "N/A"}
          </div>
          <div className="col-lg-1 col-md-2 col-sm-4 col-4">
            <div className={`small text-muted ${manageTaskPageStyle.label}`}>
              End Date : <br />{" "}
            </div>
            {DateService.convertUtcTimeToLocalTime(
              props.singleTaskItem.reminderEndTime
            ) || "N/A"}
          </div>
          <div className="col-lg-1 col-md-2 col-sm-4 col-4">
            <div className={`small text-muted ${manageTaskPageStyle.label}`}>
              Frequency : <br />{" "}
            </div>
            {capitalize(
              props.singleTaskItem.reminderFrequency?.replaceAll("_", " ") ||
                "None"
            )}
          </div>
          <div className="col-lg-1 col-md-4 col-sm-8 col-8">
            <div className={`small text-muted ${manageTaskPageStyle.label}`}>
              Photo Proof Required :{" "}
            </div>{" "}
            <span
              className={`alert py-1 small ${
                props.singleTaskItem.isPhotoProofRequired
                  ? "alert-success"
                  : "alert-danger"
              }`}
            >
              {props.singleTaskItem.isPhotoProofRequired ? "Yes" : "No"}
            </span>
          </div>
          <div className={`col-lg-1 col-md-2 col-sm-3 col-4`}>
            <div className={manageTaskPageStyle.statusItem}>
              {/* <label className={`small text-muted ${manageTaskPageStyle.label}`}>Status : </label> <br /> */}
              {statusLoading ? (
                <div className="d-flex align-items-center ">
                  <Spinner size="sm" />
                  <span className="ms-2">Done</span>
                </div>
              ) : (
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id={`taskDoneCheckBox_${props.singleTaskItem.id}`}
                    checked={props.singleTaskItem.isDone}
                    onChange={(e) => markTaskAsDone(e.target.checked)}
                  />
                  <label
                    className="form-check-label my-1 ms-2"
                    htmlFor={`taskDoneCheckBox_${props.singleTaskItem.id}`}
                  >
                    Done
                  </label>
                </div>
              )}
            </div>
          </div>
          <div className="col-lg-2 col-md-4 col-sm-9 col-8">
            <div className="d-flex align-items-center justify-content-end mt-2">
              {props.singleTaskItem.isPhotoProofRequired && (
                <button
                  className="btn btn-success btn-sm px-4"
                  onClick={() => setShowUploadModal(true)}
                >
                  Upload
                </button>
              )}
              <button
                className="btn btn-sm btn-secondary ms-2 rounded-pill"
                onClick={() => {
                  setShowEditModal(true);
                }}
              >
                <i className="fa-solid fa-pencil"></i>
              </button>
            </div>
          </div>
          {/* <div className="col-12 ">
          <b>Description : </b> {props.singleTaskItem.description}
        </div> */}
        </div>
      </div>
      {showEditModal && (
        <CreateOrEditTaskPopup
          id={props.singleTaskItem.id}
          show={showEditModal}
          onHide={() => {
            setShowEditModal(false);
          }}
          onSave={() => {
            setShowEditModal(false);
            props.getTasks();
          }}
        />
      )}
      {showUploadModal && (
        <UploadPhotosModal
          taskId={props.singleTaskItem.id}
          show={showUploadModal}
          onHide={() => {
            setShowUploadModal(false);
          }}
        />
      )}
    </React.Fragment>
  );
};
