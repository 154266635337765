import { Button, Form, Modal, Spinner } from "react-bootstrap";
import { TCountry } from "../../../activitiescommonfnb/schemas/master.schema";
import UploadAndDisplayImage from "../../../commonUi/UploadAndDisplayImage";
import { ChangeEvent, useState } from "react";
import { activityAxios } from "../../../axios/activityAxios";
import { toast } from "react-toastify";

export const EditCountryModal: React.FC<{
  show: boolean;
  onHide: () => void;
  selectedCountryDetails: TCountry;
  getCountries: () => void;
  HideModal: () => void;
}> = (props) => {
  const [countryImage, setCountryImage] = useState<File | null>(null);
  const [countryDetails, setCountryDetails] = useState<TCountry>(
    props.selectedCountryDetails
  );
  const [loading, setLoading] = useState(false);
  const handleCountryDetailsChange = (e: ChangeEvent<HTMLInputElement>) => {
    let value: string | number | boolean = e.target.value;
    let name: string = e.target.name;
    if (name === "transactionCost") value = parseFloat(value.toString());
    if (name === "roe") value = parseFloat(value.toString());
    if (name === "isActive") value = e.target.checked;

    setCountryDetails((old) => {
      return { ...old, [name]: value };
    });
  };

  const saveCountryHandler = async () => {
    try {
      if (loading) return;
      setLoading(true);

      const formData = new FormData();
      if (countryImage) {
        formData.append("countryImage", countryImage);
      }

      formData.append("countryData", JSON.stringify(countryDetails));

      const saveCountryRes = await activityAxios.post(
        "/master/createOrEditCountry",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (saveCountryRes.data.success) {
        toast.success("Details saved");
        props.getCountries();
        props.HideModal();
      }
      setLoading(false);
    } catch (error: any) {
      toast.error(error.message);
    }
  };

  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {props.selectedCountryDetails?.id
            ? "Edit Country"
            : "Add new country"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <div className="col-6">
            <label htmlFor="">Country Name</label>
            <input
              type="text"
              name="countryName"
              className="form-control"
              value={countryDetails?.countryName}
              onChange={handleCountryDetailsChange}
            />
          </div>
          <div className="col-6">
            <label htmlFor="">Country Code</label>
            <input
              type="text"
              name="countryCode"
              value={countryDetails?.countryCode}
              className="form-control"
              onChange={handleCountryDetailsChange}
            />
          </div>
          <div className="col-6">
            <label htmlFor="">ROE</label>
            <input
              type="number"
              name="roe"
              value={countryDetails?.roe}
              className="form-control"
              onChange={handleCountryDetailsChange}
            />
          </div>
          <div className="col-6">
            <label htmlFor="">Transaction Cost</label>
            <input
              value={
                countryDetails?.transactionCost &&
                parseFloat(countryDetails?.transactionCost.toString())
              }
              type="number"
              name="transactionCost"
              className="form-control"
              onChange={handleCountryDetailsChange}
            />
          </div>
          <div className="border rounded m-2 p-2 col-6 form-check form-switch">
            <Form.Check // prettier-ignore
              type="switch"
              id="custom-switch"
              label="isActive"
              name="isActive"
              checked={countryDetails?.isActive}
              onChange={handleCountryDetailsChange}
            />
          </div>
          <div className="col-6">
            <label htmlFor="">Currency</label>
            <input
              type="text"
              name="currency"
              className="form-control"
              value={countryDetails?.currency}
              onChange={handleCountryDetailsChange}
            />
          </div>

          <div className="col-6">
            <label htmlFor="">Country Image</label>
            <UploadAndDisplayImage
              onSelectImage={(file) => {
                setCountryImage(file);
              }}
              selectedImage={countryImage}
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
        <Button onClick={saveCountryHandler}>
          Submit
          {loading ? <Spinner animation="border" size="sm" /> : ""}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
