import { useEffect, useState } from "react";
import { ISessionWithCartDetail } from "../../activitiescommonfnb/types/booking.interface";
import { activityAxios } from "../../axios/activityAxios";
import { IJSONResponse } from "../../activitiescommonfnb/types/common.interface";
import { toast } from "react-toastify";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { TCity } from "../../activitiescommonfnb/schemas/master.schema";
import tourQuotaionListItemStyle from "./QuotationPageStyle.module.scss";
import Price from "../../commonUi/Price";
import { ActivitySessionActions } from "../../slice/session.slice";
import { useAppDispatch } from "../../store";

export const QuotationsPage: React.FC<{}> = () => {
  const [quotations, setQuotations] = useState<ISessionWithCartDetail[]>([]);
  const [open, setOpen] = useState<number>();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const getQoutations = async () => {
    const apiRes = await activityAxios.post<
      IJSONResponse<ISessionWithCartDetail[]>
    >("/booking/getUserSessions", {});
    if (apiRes.data.success && apiRes.data.result) {
      setQuotations(apiRes.data.result);
    } else {
      toast.error(apiRes.data.errorMessage || "Something went wrong");
    }
  };
  //set data of selected quotation in the active cart in redux
  const setQuotation = async (quotationDetails: ISessionWithCartDetail) => {
    dispatch(ActivitySessionActions.setActiveSessionData(quotationDetails));
    const citySlug = await getCitySlug(quotationDetails.cartItems[0].tourId);
    if (!citySlug) {
      return toast.error("City slug not found");
    }
    const searchUrl = `/search/${citySlug}?from=${moment(
      quotationDetails.from
    ).format("YYYY-MM-DD")}&to=${moment(quotationDetails.to).format(
      "YYYY-MM-DD"
    )}&adult=${quotationDetails.adultCount}&child=${
      quotationDetails.childCount
    }&infant=${quotationDetails.infantCount}&resume=true`;

    navigate(searchUrl);
  };

  const getCitySlug = async (tourId: number) => {
    const apiRes = await activityAxios.post<IJSONResponse<TCity>>(
      "/master/getCityByTourId",
      {
        tourId,
      }
    );
    if (apiRes.data.success) return apiRes.data.result?.slug;
    else toast.error(apiRes.data.errorMessage);
  };

  useEffect(() => {
    getQoutations();
  }, []);

  return (
    <div className={tourQuotaionListItemStyle.tourQuotationPage}>
      <div className="headerImage">
        <div className="container">
          <h3>Quotations</h3>
        </div>
      </div>
      <div className="container">
        <div className="py-5">
          {quotations &&
            quotations.map((quotation, index) => {
              return (
                <div
                  className={tourQuotaionListItemStyle.quotationListItem}
                  key={quotation.id}
                >
                  <div
                    className={tourQuotaionListItemStyle.quotationNameRow}
                    onClick={() => setOpen(quotation.id)}
                    aria-controls={quotation.id?.toString()}
                    aria-expanded={open === quotation.id}
                  >
                    <div className="d-flex align-items-center justify-content-between">
                      <div>
                        <div className="d-flex align-items-center">
                          <i className="fa-solid fa-cart-arrow-down me-3 fs-4 text-secondary"></i>
                          <div>
                            <h6 className="m-0">{index + 1}. Cart Name</h6>
                            <span className="small text-secondary fw-bold">
                              {`${moment(quotation.from).format("ll")} - 
                              ${moment(quotation.to).format("ll")}`}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="small">
                        <span className="me-2">
                          Created at :{" "}
                          <span className="fw-bold">
                            {moment(quotation.createdAt).format("lll")}
                          </span>
                        </span>
                        <button
                          className="btn btn-sm btn-primary px-3"
                          onClick={() => {
                            setQuotation(quotation);
                          }}
                        >
                          Resume
                        </button>

                        <button
                          title="Edit"
                          className="btn btn-sm btn-outline-danger ms-2"
                        >
                          <i className="fa-solid fa-trash"></i>
                        </button>
                      </div>
                    </div>
                  </div>

                  {quotation.cartItems.map((cartItem) => {
                    return (
                      <div
                        key={cartItem.tourId}
                        className={`
                        ${cartItem.isComboTour && "bg-light"}
                            ${tourQuotaionListItemStyle.quotationTourOption}
                          p-3`}
                      >
                        {cartItem.isComboTour && (
                          <div
                            className={
                              tourQuotaionListItemStyle.comboQuotationItemHeading
                            }
                          >
                            - - - COMBO OF {cartItem.tourOptions.length} - - -
                          </div>
                        )}
                        <div
                          className={`${
                            cartItem.isComboTour && "bg-white rounded mt-4"
                          }`}
                        >
                          {cartItem.tourOptions.map((bookingTourOption, i) => {
                            const tourOptionTotalPrice =
                              bookingTourOption.adultCount *
                                bookingTourOption.adultPrice +
                              bookingTourOption.childCount *
                                bookingTourOption.childPrice +
                              bookingTourOption.infantCount * 0;

                            return (
                              <>
                                <div
                                  className={`${
                                    cartItem.isComboTour &&
                                    "bg-white rounded mt-2"
                                  }`}
                                >
                                  <div className="row align-items-center">
                                    <div className="col-lg-2">
                                      <img
                                        className={`img-fluid ${tourQuotaionListItemStyle.image}`}
                                        src="https://d1i3enf1i5tb1f.cloudfront.net/Tour-Images/false-5066/Dubai_Frame.jpg"
                                        alt=""
                                      />
                                    </div>
                                    <div className="col-lg-8">
                                      <div className="">
                                        <h6 className="mb-0">
                                          {
                                            bookingTourOption.tourOption
                                              .tourOptionName
                                          }
                                        </h6>
                                        <span
                                          className="small text-muted"
                                          dangerouslySetInnerHTML={{
                                            __html:
                                              bookingTourOption.tourOption
                                                .tourOptionDescription,
                                          }}
                                        ></span>
                                        <div className="small my-1">
                                          Travel Date :{" "}
                                          <span className="fw-bold ">
                                            {bookingTourOption.travelDate}{" "}
                                          </span>
                                        </div>
                                        <div className="small">
                                          Transfer Type :{" "}
                                          <span className="fw-bold ">
                                            {bookingTourOption.transferType}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-lg-2">
                                      <div
                                        className={
                                          tourQuotaionListItemStyle.price
                                        }
                                      >
                                        <div
                                          className={
                                            tourQuotaionListItemStyle.priceItem
                                          }
                                        >
                                          <span>Adult :</span>
                                          <span className="fw-bold ms-2">
                                            {bookingTourOption.adultCount}
                                            <i className="fa-solid fa-xmark mx-1"></i>
                                          </span>
                                          <Price
                                            className="fw-bold"
                                            amount={
                                              bookingTourOption.adultPrice
                                            }
                                            currency={"₹"}
                                          />
                                        </div>
                                        <div
                                          className={
                                            tourQuotaionListItemStyle.priceItem
                                          }
                                        >
                                          <span>Child :</span>
                                          <span className="fw-bold ms-2">
                                            {bookingTourOption.childCount}
                                            <i className="fa-solid fa-xmark mx-1"></i>
                                          </span>
                                          <Price
                                            className="fw-bold"
                                            amount={
                                              bookingTourOption.childPrice
                                            }
                                            currency={"₹"}
                                          />
                                        </div>
                                        <div
                                          className={
                                            tourQuotaionListItemStyle.priceItem
                                          }
                                        >
                                          <span>Infant :</span>
                                          <span className="fw-bold ms-2">
                                            {bookingTourOption.infantCount}
                                            <i className="fa-solid fa-xmark mx-1"></i>
                                          </span>
                                          <Price
                                            className="fw-bold"
                                            amount={
                                              bookingTourOption.infantPrice
                                            }
                                            currency={"₹"}
                                          />
                                        </div>
                                        <div
                                          className={
                                            tourQuotaionListItemStyle.totalPrice
                                          }
                                        >
                                          <span className="me-2">Total :</span>
                                          <Price
                                            className="fw-bold"
                                            amount={tourOptionTotalPrice}
                                            currency={"₹"}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className={
                                    cartItem.tourOptions.length - 1 !== i
                                      ? `${tourQuotaionListItemStyle.comboMiddleBorder}`
                                      : ""
                                  }
                                ></div>
                              </>
                            );
                          })}
                        </div>
                      </div>
                    );
                  })}
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};
