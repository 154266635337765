import React from "react";
import Price from "../../commonUi/Price";
import queueTourOptionStyle from "./QueueTourOptionStyle.module.scss";
import { IBookingTourOption } from "../../activitiescommonfnb/types/booking.interface";

const QueueTourOptionItem: React.FC<{
  queueTourOptionDetails: IBookingTourOption;
}> = (props) => {
  const totalPrice =
    props.queueTourOptionDetails.adultCount *
      props.queueTourOptionDetails.adultPrice +
    props.queueTourOptionDetails.childCount *
      props.queueTourOptionDetails.childPrice +
    props.queueTourOptionDetails.infantCount * 0;

  return (
    <div>
      <div className={queueTourOptionStyle.bookingTourOptionItem}>
        <div className="conatiner-fluid">
          <div className="row ">
            <div className="col-lg-9">
              <div className={queueTourOptionStyle.tourOptionDetails}>
                <div className={`text-muted ${queueTourOptionStyle.tourName}`}>
                  {props.queueTourOptionDetails.tourOption.tourOptionName}{" "}
                  <span className="small text-secondary fw-bold">
                    {props.queueTourOptionDetails.travelDate}
                  </span>
                </div>
                <div
                  className={`small ${queueTourOptionStyle.optionShortDescription}`}
                >
                  {
                    props.queueTourOptionDetails.tourOption
                      .tourOptionDescription
                  }
                </div>
                <div className="small mt-1">
                  Transfer Type :{" "}
                  <span className=" fw-bold text-muted">
                    {props.queueTourOptionDetails.transferType}
                  </span>
                </div>
                <div className="small mt-1">
                  PickUp :{" "}
                  <span className=" fw-bold text-muted">
                    {props.queueTourOptionDetails.pickupLocation}
                  </span>
                </div>
              </div>
            </div>

            <div className="col-lg-3 ps-lg-0">
              <div className={queueTourOptionStyle.price}>
                <div className={queueTourOptionStyle.priceItem}>
                  <span className="me-2">Adult :</span>
                  <span className="fw-bold">
                    {props.queueTourOptionDetails.adultCount}
                    <i className="fa-solid fa-xmark mx-1"></i>
                  </span>
                  <Price
                    className="fw-bold"
                    amount={props.queueTourOptionDetails.adultPrice}
                    currency={"₹"}
                  />
                </div>
                <div className={queueTourOptionStyle.priceItem}>
                  <span className="me-2">Child :</span>
                  <span className="fw-bold">
                    {props.queueTourOptionDetails.childCount}
                    <i className="fa-solid fa-xmark mx-1"></i>
                  </span>
                  <Price
                    className="fw-bold"
                    amount={props.queueTourOptionDetails.childPrice}
                    currency={"₹"}
                  />
                </div>
                <div className={queueTourOptionStyle.priceItem}>
                  <span className="me-2">Infant :</span>
                  <span className="fw-bold">
                    {props.queueTourOptionDetails.infantCount}
                    <i className="fa-solid fa-xmark mx-1"></i>
                  </span>
                  <Price
                    className="fw-bold"
                    amount={props.queueTourOptionDetails.infantPrice}
                    currency={"₹"}
                  />
                </div>
                <div className={queueTourOptionStyle.totalPrice}>
                  <span className="me-2">Total :</span>

                  <Price
                    className="fw-bold"
                    amount={totalPrice}
                    currency={"₹"}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QueueTourOptionItem;
