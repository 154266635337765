import { z } from "zod";

export const signUpSchema = z.object({
  firstName: z.string().min(1),
  lastName: z.string().optional(),
  phoneNumber: z.string().min(1),
  phoneCode: z.string().min(1),
  isValid: z.boolean(),
});

export type TSignUp = z.infer<typeof signUpSchema>;
