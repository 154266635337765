import { useState } from "react";
import { CreateOrEditTourModal } from "./CreateOrEditTour";
import SingleTourListItemStyle from "./SingleTourListItem.module.scss";
import Collapse from "react-bootstrap/Collapse";
import AdminTourOption from "./AdminTourOptions/AdminTourOptions";
import { CreateOrEditTourOption } from "./AdminTourOptions/CreateOrEditTourOption";
import { EnumUser } from "../../../types/tourSearch.interface";
import { UploadTourImageModal } from "./UploadTourImageModal";
import { Carousel } from "react-bootstrap";
import {
  ITour,
  ITourOption,
} from "../../../activitiescommonfnb/types/tour.interface";
import { activityAxios } from "../../../axios/activityAxios";
import { TourResultItemLoadingSkeleton } from "../../../helper/tourHelper";

export const SingleTourListItem: React.FC<{
  tourDetails: ITour;
  getTours: () => void;
  userType?: EnumUser;
}> = (props) => {
  const [showEditModal, setShowEditModal] = useState(false);
  const [showCreateTourOptionModal, setShowCreateTourOptionModal] =
    useState(false);
  const [viewTourOption, setViewTourOption] = useState(false);
  const [showUploadImageModal, setShowUploadImageModal] = useState(false);
  const [tourOptions, setTourOption] = useState<ITourOption[]>([]);
  const [loading, setLoading] = useState(false);

  const getTourOption = async () => {
    setLoading(true);
    const apiRes = await activityAxios.post(
      "/master/tour/getTourOptionsByTourId",
      {
        tourId: props.tourDetails.id,
      }
    );
    if (apiRes.data.success) {
      setTourOption(apiRes.data.result);
    }
    setLoading(false);
  };

  return (
    <div className={SingleTourListItemStyle.singleTour}>
      <div className={`${SingleTourListItemStyle.singleTourListItem}`}>
        <div className="row ">
          <div className="col-lg-3">
            {Boolean(props.tourDetails?.tourImages?.length) ? (
              <Carousel
                fade
                interval={2000}
                // controls={false}
                indicators={false}
              >
                {props.tourDetails.tourImages?.map((singleImage) => (
                  <Carousel.Item>
                    <img
                      className="img-fluid"
                      src={singleImage.fileUrl}
                      alt=""
                    />
                  </Carousel.Item>
                ))}
              </Carousel>
            ) : (
              <img
                className="img-fluid"
                src="https://images.unsplash.com/photo-1634148551170-d37d021e0cc9?q=80&w=2084&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                alt=""
              />
            )}
          </div>
          <div className="col-lg-9">
            <div
              className={`${SingleTourListItemStyle.tourDetails} d-flex flex-column align-content-between justify-content-between h-100 `}
            >
              <div>
                <div className="tour-name h5">{props.tourDetails.name}</div>
                <div
                  className={SingleTourListItemStyle.tourShortDescription}
                  dangerouslySetInnerHTML={{
                    __html: props.tourDetails.tourShortDescription || "",
                  }}
                ></div>
              </div>
              <div className={SingleTourListItemStyle.tourBtns}>
                <div>
                  <button
                    className="btn btn-outline-primary small me-2"
                    onClick={() => {
                      setShowUploadImageModal(true);
                    }}
                  >
                    Upload Images
                  </button>

                  <button
                    className="btn btn-primary small"
                    onClick={() => {
                      setViewTourOption(!viewTourOption);
                      getTourOption();
                    }}
                    aria-controls="tour-options-collapse"
                    aria-expanded={viewTourOption}
                  >
                    Select Option <i className="fas fa-caret-down ms-1"></i>
                  </button>
                </div>
                <div>
                  {props.userType === EnumUser.ADMIN && (
                    <button
                      className="btn btn-sm btn-outline-secondary me-2"
                      onClick={() => {
                        setShowEditModal(true);
                      }}
                    >
                      <i className="fas fa-pen"></i>
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        {showEditModal && (
          <CreateOrEditTourModal
            id={props.tourDetails.id}
            show={showEditModal}
            onClose={() => {
              setShowEditModal(false);
            }}
            onSave={() => {
              setShowEditModal(false);
              props.getTours();
            }}
          />
        )}

        {showUploadImageModal && (
          <UploadTourImageModal
            onClose={() => {
              setShowUploadImageModal(false);
            }}
            show={showUploadImageModal}
            tourId={props.tourDetails.id}
          />
        )}
      </div>
      <div className={SingleTourListItemStyle.TourOptionCollapseBox}>
        <Collapse in={viewTourOption}>
          <div id="tour-options-collapse" className="p-3">
            <div className={SingleTourListItemStyle.tourOptions}>
              <div className={SingleTourListItemStyle.tourOptionHeader}>
                <h6>Tour Option</h6>
                {props.userType === EnumUser.ADMIN && (
                  <button
                    className="btn btn-primary btn-sm small"
                    onClick={() => {
                      setShowCreateTourOptionModal(true);
                    }}
                  >
                    + Create New
                  </button>
                )}
              </div>
              {loading ? (
                <>
                  <TourResultItemLoadingSkeleton />
                  <TourResultItemLoadingSkeleton />
                  <TourResultItemLoadingSkeleton />
                </>
              ) : (
                tourOptions?.map((tourOption) => {
                  return (
                    <AdminTourOption
                      tourOptionDetails={tourOption}
                      userType={props.userType}
                      showOptions={true}
                    />
                  );
                })
              )}

              {props.tourDetails.id && showCreateTourOptionModal && (
                <CreateOrEditTourOption
                  tourId={props.tourDetails.id}
                  show={showCreateTourOptionModal}
                  onClose={() => {
                    setShowCreateTourOptionModal(false);
                  }}
                  onSave={() => {
                    setShowCreateTourOptionModal(false);
                    getTourOption();
                  }}
                />
              )}
            </div>
          </div>
        </Collapse>
      </div>
    </div>
  );
};
