import React from "react";
import FloatingButtonStyle from "./FloatingButton.module.scss";

const FloatingButton: React.FC<{
  onClick: () => void;
  iconClassName: string;
}> = (props) => {
  return (
    <div className={FloatingButtonStyle.floatingButton}>
      <button
        className={`btn btn-primary ${FloatingButtonStyle.button}`}
        onClick={props.onClick}
      >
        <i className={props.iconClassName}></i>
      </button>
    </div>
  );
};

export default FloatingButton;
