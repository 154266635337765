import { Spinner, SpinnerProps } from "react-bootstrap";

export const OverlaySpinner: React.FC<{
  children: React.ReactNode;
  loading: boolean;
  spinnerSize?: "sm" | undefined;
}> = (props) => {
  return (
    <div className="position-relative">
      {props.loading && (
        <div
          className="position-absolute w-100 h-100 opacity-75 z-2"
          style={{
            backgroundColor: "rgb(6 6 6 / 58%)",
          }}
        ></div>
      )}
      {props.loading && (
        <div
          className="position-absolute start-50 top-50 z-3"
          style={{
            transform: "translate(-50%, -50%)",
          }}
        >
          <Spinner size={props.spinnerSize} />
        </div>
      )}
      {props.children}
    </div>
  );
};
