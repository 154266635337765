import React from "react";
import AppCkEditor from "../../commonUi/AppCkEditor";
import {
  EnumSharingType,
  IQuotationFormRC,
  TQuotationDataZod,
} from "../../activitiescommonfnb/types/quotation.interface";
import { ReactDatePicker } from "../../commonUi/ReactDatePicker";
import { ErrorMessage } from "../../commonUi/ErrorMessage";
import _ from "lodash";
import { handleWheel } from "../../helper/globalHelper";
import { addHotel, fields, removeHotel } from "../../helper/quotationHelper";

const QuotationForm: React.FC<IQuotationFormRC> = (props) => {
  const handleHotelChange = (
    index: number,
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    const parsedValue =
      name === "hotelCost" ? (value ? parseInt(value) : 0) : value;
    const updatedHotels = [...props.quotationData.hotels];
    updatedHotels[index] = {
      ...updatedHotels[index],
      [name]: name === "sharingType" ? value : parsedValue,
    };
    props.setQuotationData({ ...props.quotationData, hotels: updatedHotels });
  };

  const handleChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    const { name, value, type } = e.target;

    let parsedValue: any;

    if (type === "radio") {
      parsedValue = value === "true";
    } else if (type === "select-one") {
      parsedValue = parseInt(value) || value;
    } else if (type === "number") {
      parsedValue = value ? parseInt(value) : 0;
    } else if (type === "text") {
      parsedValue = value;
    }
    props.setQuotationData((old) => ({
      ...old,
      [name]: parsedValue,
    }));
  };

  return (
    <>
      <div className="">
        <AppCkEditor
          onChange={(e) =>
            e && props.setQuotationData((old) => ({ ...old, quotationBody: e }))
          }
          value={props.quotationData.quotationBody}
        />
        <ErrorMessage errorMessage={props.quotationError?.quotationBody} />
        <form className="mt-3">
          <div className="row">
            {fields.map((field) => (
              <div
                key={field.name}
                className={`${
                  field.name === "title" ? "col-xl-4" : "col-xl-2"
                } col-xl-2 col-lg-4 col-md-4 col-sm-4 col-4`}
              >
                <div className="form-group">
                  <label className="small" htmlFor={field.name}>
                    {field.label}{" "}
                  </label>
                  <input
                    type={field.type}
                    className="form-control"
                    id={field.name}
                    name={field.name}
                    onWheel={handleWheel}
                    value={
                      props.quotationData[
                        field.name as keyof TQuotationDataZod
                      ] as string
                    }
                    onChange={handleChange}
                  />
                  <ErrorMessage
                    errorMessage={
                      props.quotationError?.[
                        field.name as keyof TQuotationDataZod
                      ]
                    }
                  />
                </div>
              </div>
            ))}
            <div className="col-xl-2 col-lg-4 col-md-4  col-12">
              <div className="form-group mb-2">
                <label className="small" htmlFor="travelDate">
                  Travel Date:{" "}
                </label>
                <ReactDatePicker
                  handleDateChange={(date) =>
                    props.setQuotationData({
                      ...props.quotationData,
                      travelDate: date,
                    })
                  }
                  selected={props.quotationData.travelDate}
                  className="form-control"
                />
              </div>
            </div>
          </div>
          <div className="border container-fluid bg-light rounded my-3 py-3">
            <h6>Hotel Details</h6>
            <div className="row ">
              <div className="col-lg-9 col-md-9 col-sm-12 col-12">
                <div className="row ">
                  {props.quotationData.hotels?.map((hotel, index) => (
                    <>
                      <div className="col-lg-6 col-md-4 col-sm-4 col-12">
                        <label className="small" htmlFor={`hotelName-${index}`}>
                          Hotel Name:{" "}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id={`hotelName-${index}`}
                          name="hotelName"
                          value={hotel.hotelName}
                          onChange={(e) => handleHotelChange(index, e)}
                        />
                        <ErrorMessage
                          errorMessage={_.get(
                            props.quotationError,
                            `hotels.${0}.hotelName`
                          )}
                        />
                      </div>
                      <div className="col-lg-3 col-md-4 col-sm-4 col-12">
                        <label className="small" htmlFor={`hotelCost-${index}`}>
                          Hotel Cost:{" "}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id={`hotelCost-${index}`}
                          name="hotelCost"
                          value={hotel.hotelCost}
                          onChange={(e) => handleHotelChange(index, e)}
                        />
                        <ErrorMessage
                          errorMessage={_.get(
                            props.quotationError,
                            `hotels.${0}.hotelCost`
                          )}
                        />
                      </div>
                      <div className="col-lg-3 col-md-4 col-sm-4 col-12">
                        <label className="small">Sharing Type : </label>
                        <select
                          className="form-select object-fit-contain"
                          onChange={(e) => handleHotelChange(index, e)}
                          value={hotel.sharingType}
                          name="sharingType"
                          defaultValue={EnumSharingType.DOUBLE}
                        >
                          <option value={EnumSharingType.SINGLE}>Single</option>
                          <option value={EnumSharingType.DOUBLE} selected>
                            Double
                          </option>
                          <option value={EnumSharingType.TRIPLE}>Triple</option>
                        </select>
                        <ErrorMessage
                          errorMessage={_.get(
                            props.quotationError,
                            `hotels.${0}.hotelCost`
                          )}
                        />
                      </div>
                    </>
                  ))}
                </div>
              </div>
              <div className="col-xl-3 col-lg-3 col-md-12 col-12 align-self-end">
                <div className="d-flex justify-content-start align-items-center mt-3">
                  {props.quotationData.hotels?.length > 1 && (
                    <button
                      type="button"
                      className="btn btn-danger me-2 py-2 px-3 small rounded btn-sm"
                      onClick={(e) =>
                        removeHotel(
                          e,
                          props.quotationData,
                          props.setQuotationData
                        )
                      }
                    >
                      <i className="fa-solid fa-trash"></i>
                    </button>
                  )}
                  <button
                    className="btn btn-sm btn-outline-secondary w-100 small py-2"
                    onClick={(e) =>
                      addHotel(e, props.quotationData, props.setQuotationData)
                    }
                  >
                    + Hotel
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="form-group col-lg-4">
              <label className="small" htmlFor="markupPercentage">
                Markup Percentage:{" "}
              </label>
              <select
                className="form-control"
                id="markupPercentage"
                name="markupPercentage"
                value={props.quotationData.markupPercentage}
                onChange={handleChange}
              >
                <option value="" selected disabled>
                  Select
                </option>
                <option value={5}>5%</option>
                <option value={10}>10%</option>
                <option value={15}>15%</option>
                <option value={20}>20%</option>
              </select>
              <ErrorMessage
                errorMessage={props.quotationError?.markupPercentage}
              />
            </div>
            <div className="form-group col-lg-2">
              <label className="small">Show Breakup: </label>
              <div className="d-flex align-items-center mt-2">
                <div className="d-flex align-items-center">
                  <input
                    type="radio"
                    id="showBreakupYes"
                    name="showBreakup"
                    value="true"
                    checked={props.quotationData.showBreakup === true}
                    onChange={handleChange}
                  />
                  <label className=" ms-2" htmlFor="showBreakupYes">
                    Yes
                  </label>
                </div>
                <div className="d-flex align-items-center ms-4">
                  <input
                    type="radio"
                    id="showBreakupNo"
                    name="showBreakup"
                    value="false"
                    checked={props.quotationData.showBreakup === false}
                    onChange={handleChange}
                  />
                  <label className=" ms-2" htmlFor="showBreakupNo">
                    No
                  </label>
                </div>
              </div>
              <ErrorMessage errorMessage={props.quotationError?.showBreakup} />
            </div>
            <div className="form-group col-lg-6">
              <label className="small" htmlFor="internalRemark">
                Internal Remark:{" "}
              </label>
              <textarea
                className="form-control"
                id="internalRemark"
                name="internalRemark"
                value={props.quotationData.internalRemark}
                onChange={handleChange}
              />
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default QuotationForm;
