import { Modal } from "react-bootstrap";
import { UploadTaskPhotoProof } from "./UploadTaskPhotoProof";

export const UploadPhotosModal: React.FC<{
  taskId: number;
  show: boolean;
  onHide: () => void;
}> = (props) => {
  return (
    <Modal centered size="lg" show={props.show} onHide={props.onHide}>
      <Modal.Header closeButton>Upload Photo Proof</Modal.Header>
      <Modal.Body>
        <UploadTaskPhotoProof taskId={props.taskId} />
      </Modal.Body>
    </Modal>
  );
};
