import {
  IEnquiryFollowUp,
  TCreateOrEditEnquiry,
  TEnquiryDestination,
  TEnquiryPassenger,
  TFilterState,
  TcreateOrEditEnquiryNotes,
} from "../schemas/enquiry.schema";
import { IUserData } from "./User.interface";
import { TCity } from "../schemas/master.schema";

export interface IEnquiry
  extends Omit<TCreateOrEditEnquiry, "phoneNumber" | "phoneCode"> {
  createdByStaffId: number;
  status: EnumEnquiryStatus;
  assignedToStaffId: number | null;
  assignedToStaffDetail: IUserData;
  passengers: IEnquiryPassenger[];
  destinations: IEnquiryDestination[];
  createdByStaffDetail: IUserData;
  rejectionMessage: string | null;
  user: IUserData;
  createdAt: Date;
  updatedAt: Date;
  notes: IEnquiryNotes | null;
  enquiryFollowUps: IEnquiryFollowUp[];
  isJustificationAccepted?: boolean | null;
}

export interface IEnquiryPassenger extends TEnquiryPassenger {
  createdAt: Date;
  updatedAt: Date;
}
export interface IEnquiryNotes extends TcreateOrEditEnquiryNotes {
  createdAt: Date;
  updatedAt: Date;
}

export interface IEnquiryDestination extends TEnquiryDestination {
  city?: TCity;
  createdAt: Date;
  updatedAt: Date;
}

export enum EnumEnquiryStatus {
  All = "all",
  Untouched = "untouched",
  Rates_Requested = "rates_requested",
  Quote_Sent_Feedback_Pending = "quote_sent_feedback_pending",
  Final_Payment_Requested = "final_payment_requested",
  Pending_Booking = "pending_booking",
  Booked = "booked",
  Rejected_Got_Better_Rates = "rejected_got_better_rates",
  Rejected_Customer_Not_Interested = "rejected_customer_not_interested",
  Rejected_By_Us = "rejected_by_us",
}

export interface IGetEnquiryProps extends ISortEnquiry {
  pageNumber?: number;
  filter?: TFilterState;
}

export interface ISortEnquiry {
  orderBy?: "id" | "updatedAt" | "createdAt";
  order?: "ASC" | "DESC";
}

type EnquiryStatusOption = {
  [key: string]: string[];
};

export const enquiryStatuses: EnquiryStatusOption[] = [
  { all: [] },
  {
    active: [
      "untouched",
      "rates_requested",
      "quote_sent_feedback_pending",
      "final_payment_requested",
      "pending_booking",
      "booked",
    ],
  },
  {
    rejected: [
      "rejected_got_better_rates",
      "rejected_customer_not_interested",
      "rejected_by_us",
    ],
  },
];
