import { z } from "zod";
import { EnumTransferType, TTourOption } from "./tour.schema";
import { EnumPassengerType } from "../types/master.interface";

export const countrySchemaZod = z.object({
  id: z.number().optional(),
  countryName: z.string().min(1),
  countryCode: z.string().min(1),
  currency: z.string().min(1),
  roe: z.number(),
  transactionCost: z.number(),
  isActive: z.boolean(),
  countryImageFile: z.instanceof(File).optional(),
});

export type TCountry = z.infer<typeof countrySchemaZod> & {
  imageUrl?: string;
  seasons?: TSeason[];
};

export const seasonSchemaZod = z.object({
  id: z.number().optional(),
  name: z.string().min(1),
  countryId: z.number(),
  priority: z.number(),
  fromDate: z.date(),
  toDate: z.date(),
});

export type TSeason = z.infer<typeof seasonSchemaZod>;

export const citySchemaZod = z.object({
  id: z.number().optional(),
  name: z.string().min(1),
  countryId: z.number(),
  slug: z.string(),
  destinationGroupId: z.number(),
});

export type TCity = z.infer<typeof citySchemaZod>;

export const createOrEditTourZod = z.object({
  id: z.number().optional(),
  name: z.string(),
  countryId: z.number(),
  cityId: z.number(),
  pickupPoint: z.string(),
  dropPoint: z.string().optional().nullable(),
  reportingTime: z.string().optional().nullable(),
  isComboTour: z.boolean(),
  childAge: z.string(),
  infantAge: z.string(),
  rating: z.number().max(5).optional().nullable(),
  reviewCount: z.number().optional().nullable(),
  childCancellationPolicyDescription: z.string().optional().nullable(),
  cancellationPolicyDescription: z.string(),
  faqDetails: z.string().optional().nullable(),
  usefullInformation: z.string().optional().nullable(),
  importantInformation: z.string().optional().nullable(),
  whatsInThisTour: z.string().optional().nullable(),
  itenararyDescription: z.string().optional().nullable(),
  termsAndConditions: z.string().optional().nullable(),
  toursAdvantage: z.string().optional().nullable(),
  tourInclusion: z.string().optional().nullable(),
  tourDescription: z.string(),
  tourShortDescription: z.string(),
  onlyChild: z.boolean().optional().nullable(),
  startTime: z.string().optional().nullable(),
  slug: z.string(),
});

export const tourSchemaZod = createOrEditTourZod.extend({
  rating: z.number(),
  reviewCount: z.number(),
});

export type TCreateOrEditTour = z.infer<typeof createOrEditTourZod>;
export type TTour = z.infer<typeof tourSchemaZod> & {
  tourOptions?: TTourOption[];
};

export const destinationGroupSchema = z.object({
  id: z.number().optional(),
  title: z.string(),
  remark: z.string(),
  termsAndConditions: z.string(),
  cityIds: z.array(z.number()),
});

export type TDestinationGroupSchema = z.infer<typeof destinationGroupSchema>;
