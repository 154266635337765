import React from "react";
import _ from "lodash";
import paginationButtonStyles from "../sass/components/_paginationButtons.module.scss";

type TPaginationButtonProps = {
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  currentPage: number;
  totalPages: number;
  onPageChange: (pageNumber: number) => void;
};
export const PaginationButtons: React.FC<TPaginationButtonProps> = (props) => {
  return (
    <div
      className={` my-5 ${paginationButtonStyles.paginationButtonContainer}`}
    >
      <div className="row align-items-center justify-content-center">
        <div className="col-lg-2 col-md-2 col-sm-4 col-4">
          <button
            className="btn btn-outline-primary btn-sm w-100 py-2"
            onClick={() => {
              props.onPageChange(props.currentPage - 1);
            }}
            disabled={!props.hasPreviousPage}
          >
            <i className="fa-solid fa-chevron-left"></i> Previous
          </button>
        </div>
        <div className="col-lg-2 col-md-2 col-sm-4 col-4">
          <div className="px-0">
            {/* <span className="me-1 small">Showing</span> */}
            <select
              className="form-control text-center"
              onChange={(e) => {
                props.onPageChange(parseInt(e.target.value));
              }}
              value={props.currentPage}
            >
              {props.totalPages > 0 &&
                _.times(props.totalPages).map((pNumber) => {
                  return (
                    <option key={pNumber} value={pNumber + 1}>
                      {pNumber + 1}
                    </option>
                  );
                })}
            </select>
            {/* <span className="ms-1 small">of {props.totalPages} pages</span> */}
          </div>
        </div>
        <div className="col-lg-2 col-md-2 col-sm-4 col-4">
          <button
            className="btn btn-outline-primary btn-sm w-100 py-2"
            onClick={() => {
              props.onPageChange(props.currentPage + 1);
            }}
            disabled={!props.hasNextPage}
          >
            Next <i className="fa-solid fa-chevron-right"></i>
          </button>
        </div>
      </div>
    </div>
  );
};
