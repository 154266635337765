import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { toast } from "react-toastify";

export const useRecaptcha = () => {
  const { executeRecaptcha } = useGoogleReCaptcha();

  const getRecaptchaToken = async (): Promise<string | null> => {
    if (!executeRecaptcha) {
      toast.error("reCAPTCHA not initialized");
      return null;
    }

    try {
      const token = await executeRecaptcha();
      return token;
    } catch (error) {
      console.error("Error executing reCAPTCHA", error);
      toast.error("Error executing reCAPTCHA");
      return null;
    }
  };

  return { getRecaptchaToken };
};
