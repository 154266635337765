import React, { useEffect, useState } from "react";
import CitiesListItemStyle from "./CitiesListStyle.module.scss";
import { activityAxios } from "../../../axios/activityAxios";
import {
  TCity,
  TCountry,
} from "../../../activitiescommonfnb/schemas/master.schema";
import _ from "lodash";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import CreateOrEditCity, { ISaveOrEditCity } from "./CreateOrEditCity";

const CitiesList: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [loadingCities, setLoadingCities] = useState(false);
  const [countries, setCountries] = useState<TCountry[]>([]);
  const [selectedCountry, setSelectedCountry] = useState<{
    countryName: string | undefined;
    countryId: number;
  }>();
  const [showAddNewCountry, setShowAddNewCountry] = useState(false);
  const [editCity, setEditCity] = useState<number | undefined>();
  const [citesOfSelectedCountry, setCitesOfSelectedCountry] =
    useState<TCity[]>();
  const [newCity, setNewCity] = useState<ISaveOrEditCity>({
    name: "",
    slug: "",
    countryId: undefined,
  });
  useEffect(() => {
    getCountries();
  }, []);

  const getCountries = async () => {
    setLoading(true);
    const res = await activityAxios.get("/master/getCountries", {});

    if (res?.data?.success) {
      setCountries(res?.data.result);
      const initialSelectCountry: TCountry = res?.data.result[0];

      if (initialSelectCountry?.id) {
        setSelectedCountry({
          countryId: initialSelectCountry?.id,
          countryName: initialSelectCountry.countryName,
        });
        setNewCity((old) => {
          return { ...old, countryId: initialSelectCountry.id };
        });
        getCities(initialSelectCountry.id);
        setSelectedCountry({
          countryId: initialSelectCountry.id,
          countryName: initialSelectCountry.countryName,
        });
      }
    }
    setLoading(false);
  };

  const getCities = async (countryId: number) => {
    setLoadingCities(true);
    const citesOfCountryRes = await activityAxios.post("/master/getCities", {
      countryId,
    });
    if (citesOfCountryRes?.data?.success) {
      setCitesOfSelectedCountry(citesOfCountryRes?.data?.result);
    }
    setLoadingCities(false);
  };

  return (
    <div>
      <div className={CitiesListItemStyle.citiesList}>
        {/* <h3>Cities</h3> */}

        {!loading && countries.length > 0 && (
          <Tab.Container
            id="left-tabs-example"
            defaultActiveKey={countries[0]?.id}
            onSelect={(key) => {
              if (!key) {
                return;
              }

              const countryId = parseInt(key);

              let countryDetails = _.find(countries, {
                id: countryId,
              });
              setSelectedCountry({
                countryId: countryId,
                countryName: countryDetails?.countryName,
              });
              if (countryId)
                setNewCity((old) => {
                  return { ...old, countryId: countryId };
                });
              getCities(countryId);
            }}
          >
            <div className="row">
              <div className={CitiesListItemStyle.citiesHeading}>
                Choose Country <i className="fa-solid fa-angle-down ms-2"></i>
              </div>
              <div className={`col-lg-3 ps-0 `}>
                <div className={CitiesListItemStyle.chooseCountryTab}>
                  <Nav variant="pills" className="flex-column">
                    {countries.map((countryName) => {
                      return (
                        <Nav.Item key={countryName.id}>
                          <Nav.Link eventKey={countryName.id}>
                            {countryName.countryName}
                          </Nav.Link>
                        </Nav.Item>
                      );
                    })}
                  </Nav>
                </div>
              </div>
              <div className="col-lg-9">
                <Tab.Content>
                  {countries.map((country) => {
                    return (
                      <Tab.Pane eventKey={country.id} key={country.id}>
                        {selectedCountry && citesOfSelectedCountry && (
                          <div
                            className={`${CitiesListItemStyle.CityListItem}`}
                          >
                            <div
                              className={`container-fluid ${CitiesListItemStyle.cityItems}`}
                            >
                              <div className="mb-4">
                                <span className="text-muted">
                                  {country.countryName}
                                </span>
                                <span>
                                  <i className="fa-solid fa-angle-right mx-2 text-muted"></i>
                                </span>
                                <span className="fs-6">Cities</span>
                              </div>
                              <div className="row ">
                                {!loadingCities ? (
                                  citesOfSelectedCountry.map((city) => {
                                    return (
                                      <div className="col-lg-12" key={city.id}>
                                        {editCity === city.id ? (
                                          <CreateOrEditCity
                                            newCity={newCity}
                                            setShowAddNewCountry={
                                              setShowAddNewCountry
                                            }
                                            selectedCountry={selectedCountry}
                                            getCities={getCities}
                                            setEditCity={setEditCity}
                                          />
                                        ) : (
                                          <div
                                            className={`${CitiesListItemStyle.city} w-100`}
                                          >
                                            <span>
                                              {city.name} ({city.slug}){" "}
                                            </span>
                                            <button
                                              className="btn btn-primary btn-sm"
                                              onClick={(e) => {
                                                setEditCity(city.id);
                                                setNewCity(city);
                                                setShowAddNewCountry(false);
                                              }}
                                            >
                                              <i className="fa-solid fa-pen"></i>
                                            </button>{" "}
                                          </div>
                                        )}
                                      </div>
                                    );
                                  })
                                ) : (
                                  <p className="text-center">Loading...</p>
                                )}
                                {showAddNewCountry && (
                                  <div className="col-lg-12">
                                    <CreateOrEditCity
                                      newCity={newCity}
                                      setShowAddNewCountry={
                                        setShowAddNewCountry
                                      }
                                      selectedCountry={selectedCountry}
                                      getCities={getCities}
                                      setEditCity={setEditCity}
                                    />
                                  </div>
                                )}
                                <div
                                  onClick={() => {
                                    setEditCity(undefined);
                                    setNewCity({
                                      name: "",
                                      slug: "",
                                      countryId: selectedCountry.countryId,
                                    });
                                    setShowAddNewCountry(true);
                                  }}
                                  className={`btn btn-outline-secondary w-100 ${CitiesListItemStyle.addCityButton}`}
                                >
                                  + Add{" "}
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </Tab.Pane>
                    );
                  })}
                </Tab.Content>
              </div>
            </div>
          </Tab.Container>
        )}
      </div>
    </div>
  );
};

export default CitiesList;
