import { z } from "zod";
import { EnumTourOptionTransferType } from "../types/tour.interface";
import { ZodHelperService } from "./zodHelper.service";

export enum EnumSessionType {
  BOOKING = "booking",
  CART = "cart",
}

export enum EnumPaxType {
  ADULT = "adult",
  CHILD = "child",
  INFANT = "infant",
}

export enum EnumPaxTitle {
  MR = "Mr",
  MRS = "Mrs",
  MISS = "Miss",
  MASTER = "Master",
}

z.setErrorMap(ZodHelperService.errorMap);

const zodNonEmptyString = (name: string) =>
  z.string().trim().min(1, `${name} is required`);

const pickUpCoordinatesZod = z.object({
  x: z.number(),
  y: z.number(),
});

export const createBookingTourOptionZod = z.object({
  tourOptionId: z.number(),
  transferId: z.number(),
  uniqueResultId: z.string(),
  pickUpCoordinates: pickUpCoordinatesZod,
  pickupLocation: z.string(),
  tourId: z.number(),
  timeSlotId: z.string().optional().nullable(),
  transferType: z.nativeEnum(EnumTourOptionTransferType),
  travelDate: z.string(),
  adultCount: z.number(),
  childCount: z.number(),
  infantCount: z.number(),
  adultPrice: z.number(),
  childPrice: z.number(),
  infantPrice: z.number(),
});

export const cartItemZod = z.object({
  tourId: z.number(),
  isComboTour: z.boolean(),
  tourOptions: z.array(createBookingTourOptionZod).min(1),
});

export const createSessionZod = z.object({
  sessionSlug: z.string().optional(),
  from: z.string(),
  to: z.string(),
  adultCount: z.number(),
  infantCount: z.number(),
  childCount: z.number(),
  cartItems: z.array(cartItemZod).min(1),
});

const singleBookingPaxDetailZod = z.object({
  paxIndex: z.number(),
  title: z.nativeEnum(EnumPaxTitle),
  firstName: z.string().trim().min(3),
  lastName: z.string().trim().min(3),
  email: zodNonEmptyString("Email").email(),
  phoneCode: zodNonEmptyString("Phone code"),
  phoneNumber: zodNonEmptyString("Phone number"),
  nationality: zodNonEmptyString("Nationality"),
  leadPassenger: z.boolean(),
  paxType: z.nativeEnum(EnumPaxType),
});

export const setBookingPassengerZod = z.array(singleBookingPaxDetailZod);
export type TPickUpCoordinates = z.infer<typeof pickUpCoordinatesZod>;
export type TCreateCartItem = z.infer<typeof cartItemZod>;
export type TCreateSession = z.infer<typeof createSessionZod>;
export type TSetBookingPassenger = z.infer<typeof singleBookingPaxDetailZod>;

export type TBookingTourOptionPaxMapping = Array<{
  tourOptionId: number;
  paxIndex: number;
}>;

export type TCreateBookingTourOption = z.infer<
  typeof createBookingTourOptionZod
>;
