import { useEffect, useState } from "react";
import { useAppDispatch } from "../store";
import { setAuthData } from "../slice/auth.slice";
import { getAuthToken } from "../functions/commonFx";
import { activityAxios } from "../axios/activityAxios";
import { IJSONResponse } from "../activitiescommonfnb/types/common.interface";
import { IUserData } from "../activitiescommonfnb/types/User.interface";

export const useInitApp = () => {
  const dispatch = useAppDispatch();
  const [loadingData, setLoadingData] = useState(true);

  const getUserData = async () => {
    const authToken = getAuthToken();
    let userData: IUserData | null = null;
    if (authToken) {
      const apiRes = await activityAxios.post<IJSONResponse<IUserData>>(
        "/auth/getLoggedInUserData"
      );

      userData = apiRes.data.result;
    }
    dispatch(setAuthData(userData));

    setLoadingData(false);
  };

  useEffect(() => {
    getUserData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return loadingData;
};
