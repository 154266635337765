export enum EnumTourOptionTransferType {
  WITHOUT_TRANSFER = "WITHOUT_TRANSFER",
  PRIVATE = "PRIVATE",
  SHARING = "SHARING",
}

export interface ITour {
  id: number;
  name: string;
  countryId: number;
  cityId: number;
  slug: string;
  pickupPoint: string;
  dropPoint: string | null;
  reportingTime: string | null;
  childAge: string;
  infantAge: string;
  childCancellationPolicyDescription: string | null;
  cancellationPolicyDescription: string;
  faqDetails: string | null;
  usefullInformation: string | null;
  importantInformation: string | null;
  whatsInThisTour: string | null;
  itenararyDescription: string | null;
  termsAndConditions: string | null;
  toursAdvantage: string | null;
  tourInclusion: string | null;
  tourDescription: string;
  tourShortDescription: string;
  onlyChild: boolean | null;
  startTime: number | null;
  isComboTour: boolean;
  rating: number;
  reviewCount: number;
  tourImages?: ITourImage[];
  tourOptions?: ITourOption[];
}

export interface ITourOption {
  id: number;
  tourOptionName: string;
  tourOptionDescription: string;
  tourId: number;
  childAge: string;
  infantAge: string;
  cancellationPolicyDescription: string;
  childPolicyDescription: string;
  durationInMinutes: number;
  timezone: string;
  hasTourGuide: boolean;
  buffer: number;
  startTime: number;
  hasSlot: boolean;
  isHourly: boolean;
  monday: boolean;
  tuesday: boolean;
  wednesday: boolean;
  thrusday: boolean;
  friday: boolean;
  saturday: boolean;
  sunday: boolean;
}

export interface ITourOptionTransfer {
  id: number;
  tourOptionId: number;
  transferType: EnumTourOptionTransferType;
  transferTime: string;
  transferDurationInMinutes: number;
  disableChild: boolean;
  disableInfant: boolean;
  twoWayTransfer: boolean;
}

export interface ITourImage {
  id: number;
  tourId: number;
  fileUrl: string;
  createdAt: Date;
  updatedAt: Date;
}
