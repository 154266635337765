import {
  ITourOptionTransferWithPrice,
  ITourOptionWithTransfer,
} from "../activitiescommonfnb/types/activitySearch.interface";
import { IBookingTourOption } from "../activitiescommonfnb/types/booking.interface";
import { IJSONResponse } from "../activitiescommonfnb/types/common.interface";
import { ITourOption } from "../activitiescommonfnb/types/tour.interface";
import { activityAxios } from "../axios/activityAxios";

type TGetTourOptionWithTranferProps = {
  tourId: number;
  citySlug: string;
  travelDate: string;
  adultCount: number;
  childCount: number;
  infantCount: number;
};

type TGetSingleTourOptionWithTranferProps = TGetTourOptionWithTranferProps & {
  tourOptionId: number;
};

type TTranformTourOptionToBookingTourOptionProps = {
  tourOption: ITourOption;
  transfer: ITourOptionTransferWithPrice;
  timeSlotId: string | null;
  travelDate: string;
  adultCount: number;
  childCount: number;
  infantCount: number;
};

export class TourOptionHelper {
  static tranformTourOptionToBookingTourOption = (
    props: TTranformTourOptionToBookingTourOptionProps
  ): IBookingTourOption => {
    return {
      tourId: props.tourOption.tourId,
      tourOptionId: props.tourOption.id,
      tourOption: props.tourOption,
      adultCount: props.adultCount,
      childCount: props.childCount,
      infantCount: props.infantCount,
      adultPrice: props.transfer.adultPrice,
      childPrice: props.transfer.childPrice,
      infantPrice: props.transfer.infantPrice,
      durationInMinutes: props.tourOption.durationInMinutes,
      startTime: props.tourOption.startTime,
      pickUpCoordinates: {
        x: 0,
        y: 0,
      },
      pickupLocation: "",
      buffer: props.tourOption.buffer,
      timeSlotId: props.timeSlotId,
      transferId: props.transfer.id,
      transferType: props.transfer.transferType,
      travelDate: props.travelDate,
      uniqueResultId: props.transfer.uniqueResultId,
    };
  };

  static getSingleTourOptionWithTransfer = async (
    props: TGetSingleTourOptionWithTranferProps
  ) => {
    const singleTourOptionPriceRes = await activityAxios.post<
      IJSONResponse<ITourOptionWithTransfer>
    >("/master/tour/getSingleTourOptionPrice", {
      tourId: props.tourId,
      tourOptionId: props.tourOptionId,
      citySlug: props.citySlug,
      travelDate: props.travelDate,
      noOfAdult: props.adultCount,
      noOfChild: props.childCount,
      noOfInfant: props.infantCount,
    });

    return singleTourOptionPriceRes.data.result;
  };
}
