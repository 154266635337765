import { useForm } from "react-hook-form";
import { Form, Button } from "react-bootstrap";
import { EnumTransferType } from "../../activitiescommonfnb/schemas/tour.schema";
import { IManagePriceForm } from "../../activitiescommonfnb/types/master.interface";
import { useEffect } from "react";

const ManagePriceForm: React.FC<{
  getTourPrice: (data: IManagePriceForm) => void;
}> = (props) => {
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    reset,
    formState: { errors },
  } = useForm<IManagePriceForm>({
    defaultValues: {
      transferType: EnumTransferType.PRIVATE_TRANSFER,
    },
  });

  const watcheTransferType = watch("transferType");

  useEffect(() => {
    setValue("adultPrice", undefined);
    setValue("childPrice", undefined);
    setValue("pricePerPax", undefined);
    setValue("fromPax", undefined);
    setValue("toPax", undefined);
  }, [watcheTransferType]);

  const onSubmit = (data: IManagePriceForm) => props.getTourPrice(data);

  return (
    <Form onSubmit={handleSubmit(onSubmit)} className="border p-3 my-4 rounded">
      <Form.Group className="d-flex align-items-center">
        <label className="text-nowrap me-1">Transfer Type : </label>
        <Form.Control
          as="select"
          {...register("transferType", {
            required: "Please select a transfer type",
          })}
        >
          <option value={EnumTransferType.SHARING_TRANSFER}>Sharing</option>
          <option value={EnumTransferType.PRIVATE_TRANSFER} selected>
            Private
          </option>
          <option value={EnumTransferType.WITHOUT_TRANSFER}>
            Without Transfer
          </option>
        </Form.Control>
      </Form.Group>

      {watcheTransferType === EnumTransferType.PRIVATE_TRANSFER && (
        <Form.Group className="d-flex mt-4 align-items-center">
          <div className="text-nowrap me-1">Price for :</div>
          <Form.Control
            type="number"
            {...register("fromPax", {
              required: "Passenger is required",
              valueAsNumber: true,
              minLength: {
                value: 1,
                message: "",
              },
            })}
          />

          <label className="text-nowrap mx-1"> passenger </label>
          <label className="text-nowrap mx-1"> to : </label>
          <Form.Control
            type="string"
            {...register("toPax", {
              required: "Passenger is required",
              valueAsNumber: true,
              minLength: {
                value: 1,
                message: "",
              },
            })}
          />
          <label className="ms-1">passenger </label>
        </Form.Group>
      )}

      {watcheTransferType === EnumTransferType.WITHOUT_TRANSFER && (
        <Form.Group className="d-flex my-4 align-items-center">
          <div className="text-nowrap me-1">Price for adult:</div>
          <Form.Control
            type="number"
            {...register("adultPrice", {
              required: "Passenger is required",
              valueAsNumber: true,
              minLength: {
                value: 1,
                message: "",
              },
            })}
          />

          <label className="text-nowrap mx-1"> Price for Child : </label>
          <Form.Control
            type="number"
            {...register("childPrice", {
              required: "Passenger is required",
              valueAsNumber: true,
              minLength: {
                value: 1,
                message: "",
              },
            })}
          />
        </Form.Group>
      )}

      {watcheTransferType !== EnumTransferType.WITHOUT_TRANSFER && (
        <div>
          <Form.Group className="d-flex col-6  align-items-center my-4 w-100">
            <label className="text-nowrap me-1">Price per Pax :</label>
            <Form.Control
              type="number"
              {...register("pricePerPax", {
                required: "Price per pax is required",
                valueAsNumber: true,
              })}
            />
          </Form.Group>
        </div>
      )}
      <div className="d-flex justify-content-center pb-2">
        <Button variant="primary" type="submit">
          Updated Price
        </Button>
      </div>
    </Form>
  );
};

export default ManagePriceForm;
