import { useNavigate } from "react-router";

export const SupplierDashboard: React.FC<{}> = () => {
  const navigate = useNavigate();
  return (
    <div className="container">
      <h4>Supplier Dashboard</h4>
    </div>
  );
};
