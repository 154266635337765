import { z } from "zod";

export const SettingsSchema = z.object({
  domesticQuotationTandC: z.string(),
  internationalQuotationTandC: z.string(),
  b2bMarkup: z.number(),
  b2cMarkup: z.number(),
});

export type TSettingsZod = z.infer<typeof SettingsSchema>;
