import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import AppCkEditor from "../../commonUi/AppCkEditor";
import { IEnquiryNotes } from "../../activitiescommonfnb/types/enquiry.interface";
import { LoadingButton } from "../../commonUi/LoadingButton";

export const CreateOrEditEnquiryNotes: React.FC<{
  notesDetail: IEnquiryNotes | null;
  show: boolean;
  onClose: () => void;
  onSave: (enquiryNotes: string) => Promise<void>;
}> = (props) => {
  const [enquiryNotes, setEnquiryNotes] = useState<string | null>(
    props.notesDetail?.message || ""
  );
  const [createNotesLoading, setCreateNotesLoading] = useState(false);

  return (
    <div>
      <Modal
        show={props.show}
        onHide={props.onClose}
        animation={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="h6">Notes</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AppCkEditor
            onChange={(e) => {
              setEnquiryNotes(e || null);
            }}
            value={enquiryNotes}
            className="small"
            label="Add Notes"
          />
        </Modal.Body>
        <Modal.Footer>
          <LoadingButton
            btnClass="btn btn-sm btn-primary px-5"
            loading={createNotesLoading}
            onClick={async () => {
              setCreateNotesLoading(true);
              if (enquiryNotes) {
                await props.onSave(enquiryNotes);
              }
              setCreateNotesLoading(false);
            }}
            minWidth="20px"
            disabled={!enquiryNotes?.length}
          >
            {props?.notesDetail?.id ? "Update" : "Save"}
          </LoadingButton>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
