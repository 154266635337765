import {
  ICartItem,
  ISessionWithCartDetail,
} from "../activitiescommonfnb/types/booking.interface";

export const getTotalAmount = (cartItems: ICartItem[] | undefined) => {
  let totalAmount = 0;
  cartItems
    ?.flatMap((carItem) => carItem.tourOptions)
    .forEach((tourOption) => {
      totalAmount +=
        tourOption.adultCount * tourOption.adultPrice +
        tourOption.childCount * tourOption.childPrice +
        tourOption.infantCount * tourOption.infantPrice;
    });
  return totalAmount;
};

export const getPaxCount = (
  activeSessionData: ISessionWithCartDetail | null
): { adultCount: number; infantCount: number; childCount: number } => {
  const adultCount =
    activeSessionData?.cartItems[0]?.tourOptions[0]?.adultCount || 0;
  const infantCount =
    activeSessionData?.cartItems[0]?.tourOptions[0]?.infantCount || 0;
  const childCount =
    activeSessionData?.cartItems[0]?.tourOptions[0]?.childCount || 0;
  return { adultCount, infantCount, childCount };
};

export const getTotalPrice = (
  activeSessionDetails: ISessionWithCartDetail | null
) => {
  let totalAmount = 0;

  activeSessionDetails?.cartItems.forEach((cartItem) => {
    cartItem.tourOptions.forEach((singleTourOption) => {
      let tourOptionPrice =
        singleTourOption.adultPrice * singleTourOption.adultCount +
        singleTourOption.childPrice * singleTourOption.childCount +
        singleTourOption.infantPrice * singleTourOption.infantCount;
      totalAmount += tourOptionPrice;
    });
  });

  return totalAmount;
};
