import { CKEditor } from "@ckeditor/ckeditor5-react";
import {
  BlockQuote,
  Bold,
  ClassicEditor,
  CodeBlock,
  Essentials,
  FontSize,
  Heading,
  Italic,
  Link,
  List,
  Paragraph,
  TodoList,
  Underline,
  Table,
  TableToolbar,
} from "ckeditor5";

import "ckeditor5/ckeditor5.css";

const AppCkEditor: React.FC<{
  label?: string;
  value?: string | null;
  className?: string;
  isRequired?: boolean;
  placeHolder?: string;
  onChange: (value?: string) => void;
}> = (props) => {
  return (
    <div className={props.className}>
      <label htmlFor="">
        {props.label}
        {props.isRequired && <span className="text-danger"> *</span>}
      </label>
      <CKEditor
        editor={ClassicEditor}
        data={props.value || ""}
        onChange={(event, editor) => {
          const data = editor.getData();
          props.onChange(data || undefined);
        }}
        config={{
          plugins: [
            Essentials,
            Bold,
            Italic,
            Paragraph,
            Underline,
            List,
            Link,
            TodoList,
            FontSize,
            CodeBlock,
            BlockQuote,
            Heading,
            Table,
            TableToolbar,
          ],
          toolbar: [
            "undo",
            "redo",
            "|",
            "heading",
            "bold",
            "fontsize",
            "italic",
            "underline",
            "|",
            "numberedList",
            "bulletedList",
            "|",
            "link",
            "todoList",
            "codeBlock",
            "blockQuote",
            "insertTable",
            "tableColumn", 
            "tableRow",
            "mergeTableCells",
          ],
          placeholder: props.placeHolder,
        }}
      />
    </div>
  );
};
export default AppCkEditor;
