import { z } from "zod";
import { ZodHelperService } from "./zodHelper.service";

export const createQuotationSchema = z.object({
  leadPassengerName: z
    .string()
    .min(1, "Lead passenger name cannot be empty")
    .trim(),
  title: z.string().min(1, "Title cannot be empty").trim(),
  travelDate: ZodHelperService.getZodDate(),
});

export type TCreateQuotation = z.infer<typeof createQuotationSchema>;
