import { Carousel } from "react-bootstrap";
import { ActivitySearch } from "../../components/common/Search/ActivitySearch";
import activitySearchPageStyle from "./ActivitySearchPageStyle.module.scss";
import slideOne from "../../assets/images/frontPage/frontPageSlide/slideOne.jpeg";
import slideTwo from "../../assets/images/frontPage/frontPageSlide/slideTwo.jpeg";
import slideThree from "../../assets/images/frontPage/frontPageSlide/slideThree.jpeg";
import slideFour from "../../assets/images/frontPage/frontPageSlide/slideFour.jpeg";
import slideFive from "../../assets/images/frontPage/frontPageSlide/slideFive.jpeg";
import slideSix from "../../assets/images/frontPage/frontPageSlide/slideSix.jpeg";
import dubaiDestination from "../../assets/images/frontPage/destination/dubai.jpeg";
import thailandDestination from "../../assets/images/frontPage/destination/thailand.jpeg";
import abudhabiDestination from "../../assets/images/frontPage/destination/abuDhabi.jpeg";
import kualalampurDestination from "../../assets/images/frontPage/destination/kualalampur.jpeg";
import bakuDestination from "../../assets/images/frontPage/destination/baku.jpeg";
import singaporeDestination from "../../assets/images/frontPage/destination/singapore.jpeg";
import why1 from "../../assets/images/frontPage/why/why1.png";
import why2 from "../../assets/images/frontPage/why/why2.png";
import why3 from "../../assets/images/frontPage/why/why3.png";
import why4 from "../../assets/images/frontPage/why/why4.png";
import marquee1 from "../../assets/images/frontPage/partnerMarqueeLogo/marquee1.jpg";
import marquee2 from "../../assets/images/frontPage/partnerMarqueeLogo/marquee2.png";
import marquee3 from "../../assets/images/frontPage/partnerMarqueeLogo/marquee3.png";
import marquee4 from "../../assets/images/frontPage/partnerMarqueeLogo/marquee4.png";
import marquee5 from "../../assets/images/frontPage/partnerMarqueeLogo/marquee5.png";
import marquee6 from "../../assets/images/frontPage/partnerMarqueeLogo/marquee6.png";
import marquee7 from "../../assets/images/frontPage/partnerMarqueeLogo/marquee7.png";
import marquee8 from "../../assets/images/frontPage/partnerMarqueeLogo/marquee8.png";
import marquee9 from "../../assets/images/frontPage/partnerMarqueeLogo/marquee9.png";
import marquee10 from "../../assets/images/frontPage/partnerMarqueeLogo/marquee10.png";
import marquee11 from "../../assets/images/frontPage/partnerMarqueeLogo/marquee11.png";
import marquee12 from "../../assets/images/frontPage/partnerMarqueeLogo/marquee12.svg";
import marquee13 from "../../assets/images/frontPage/partnerMarqueeLogo/marquee13.png";

import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useGSAP } from "@gsap/react";
import FrontPageActivityCard from "./FrontPageActivityCard";

gsap.registerPlugin(useGSAP, ScrollTrigger);

export const activityData = [
  {
    image:
      "https://www.dubaidesertsafaris.com/wp-content/uploads/2024/04/Untitled-design-1.png",
    title: "Desert Safari",
    price: "$465 / Pax",
    days: "5 Nights 6 Days",
    details:
      "Once registered login credentials will be sent to the registered email .",
  },
  {
    image:
      "https://media1.thrillophilia.com/filestore/u8rl2xpamrw2v21p7b60e4loqhxe_1634798518_dubai_sky.png?w=400&dpr=2",
    title: "Sky Diving",
    price: "$465 / Pax",
    days: "5 Nights 6 Days",
    details:
      "Once registered login credentials will be sent to the registered email .",
  },
  {
    image:
      "https://media.tacdn.com/media/attractions-splice-spp-720x480/12/50/b9/9d.jpg",
    title: "Burj Khalifa",
    price: "$465 / Pax",
    days: "5 Nights 6 Days",
    details:
      "Once registered login credentials will be sent to the registered email .",
  },
  {
    image:
      "https://www.visitdubai.com/-/media/images/leisure/campaigns/dubai-presents/itineraries/spy/spy-media-museum-of-the-future.jpg",
    title: "Museum of the Future",
    price: "$465 / Pax",
    days: "5 Nights 6 Days",
    details:
      "Once registered login credentials will be sent to the registered email .",
  },
];

const ActivitySearchPage: React.FC = () => {
  useGSAP(() => {
    gsap.from(".cityItem", {
      y: 100,
      opacity: 0,
      stagger: 0.2,
      scrollTrigger: {
        trigger: ".cityItem",
        // markers: true,
        start: "30% 90%",
        end: "80% 70%",
        // scrub: 0.2,
      },
    });
  });

  const frontSlidesImages = [
    slideOne,
    slideTwo,
    slideThree,
    slideFour,
    slideFive,
    slideSix,
  ];

  const destinationCities = [
    { image: dubaiDestination, name: "Dubai" },
    { image: abudhabiDestination, name: "Abu Dhabi" },
    { image: singaporeDestination, name: "Singapore" },
    { image: thailandDestination, name: "Thailand" },
    { image: bakuDestination, name: "Baku" },
    { image: kualalampurDestination, name: "Kuala Lampur" },
  ];

  const marqueeLogo = [
    marquee1,
    marquee2,
    marquee3,
    marquee4,
    marquee5,
    marquee6,
    marquee7,
    marquee8,
    marquee9,
    marquee10,
    marquee11,
    marquee12,
    marquee13,
  ];

  return (
    <div className={activitySearchPageStyle.activitySearchPage}>
      <div className={activitySearchPageStyle.searchPageCarousel}>
        <div>
          <Carousel fade interval={6000} controls={false} indicators={false}>
            {frontSlidesImages.map((slideImageItem) => {
              return (
                <Carousel.Item>
                  <img
                    className={`img-fluid ${activitySearchPageStyle.frontPageCarouselImage}`}
                    src={slideImageItem}
                    alt="package kart"
                  />
                </Carousel.Item>
              );
            })}
          </Carousel>
        </div>
        {/* <div className={activitySearchPageStyle.activityFrontPageFloatBackground}>
        <div className={activitySearchPageStyle.bgImage}></div>
      </div> */}
        <div className={activitySearchPageStyle.tagLine}>
          <div className="container">
            <div className={activitySearchPageStyle.typewriter}>
              <h1>
                Journey Beyond <span className="me-3">Imagination </span>
              </h1>
            </div>
            <h6>
              Dive into the heart of adventure as we take you on a mesmerizing
              exploration <br /> of the world's most captivating destinations.
            </h6>
          </div>
          <div className={activitySearchPageStyle.formContainer}>
            <div className="container">
              <div
                className={`${activitySearchPageStyle.activitySearchForm} activitySearchForm`}
              >
                <ActivitySearch isDisabled={true} />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* why us */}
      <section className={activitySearchPageStyle.whyUs}>
        {" "}
        <div className="container">
          <div className="row">
            <div className="col-lg-3">
              <div className={activitySearchPageStyle.whyUsItem}>
                <div>
                  <img
                    className={`img-fluid ${activitySearchPageStyle.whyImg}`}
                    src={why1}
                    alt="Enjoy culture your way"
                  />
                </div>
                <div className="">
                  <h6 className="fw-bold">Enjoy culture your way</h6>
                  <p className="small">
                    The best experiences at museums and attractions worldwide .
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div className={activitySearchPageStyle.whyUsItem}>
                <div>
                  <img
                    className={`img-fluid ${activitySearchPageStyle.whyImg}`}
                    src={why2}
                    alt="Discover the possibilities"
                  />
                </div>
                <div className="">
                  <h6 className="fw-bold">Discover the possibilities</h6>
                  <p className="small">
                    With nearly half a million attractions, hotels & more,
                    you're sure to find joy.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div className={activitySearchPageStyle.whyUsItem}>
                <div>
                  <img
                    className={`img-fluid ${activitySearchPageStyle.whyImg}`}
                    src={why3}
                    alt="Book with confidence"
                  />
                </div>
                <div className="">
                  <h6 className="fw-bold">Book with confidence</h6>
                  <p className="small">
                    Easy booking and skip-the-line entry on your phone .
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div className={activitySearchPageStyle.whyUsItem}>
                <div>
                  <img
                    className={`img-fluid ${activitySearchPageStyle.whyImg}`}
                    src={why4}
                    alt="Enjoy deals & delights
"
                  />
                </div>
                <div className="">
                  <h6 className="fw-bold">Enjoy deals & delights</h6>
                  <p className="small">Quality activities with Great prices.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* top cities */}
      <section className={activitySearchPageStyle.citySection}>
        <div className="container">
          <div className={activitySearchPageStyle.heading}>
            <h5 className="text-muted">
              Explore{" "}
              <span className="text-primary fw-bold">
                Popular Destinations{" "}
              </span>
            </h5>
            {/* <span className="text-muted small">Popular Places to Visit</span> */}
          </div>
          <div className={activitySearchPageStyle.cities}>
            <div className="row justify-content-center">
              {destinationCities.map((destination) => {
                return (
                  <div className="col-lg-2 col-md-4 col-sm-6 col-6">
                    <div
                      className={`${activitySearchPageStyle.cityItem} cityItem`}
                    >
                      <img
                        className={`img-fluid ${activitySearchPageStyle.cityImage}`}
                        src={destination.image}
                        alt="Top 10 Popular Destination"
                      />
                      <div className={activitySearchPageStyle.cityName}>
                        <h6>{destination.name}</h6>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </section>

      {/* popular Packages */}
      <section
        className={`bg-light py-5  ${activitySearchPageStyle.mostSellingPackage}`}
      >
        <div className="container py-3">
          <div className={`mb-3 ${activitySearchPageStyle.heading}`}>
            <h5 className="text-nuted">
              Most Selling{" "}
              <span className="text-primary fw-bold"> Package </span>
            </h5>
          </div>
          <div className="row">
            <div className="col-lg-4">
              <div className={activitySearchPageStyle.packageItem}>
                <img
                  src={slideOne}
                  className="img-fluid"
                  alt="things to do in dubai"
                />
                <div className={activitySearchPageStyle.content}>
                  <h6 className="text-primary">Dubai Super Saver </h6>
                  <p className="small">
                    Once registered login credentials will be sent to the
                    registered email .
                  </p>
                  <p className="text-muted small fw-bold mt-3">
                    Enquire Now
                    <i className="fa-solid fa-arrow-right-long ms-2"></i>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className={activitySearchPageStyle.packageItem}>
                <img
                  src={slideThree}
                  className="img-fluid"
                  alt="things to do in singapore"
                />
                <div className={activitySearchPageStyle.content}>
                  <h6 className="text-primary">Singapore 5 Night 6 Days</h6>
                  <p className="small">
                    Once registered login credentials will be sent to the
                    registered email .
                  </p>
                  <p className="text-muted small fw-bold mt-3">
                    Enquire Now{" "}
                    <i className="fa-solid fa-arrow-right-long ms-2"></i>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className={activitySearchPageStyle.packageItem}>
                <img
                  src={slideFour}
                  className="img-fluid"
                  alt="things to do in bali"
                />
                <div className={activitySearchPageStyle.content}>
                  <h6 className="text-primary">Bali Tour</h6>
                  <p className="small">
                    Once registered login credentials will be sent to the
                    registered email .
                  </p>
                  <p className="text-muted small fw-bold mt-3">
                    Enquire Now{" "}
                    <i className="fa-solid fa-arrow-right-long ms-2"></i>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* partners Logo  */}
      <section className={activitySearchPageStyle.partnersLogo}>
        <div className="marquee">
          <div className="marquee-content">
            {[...marqueeLogo, ...marqueeLogo].map((logoItem) => {
              return (
                <>
                  <div className="marquee-item">
                    <img
                      className={`img-fluid ${activitySearchPageStyle.partnerLogoItem}`}
                      src={logoItem}
                      alt="destination logo"
                    />
                  </div>
                </>
              );
            })}
          </div>
        </div>
      </section>

      <div className="mb-5">
        {/* popular Packages */}
        <section className={activitySearchPageStyle.popularPackage}>
          <div className="container">
            {" "}
            <div className={` ${activitySearchPageStyle.heading}`}>
              <h5 className="">
                Things to do in
                <span className="text-primary fw-bold"> Dubai </span>
              </h5>
              {/* <span className="text-muted small">Popular Places to Visit</span> */}
            </div>
            <div className="row">
              {activityData.map((activity) => {
                return (
                  <div className="col-lg-3">
                    <FrontPageActivityCard activityData={activity} />
                  </div>
                );
              })}
            </div>
          </div>
        </section>
        {/* popular Packages */}
        <section className={activitySearchPageStyle.popularPackage}>
          <div className="container">
            {" "}
            <div className={`${activitySearchPageStyle.heading}`}>
              <h5 className="">
                Things to do in
                <span className="text-primary fw-bold"> Pattaya </span>
              </h5>
              {/* <span className="text-muted small">Popular Places to Visit</span> */}
            </div>
            <div className="row">
              {activityData.map((activity) => {
                return (
                  <div className="col-lg-3">
                    <FrontPageActivityCard activityData={activity} />
                  </div>
                );
              })}
            </div>
          </div>
        </section>
        {/* popular Packages */}
        <section className={activitySearchPageStyle.popularPackage}>
          <div className="container">
            {" "}
            <div className={`${activitySearchPageStyle.heading}`}>
              <h5 className="">
                Things to do in
                <span className="text-primary fw-bold"> Bangkok </span>
              </h5>
              {/* <span className="text-muted small">Popular Places to Visit</span> */}
            </div>
            <div className="row">
              {activityData.map((activity) => {
                return (
                  <div className="col-lg-3">
                    <FrontPageActivityCard activityData={activity} />
                  </div>
                );
              })}
            </div>
          </div>
        </section>
        {/* popular Packages */}
        <section className={activitySearchPageStyle.popularPackage}>
          <div className="container">
            {" "}
            <div className={`${activitySearchPageStyle.heading}`}>
              <h5 className="">
                Things to do in
                <span className="text-primary fw-bold"> Singapore </span>
              </h5>
              {/* <span className="text-muted small">Popular Places to Visit</span> */}
            </div>
            <div className="row">
              {activityData.map((activity) => {
                return (
                  <div className="col-lg-3">
                    <FrontPageActivityCard activityData={activity} />
                  </div>
                );
              })}
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default ActivitySearchPage;
