export interface ISignUp {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  phoneCode: string;
  isValid: boolean;
}

export const signUpTemplate = {
  firstName: "",
  lastName: "",
  phoneCode: "",
  phoneNumber: "",
  isValid: false,
};
