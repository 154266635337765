import moment from "moment";
import { Season } from "../activitiescommonfnb/types/common.interface";
import { formatDate } from "../functions/commonFx";
import { IGaps } from "../activitiescommonfnb/types/master.interface";

export const getDayOfYear = (date: string | Date) => {
  return moment(date).dayOfYear();
};

export const getYear = (date: string | Date) => {
  return moment(date).year();
};

export const getSeasonFirstDay = (date: string | Date, currentYear: number) => {
  return getYear(date) === currentYear ? getDayOfYear(date) : 1;
};

export const getSeasonEndDay = (date: string | Date, currentYear: number) => {
  const totalDaysInYear = getDayOfYear(`${currentYear}-12-31`);
  return getYear(date) === currentYear ? getDayOfYear(date) : totalDaysInYear;
};

export const uniqueYearsList = (seasons: Season[] | undefined) => {
  const years = new Set<number>();
  seasons?.forEach((season) => {
    years.add(moment(season.fromDate).year());
    years.add(moment(season.toDate).year());
  });
  return Array.from(years);
};

export const sortSeasons = (
  seasons: Season[],
  currentYear: number
): Season[] => {
  const filteredCurrentYearSeasons = seasons.filter((season) => {
    const startYear = getYear(season.fromDate);
    const endYear = getYear(season.toDate);
    return startYear <= currentYear && endYear >= currentYear;
  });

  const result = filteredCurrentYearSeasons.sort((a, b) =>
    moment(a.fromDate).diff(moment(b.fromDate))
  );

  return result;
};

export const seasonBarJSX = (
  startDay: number,
  endDay: number,
  totalDaysInYear: number,
  season: Season
) => {
  const startPercentage = (startDay / totalDaysInYear) * 100;
  const endPercentage = (endDay / totalDaysInYear) * 100;
  return (
    <div
      key={season?.id}
      className="season-bar"
      style={{
        left: `${startPercentage}%`,
        width: `${endPercentage - startPercentage}%`,
        backgroundColor: season.id
          ? season.id % 2 === 0
            ? "#17a2b8"
            : "#28a745"
          : "#17a2b8",
      }}
      title={` ${season.name} From: ${formatDate(
        season.fromDate
      )} To: ${formatDate(season.toDate)}`}
    >
      {season.name}
    </div>
  );
};

export const getGap = (currentYear: number, gap: IGaps): string => {
  const startDate = moment().year(currentYear).dayOfYear(gap.startDay);
  const endDate = moment().year(currentYear).dayOfYear(gap.endDay);
  const formattedStartDate = formatDate(startDate.toDate());
  const formattedEndDate = formatDate(endDate.toDate());

  return `${formattedStartDate} To ${formattedEndDate}`;
};
