import { Button } from "react-bootstrap";

export const AppButton: React.FC<{
  variant?: "primary" | "secondary";
  size?: "sm" | "lg";
  onClick: () => void;
  children: React.ReactNode;
}> = (props) => {
  return (
    <Button
      variant={props.variant}
      size={props.size || "sm"}
      onClick={props.onClick}
    >
      {props.children}
    </Button>
  );
};
