import { useEffect, useState } from "react";
import { CreateOrEditStaffPopup } from "./CreateOrEditStaffPopup";
import { TCreateUserZod } from "../../activitiescommonfnb/schemas/user.schema";
import { IUserData } from "../../activitiescommonfnb/types/User.interface";
import manageStaffPageStyles from "./manageStaffPageStyles.module.scss";
import { activityAxios } from "../../axios/activityAxios";
import { toast } from "react-toastify";

const ManageStaffSkelton = () => {
  return (
    <div className="col-12 py-1">
      <p className="placeholder-glow">
        <span className="placeholder col-12"></span>
      </p>
    </div>
  );
};

export const ManageStaff = () => {
  const [showCreateOrEditStaff, setShowCreateOrEditStaff] = useState(false);
  const [selectedStaffId, setSelectedStaffId] = useState<number>();
  const [staffs, setStaffs] = useState<IUserData[]>([]);
  const [loading, setLoading] = useState(false);

  const getAllStaff = async () => {
    setLoading(true);
    //api call to get all staffs
    const apiRes = await activityAxios.post("/user/getAllStaff", {});
    if (apiRes.data.success) setStaffs(apiRes.data.result);
    else toast.error(apiRes.data.errorMessage || "Something went wrong");
    setLoading(false);
  };
  useEffect(() => {
    getAllStaff();
  }, []);
  return (
    <div className={manageStaffPageStyles.manageStaffPage}>
      <div className="container py-5">
        <div className="d-flex justify-content-between pb-3">
          <span>
            <b>Manage Staff</b>
          </span>
          <span>
            <button
              className="btn btn-primary"
              onClick={() => {
                //   setSelectedStaff(null);
                setShowCreateOrEditStaff(true);
              }}
            >
              +Add staff
            </button>
          </span>
        </div>
        <div className="row bg-light mb-2 p-2 border rounded">
          <div className="col-3 small">Staff Name</div>
          <div className="col-3 text-center small">Staff Email</div>
          <div className="col-3 text-center small">Staff Phone</div>
          <div className="col-3 text-end small">Edit</div>
        </div>
        {staffs.length > 0 ? (
          staffs.map((staff) => {
            return (
              <div className="row border-bottom p-2" key={staff.id}>
                <div className="col-3">
                  {staff.firstName} {staff.lastName}
                </div>
                <div className="col-3 text-center">{staff.email}</div>
                <div className="col-3 text-center">
                  {staff.phoneCode} {staff.phoneNumber}
                </div>
                <div className="col-3 text-end">
                  <button
                    className="btn"
                    onClick={() => {
                      //set selected staff id
                      //open modal
                    }}
                  >
                    <i className="fa-solid fa-pen-to-square"></i>
                  </button>
                </div>
              </div>
            );
          })
        ) : loading ? (
          <>
            <ManageStaffSkelton />
            <ManageStaffSkelton />
            <ManageStaffSkelton />
            <ManageStaffSkelton />
          </>
        ) : (
          <p className="text-danger text-center small">No Staff Found</p>
        )}
        {showCreateOrEditStaff && (
          <CreateOrEditStaffPopup
            staffId={selectedStaffId}
            //   staffDetails={selectedStaff}
            show={showCreateOrEditStaff}
            onHide={() => {
              setShowCreateOrEditStaff(false);
            }}
          />
        )}
      </div>
    </div>
  );
};
