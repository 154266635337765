import React, { useState, useEffect } from "react";
import { Alert } from "react-bootstrap";
import moment from "moment";
import _ from "lodash";
import "./seasonalTimelineStyles.css";
import { SeasonalTimelineProps } from "../../../activitiescommonfnb/types/common.interface";
import {
  getDayOfYear,
  sortSeasons,
  seasonBarJSX,
  uniqueYearsList,
  getGap,
  getSeasonFirstDay,
  getSeasonEndDay,
} from "../../../helper/adminHelper";
import {
  IGaps,
  ILines,
} from "../../../activitiescommonfnb/types/master.interface";

const SeasonalTimeline: React.FC<SeasonalTimelineProps> = ({ seasons }) => {
  const [currentYear, setCurrentYear] = useState(moment().year());
  const [availableYears, setAvailableYears] = useState<number[]>([]);

  useEffect(() => {
    const years = uniqueYearsList(seasons);
    setAvailableYears(years);
  }, [seasons]);

  if (!seasons || seasons.length === 0) {
    return <div className="ms-3">No seasons data available</div>;
  }

  const totalDaysInYear = getDayOfYear(`${currentYear}-12-31`);
  const sortedSeasons = sortSeasons(seasons, currentYear);
  const lines: ILines[] = [];
  const gaps: IGaps[] = [];
  let previousSeasonEndDay = 0;

  sortedSeasons.forEach((season) => {
    const startDay = getSeasonFirstDay(season.fromDate, currentYear);
    const endDay = getSeasonEndDay(season.toDate, currentYear);
    const seasonBar = seasonBarJSX(startDay, endDay, totalDaysInYear, season);

    let placed = false;
    for (const line of lines) {
      if (startDay > line.endDay) {
        line.bars.push(seasonBar);
        line.endDay = endDay;
        placed = true;
        break;
      }
    }

    if (!placed) {
      lines.push({ endDay, bars: [seasonBar] });
    }
    previousSeasonEndDay = Math.max(previousSeasonEndDay, endDay);
  });

  for (let day = 1; day <= totalDaysInYear; day++) {
    const isCovered = sortedSeasons.some((season) => {
      const startDay = getSeasonFirstDay(season.fromDate, currentYear);
      const endDay = getSeasonEndDay(season.toDate, currentYear);
      return day >= startDay && day <= endDay;
    });

    if (!isCovered) {
      if (gaps.length === 0 || gaps[gaps.length - 1].endDay !== day - 1) {
        gaps.push({ startDay: day, endDay: day });
      } else {
        gaps[gaps.length - 1].endDay = day;
      }
    }
  }

  const handleNextYear = () => {
    if (availableYears.includes(currentYear + 1)) {
      setCurrentYear(currentYear + 1);
    }
  };

  const handlePreviousYear = () => {
    if (availableYears.includes(currentYear - 1)) {
      setCurrentYear(currentYear - 1);
    }
  };

  return (
    <div className="m-2">
      <div className="d-flex justify-content-between mb-2">
        <button
          className="btn btn-primary btn-sm small"
          onClick={handlePreviousYear}
          disabled={!availableYears.includes(currentYear - 1)}
        >
          Previous Year
        </button>
        <h5>{currentYear}</h5>
        <button
          className="btn btn-primary btn-sm small"
          onClick={handleNextYear}
          disabled={!availableYears.includes(currentYear + 1)}
        >
          Next Year
        </button>
      </div>
      <div className="timeline">
        <div className="line">
          <div className="marker">1</div>
          <div className="marker">{totalDaysInYear}</div>
        </div>
        <div className="bars">
          {lines.map((line, index) => (
            <div key={index} className="line-container">
              {line.bars}
            </div>
          ))}
        </div>
      </div>
      <Alert variant="warning">
        Warning: There are gaps in the season timeline
        {gaps?.map((gap, index) => (
          <li key={index}>{`Gap from: ${getGap(currentYear, gap)}`}</li>
        ))}
      </Alert>
    </div>
  );
};

export default SeasonalTimeline;
