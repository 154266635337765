import { useParams } from "react-router";
import { useEffect, useState } from "react";
import ManagePriceForm from "./ManagePriceForm";
import PriceChart from "./PriceChart";
import { activitySupplierAxios } from "../../axios/activitySupplierAxios";
import { IPriceChartData } from "../../activitiescommonfnb/types/supplier.interface";
import { TSeason } from "../../activitiescommonfnb/schemas/master.schema";
import { SeasonSelect } from "./ManagePriceTabs/SeasonSelect";
import { IManagePriceForm } from "../../activitiescommonfnb/types/master.interface";
import { SupplierPriceSchema } from "../../activitiescommonfnb/schemas/supplier.schema";
import { ZodHelperService } from "../../activitiescommonfnb/schemas/zodHelper.service";
import { toast } from "react-toastify";
import AdminTourOption from "../admin/Tours/AdminTourOptions/AdminTourOptions";
import { activityAxios } from "../../axios/activityAxios";
import { ITourOption } from "../../activitiescommonfnb/types/tour.interface";
import { EnumUser } from "../../types/tourSearch.interface";

export const ManagePrice: React.FC<{}> = () => {
  const { tourId } = useParams();
  const [selectedSeason, setSelectedSeason] = useState<TSeason>();
  const [priceChartData, setPriceChartData] = useState<IPriceChartData[]>([]);
  const [tourOptions, setTourOptions] = useState<ITourOption[]>([]);
  const [selectedTourOption, setSelectedTourOption] =
    useState<ITourOption | null>(null);

  const updateSupplierPrice = async (data: IManagePriceForm) => {
    const validatedData = SupplierPriceSchema.safeParse({
      ...data,
      tourOptionId: selectedTourOption?.id!,
      seasonId: selectedSeason?.id,
    });
    if (!validatedData.success) {
      const validationErrors = ZodHelperService.formatZodErrors(
        validatedData.error,
        "errors"
      );
      console.error(validationErrors);
      toast.error("Invalid Form Data. Please fill form values correctly");
    }

    const apiRes = await activitySupplierAxios.post(
      "/managePrice/saveSupplierPrices",
      {
        ...validatedData.data,
      }
    );
    if (apiRes.data.success) {
      getSupplierPrices(selectedTourOption?.id!);
      toast.success("Price updated sucessfully");
    }
  };

  const getTourOptions = async (tourId: number) => {
    const apiRes = await activityAxios.post(
      "/master/tour/getTourOptionsByTourId",
      {
        tourId: tourId,
      }
    );
    if (apiRes.data.success) {
      setTourOptions(apiRes.data.result);
      setSelectedTourOption(apiRes.data.result[0]);
    }
  };

  const getSupplierPrices = async (tourOptionId: number) => {
    const apiRes = await activitySupplierAxios.post(
      "/managePrice/getSupplierPrices",
      {
        tourOptionId: tourOptionId,
        seasonId: selectedSeason?.id,
      }
    );
    if (apiRes.data.success) {
      setPriceChartData(apiRes.data.result);
    }
  };

  const handleTourOptionClick = (tourOption: ITourOption) => {
    setSelectedTourOption(tourOption);
  };

  useEffect(() => {
    if (selectedSeason && selectedTourOption?.id) {
      getSupplierPrices(selectedTourOption?.id);
    }
  }, [selectedSeason, selectedTourOption]);

  useEffect(() => {
    if (tourId) {
      getTourOptions(parseInt(tourId));
    }
  }, [tourId]);

  useEffect(() => {
    if (selectedTourOption) {
      let tourOption = tourOptions.find(
        (tourOption) => tourOption.id === selectedTourOption.id
      );

      if (tourOption) setSelectedTourOption(tourOption);
    }
  }, [selectedTourOption]);

  return (
    <div className="container mt-5">
      <h3 className="mb-5 text-primary border p-4 rounded">
        <span className="text-decoration-underline"> Tour Option:</span>{" "}
        <span className="text-muted">
          {" "}
          {selectedTourOption?.tourOptionName}
        </span>
      </h3>
      <div className="d-flex col-lg-12 justify-content-between">
        <div className="col-lg-5">
          <SeasonSelect
            isRequired
            onChange={(selectedSeason) => {
              setSelectedSeason(selectedSeason);
            }}
            tourOptionId={selectedTourOption?.id!}
          />
          <ManagePriceForm getTourPrice={updateSupplierPrice} />
        </div>
        <div className="col-lg-6">
          <h6 className="border p-2 mt-4 text-primary">Tour Options List: </h6>
          <div
            className="border overflow-auto"
            style={{
              maxHeight: "300px",
            }}
          >
            {tourOptions?.map((tourOption) => (
              <div
                onClick={() => handleTourOptionClick(tourOption)}
                key={tourOption.id}
                role="button"
              >
                <AdminTourOption
                  tourOptionDetails={tourOption}
                  userType={EnumUser.SUPPLIER}
                  showOptions={false}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
      <h5 className="mt-5 mb-2 font-weight-bold ">Price Chart :</h5>
      <PriceChart data={priceChartData} />
    </div>
  );
};
