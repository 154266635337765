import React, { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { AppUploadInput } from "../../../commonUi/AppUploadInput";
import { LoadingButton } from "../../../commonUi/LoadingButton";
import ReactCrop, { Crop } from "react-image-crop";
import "react-image-crop/src/ReactCrop.scss";
import { activityAxios } from "../../../axios/activityAxios";
import { toast } from "react-toastify";
import { ListSkeleton } from "../../../commonUi/ListSkeleton";
import { ITourImage } from "../../../activitiescommonfnb/types/tour.interface";

const SingleTourImageView: React.FC<{
  imageUrl: string;
  imageId: number;
  getTourImages: () => void;
  tourId: number;
}> = (props) => {
  const [imageDeleteLoading, setImageDeleteLoading] = useState(false);

  const onDelete = async () => {
    setImageDeleteLoading(true);
    const deleteResponse = await activityAxios("/master/tour/deleteTourImage", {
      method: "POST",
      data: {
        // tourImageId: props.imageId,
        tourId: props.tourId,
        fileUrl: props.imageUrl,
      },
    });
    if (deleteResponse.data.success) {
      toast.success("delete Successfully");
      props.getTourImages();
    } else {
      toast.error(deleteResponse.data.errorMessage || "unable to delete image");
    }
    setImageDeleteLoading(false);
  };

  return (
    <div className="col-lg-3">
      <div className="border p-3 rounded mb-3 text-center">
        <img src={props.imageUrl} className="img-fluid" alt="" />
        <LoadingButton
          btnClass="btn-sm rounded btn-danger btn-sm mt-2 w-100 small"
          loading={imageDeleteLoading}
          onClick={onDelete}
        >
          Delete
        </LoadingButton>
      </div>
    </div>
  );
};
export const UploadTourImageModal: React.FC<{
  show: boolean;
  onClose: () => void;
  tourId: number | undefined;
  // onUpload: (image: Blob, imageNameWithExtension: string) => void;
}> = (props) => {
  const [selectedImageBlob, setSelectedImageBlob] = useState<Blob | null>(null);
  const [selectedImage, setSelectedImage] = useState<File>();
  const [uploadImageLoading, setuploadImageLoading] = useState(false);
  const [tourImages, setTourImages] = useState<ITourImage[]>([]);
  const [tourImagesLoading, setTourImagesLoader] = useState(false);
  // const [imageSrc, setImageSrc] = useState<string>("");
  const [crop, setCrop] = useState<Crop>({
    unit: "%",
    x: 50,
    y: 50,
    width: 30,
    height: 20,
  });
  //   const [imageRef, setImageRef] = useState<HTMLImageElement | null>(null);
  const [previewUrl, setPreviewUrl] = useState<string>("");
  const imgRef = useRef<HTMLImageElement | null>(null);
  const onSelectImage = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setSelectedImage(e.target.files[0]);
      // const reader = new FileReader();
      // reader.addEventListener("load", () => {
      //   setImageSrc(reader.result as string);
      // });
      // reader.readAsDataURL(e.target.files[0]);
    }
  };

  const onImageLoad = (image: HTMLImageElement) => {
    imgRef.current = image;
  };

  const onCropComplete = async (crop: Crop) => {
    if (imgRef.current && crop.width && crop.height) {
      const croppedImageUrl = await getCroppedImg(imgRef.current, crop);
      // setCroppedImage(croppedImageUrl);
    }
  };

  const getCroppedImg = async (image: HTMLImageElement, crop: Crop) => {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    var originWidth = crop.width * scaleX;
    var originHeight = crop.height * scaleY;
    var maxWidth = 1200,
      maxHeight = 1200 / (1 / 1);
    var targetWidth = originWidth,
      targetHeight = originHeight;
    if (originWidth > maxWidth || originHeight > maxHeight) {
      if (originWidth / originHeight > maxWidth / maxHeight) {
        targetWidth = maxWidth;
        targetHeight = Math.round(maxWidth * (originHeight / originWidth));
      } else {
        targetHeight = maxHeight;
        targetWidth = Math.round(maxHeight * (originWidth / originHeight));
      }
    }
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");
    ctx!.imageSmoothingQuality = "high";

    ctx!.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          reject(new Error("Canvas is empty"));
          return;
        }
        // blob.name = fileName;
        window.URL.revokeObjectURL(previewUrl);
        setPreviewUrl(window.URL.createObjectURL(blob));
        console.log("@@@@@@@\n\n", blob);
        setSelectedImageBlob(blob);
      }, "image/jpeg");
    });
  };

  const onUploadImage = async () => {
    setuploadImageLoading(true);
    const uploadResponse = await activityAxios("/master/tour/uploadTourImage", {
      method: "POST",
      data: {
        tourImage: selectedImageBlob,
        tourId: props.tourId,
        originalName: selectedImage?.name,
      },
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    if (uploadResponse.data.success) {
      toast.success("Upload Successfully");
      setSelectedImage(undefined);
      setPreviewUrl("");
      getTourImages();
    } else {
      toast.error("upload failed");
    }
    setuploadImageLoading(false);
  };

  const getTourImages = async () => {
    setTourImagesLoader(true);
    const imageResponse = await activityAxios("/master/tour/getTourImages", {
      method: "POST",
      data: {
        tourId: props.tourId,
      },
    });

    if (imageResponse.data.success) {
      setTourImages(imageResponse.data.result);
    }
    setTourImagesLoader(false);
  };

  useEffect(() => {
    getTourImages();
  }, []);
  return (
    <div>
      <Modal show={props.show} onHide={props.onClose} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>Upload Image</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {tourImagesLoading ? (
            <div className="row">
              <div className="col-lg-3">
                <ListSkeleton count={1} skeletonHeight="140px" />
              </div>
              <div className="col-lg-3">
                <ListSkeleton count={1} skeletonHeight="140px" />
              </div>
              <div className="col-lg-3">
                <ListSkeleton count={1} skeletonHeight="140px" />
              </div>
              <div className="col-lg-3">
                <ListSkeleton count={1} skeletonHeight="140px" />
              </div>
            </div>
          ) : (
            <div className="row">
              {tourImages.map((image) => (
                // <div className="col-lg-3">
                //   <img src={image.imageUrl} className="w-100 m-1" />
                //   <LoadingButton
                //     btnClass="btn-sm rounded btn-danger-outline"
                //     loading={deleteImageLoading}
                //     onClick={() => onDeleteImage(image.id)}
                //   >
                //     <i className="fa-solid fa-trash"></i>
                //   </LoadingButton>
                // </div>

                <SingleTourImageView
                  imageId={image.id}
                  imageUrl={image.fileUrl}
                  getTourImages={getTourImages}
                  tourId={props.tourId!}
                />
              ))}
            </div>
          )}
          {!tourImages.length && !tourImagesLoading && (
            <div className="text-danger text-center m-2">
              No Tour Images Found
            </div>
          )}

          <AppUploadInput
            onChange={(e) => {
              //   setSelectedImages(e.target.files);
              onSelectImage(e);
            }}
          />

          {selectedImage && (
            <div>
              <ReactCrop
                crop={crop}
                aspect={16 / 9}
                onComplete={onCropComplete}
                onChange={(c) => setCrop(c)}
                keepSelection
              >
                <img
                  src={URL.createObjectURL(selectedImage)}
                  // width={1920}
                  onLoad={(e) => onImageLoad(e.target as HTMLImageElement)}
                  alt=""
                />
              </ReactCrop>
            </div>
          )}
          <div className="w-100">
            {previewUrl && <img alt="Crop preview" src={previewUrl} />}
          </div>

          <LoadingButton
            loading={uploadImageLoading}
            btnClass="rounded btn-primary btn-sm mt-2"
            onClick={onUploadImage}
            disabled={!selectedImage}
          >
            {" "}
            Upload
          </LoadingButton>
        </Modal.Body>
      </Modal>
    </div>
  );
};
