import { useEffect, useRef, useState } from "react";
import notificationRingtone from "../assets/audio/notificationRingtone.mp3";
import { ITask } from "../activitiescommonfnb/types/task.interface";
import { activityAxios } from "../axios/activityAxios";
import { IJSONResponse } from "../activitiescommonfnb/types/common.interface";
import { useAppSelector } from "../store";
import { EnumUserType } from "../activitiescommonfnb/types/User.interface";

export const useTaskNotification = () => {
  const userData = useAppSelector((state) => state.authData.data);
  const [showTaskModal, setShowTaskModal] = useState(false);
  const [taskNotifications, setTaskNotications] = useState<ITask[]>([]);

  const notificationIntervalRef = useRef<NodeJS.Timer>();
  const audioElementRef = useRef<HTMLAudioElement>(
    new Audio(notificationRingtone)
  );

  const fetchNotifications = async () => {
    const apiResponse = await activityAxios.post<IJSONResponse<ITask[]>>(
      "/task/getTasksForNotification"
    );

    const newTaskNotifications = apiResponse.data.result;

    if (apiResponse.data.success && newTaskNotifications?.length) {
      setShowTaskModal(true);
      playNotification();
      setTaskNotications((old) => {
        newTaskNotifications.forEach((task) => {
          const taskAlreadyExists = old.find(
            (singleOldTask) => singleOldTask.id === task.id
          );

          if (!taskAlreadyExists) {
            old.push(task);
          }
        });

        return [...old];
      });
    }
  };

  const playNotification = () => {
    audioElementRef.current.play();
  };

  const pauseNotification = () => {
    audioElementRef.current.pause();
  };

  const dismissTaskNotification = (taskId: number) => {
    setTaskNotications((old) => {
      old = old.filter((task) => task.id !== taskId);
      return [...old];
    });

    if (taskNotifications.length === 1 && taskNotifications[0].id === taskId) {
      pauseNotification();
      setShowTaskModal(false);
    }
  };

  const snoozeAllTaskNotifications = () => {
    pauseNotification();
    setShowTaskModal(false);
  };

  useEffect(() => {
    if (
      !notificationIntervalRef.current &&
      userData &&
      userData.userType === EnumUserType.STAFF
    ) {
      fetchNotifications();
      notificationIntervalRef.current = setInterval(
        fetchNotifications,
        60 * 1000
      );
    }

    if (notificationIntervalRef.current && !userData) {
      clearInterval(notificationIntervalRef.current);
      notificationIntervalRef.current = undefined;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);

  return {
    showTaskModal,
    taskNotifications,
    dismissTaskNotification,
    snoozeAllTaskNotifications,
  };
};
