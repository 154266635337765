import React from "react";

export const ListSkeleton: React.FC<{
  count: number;
  skeletonHeight?: string;
  skeletonWidth?: string;
  containerClassName?: string;
}> = (props) => {
  const printSekeletons = () => {
    const skeletons = new Array(props.count).fill(0);
    return skeletons.map((skeleton, index) => (
      <div
        key={`skeleton-${index}`}
        className={`list-skeleton mb-3 ${props.containerClassName}`}
      >
        <p className="placeholder-wave">
          <span
            className="placeholder col-12"
            style={{
              height: props.skeletonHeight || "80px",
              width: props.skeletonWidth || "100%",
            }}
          ></span>
        </p>
      </div>
    ));
  };

  return <React.Fragment>{printSekeletons()}</React.Fragment>;
};
