import { Form, Modal } from "react-bootstrap";
import { AppButton } from "../../../../commonUi/AppButton";
import { Controller, useForm } from "react-hook-form";
import {
  TTourOption,
  tourOptionZod,
} from "../../../../activitiescommonfnb/schemas/tour.schema";
import { zodResolver } from "@hookform/resolvers/zod";
import { activityAxios } from "../../../../axios/activityAxios";
import { IJSONResponse } from "../../../../activitiescommonfnb/types/common.interface";
import { toast } from "react-toastify";
import { useEffect } from "react";
import { AppControlInput, AppInput } from "../../../../commonUi/AppInput";
import AppCkEditor from "../../../../commonUi/AppCkEditor";
import {
  convertToUnixTimeStamp,
  convertUnixToTimeString,
} from "../../../../helper/tourHelper";

type TWeekDay =
  | "sunday"
  | "monday"
  | "tuesday"
  | "wednesday"
  | "thrusday"
  | "friday"
  | "saturday";

const weekDays: Array<{
  day: TWeekDay;
  short: string;
}> = [
  { day: "sunday", short: "Sun" },
  { day: "monday", short: "Mon" },
  { day: "tuesday", short: "Tue" },
  { day: "wednesday", short: "Wed" },
  { day: "thrusday", short: "Thu" },
  { day: "friday", short: "Fri" },
  { day: "saturday", short: "Sat" },
];

const defaultFormValues: Partial<TTourOption> = {
  isHourly: false,
  hasTourGuide: false,
  monday: true,
  tuesday: true,
  wednesday: true,
  thrusday: true,
  friday: true,
  saturday: true,
  sunday: true,
  hasSlot: false,
};

export const CreateOrEditTourOption: React.FC<{
  id?: number;
  tourId: number;
  show: boolean;
  onSave: () => void;
  onClose: () => void;
}> = (props) => {
  const { watch, setValue, handleSubmit, control, reset } = useForm({
    resolver: zodResolver(tourOptionZod),
    defaultValues: defaultFormValues,
  });

  const tourOptionState = watch();

  const getTourOptionById = async (id: number) => {
    const apiRes = await activityAxios.post<IJSONResponse<TTourOption>>(
      "/master/tour/getTourOptionByTourOptionId",
      {
        tourOptionId: id,
      }
    );

    if (apiRes.data.success && apiRes.data.result) {
      reset(apiRes.data.result);
    }
  };

  const saveTourOptionHandler = async (
    tourOptionDetails: Partial<TTourOption>
  ) => {
    if (props.id) {
      tourOptionDetails.id = props.id;
    }

    const apiRes = await activityAxios.post<IJSONResponse>(
      "/master/tour/createOrEditTourOption",
      {
        tourOptionDetails: tourOptionDetails,
      }
    );

    if (apiRes.data.success) {
      toast.success(
        `Tour Option ${props.id ? "Edited" : "Created"} Successfully.`
      );
    } else {
      toast.error(
        apiRes.data.errorMessage ||
          `Unable to ${props.id ? "edit" : "create"} tour option.`
      );
    }

    props.onSave();
  };

  useEffect(() => {
    if (props.id) {
      getTourOptionById(props.id);
    }

    setValue("tourId", props.tourId);
  }, []);

  return (
    <Modal centered size="lg" show={props.show} onHide={props.onClose}>
      <Modal.Header closeButton>
        <Modal.Title>{props.id ? "Edit " : "Add New "} Tour Option</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="mb-2">
          <div>Week Days Availability: </div>
          <div className="d-flex">
            {weekDays.map((singleDay) => {
              const isChecked = tourOptionState[singleDay.day];
              return (
                <span
                  key={singleDay.day}
                  className={`border mx-1 rounded-pill flex-grow-1 text-center p-2 ${
                    isChecked ? "bg-primary text-white" : ""
                  }`}
                  onClick={() => {
                    setValue(singleDay.day, !isChecked);
                  }}
                  role="button"
                >
                  {singleDay.short}
                </span>
              );
            })}
          </div>
        </div>
        <div className="row">
          <AppControlInput
            label="Tour Option Name"
            control={control}
            name="tourOptionName"
            containerClassName="col-12"
            isRequired
          />

          <Controller
            control={control}
            name="durationInMinutes"
            render={({ field: { onChange, value } }) => (
              <AppInput
                label="Duration"
                type="time"
                fieldName="duration"
                containerClassName="col-6 mt-2"
                isRequired
                onChange={(_, value) => {
                  const duration = convertToUnixTimeStamp(value);
                  onChange(duration);
                }}
                value={value ? convertUnixToTimeString(value) : null}
              />
            )}
          />
          <AppControlInput
            control={control}
            isRequired
            label="Timezone"
            name="timezone"
            containerClassName="col-6 mt-2"
          />
          <AppControlInput
            label="Child Age"
            name="childAge"
            containerClassName="col-6 mt-2"
            control={control}
            isRequired
          />
          <AppControlInput
            label="Infant Age"
            name="infantAge"
            containerClassName="col-6 mt-2"
            control={control}
            isRequired
          />
          <Controller
            control={control}
            name="startTime"
            render={({ field: { onChange, value } }) => (
              <AppInput
                label="Start Time"
                type="time"
                fieldName="startTime"
                containerClassName="col-6 mt-2"
                isRequired
                onChange={(_, value) => {
                  const startTime = convertToUnixTimeStamp(value);
                  onChange(startTime);
                }}
                value={value ? convertUnixToTimeString(value) : null}
              />
            )}
          />
          <Controller
            control={control}
            name="buffer"
            render={({ field: { onChange, value } }) => (
              <AppInput
                label="Buffer"
                type="time"
                fieldName="buffer"
                containerClassName="col-6 mt-2"
                isRequired
                onChange={(_, value) => {
                  const buffer = convertToUnixTimeStamp(value);
                  console.log(value, "buffer");
                  onChange(buffer);
                }}
                value={value ? convertUnixToTimeString(value) : null}
              />
            )}
          />
          <div className="col-12 mt-3">Tour Options</div>
          <div className="col-4">
            <Form.Check
              type="checkbox"
              id="hasTourGuide"
              checked={tourOptionState.hasTourGuide}
              onChange={(e) => {
                setValue("hasTourGuide", e.target.checked);
              }}
              label="Has Tour Guide?"
            />
          </div>
          <div className="col-4">
            <Form.Check
              type="checkbox"
              id="isHourly"
              label="Houry Tour?"
              checked={tourOptionState.isHourly}
              onChange={(e) => {
                setValue("isHourly", e.target.checked);
              }}
            />
          </div>
          <div className="col-4">
            <Form.Check
              type="checkbox"
              id="hasSlot"
              label="Has Slot?"
              checked={tourOptionState.hasSlot}
              onChange={(e) => {
                setValue("hasSlot", e.target.checked);
              }}
            />
          </div>

          <AppCkEditor
            className="mt-2"
            isRequired
            label="Tour Option Description"
            value={tourOptionState.tourOptionDescription}
            onChange={(value) => setValue("tourOptionDescription", value)}
          />
          <AppCkEditor
            className="mt-2"
            isRequired
            label="Cancellation Policy Description"
            value={tourOptionState.cancellationPolicyDescription}
            onChange={(value) =>
              setValue("cancellationPolicyDescription", value)
            }
          />
          <AppCkEditor
            className="mt-2"
            isRequired
            label="Child Policy Description"
            value={tourOptionState.childPolicyDescription}
            onChange={(value) => setValue("childPolicyDescription", value)}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <AppButton onClick={props.onClose} variant="secondary">
          Close
        </AppButton>

        <AppButton
          onClick={handleSubmit(saveTourOptionHandler)}
          variant="primary"
        >
          Save
        </AppButton>
      </Modal.Footer>
    </Modal>
  );
};
