import React from "react";

export const AppProgressBar: React.FC<{
  color?: string;
  percentage: number;
}> = (props) => {
  const progressStyle = {
    width: `${props.percentage}%`,
    marginRight: " 10px",
    height: "6px",
    backgroundColor: props.color,
    color: "white",
    // textAlign: "center",
    borderRadius: 10,
    transition: "width 0.5s ease-in-out",
  };
  const progressContainerStyle = {
    backgroundColor: "#f1f1f1",
    borderRadius: 10,
  };
  return (
    <div className="progress-bar mb-2" style={progressContainerStyle}>
      <div className="progress" style={progressStyle}></div>
    </div>
  );
};

export default AppProgressBar;
