import { Placeholder } from 'react-bootstrap';

const FormSkeletonLoader = () => {
  return (
    <div>
      <div className="mb-3">
        <Placeholder as="p" animation="glow">
          <Placeholder xs={6} /> 
        </Placeholder>
        <Placeholder as="div" animation="glow">
          <Placeholder xs={12} style={{ height: '38px' }} />
        </Placeholder>
      </div>
      <div className="mb-3">
        <Placeholder as="p" animation="glow">
          <Placeholder xs={6} />
        </Placeholder>
        <Placeholder as="div" animation="glow">
          <Placeholder xs={12} style={{ height: '38px' }} />
        </Placeholder>
      </div>
      <div className="mb-3">
        <Placeholder as="p" animation="glow">
          <Placeholder xs={6} />
        </Placeholder>
        <Placeholder as="div" animation="glow">
          <Placeholder xs={12} style={{ height: '38px' }} />
        </Placeholder>
      </div>

    </div>
  );
};

export default FormSkeletonLoader;
