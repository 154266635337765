import { activityAxios } from "../../../axios/activityAxios";
import { IJSONResponse } from "../../../activitiescommonfnb/types/common.interface";
import { IUserData } from "../../../activitiescommonfnb/types/User.interface";
import { AppAsyncSelect } from "../../../commonUi/AppSelect";

export const UserSelect: React.FC<{
  containerClassName?: string;
  labelClassName?: string;
  label?: string;
  value?: IUserData | null;
  onChange?: (user: IUserData | null) => void;
  onlyStaff?: boolean;
}> = (props) => {
  const searchUser = async (searchQuery: string) => {
    const apiRes = await activityAxios.post<IJSONResponse<IUserData[]>>(
      "/user/search",
      {
        searchQuery: searchQuery,
        onlyStaff: props.onlyStaff,
      }
    );

    return apiRes.data.result || [];
  };

  return (
    <div className={props.containerClassName}>
      {props.label && (
        <label htmlFor="user" className={props.labelClassName}>
          {props.label}
        </label>
      )}
      <AppAsyncSelect
        loadOptions={searchUser}
        isMulti={false}
        value={props.value}
        isClearable
        onChange={props.onChange}
        getOptionLabel={(user) => {
          return `${user.firstName} ${user.lastName} ${user.phoneCode}-${user.phoneNumber}`;
        }}
      />
    </div>
  );
};
