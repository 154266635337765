import React from "react";
export const MainLoader: React.FC<{
  loading: boolean;
  height?: string;
  width?: string;
}> = (props) => {
  return (
    <React.Fragment>
      {props.loading ? (
        <div
          style={{
            width: props.width || "100vw",
            height: props.height || "100vh",
            opacity: "0.7",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div className="loadingio-spinner-pulse-wjymhpuiss">
            <div className="ldio-e63ka05htst">
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        </div>
      ) : null}
    </React.Fragment>
  );
};
