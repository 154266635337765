import { z } from "zod";
import { EnumPaxType } from "./booking.schema";
import { EnumEnquiryStatus } from "../types/enquiry.interface";
import { IUserData } from "../types/User.interface";
import { ZodHelperService } from "./zodHelper.service";
import { rejectionStatuses } from "../../helper/enquiryHelper";

z.setErrorMap(ZodHelperService.errorMap);

export const enquiryPassengerSchema = z.object({
  // id: z.number().optional(),
  paxType: z.nativeEnum(EnumPaxType),
  age: z.number().optional().nullable(),
});

export type TEnquiryPassenger = z.infer<typeof enquiryPassengerSchema>;

export const enquiryDestinationSchema = z.object({
  // id: z.number().optional(),
  destinationId: z.number(),
});

export type TEnquiryDestination = z.infer<typeof enquiryDestinationSchema>;

export const createOrEditEnquirySchema = z.object({
  id: z.number().optional(),
  userId: z.number().optional(),
  leadPassengerName: z.string(),
  userName: z.string(),
  title: z.string(),
  remark: z.string(),
  internalRemark: z.string().optional().nullable(),
  phoneCode: z.string(),
  phoneNumber: z.string(),
  // createdByStaffId: z.number(),
  passengers: z.array(enquiryPassengerSchema),
  destinations: z.array(enquiryDestinationSchema).min(1),
});

export type TCreateOrEditEnquiry = z.infer<typeof createOrEditEnquirySchema>;

export const updateEnquirySchema = z
  .object({
    status: z.nativeEnum(EnumEnquiryStatus),
    assignedToStaffId: z.number().nullable(),
    rejectionMessage: z.string().nullable(),
  })
  .superRefine((updateEnquiryObj, ctx) => {
    //checking if the status is one of the 3 rejection
    if (
      updateEnquiryObj.status &&
      rejectionStatuses.includes(updateEnquiryObj.status)
    ) {
      //checking if the rejection message is not empty
      if (
        !updateEnquiryObj.rejectionMessage ||
        updateEnquiryObj.rejectionMessage.trim().length === 0
      ) {
        ctx.addIssue({
          code: "custom",
          path: ["rejectionMessage"],
          message: `Rejection message can't be empty`,
        });
      }
    } else {
      //if the status is not from rejection statues then rejection message should be empty
      updateEnquiryObj.rejectionMessage = null;
    }
  });

export type TUpdateEnquiry = z.infer<typeof updateEnquirySchema>;

export const createOrEditEnquiryNotesSchema = z.object({
  id: z.number().optional(),
  enquiryId: z.number().optional().nullable(),
  message: z.string(),
});

export type TcreateOrEditEnquiryNotes = z.infer<
  typeof createOrEditEnquiryNotesSchema
>;
export enum EnumFollowUpMode {
  MESSAGE = "message",
  CALL = "call",
}

export const createOrEditEnquiryFollowUpSchema = z.object({
  id: z.number().optional(),
  enquiryId: z.number(),
  followUpMessage: z.string(),
  staffId: z.number().optional(),
  followUpMode: z.nativeEnum(EnumFollowUpMode),
});

type TCreateOrEditEnquiryFollowUp = z.infer<
  typeof createOrEditEnquiryFollowUpSchema
>;

export interface IEnquiryFollowUp extends TCreateOrEditEnquiryFollowUp {
  createdAt?: Date;
  updatedAt?: string;
  createdByStaffDetail?: IUserData;
}

export type TFilterState = {
  status?: EnumEnquiryStatus;
  searchId?: number;
  assignedToStaffId?: number;
  searchByUserId?: number;
  staledEnquiriesDays?: number;
  unassigned?: boolean;
  searchTitle?: string;
  leadPassenger?: string;
  datefilter?: number;
};
