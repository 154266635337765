import React, { ChangeEvent, HTMLInputTypeAttribute, useState } from "react";
import { ErrorMessage } from "./ErrorMessage";

export const AppUploadInput: React.FC<{
  id?: string;
  label?: string;
  containerClassName?: string;
  isRequired?: boolean;

  value?: string;
  errorMessage?: string | null;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
}> = (props) => {
  return (
    <div>
      <div className={props.containerClassName}>
        {props.label && (
          <label
            htmlFor={props.id}
            className={`${props.isRequired ? "required" : ""}`}
          >
            {props.label}
          </label>
        )}
        <input
          id={props.id}
          //   name={props.fieldName}
          type="file"
          onChange={props.onChange}
          value={props.value}
          className="form-control"
          accept="image/*"
        />
        <ErrorMessage errorMessage={props.errorMessage} />
      </div>
    </div>
  );
};
