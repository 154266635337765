import { useEffect, useState } from "react";
import { IJSONResponse } from "../../../activitiescommonfnb/types/common.interface";
import { activityAxios } from "../../../axios/activityAxios";
import { TSeason } from "../../../activitiescommonfnb/schemas/master.schema";
import { AppSelect } from "../../../commonUi/AppSelect";

export const SeasonSelect: React.FC<{
  className?: string;
  defaultSeasonId?: number;
  isRequired?: boolean;
  onChange: (season: TSeason | undefined) => void;
  tourOptionId: number;
}> = (props) => {
  const [seasons, setSeasons] = useState<Required<TSeason>[]>([]);
  const [selectedSeason, setSelectedSeason] = useState<Required<TSeason>>();

  const getAllSeasons = async () => {
    const apiRes = await activityAxios.post<IJSONResponse>(
      "/master/getSeasonsByTourOptionId",
      {
        tourOptionId: props.tourOptionId,
      }
    );
    if (apiRes.data.success && apiRes.data.result) {
      setSeasons(apiRes.data.result);
    }
  };

  useEffect(() => {
    getAllSeasons();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.tourOptionId]);

  useEffect(() => {
    if (props.defaultSeasonId && !selectedSeason) {
      setSelectedSeason(seasons.find((s) => s.id === props.defaultSeasonId));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.defaultSeasonId, seasons]);

  return (
    <AppSelect
      label="Select Season"
      required={props.isRequired}
      containerClassName={props.className}
      value={
        selectedSeason
          ? {
              label: selectedSeason.name,
              value: selectedSeason.id,
              season: selectedSeason,
            }
          : undefined
      }
      onChange={(data) => {
        let selectedSeason: Required<TSeason> | undefined = undefined;

        if (data) {
          selectedSeason = data.season;
        }

        setSelectedSeason(selectedSeason);

        props.onChange(selectedSeason);
      }}
      options={seasons.map((season) => {
        return {
          label: season.name,
          value: season.id,
          season: season,
        };
      })}
    />
  );
};
