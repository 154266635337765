import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export const ReactDatePicker: React.FC<{
  handleDateChange: (date: Date) => void;
  selected?: Date | null | undefined;
  className?: string;
  timeOnly?: boolean;
  minDate?: Date | null;
  maxDate?: Date | null;
  timeInterval?: number;
}> = (props) => {
  return (
    <DatePicker
      timeIntervals={props.timeInterval}
      showTimeSelectOnly={props.timeOnly}
      showTimeSelect={props.timeOnly}
      minDate={props.minDate}
      maxDate={props.maxDate}
      dateFormat={props.timeOnly ? "h:mm aa" : "dd/MM/YYYY"}
      wrapperClassName="w-100"
      className={`form-control ${props.className}`}
      selected={props.selected}
      onChange={props.handleDateChange}
    />
  );
};
