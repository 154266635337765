import { combineReducers } from "redux";
import siteDataReducer from "./siteDataSlice";
import { authSlice } from "./auth.slice";
import supplierAuthreducer from "./supplierAuth.slice";
import activitySessionReducer from "./session.slice";
import tourResultReducer from "./tourResult.slice";

const rootReducer = combineReducers({
  siteData: siteDataReducer,
  authData: authSlice.reducer,
  supplierAuthData: supplierAuthreducer,
  activitySession: activitySessionReducer,
  tourResult: tourResultReducer,
});

export default rootReducer;
