import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { IModuleUrls } from "../types/websiteData.interface";

const initialState: {
  data: any;
  moduleUrls: IModuleUrls | null;
} = {
  data: null,
  moduleUrls: null,
};

export const siteSlice = createSlice({
  name: "site",
  initialState,
  reducers: {
    setSiteData: (state, action: PayloadAction<any>) => {
      localStorage.setItem("siteData", JSON.stringify(action.payload));
      state.data = action.payload;
    },

    setModuleUrls: (state, action: PayloadAction<IModuleUrls | null>) => {
      state.moduleUrls = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setSiteData, setModuleUrls } = siteSlice.actions;

export const selectSiteData = (state: RootState) => state.siteData.data;

export default siteSlice.reducer;
