import React from "react";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.scss";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MainHeader from "./components/common/MainHeader";
import Footer from "./components/common/Footer";
import { useInitApp } from "./hooks/useInitApp";
import { Outlet } from "react-router-dom";
import { MainLoader } from "./commonUi/MainLoader";
import { TaskNotificationModal } from "./components/Tasks/TaskNotificationModal";

const App: React.FC = () => {
  const loading = useInitApp();

  const nonHeaderPaths = [
    "/activity/admin/master",
    "/activity/supplier/login",
    "/activity/supplier/dashboard",
    "/activity/supplier/createorblocksupplier",
    "/activity/supplier/generatepassword",
    "/activity/supplier/manageprice",
  ];
  const nonFooterPaths = [
    "/activity/supplier/login",
    "/activity/supplier/dashboard",
    "/activity/supplier/createorblocksupplier",
    "/activity/supplier/generatepassword",
    "/activity/supplier/manageprice",
  ];

  const donotIncludeHeader = nonHeaderPaths.some((path) =>
    window.location.pathname.includes(path)
  );
  const donotIncludeFooter = nonFooterPaths.some((path) =>
    window.location.pathname.includes(path)
  );

  return (
    <React.Fragment>
      {loading ? (
        <MainLoader loading={loading} />
      ) : (
        <React.Fragment>
          <ToastContainer />
          {!donotIncludeHeader && <MainHeader />}
          <Outlet />
          {!donotIncludeFooter && <Footer />}
          <TaskNotificationModal />
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default App;
